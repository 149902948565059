import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";

import { ApiUrl, ProductName } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import { dcrypt, bcrypt, uToken } from "../../../useToken";
import CampaignReportingNav from "./nav";
import { Modal } from "react-bootstrap";

export default class CampaignReportSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            campaign_id: 0,
            page_name: "Report",
            filter: {},
            record: {},
            showModal: false,
            records: [],
            total_pages: 0,
            series: [],
            campaigns: [],
            master_type: '0',
            type: '',
        };
    }


    componentDidMount() {
        this.getData();
        this.getCampaigns();
    }

    getCampaignsState = (campaign_id) => {
        this.setState({ campaign_id: campaign_id });
        this.getSeries(0, 0);
    }

    getData = (queryString = "", data) => {
        fetch(`${ApiUrl}` + `report/summary` + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        total_pages: response.data.total,
                        records: response.data.records,
                    });
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    handleFilterSubmit = async (event, values) => {
        this.setState({
            filter: values,
        });
        this.getData("", values);
    };

    cancelFilter = async () => {
        this.form && this.form.reset();
        this.setState({ filter: {} });
        this.getData("", "");
    };

    tableChangeHandler = (data) => {
        let queryString = Object.keys(data)
            .map((key) => {
                if (key === "sort_order" && data[key]) {
                    return (
                        encodeURIComponent("sort_order") +
                        "=" +
                        encodeURIComponent(data[key].order) +
                        "&" +
                        encodeURIComponent("sort_column") +
                        "=" +
                        encodeURIComponent(data[key].column)
                    );
                } else {
                    return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
                }
            })
            .join("&");
        this.getData("?" + queryString, this.state.filter);
    };


    getSeries = (master_type, id) => {
        fetch(`${ApiUrl}` + `campaign/series/report/${id}/${master_type}/0`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {
                    this.setState({
                        series: response.data.records,
                        master_type: master_type,
                    });

                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    getCampaigns = () => {
        fetch(`${ApiUrl}` + `get/campaigns`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {
                    console.log(response, 'debuggingCampaigns');
                    this.setState({
                        campaigns: response.data.records,
                    });

                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }


    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.state.page_name}
                        Tables
                    </title>
                </Helmet>

                <Row>
                    <Col sm={5}>
                        <div className="page-title-box">
                            <h4>{this.state.page_name}</h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#">{this.state.page_name}</Link>
                                </li>
                            </ol>
                        </div>
                    </Col>
                </Row>

                <ToastContainer />


                <Row>
                    <Col className="col-12">
                        <Card>
                            <CampaignReportingNav activeTab={'1'} campaignId={bcrypt(this.state.campaign_id)} />

                            <CardBody className="py-0">
                                <AvForm
                                    onValidSubmit={this.handleFilterSubmit}
                                    ref={(c) => (this.form = c)}
                                    className="needs-validation"
                                >
                                    <Row>
                                        <Col md="2">
                                            <div className="mb-0">
                                                <AvField
                                                    className="select form-select"
                                                    type="select"
                                                    name="campaign_id"
                                                    label="Report Type"
                                                    onChange={(e) =>
                                                        this.getCampaignsState(e.target.value)
                                                    }
                                                >
                                                    <optgroup key="all" label="All">
                                                        <option value=""> Select All</option>
                                                    </optgroup>
                                                    <optgroup key="manual" label="Non-Campaign">
                                                        <option value="-1"> Non Campaign Area</option>
                                                    </optgroup>
                                                    <optgroup key="campaigns" label="Campaigns">
                                                        {
                                                            this.state.campaigns.map((row, i) => {
                                                                return <option value={row.campaign_id}> {row.title} </option>
                                                            })
                                                        }
                                                    </optgroup>
                                                </AvField>
                                            </div>
                                        </Col>
                                        <Col md="2" className="px-0">
                                            <div className="mb-0">
                                                <AvField
                                                    className="select form-select"
                                                    type="select"
                                                    name="master_type"
                                                    label="Select Area"
                                                    onChange={(e) =>
                                                        this.getSeries(e.target.value, this.state.campaign_id)
                                                    }
                                                >
                                                    <option value="0"> All Time </option>
                                                    <option value="Scheduling">Broadcasts </option>
                                                    <optgroup label="Automation">
                                                        <option value="Autoresponder">Drips</option>
                                                        <option value="Eventreminder">Event Reminder</option>
                                                        <option value="Automation">Trigger</option>
                                                    </optgroup>
                                                    <option value="Manual">Manual</option>
                                                </AvField>
                                            </div>
                                        </Col>
                                        {
                                            (this.state.master_type != 0 && this.state.master_type != 'Manual') && (
                                                <Col md="2">
                                                    <div className="mb-0">
                                                        <AvField
                                                            className="select form-select"
                                                            type="select"
                                                            name="series_id"
                                                            label={`Select ${this.state.master_type}`}
                                                        >
                                                            <option value=""> All </option>
                                                            {
                                                                this.state.series &&
                                                                this.state.series.map((row, i) => {
                                                                    return <option value={row.series_id}>{row.type} #{row.series_id}</option>
                                                                })
                                                            }
                                                        </AvField>
                                                    </div>
                                                </Col>
                                            )
                                        }

                                        <Col md="1">
                                            <div className="mb-0">
                                                <AvField
                                                    className="select form-select"
                                                    type="select"
                                                    name="report_type"
                                                    label="Type"
                                                >
                                                    <option value=""> All </option>
                                                    <option value="sms">SMS </option>
                                                    <option value="rvm">RVM</option>
                                                    <option value="email">Email</option>
                                                    <option value="call">Call</option>
                                                </AvField>
                                            </div>
                                        </Col>

                                        <Col md="1" className="px-0">
                                            <div className="mb-0">
                                                <AvField
                                                    className="select form-select"
                                                    type="select"
                                                    name="flow"
                                                    label="Direction"
                                                >
                                                    <option value=""> All </option>
                                                    <option value="in">IN </option>
                                                    <option value="out">OUT</option>
                                                    <option value="system_out">SYSTEM OUT</option>
                                                </AvField>
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-0">
                                                <AvField
                                                    name="from_date"
                                                    label="From Date"
                                                    className="form-control"
                                                    placeholder="Enter Email Address"
                                                    type="date"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2" className="px-0">
                                            <div className="mb-0">
                                                <AvField
                                                    name="to_date"
                                                    label="To date"
                                                    className="form-control"
                                                    type="date"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-0">
                                                <label className="visibilty-hidden"> {"Action"}</label>
                                                <div className="button-items">

                                                    <Button className="btn-sm" color="primary" type="submit">
                                                        Filter
                                                    </Button>
                                                    <Button
                                                        onClick={() => this.cancelFilter()}
                                                        outline
                                                        color="secondary"
                                                        className="waves-effect btn-sm"
                                                        type="button"
                                                    >
                                                        Reset
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <div className="card summary-report-campaign">
                    <div className="card-body pt-0">
                        {
                            this.state.records.map((row, i) => {
                                return (
                                    <div className={`row`} key={'main_' + i}>
                                        <div className="row">
                                            <div className="col-md-12 report fw-bold bg-primary text-light" >{row.label}</div>
                                        </div>
                                        <div className="row">
                                            {
                                                row.data.map((data, k) => {
                                                    return (
                                                        <div className={`col-md-6 ${k}`}>
                                                            <div className={`row ${k}`}>
                                                                <div className="col-md-6 report border-right-0">{data.key}</div>
                                                                <div className={`col-md-6 report`}>{data.value}</div>
                                                            </div>
                                                        </div>
                                                    )


                                                })
                                            }
                                        </div>
                                    </div>

                                )
                            })

                        }

                    </div>
                </div>
            </React.Fragment>
        );
    }
}
