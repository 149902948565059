import React, { useState, useEffect, useRef } from "react";
import {
    Row, Col, Card, CardBody, CardTitle, Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Button,
} from "reactstrap"
import Helmet from "react-helmet"
import { ApiUrl, ProductName } from "../../config";
import { Link, useLocation, useParams } from "react-router-dom";
import { uInfo, uToken, dcrypt } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import * as SignaturePad from 'signature_pad';


const ClientForm = (props) => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [tab, setTab] = useState("1");
    const [textSig, setTextSig] = useState("");
    const [drawSig, setDrawSig] = useState([]);
    const [imageSig, setImageSig] = useState(null);
    const search = props.location.search;
    // const query = queryString.parse(search);
    const u = new URLSearchParams(search).get('u');
    const c = new URLSearchParams(search).get('c');
    const m = new URLSearchParams(search).get('meta');
    useEffect(() => {
        getData();
    }, []);


    const getData = (queryString = "", data) => {
        fetch(`${ApiUrl}` + "get/contract/" + dcrypt(c), {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: dcrypt(u) }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setData(response.data.records);
                    setLoading(false);
                } else {
                     toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const CreateCustomFieldUI = () => {
        return data.form_data_array.map((row, i) => {
            if (row.type == "text" || row.type == "date" || row.type == "number") {
                return <div key={row.type + '--' + i} className={`mb-3 form-group ${row.name}`}>
                    <label htmlFor={row.name} className={`formbuilder-text-label`}>{row.label}
                        {row.description && (
                            <OverlayTrigger
                                key={row.type + '--' + i}
                                placement={'auto'}
                                overlay={
                                    <Tooltip>
                                        {row.description}
                                    </Tooltip>
                                }
                            >
                                <i className="fas fa-question-circle pl-3"></i>
                            </OverlayTrigger>
                        )}
                    </label>
                    <input
                        type={row.type}
                        placeholder={row.placeholder}
                        name={row.name}
                        defaultValue={data.lead_rec_array[data.mapped_data_array[row.name]] && (data.lead_rec_array[data.mapped_data_array[row.name]])}
                        readOnly={data.lead_rec_array[data.mapped_data_array[row.name]] ? true : false}
                        maxLength={row.maxlength ? row.maxlength : ''}
                        className={row.className}
                        id={row.name}
                        title={row.description}
                        required={row.required}

                    />
                </div>
            } else if (row.type == "select") {
                return <div key={row.type + '--' + i} className={`mb-3 fb-select form-group field-${row.name}>`}>
                    <label htmlFor={row.name} className={`formbuilder-text-label`}>{row.label}
                        {row.description && (
                            <OverlayTrigger
                                key={row.type + '--' + i}
                                placement={'auto'}
                                overlay={
                                    <Tooltip>
                                        {row.description}
                                    </Tooltip>
                                }
                            >
                                <i className="fas fa-question-circle pl-3"></i>
                            </OverlayTrigger>


                            // <span className="tooltip-element"><i className="fas fa-question-circle"></i><div className="helper_tooltip">{row.description}</div></span>
                        )}
                    </label>
                    <select className={row.className} name={row.name}
                        defaultValue={data.lead_rec_array[data.mapped_data_array[row.name]]}
                        disabled={data.lead_rec_array[data.mapped_data_array[row.name]] ? true : false}

                    >
                        {row.values.map((row, i) => {
                            return <option key={row.value + '--' + i} value={row.value}


                                selected={data.lead_rec_array[data.mapped_data_array[row.name]] == row.value ? 'selected' : ''}
                                disabled={
                                    row.value == data.lead_rec_array[data.mapped_data_array[row.name]] ? true : false}
                            >{row.label}</option>

                        })}
                    </select>
                </div>

            } else if (row.type == "radio-group") {
                return <div key={row.type + '--' + i} className={`fb-select mb-3 form-group field-${row.name}>`}>
                    <label htmlFor={row.name} className={`formbuilder-text-label`}>{row.label}
                        {row.description && (
                            <OverlayTrigger
                                key={row.type + '--' + i}
                                placement={'auto'}
                                overlay={
                                    <Tooltip>
                                        {row.description}
                                    </Tooltip>
                                }
                            >
                                <i className="fas fa-question-circle pl-3"></i>
                            </OverlayTrigger>
                            // <span className="tooltip-element"><i className="fas fa-question-circle"></i><div className="helper_tooltip">{row.description}</div></span>
                        )}
                    </label>
                    {row.values.map((option, i) => {
                        return <div key={option.value + '--' + i} className={`form-check ${row.className}`}>
                            <input
                                name={row.name} type="radio"
                                className="form-check-input" value={option.value} />
                            <label className="form-check-label">{`${option.label}`}</label>
                        </div>
                    })}
                </div>
            } else if (row.type == "checkbox-group") {
                return <div key={row.type + '--' + i} className={`fb-select mb-3 form-group field-${row.name}>`}>
                    <label htmlFor={row.name} className={`formbuilder-text-label pr-3`}>{row.label}
                        {row.description && (
                            <OverlayTrigger
                                key={row.type + '--' + i}
                                placement={'auto'}
                                overlay={
                                    <Tooltip>
                                        {row.description}
                                    </Tooltip>
                                }
                            >
                                <i className="fas fa-question-circle pl-3"></i>
                            </OverlayTrigger>
                        )}
                    </label>
                    {row.values.map((option, i) => {
                        return <div key={option.value + '--' + i} className="form-check form-check-inline">
                            <input name={`${row.name}`} type="checkbox"
                                className=" form-check-input" value={`${option.value}`} />
                            <label className="form-check-label">{`${option.label}`}</label>
                        </div>
                    })}
                </div>
            } else if (row.type == "textarea") {
                return <div className={`fb-select mb-3 form-group field-${row.name}>`}>
                    <label htmlFor={row.name} className={`formbuilder-text-label pr-3`}>{row.label}
                        {row.description && (
                            <OverlayTrigger
                                key={row.type + '--' + i}
                                placement={'auto'}
                                overlay={
                                    <Tooltip>
                                        {row.description}
                                    </Tooltip>
                                }
                            >
                                <i className="fas fa-question-circle pl-3"></i>
                            </OverlayTrigger>
                        )}
                    </label>
                    <textarea
                        type={row.type}
                        placeholder={row.placeholder}
                        name={row.name}
                        defaultValue={data.lead_rec_array[data.mapped_data_array[row.name]] && (data.lead_rec_array[data.mapped_data_array[row.name]])}
                        readOnly={data.lead_rec_array[data.mapped_data_array[row.name]] ? true : false}
                        maxLength={row.maxlength ? row.maxlength : ''}
                        className={row.className}
                        id={row.name}
                        title={row.description}
                        required={row.required}

                    >

                    </textarea>

                </div>
            } else if (row.type == 'file') {
                return <div key={row.type + '--' + i} className={`mb-3 form-group ${row.name}`}>
                    <label htmlFor={row.name} className={`formbuilder-text-label`}>{row.label}
                        {row.description && (
                            <OverlayTrigger
                                key={row.type + '--' + i}
                                placement={'auto'}
                                overlay={
                                    <Tooltip>
                                        {row.description}
                                    </Tooltip>
                                }
                            >
                                <i className="fas fa-question-circle pl-3"></i>
                            </OverlayTrigger>
                        )}
                    </label>
                    <input
                        type={row.type}
                        placeholder={row.placeholder}
                        name={row.name}
                        className={row.className}
                        id={row.name}
                        title={row.description}
                        required={row.required}
                    />
                </div>
            } else if (row.type == 'paragraph') {
                return <div key={row.type + '--' + i} className="fb-text  form-group "><div dangerouslySetInnerHTML={{ __html: row.label }}></div></div>
            } else if (row.type == 'header') {
                return <div key={row.type + '--' + i} className="fb-text  form-group "><p className={row.subtype}>{row.label}</p></div>
            }
        })
    }
    let signaturePad = null;
    if (document.querySelector('canvas') && tab == '2') {
        var canvas = document.querySelector('canvas');
        signaturePad = new SignaturePad.default(canvas, { backgroundColor: 'rgb(255, 255, 255)', width: '100%' });
        signaturePad.addEventListener("beginStroke", () => {
            setDrawSig(signaturePad.toData());
        }, { once: true });


        document.getElementById('clear').addEventListener('click', function () {
            signaturePad.clear();
            setDrawSig([]);
        });

    }

    const handleSubmit = async (event) => {

        event.preventDefault();

        if (tab == '2' && drawSig.length < 1) {
            alert('Please Provide your Signature');
            return;
        }



        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();

        if (data.form_res.signature == 'Y') {
            if (tab == '1') {
                submitData.append('digital_signature', textSig);
                submitData.append('canvas_jason', '');
                submitData.append('signature_type', 'type_it')
            } else if (tab == '2') {
                submitData.append('digital_signature', data.lead_rec[0].first_name + '' + data.lead_rec[0].last_name);
                submitData.append('canvas_jason', JSON.stringify(drawSig));
                submitData.append('digital_signature_image_url', signaturePad.toDataURL('image/jpeg'));
                submitData.append('signature_type', 'drwa_it');

            }
        }
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        fetch(`${ApiUrl}` + "contract/meta/saved/" + dcrypt(m), {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData,
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });

                    if (props.location.state) {
                        if (props.location.state.from) {
                            setTimeout(
                                () => props.history.goBack(),
                                1000
                            );

                        } else {
                            setTimeout(
                                () => props.history.push('/client/profile'),
                                1000
                            );
                        }

                    } else {
                        setTimeout(
                            () => props.history.push('/client/profile'),
                            1000
                        );
                    }

                } else {
                     toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });

    }

    const toggle = (index) => {
        if (tab !== index) {
            setTab(index);
        }
    };



    return (
        <>
            {
                !loading && (
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2">
                            <div className="page-title-box d-flex space-between">
                                <h4 className="mb-3"> Contract {data.form_res.name}  </h4>
                                <Button
                                    type="button"
                                    color="primary"
                                    onClick={() => props.location.state ? props.location.state.from ? props.history.goBack() : props.history.push('/client/profile') : props.history.push('/client/profile')}
                                >
                                    Back
                                </Button>
                            </div>

                            <Card>
                                <CardBody>
                                    <CardTitle>{data.form_res.name}</CardTitle>
                                    <form onSubmit={handleSubmit} method="post" encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                                        {CreateCustomFieldUI()}
                                        {
                                            data.form_res.signature == 'Y' && (
                                                <>
                                                    <Nav tabs>
                                                        <NavItem>
                                                            <NavLink
                                                                className={tab == 1 ? "active" : ""}
                                                                onClick={() => {
                                                                    toggle("1");
                                                                }}
                                                            >
                                                                Type it
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink
                                                                className={tab == 2 ? "active" : ""}
                                                                onClick={() => {
                                                                    toggle("2");
                                                                }}
                                                            >
                                                                Draw it
                                                            </NavLink>
                                                        </NavItem>
                                                    </Nav>
                                                    <TabContent activeTab={tab}>
                                                        <TabPane tabId="1">
                                                            <div className="pt-20">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label className={`mt-3`}>
                                                                            Type Your Signature (Full Name)
                                                                        </label>
                                                                        <div className={`mb-3 form-group`}>
                                                                            <input
                                                                                id="text-sig"
                                                                                type="text"
                                                                                className="form-control"
                                                                                name="text-sig"
                                                                                required={tab == '1' ? true : false}
                                                                                onChange={(e) => setTextSig(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label className={`mt-3`}>

                                                                        </label>
                                                                        <div className="outer_type_it">
                                                                            <div className="type_it">{textSig}</div>

                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </TabPane>
                                                        <TabPane tabId="2">
                                                            <div className="mt-3">
                                                                <div className="canvas-wrapper">
                                                                    <canvas style={{ border: '2px solid #000' }} width={'1000px'} height={'200px'} ></canvas>
                                                                    <button className="btn btn-link" type="button" id="clear">Clear</button>
                                                                </div>
                                                            </div>
                                                        </TabPane>
                                                    </TabContent>
                                                </>
                                            )
                                        }

                                        <div className="form-group mt-3">
                                            <button className="btn btn-sm btn-block btn-primary" type="submit" id="submit_selection_myform" value="submit">
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </div>

                    </div>
                )
            }
        </>
    )

}

export default ClientForm;
