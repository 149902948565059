import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Table,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
} from "reactstrap";

import Helmet from "react-helmet";
// availity-reactstrap-validation
import {
  Asia,
  Africa,
  America,
  Antartica,
  Atlantic,
  Australia,
  Europe,
  Indian,
  Pacific,
} from "../../common/data/timezones";
import { Link, useLocation } from "react-router-dom";
import "../../assets/css/client-style.css";
import Phone from "../InitiateCall/Phone";
import { ApiUrl, AttachementsUrl, ProductName, PusherKey } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import {
  bcrypt,
  convertPhoneFormat,
  dcrypt,
  uInfo,
  uToken,
} from "../../useToken";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Badge from "react-bootstrap/Badge";
import {
  AvForm,
  AvField,
  AvInput,
  AvGroup,
  AvFeedback,
} from "availity-reactstrap-validation";
import Select from "react-select";
import { Accordion, Dropdown, Tab, Tabs, Tooltip } from "react-bootstrap";
import Avatar from "react-avatar";
import Inbox from "./../../assets/images/profile-icons/envelope.png";
import Activity from "./../../assets/images/profile-icons/activity.png";
import Attachments from "./../../assets/images/profile-icons/attachments.png";
import Trainings from "./../../assets/images/profile-icons/trainings.png";
import Invoices from "./../../assets/images/profile-icons/invoices.png";
import Video from "./../../assets/images/profile-icons/zoom.png";
import Calendar from "./../../assets/images/profile-icons/calendar.png";
import Assessment from "./../../assets/images/profile-icons/assessment.png";
import Profit from "./../../assets/images/profile-icons/profit.png";
import DataCenter from "./../../assets/images/profile-icons/datacenter.png";
import Gulak from "./../../assets/images/profile-icons/gulak.png";

// actions

// import CreateUpdate from "../Coaches/Clients/ClientCreateUpdate.js";
import CreateTask from "../Tasks/create.js";

import CreateAssessment from "../Assessment/create.js";
import EditAssessment from "../Assessment/update.js";

import ClientContract from "../Contract/clientContract.js";
import $ from "jquery";
import Loader from "react-loader";

import swal from "sweetalert";
import Pusher from "pusher-js";
import ProfileMenu from "../../components/CommonForBoth/TopbarDropdown/ProfileMenu";
import Room from "../TwilioVideo/Room";
import ClientVideoChat from "../TwilioVideo/ClientVideoChat";
import ItemShape from "./../../assets/images/shape.png";
import CalanderView from "../Dashboard/calanderView";
import GoalAchivenmnet from "../../components/Custom/GoalAchivenmnet";
import transactionHistory from "../../assets/images/history.png";

let pusher = new Pusher(PusherKey, {
  cluster: "mt1",
});
var channel = pusher.subscribe("my-channel");

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ClientDetails = (props) => {
  let query = useQuery();
  const [page_name] = useState("Client");
  const [idx] = useState(uInfo().client_id);
  const [coach_id] = useState(uInfo().coach_id);
  const [info, setInfo] = useState({});
  const [tab, setTab] = useState("7");
  const [btab, setBTab] = useState("7");
  const [groupModal, setGroupModal] = useState(false);
  const [tagModal, setTagModal] = useState(false);
  const [loginHistory, setLoginHistory] = useState([]);
  const [groups, setGroups] = useState([]);
  const [loginHistoryColumn, setLoginHistoryColumn] = useState([]);
  const [index, setIndex] = useState("");
  const [tags, setTags] = useState([]);
  const [tab7, setTab7] = useState("");
  const [tab12, setTab12] = useState("");
  const [tab13, setTab13] = useState("");
  const [tab14, setTab14] = useState("");
  const [calendarReload, setCalendarReload] = useState(true);

  const [tag, setTag] = useState("");
  const [selectedValue, setSelectedValue] = useState([]);
  const [style, setStyle] = useState({ display: "none" });
  const [page2Class, setpage2Class] = useState("");
  const [page3Class, setpage3Class] = useState("");
  const [page4Class, setpage4Class] = useState("");
  const [page5Class, setpage5Class] = useState("");
  const [page6Class, setpage6Class] = useState("");
  const [page7Class, setpage7Class] = useState("");
  const [page9Class, setpage9Class] = useState("");
  const [page10Class, setpage10Class] = useState("");
  const [page11Class, setpage11Class] = useState("");
  const [page16Class, setpage16Class] = useState("");
  const [callToken, setCallToken] = useState(null);
  const [searchClient, setSearchClient] = useState("");
  const [existing, setExisting] = useState([]);
  const [clients, setClients] = useState([]);
  const [DynamicModal, setDynamicModal] = useState(false);
  const [EmailModal, setEmailModal] = useState(false);
  const [smsModal, setSmsModal] = useState(false);
  const [DynamicModalName, setDynamicModalName] = useState("");
  const [activity, setActivity] = useState([]);
  const [script, setScript] = useState("");
  const [templates, setSmsTemplates] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [emailSetting, setEmailSetting] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [smsBody, setSmsBody] = useState("");
  const [token, setToken] = useState("");
  const [subject, setSubject] = useState("");
  const [html, setHtml] = useState("");
  const [sentEmailTemplateModal, setSentEmailTemplateModal] = useState(false);
  const [emailData, setEmailData] = useState("");
  const [taskCompleteModal, setTaskCompleteModal] = useState(false);
  const [taskID, setTaskID] = useState(false);
  const [attachment, setAttachment] = useState({});
  const [pipeline, setPipeline] = useState({});
  const [pipelineStatus, setPipelineStatus] = useState([]);
  const [selectedPipeline, setSelectedPipeline] = useState({});
  const [pipelineModal, setPipelineModal] = useState(false);
  const [coachPartners, setCoachPartners] = useState([]);
  //const [coachClients, setCoachClients] = useState([]);
  const [coachUser, setCoachUser] = useState({});
  const [invoices, setInvoices] = useState([]);
  const [defaultCalendar, setDefaultCalendar] = useState(0);
  const [assignTasked, setAssignedTask] = useState([]);
  const [attachmentFile, setattachmentFile] = useState("");
  const [assessment_id, setAssessmentId] = useState("");
  const identity = coach_id;
  const childRef = useRef();
  const [phoneno, setphoneno] = useState(null);
  const [loader, setLoader] = useState(false);
  const [key, setKey] = useState("chat");

  const [chatUser, setChatUser] = useState({});
  const [chats, setChats] = useState([]);
  const [group_chats, setGroupChats] = useState([]);
  const [message, setMessage] = useState("");
  const [toggleSwitch, settoggleSwitch] = useState(true);
  const [inbox_activity, setInboxActivity] = useState({
    report_results: [],
    sms_count: 0,
    email_count: 0,
    call_count: 0,
    voice_count: 0,
    voice_message_un_listen: 0,
  });
  const [taskClientLog, setTaskClientLog] = useState(0);

  let this_form = useRef(null);
  const paramsters = {
    token: callToken,
    from_number: uInfo().outbound_phone,
    phoneno: phoneno,
    record: "false",
    client_id: info.client_id,
    coach_id: coach_id,
  };
  const [task_record, setTaskRecord] = useState({
    task_log: [],
    today: 0,
    weekly: 0,
    monthly: 0,
    unassigned: 0,
  });
  const [inboxDay, setInboxDay] = useState("today");
  const [action_results, setActionResults] = useState([]);
  const [path_way_progression, setPathWayProgression] = useState([]);
  const [PathwayProgressionData, setPathWayProgressionData] = useState({});
  const [customer_conversion, setCustomerConversion] = useState({});
  const [toggleTrackerSwitch, settoggleTrackerSwitch] = useState(false);
  const [toggleDollorSwitch, settoggleDollorSwitch] = useState(true);
  const [taskModal, setTaskModal] = useState(false);

  const [timeZone_data, setnewzone] = useState(Asia);
  const [timezone, setTimeZone] = useState("");
  const [maintimezone, setMainTimeZone] = useState("");
  const [activeTab, setActiveTab] = useState("user");
  const [traingGroup, setTraingGroup] = useState("0");

  const childClientRef = useRef();
  useEffect(() => {
    getData();
    getTag();
    getActivity("3");
    getInboxActivity();
    getCompanyClients();
    getCoachUsers();
    getChatUser("", activeTab, traingGroup);
    getTaskStatistics("monthly");
    // console.log(channel)
    channel.bind("chat-event", function (data) {
      if (data.data.type == "group") {
        getUserChats(
          { user_id: data.data.sent_to, id: data.data.sent_to },
          "group"
        );
      } else {
        if (data.data.sent_to == uInfo().user_id) {
          getUserChats({ user_id: data.data.user_id }, "user");
        }
      }
    });

    channel.bind("meeting-event", function (data) {
      if (data.data.user_id == uInfo().user_id) {
        swal({
          title: data.data.message,
          icon: "info",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            window.location.href = data.data.url;
          }
        });
      }
    });
  }, []);
  const maintimezones = [
    { id: "Asia", label: "Asia" },
    { id: "America", label: "America" },
    { id: "Australia", label: "Australia" },
    { id: "Europe", label: "Europe" },
    { id: "Indian", label: "Indian" },
  ];

  const scheduleEvent = (row) => {
    if (!row) {
      setDynamicModal(true);
      setDynamicModalName("meeting");
    }
  };
  function settimezone(e) {
    setMainTimeZone(e);
    if (e.label == "Australia") {
      setnewzone(Australia);
    } else if (e.label == "America") {
      setnewzone(America);
    } else if (e.label == "Asia") {
      setnewzone(Asia);
    } else if (e.label == "Europe") {
      setnewzone(Europe);
    } else if (e.label == "Indian") {
      setnewzone(Indian);
    }
    setTimeZone({});
    // console.log(timeZone_data,'Zone Change');
  }
  const handleTaskSubmit = async (event, values) => {
    setCalendarReload(false);
    values.client_id = info.client_id;
    fetch(`${ApiUrl}` + "add/task/activity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          setTaskModal(false);
          getTaskStatistics("monthly");
          setCalendarReload(true);
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getInboxActivity = (type = "all", day = inboxDay) => {
    fetch(`${ApiUrl}` + "get/business/inbox", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        day: day,
        type: type,
        client_id: uInfo().client_id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setInboxActivity(response.data.records);
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getVideoTokenTwillio = (roomName) => {
    fetch(`${ApiUrl}` + "video/token", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ room: roomName, identity: uInfo().name }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          console.log(data.data.token, "data.data.token");
          setToken(data.data.token);
          setpage11Class("slide-in");
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getPathwayProgression = (id, slug = "") => {
    fetch(`${ApiUrl}` + "get/pathway/progression", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: id,
        slug: slug,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setPathWayProgression(response.data.records);
          setCustomerConversion(response.data.module_calculation);
          setPathWayProgressionData(response.data);
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getActionStepsResults = (slug = "") => {
    fetch(`${ApiUrl}` + "get/assessments/action_steps_results", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: info.assessment_id,
        slug: slug,
        status: "pending",
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setActionResults(response.data.records);
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleLogout = useCallback((event) => {
    setpage11Class("slide-out2");
    setToken(null);
    // props.history.push('/client/profile');
  }, []);

  const getChatUser = (search = "", chat_type = "", traingGroup = "0") => {
    console.log(chat_type, "chat_type");
    fetch(`${ApiUrl}` + "get/chat/user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ search: search, chat_type: chat_type }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          if (chat_type == "group" || data.data.groups.length > 0) {
            getUserChats({ user_id: traingGroup, id: traingGroup }, "group");
          }
          if (chat_type == "user" || data.data.lists.length > 0) {
            getUserChats(data.data.lists[0], "user");
          }
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {}, [chatUser]);

  const getUserChats = (data = "", chat_type = "user") => {
    let user_id = "";
    if (chat_type == "group") {
      user_id = data.id;
    } else {
      if (data != "") {
        user_id = data.user_id;
      } else {
        user_id = chatUser.user_id;
      }
    }

    fetch(`${ApiUrl}` + "get/chats", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: user_id, type: chat_type }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          if (chat_type == "group") {
            setGroupChats(data.data.chats);
          } else {
            setChats(data.data.chats);
          }
          setChatUser(data.data.user);
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getCoachUsers = () => {
    fetch(`${ApiUrl}` + "get/coach/users", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json({}))
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setCoachPartners(response.data.coachPartners);
          //setCoachClients(response.data.coachClients);
          setCoachUser(response.data.coachUser);
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getCompanyClients = () => {
    fetch(`${ApiUrl}` + "get/client/information/" + idx + "/" + coach_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setClients(response.data.records);
          setpage3Class("slide-out2");
          setAttachment(response.data.attachments);
          // setPipelineStatus(response.data.allPipelines);
          // if (response.data.allPipelines.length > 0) {
          //   setPipeline(response.data.allPipelines[0]);
          // }
          setAssignedTask(response.data.assignTasked);
          setInvoices(response.data.invoices);
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleExistingClient = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    let companyIds = [];
    for (let [key, value] of formData.entries()) {
      companyIds.push(value);
    }

    if (companyIds.length > 0) {
      fetch(`${ApiUrl}` + "update/company/client", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify({
          clients: info.client_id,
          comapny_id: companyIds[0],
        }),
      })
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((response) => {
          // console.log(response, "data");
          if (response.status === true) {
            setpage3Class("slide-out2");
            setSearchClient("");
            setExisting([]);
            getCompanyClients();
            toast(response.message, {
              hideProgressBar: true,
              position: "top-center",
              transition: Flip,
              className: "toast-custom-cls success",
            });
          } else {
            toast(response.message, {
              hideProgressBar: true,
              position: "top-center",
              transition: Flip,
              className: "toast-custom-cls error",
            });
          }
        })
        //Then with the error genereted...
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const getData = () => {
    fetch(`${ApiUrl}` + "lead/details/" + idx + "/" + coach_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setInfo(response.data.records);
          setGroups(response.data.groups_data);
          setTags(response.data.tag_data);
          if (response.data.records.phone != "") {
            setphoneno(
              response.data.records.country_code + response.data.records.phone
            );
          } else {
            setphoneno(
              response.data.records.country_code + response.data.records.mobile
            );
          }

          if (response.data.records.assessment_id != 0) {
            getPathwayProgression(response.data.records.assessment_id, "");
          }
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const btoggle = (index) => {
    if (btab !== index) {
      setBTab(index);
      getActivity(index);
      setActivity([]);
    }
  };

  const toggle = (index) => {
    if (tab !== index) {
      setTab(index);
      getActivity(index);
      setActivity([]);
    }
  };

  const removeGroupLead = () => {
    fetch(
      `${ApiUrl}` + "remove/group/lead/" + idx + "/" + index + "/" + coach_id,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
      }
    )
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        // console.log(response, "Group removed");
        if (response.status === true) {
          setGroupModal(false);
          getData();
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls success",
          });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const removeTagLead = () => {
    // console.log(index, "index");
    fetch(
      `${ApiUrl}` + "remove/tag/lead/" + idx + "/" + index + "/" + coach_id,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
      }
    )
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          // console.log(response);
          setTagModal(false);
          getData();
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls success",
          });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getTag = () => {
    fetch(`${ApiUrl}` + "get/group/tag", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },

      body: JSON.stringify({ manager_id: coach_id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        // console.log(response, "data");
        if (response.status === true) {
          setTags(response.data.tags);
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const removeGroupModal = (i) => {
    setGroupModal(!groupModal);
    setIndex(i);
  };
  const removeTagModal = (i) => {
    setTagModal(!tagModal);
    setIndex(i);
  };

  const AddTags = async (event, values) => {
    // console.log(index, "index");
    fetch(`${ApiUrl}` + "add/tags/lead/" + idx + "/" + coach_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setGroupModal(false);
          setTagModal(false);
          getData();
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls success",
          });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.id) : []);
  };

  useEffect(() => {
    if (searchClient != "") {
      fetch(`${ApiUrl}` + "get/companies", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify({ filter_value: searchClient }),
      })
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((response) => {
          // console.log(response, "data");
          if (response.status === true) {
            setExisting(response.data.records);
            // this.setState({
            //   records: response.data.records,
            // });
          } else {
            toast(response.message, {
              hideProgressBar: true,
              position: "top-center",
              transition: Flip,
              className: "toast-custom-cls error",
            });
          }
        })
        //Then with the error genereted...
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setExisting([]);
    }
  }, [searchClient]);

  const handleSendSMSToCoachSubmit = async (event, values) => {
    values.client_id = info.client_id;
    fetch(`${ApiUrl}` + "send/sms/to/coach", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          setSmsModal(false);
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls success",
          });
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSubmit = async (event, values) => {
    setCalendarReload(false);
    values.client_id = info.client_id;
    values.type = DynamicModalName.toLowerCase();
    fetch(`${ApiUrl}` + "lead/activity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log(data, "Add new attach");
        if (data.status === true) {
          // getActivity(tab);
          // setDynamicModal(false);
          // setDynamicModalName("");
          setCalendarReload(true);
          setDynamicModal(false);
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls success",
          });
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleAtSubmit = async (event, values) => {
    setLoader(false);

    // values.client_id = info.client_id;
    // values.attachment_file = attachmentFile;
    const formData = new FormData();
    formData.append("client_id", info.client_id);
    formData.append("attachment_file", attachmentFile);
    formData.append("file_title", values.file_title);
    formData.append("type", uInfo().type);
    fetch(`${ApiUrl}` + "client/attachment", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: formData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log(data, "Add new attach");
        if (data.status === true) {
          this_form && this_form.reset();
          getActivity(tab);
          getCompanyClients();
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls success",
          });
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
        setLoader(true);
      })
      //Then with the error genereted...
      .catch((error) => {
        setLoader(true);

        console.error("Error:", error);
      });
  };
  const handleDealSubmit = async (event, values) => {
    fetch(`${ApiUrl}` + "assinged_pipeline", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log(data, "Editing");
        if (data.status === true) {
          getActivity(tab);
          setPipelineModal(false);
          setpage5Class("slide-out2");
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls success",
          });
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getTaskStatistics = (task_interval) => {
    fetch(`${ApiUrl}` + "dashboard/task/statistics", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ task_interval: task_interval, client_id: idx }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          //console.log(response.data.record, 'recccc')
          setTaskRecord(response.data.record);
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleEmailSubmit = async (event, values) => {
    values.client_id = info.client_id;
    values.type = DynamicModalName.toLowerCase();

    if (html != "") {
      let body = document.getElementById("custom-template").innerHTML;
      values.body = body;
    }

    fetch(`${ApiUrl}` + "company/email/activity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log(data, "Editing");
        if (data.status === true) {
          getActivity(tab);
          setEmailModal(false);
          setDynamicModalName("");
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls success",
          });
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getActivity = (index) => {
    console.log("activity  records", idx, index);
    fetch(`${ApiUrl}` + "get/activity/" + idx + "/" + index, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        console.log("activity  records", response.data);
        if (response.status === true) {
          setActivity(response.data.records);
          setLoader(true);
          // setpage3Class('slide-out2');
          setDefaultCalendar(response.data.calendarId);
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
          setLoader(true);
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        setLoader(true);
        console.error("Error:", error);
      });
  };

  function groupArrayOfObjects(list, key) {
    return list.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  const getEmailTemplate = () => {
    fetch(`${ApiUrl}` + "get/email/template", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setSmsTemplates(response.data.templates);
          var tokens_group = [
            {
              label: "Default Field",
              options: response.data.tokens.default_fields,
            },
            {
              label: "Custom Field",
              options: response.data.tokens.custom_fields,
            },
          ];

          setTokens(tokens_group);
          setEmailSetting(response.data.phoneNumbers);
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getSmsTemplate = () => {
    fetch(`${ApiUrl}` + "get/sms/template", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          var templates = groupArrayOfObjects(response.data.templates, "type");
          var templates_group = [
            { label: "SMS", options: templates.SMS },
            { label: "MMS", options: templates.MMS },
          ];
          setSmsTemplates(templates_group);

          var tokens_group = [
            {
              label: "Default Field",
              options: response.data.tokens.default_fields,
            },
            {
              label: "Custom Field",
              options: response.data.tokens.custom_fields,
            },
          ];

          setTokens(tokens_group);
          setPhoneNumbers(response.data.phoneNumbers);
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const changeHandler = (e) => {
    let attachmentFile = [];
    var reader = new FileReader();
    reader.onload = () => {
      attachmentFile.push(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
    setattachmentFile(attachmentFile);
  };
  const getRVMTemplate = () => {
    fetch(`${ApiUrl}` + "get/rvm/template", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setSmsTemplates(response.data.templates);

          setPhoneNumbers(response.data.phoneNumbers);
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getEmailTemplateHtml = (id) => {
    fetch(`${ApiUrl}` + "get/html/" + id + "/" + uInfo().user_id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        setHtml(response.theme);
        // localStorage.setItem("theme", response.theme);
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const cancelTask = () => {
    setpage4Class("slide-out2");
    if (index == "6") {
      getActivity("6");
    }
  };
  const cancelAssessment = () => {
    setpage9Class("slide-out2");
    setpage10Class("slide-out2");
    getActivity("13");
  };
  const completeTask = () => {
    fetch(`${ApiUrl}` + "update/task/status", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ task_id: taskID, client_id: info.client_id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          getActivity("6");
          setTaskCompleteModal(false);
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleOutCall = () => {
    fetch(`${ApiUrl}` + "voice/token", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ identity: encodeURIComponent(identity) }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setCallToken(response.data.token);
          setClicked(true);
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const removeAttachement = async (id, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmation) => {
      if (confirmation) {
        const formData = new FormData();
        formData.append("id", id);
        formData.append("index", index);
        formData.append("client_id", info.client_id);
        fetch(`${ApiUrl}` + "remove/attachments", {
          method: "POST",
          headers: {
            Authorization: `Bearer ` + uToken(),
          },
          body: formData,
        })
          .then((response) => response.json())
          //Then with the data from the response in JSON...
          .then((data) => {
            if (data.status === true) {
              getActivity(tab);
              getCompanyClients();
              toast(data.message, {
                hideProgressBar: true,
                position: "top-center",
                transition: Flip,
                className: "toast-custom-cls success",
              });
            } else {
              toast(data.message, {
                hideProgressBar: true,
                position: "top-center",
                transition: Flip,
                className: "toast-custom-cls error",
              });
            }
          })
          //Then with the error genereted...
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    });
  };

  const sendMessage = (
    chatType,
    roomName = "",
    chatUserId = "",
    chatInit = ""
  ) => {
    let message_text = message;
    let sub_type = "text";
    let sent_to = chatUser.user_id;
    let chat_init = chatUser.chat_init;
    if (chatType == "video") {
      message_text = roomName;
      sub_type = "video";
      sent_to = chatUserId;
      chat_init = chatInit;
    }

    fetch(`${ApiUrl}` + "send/message", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        send_to: sent_to,
        message: message_text,
        chat_init: chat_init,
        chat_type: chatType,
        type: activeTab,
        sub_type: sub_type,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          setMessage("");
          getChatUser("", activeTab, traingGroup);
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const chatToggle = (tab, traingGroup) => {
    setTraingGroup(traingGroup);
    //if (activeTab !== tab) {
    setActiveTab(tab);
    getChatUser("", tab, traingGroup);
    //}
    getData();
  };
  const actionStepVerify = (step_id) => {
    swal({
      title: "Are you sure?",
      text: "Send request to the coach to complete your task",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmation) => {
      if (confirmation) {
        fetch(`${ApiUrl}` + "action/step/verify", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
          body: JSON.stringify({
            id: step_id,
          }),
        })
          .then((response) => response.json())
          //Then with the data from the response in JSON...
          .then((response) => {
            if (response.status === true) {
              swal({
                title: response.message,
                icon: "success",
              });
              // getActionStepsResults();
            } else {
              swal({
                title: response.message,
                icon: "warning",
              });
            }
          })
          //Then with the error genereted...
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        document.getElementById("complete_" + step_id).checked = false;
      }
    });
  };

  function NumberWithCommas(x) {
    let y = Math.trunc(x);
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{page_name}</title>
      </Helmet>
      <ToastContainer />

      <Row className="CompanyDetail ClientProfileDetails">
        <Col md={3}>
          <div className="NoShadow custom-scroll LeftSideUser">
            {info.coach_login == "yes" && chatUser.user_id && (
              <div className="pull-right video-button">
                <ClientVideoChat
                  userId={chatUser.user_id}
                  chatInit={chatUser.chat_init}
                  sendMessage={sendMessage}
                  loginStatus={"yes"}
                />
              </div>
            )}
            <div className="user-profile-pic-area ClientsProfiles ClientsProlie">
              <Card className="UserProfileBG">
                <CardBody className="px-0">
                  <div className="company p-2">
                    <div className="company-info">
                      {info.coach_login == "yes" ? (
                        <div className="OnlineOffline">
                          <span className="OnlineIcon"></span>
                          Online
                        </div>
                      ) : (
                        <div className="OnlineOffline">
                          <span className="OfflineIcon OnlineIcon"></span>
                          Offline
                        </div>
                      )}
                      {info.coach_login == "no" && (
                        <button
                          onClick={() => {
                            setSmsModal(true);
                          }}
                          className="btn btn-primary btn-md pull-right"
                          title="send sms"
                        >
                          <i className="fas fa-comment"></i>
                        </button>
                      )}

                      {/* <div className="settingArea pull-right"><ProfileMenu from={'1'}/></div> */}
                      <div className="logo">
                        <img src={info.coach_image} alt="USer"></img>
                        {/* <Avatar
                          name={`${info.first_name} ${info.last_name}`}
                          size="45"
                          round={true}
                        /> */}
                      </div>

                      <div className="info ProfileInfo">
                        <h5>{info.coach_name}</h5>
                        {info.coach_website != "" &&
                          info.coach_website != null && (
                            <div className="url">
                              <a
                                href={`https://${info.coach_website}`}
                                target="_blank"
                                className="pr-5"
                              >
                                {info.coach_website}
                                <i className="fas fa-external-link-alt"></i>
                              </a>

                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                  <Tooltip id="overlay-example">
                                    Copy domain to clipboard
                                  </Tooltip>
                                }
                              >
                                <button
                                  className="btn btn-link btn-md"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      `${info.coach_website}`
                                    );
                                  }}
                                >
                                  <i className="fas fa-copy"></i>
                                </button>
                              </OverlayTrigger>
                            </div>
                          )}

                        {info.coach_number != "" && (
                          <div className="UserPhoneNbmr">
                            <div className="d-flex">
                              <Link to={"#"}>
                                {convertPhoneFormat(info.coach_number)}
                              </Link>
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                  <Tooltip id="overlay-example">
                                    Copy phone number to clipboard
                                  </Tooltip>
                                }
                              >
                                <button
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      `${convertPhoneFormat(info.coach_number)}`
                                    );
                                  }}
                                  className="btn btn-link btn-md"
                                >
                                  <i className="fas fa-copy"></i>
                                </button>
                              </OverlayTrigger>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="compant-action-btn text-center none">
                      <OverlayTrigger
                        placement="auto"
                        overlay={
                          <Tooltip id="overlay-example">Create a note</Tooltip>
                        }
                      >
                        <button
                          onClick={() => {
                            setDynamicModal(true);
                            setDynamicModalName("Note");
                          }}
                          className="btn btn-primary btn-md"
                        >
                          <i className="fas fa-edit"></i>
                        </button>
                      </OverlayTrigger>
                      {info.mobile != "" && info.mobile != null && (
                        <OverlayTrigger
                          placement="auto"
                          overlay={
                            <Tooltip id="overlay-example">Send Sms</Tooltip>
                          }
                        >
                          <button
                            onClick={() => {
                              setDynamicModal(true);
                              setDynamicModalName("sms");
                              getSmsTemplate();
                            }}
                            className="btn btn-primary btn-md"
                          >
                            <i className="fas fa-comment"></i>
                          </button>
                        </OverlayTrigger>
                      )}

                      {info.email != "" && info.email != null && (
                        <OverlayTrigger
                          placement="auto"
                          overlay={
                            <Tooltip id="overlay-example">
                              Create an email
                            </Tooltip>
                          }
                        >
                          <button
                            onClick={() => {
                              setEmailModal(true);
                              setDynamicModalName("email");
                              getEmailTemplate();
                            }}
                            className="btn btn-primary btn-md"
                          >
                            <i className="fas fa-envelope"></i>
                          </button>
                        </OverlayTrigger>
                      )}

                      <OverlayTrigger
                        placement="auto"
                        overlay={
                          <Tooltip id="overlay-example">
                            Make a phone call
                          </Tooltip>
                        }
                      >
                        <button
                          onClick={() => {
                            setDynamicModal(true);
                            setDynamicModalName("call");
                            handleOutCall();
                          }}
                          className="btn btn-primary btn-md"
                        >
                          <i className="fas fa-phone-alt"></i>
                        </button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="auto"
                        overlay={<Tooltip id="overlay-example">Task</Tooltip>}
                      >
                        <button
                          onClick={() => {
                            setpage4Class("slide-in");
                          }}
                          className="btn btn-primary btn-md"
                        >
                          <i className="fas fa-tasks"></i>
                        </button>
                      </OverlayTrigger>

                      {(info.mobile || info.phone) && (
                        <OverlayTrigger
                          placement="auto"
                          overlay={
                            <Tooltip id="overlay-example">Send RVM</Tooltip>
                          }
                        >
                          <button
                            onClick={() => {
                              setDynamicModal(true);
                              setDynamicModalName("rvm");
                              getRVMTemplate();
                            }}
                            className="btn btn-primary btn-md"
                          >
                            <i className="fas fa-microphone"></i>
                          </button>
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>

              {info.training_groups && (
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={activeTab == "user" ? "active" : ""}
                      onClick={() => {
                        chatToggle("user", 0);
                      }}
                    >
                      One on One
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="GroupSelect">
                      <select
                        className="form-select ProfileSelectGroup"
                        onChange={(e) => {
                          chatToggle("group", e.target.value);
                        }}
                        value={traingGroup}
                      >
                        <option value={""}>Select Coaching Group</option>
                        {info.training_groups.map((row, i) => {
                          return (
                            <option key={i} value={row.id}>
                              {row.name} ({row.unread})
                            </option>
                          );
                        })}
                      </select>
                    </NavLink>
                  </NavItem>

                  {/* {info.training_groups.map((group, k) => {
                    return (
                      <NavItem>
                        <NavLink
                          className={activeTab == "group" ? "active" : ""}
                          onClick={() => {
                            chatToggle("group", group.id);
                          }}
                        >
                          {group.name}
                          
                        </NavLink>
                      </NavItem>
                    );
                })} */}
                </Nav>
              )}
              <TabContent activeTab={activeTab}>
                <TabPane tabId="user">
                  <div className="client-chat-area">
                    <div className="card">
                      <div className="card-body">
                        <div className="position-relative">
                          <div className="chat-messages p-1 custom-scroll">
                            {chats.map((chat, k) => {
                              return (
                                <div
                                  key={k}
                                  className={`${
                                    chatUser.user_id != chat.user_id
                                      ? " chat-message-right"
                                      : "chat-message-left"
                                  } pb-3`}
                                >
                                  <div className="bg-light rounded py-2 px-2">
                                    <div>
                                      <Avatar
                                        name={chat.user?.name}
                                        size="30"
                                        round={true}
                                      />
                                    </div>

                                    {chat.sub_type == "video" ? (
                                      chat.user_id == uInfo().user_id ? (
                                        <button className="btn btn-link">
                                          {" "}
                                          Video link sent
                                        </button>
                                      ) : chat.created_ago <= 10 ? (
                                        <button
                                          className="btn btn-primary joinVideoLink"
                                          onClick={() =>
                                            getVideoTokenTwillio(chat.message)
                                          }
                                        >
                                          {" "}
                                          Click here to join video call
                                        </button>
                                      ) : (
                                        <button className="btn btn-link">
                                          Call link expired
                                        </button>
                                      )
                                    ) : (
                                      <p
                                        className="text-break"
                                        dangerouslySetInnerHTML={{
                                          __html: chat.message,
                                        }}
                                      />
                                    )}

                                    {/* {chat.message} */}
                                  </div>
                                  <div className="text-muted small text-nowrap">
                                    {chat.created_at}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="flex-grow-0 p-2 border-top">
                          <div className="input-group">
                            <input
                              onChange={(e) => setMessage(e.target.value)}
                              value={message}
                              type="text"
                              className="form-control"
                              placeholder="Type your message"
                            />
                            <button
                              disabled={message == "" ? true : false}
                              onClick={() => sendMessage("chat")}
                              className="btn btn-primary"
                            >
                              Send
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="group">
                  <div className="client-chat-area">
                    <div className="card">
                      <div className="card-body">
                        <div className="position-relative">
                          <div className="chat-messages p-1 custom-scroll">
                            {group_chats.map((chat, k) => {
                              return (
                                <div
                                  key={k}
                                  className={`${
                                    uInfo().user_id == chat.user?.user_id
                                      ? " chat-message-right"
                                      : "chat-message-left"
                                  } pb-3`}
                                >
                                  <div className="bg-light rounded py-2 px-2">
                                    <div>
                                      <Avatar
                                        name={chat.user?.name}
                                        size="30"
                                        round={true}
                                      />
                                    </div>

                                    {chat.sub_type == "video" ? (
                                      chat.user_id == uInfo().user_id ? (
                                        <button className="btn btn-link">
                                          {" "}
                                          Video link sent
                                        </button>
                                      ) : chat.created_ago <= 10 ? (
                                        <button
                                          className="btn btn-primary joinVideoLink"
                                          onClick={() =>
                                            getVideoTokenTwillio(chat.message)
                                          }
                                        >
                                          {" "}
                                          Click here to join video call
                                        </button>
                                      ) : (
                                        <button className="btn btn-link">
                                          Call link expired
                                        </button>
                                      )
                                    ) : (
                                      <p
                                        className="text-break"
                                        dangerouslySetInnerHTML={{
                                          __html: chat.message,
                                        }}
                                      />
                                    )}

                                    {/* {chat.message} */}
                                  </div>
                                  <div className="text-muted small text-nowrap">
                                    {chat.created_at}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="flex-grow-0 p-2 border-top">
                          <div className="input-group">
                            <input
                              onChange={(e) => setMessage(e.target.value)}
                              value={message}
                              type="text"
                              className="form-control"
                              placeholder="Type your message"
                            />
                            <button
                              disabled={message == "" ? true : false}
                              onClick={() => sendMessage("chat")}
                              className="btn btn-primary"
                            >
                              Send
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </div>
          <div>
            <div className={`page2 ${page2Class} custom-scroll`}>
              <div>
                <div className="modal-head top-heading">
                  <h2>Edit Detail</h2>
                  <button
                    onClick={() => {
                      setpage2Class("slide-out");
                    }}
                    className="btn btn-link text-white"
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>

                {/* <CreateUpdate
                  ref={childRef}
                  getData={getData}
                  id={info.client_id}
                  coach_id={info.coach_id}
                  from="companyDetails"
                /> */}
              </div>
            </div>
          </div>
        </Col>
        <Col md={9}>
          <div className="row">
            <div className="col-lg-4 px-1">
              <Card className="mt-3 boxescard  ClientProfileGoals">
                {info.assessment_id > 0 ? (
                  <GoalAchivenmnet
                    canEdit={false}
                    name={info.first_name + " " + info.last_name}
                    from={"ClinetProfile"}
                    assessment={info.assessment_id}
                    slug={"scheduling_freedom"}
                  />
                ) : (
                  <div className="card card-body">
                    <section className="breakthrough clientProfileHeading sub-nav-bg mb-0 text-center">
                      <h5 className="mb-0">Monthly Achievements</h5>
                    </section>
                  </div>
                )}
              </Card>
            </div>
            <div className="col-lg-4 px-1">
              <div className="card card-body Breakdown PathwayProgression mt-3">
                <section className="breakthrough clientProfileHeading sub-nav-bg mb-0 text-center">
                  <h5 className="mb-0">Pathway Progression</h5>
                </section>
                <div className="ProfileBreakDown">
                  {path_way_progression.length > 0 ? (
                    path_way_progression.map((row, i) => {
                      return (
                        <div className="ROiPercents mb-2" key={i}>
                          <div className="revenue pull-left">
                            <p>{row.cat_name}</p>
                          </div>
                          <div className="SectionHeader RoiPage ">
                            <div className="Percents">
                              {row.total_complete > 0 &&
                                row.total_complete < 100 && (
                                  <h4
                                    className="GrayBG justify-content-center"
                                    style={{
                                      background: "#008037",
                                      width: `${
                                        row.total_complete > 0
                                          ? row.total_complete + "%"
                                          : "0%"
                                      }`,
                                    }}
                                  >
                                    <span className="pull-left">
                                      {" "}
                                      {row.total_complete}%
                                    </span>
                                  </h4>
                                )}
                              {row.total_remaining > 0 && (
                                <h4
                                  className="GreenBG justify-content-center"
                                  style={{
                                    background: "#041934",
                                    alignItems: `${
                                      row.total_remaining > 0 &&
                                      row.total_complete == 0
                                        ? "center"
                                        : "end"
                                    }`,
                                    width: `100%`,
                                  }}
                                >
                                  {row.total_remaining}% Remaining
                                </h4>
                              )}
                              {row.total_complete == 100 && (
                                <h4
                                  className="GreenBG text-center FullBG"
                                  style={{
                                    background: "#f4bc33",
                                    width: `${
                                      row.total_complete > 0
                                        ? row.total_complete + "%"
                                        : "0%"
                                    }`,
                                  }}
                                >
                                  <span className="pull-left">
                                    {" "}
                                    {row.total_complete}%
                                  </span>
                                </h4>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="ROiPercents mb-2">
                      <div className="text-center no-record">
                        <p>No record found</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4 px-1">
              <div className="card card-body mt-3">
                <section className="breakthrough clientProfileHeading sub-nav-bg mb-0 text-center">
                  <h5 className="mb-0">Assigned Tasks </h5>
                </section>
                <div className="ProfileBreakDown">
                  {assignTasked.length > 0 ? (
                    <ul className="TasksAsign">
                      {assignTasked.map((row, i) => {
                        return (
                          <li key={i}>
                            {/* <div className="StatusIcon"><i className="fas fa-info-circle"></i></div> */}
                            <div className="TaskNumber assigned">
                              {row.listing_order}
                            </div>
                            <div className="TaskDesription">
                              <small>
                                <b>{row.cat_name}</b>
                              </small>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: row.description,
                                }}
                              />
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <div className="col-12 col-lg-12 col-xl-12 text-center">
                      No record found
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 px-1">
              <Card className="VariousCalls TotalRecord MyScheduleMain TaskManager mt-3">
                <CardBody>
                  <div className="DashboardHeadingSection mb-4">
                    <h4 className="card-title">Task Manager</h4>
                  </div>
                  <div className="selectboxtop PlusSign">
                    <h5 className="card-title mb-3">
                      <button
                        onClick={() => {
                          setTaskModal(true);
                        }}
                        className="btn btn-primary code-green border-0 float-end mt-3"
                      >
                        <i className="fas fa-plus"></i>
                      </button>
                    </h5>
                  </div>
                  <div className="border-three mt-3">
                    <Row>
                      <Col xl="6" lg="6" xs="6">
                        <Card
                          onClick={() => getTaskStatistics("today")}
                          className="Message p-2 mb-3"
                        >
                          <div className="IconWithCount">
                            <div className="dot code-red"></div>
                            <span>Today</span>
                          </div>
                          <div className="NumberFunc">{task_record.today}</div>
                        </Card>
                      </Col>
                      <Col xl="6" lg="6" xs="6">
                        <Card
                          onClick={() => getTaskStatistics("weekly")}
                          className="Email p-2 mb-3"
                        >
                          <div className="IconWithCount">
                            <div className="dot code-dark"></div>
                            <span>This Week</span>
                          </div>
                          <div className="NumberFunc">{task_record.weekly}</div>
                        </Card>
                      </Col>
                      <Col xl="6" lg="6" xs="6">
                        <Card
                          onClick={() => getTaskStatistics("monthly")}
                          className="ReceivedCalls p-2"
                        >
                          <div className="IconWithCount">
                            <div className="dot code-green"></div>
                            <span>This Month</span>
                          </div>
                          <div className="NumberFunc">
                            {task_record.monthly}
                          </div>
                        </Card>
                      </Col>
                      <Col xl="6" lg="6" xs="6">
                        <Card
                          onClick={() => getTaskStatistics("unassigned")}
                          className="MissedCalls p-2"
                        >
                          <div className="IconWithCount">
                            <div className="dot code-yellow"></div>
                            <span>Unassigned</span>
                          </div>
                          <div className="NumberFunc">
                            {task_record.unassigned}
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                  <Card className="mb-3 MyScheduleMain mt-2">
                    <CardBody className="RecentRecord MyTasks NewTasks">
                      <ul>
                        {task_record.task_log.length > 0 ? (
                          task_record.task_log.map((row, i) => {
                            return (
                              <li className="pt-2 pb-2 mt-1 mb-2">
                                <div
                                  className={`DotTask code-${row.color_code}`}
                                ></div>
                                <div className="TaskDescp">
                                  <div className="research-date">
                                    <p>{row.created_at}</p>
                                  </div>
                                  <hr className="my-1"></hr>
                                  <p>{row.title}</p>
                                </div>
                              </li>
                            );
                          })
                        ) : (
                          <li key={"task1"} className="mt-3 no-record mb-1">
                            <div className="ContactDescription p-2">
                              <div className="UserMessage text-center">
                                <p>No record found</p>
                              </div>
                            </div>
                          </li>
                        )}

                        {/* <li className="pt-2 pb-2">
                        <div className="DotTask code-dark"></div>
                        <div className="TaskDescp">
                          <div className="research-date"><p>September 6th</p></div>
                          <hr className="my-1"></hr>
                          <p>Client research</p>
                        </div>
                      </li>
                      <li className="pt-2 pb-2">
                        <div className="DotTask code-green"></div>
                        <div className="TaskDescp">
                          <div className="research-date"><p>September 6th</p></div>
                          <hr className="my-1"></hr>
                          <p>Confirm book interview with client.</p>
                        </div>
                      </li>
                      <li className="pt-2 pb-2">
                        <div className="DotTask code-red"></div>
                        <div className="TaskDescp">
                          <div className="research-date"><p>September 6th</p></div>
                          <hr className="my-1"></hr>
                          <p>Book meeting setup. Make sure to check notes from past interviews.</p>
                          <p>Have your pricing figured out incase they ask about it.</p>
                        </div>
                      </li> */}
                      </ul>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </div>

            <div className="col-lg-4 px-1">
              <Card className="NoShadow custom-scroll Activity UserActivityDetails ProfileClientActivity mb-2 mt-3">
                <CardBody>
                  <section className="breakthrough clientProfileHeading ToolKit sub-nav-bg mb-0 text-center">
                    <h5 className="mb-0">Productivity Tool Kit</h5>
                  </section>
                  <div className="ProductivityIcons">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={tab == 7 ? "active" : ""}
                          onClick={() => {
                            toggle("7");
                            setTab7("slide-in");
                          }}
                        >
                          <div className="ProfileIcons Inbox">
                            <img src={Inbox}></img>{" "}
                          </div>{" "}
                          <span>Inbox</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        {info.assessment_id != 0 ? (
                          <NavLink
                            className={tab == 16 ? "active" : ""}
                            onClick={() => {
                              toggle("16");
                              setpage16Class("slide-in");
                              getActionStepsResults();
                            }}
                          >
                            <div className="ProfileIcons Activity">
                              <img src={Activity}></img>{" "}
                            </div>{" "}
                            <span>Task Manager</span>
                          </NavLink>
                        ) : (
                          <NavLink
                            className={tab == 16 ? "active" : ""}
                            onClick={() => {
                              swal({
                                title: "No assessment assign yet",
                                icon: "warning",
                              });
                            }}
                          >
                            <div className="ProfileIcons Activity">
                              <img src={Activity}></img>{" "}
                            </div>{" "}
                            <span>Task Manager</span>
                          </NavLink>
                        )}
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={tab == 15 ? "active" : ""}
                          onClick={() => {
                            toggle("15");
                            setpage6Class("slide-in");
                          }}
                        >
                          <div className="ProfileIcons Attachments">
                            {" "}
                            <img src={Attachments}></img>
                          </div>{" "}
                          <span>Attachments</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={tab == 14 ? "active" : ""}
                          onClick={() => {
                            toggle("14");
                            setTab14("slide-in");
                          }}
                        >
                          <div className="ProfileIcons  Invoices client">
                            <img src={Invoices}></img>
                          </div>{" "}
                          <span>Invoices</span>
                        </NavLink>
                      </NavItem>

                      {info.assessment_id != 0 ? (
                        <NavItem>
                          <NavLink
                            className={tab == 14 ? "active" : ""}
                            href={`/progress/tracker/${bcrypt(
                              info.assessment_id
                            )}/${info.running_category}`}
                          >
                            <div className="ProfileIcons  Profit">
                              <img src={Profit}></img>
                            </div>{" "}
                            <span>Profit Center</span>
                          </NavLink>
                        </NavItem>
                      ) : (
                        <NavItem>
                          <NavLink
                            className={tab == 14 ? "active" : ""}
                            onClick={() => {
                              swal({
                                title: "No assessment assign yet",
                                icon: "warning",
                              });
                            }}
                            // onClick={() => {
                            //   toggle("13");
                            //   setTab13("slide-in");
                            // }}
                          >
                            <div className="ProfileIcons  Profit">
                              <img src={Profit}></img>
                            </div>{" "}
                            <span>Profit Center</span>
                          </NavLink>
                        </NavItem>
                      )}
                      <NavItem>
                        <NavLink
                          className={tab == 16 ? "active" : ""}
                          onClick={() => {
                            props.history.push("/trainings");
                          }}
                        >
                          <div className="ProfileIcons Trainings">
                            {" "}
                            <img src={Trainings}></img>
                          </div>{" "}
                          <span>Trainings</span>
                        </NavLink>
                      </NavItem>

                      {/* <NavItem>
                  <NavLink
                    className={tab == 11 ? "active" : ""}
                    onClick={() => {
                      toggle("11");
                    }}
                  >
                    Contracts
                  </NavLink>
                </NavItem> */}

                      <NavItem>
                        <NavLink
                          className={tab == 12 ? "active" : ""}
                          onClick={() => {
                            // toggle("12");
                            // setTab12("slide-in");
                          }}
                        >
                          <div className="ProfileIcons Video disable">
                            <div className="VideoUploads">
                              <img src={Video}></img>
                            </div>
                          </div>{" "}
                          <span>Video Message</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={tab == 12 ? "active" : ""}
                          onClick={() => {
                            toggle("12");
                            setTab12("slide-in");
                          }}
                        >
                          <div className="ProfileIcons Calendar">
                            <img src={Calendar}></img>
                          </div>{" "}
                          <span>Calendar</span>
                        </NavLink>
                      </NavItem>
                      {info.assessment_id != 0 ? (
                        <NavItem>
                          <NavLink
                            className={tab == 13 ? "active" : ""}
                            href={`/assessment/${bcrypt(
                              info.assessment_id
                            )}/summary`}
                            // onClick={() => {
                            //   toggle("13");
                            //   setTab13("slide-in");
                            // }}
                          >
                            <div className="ProfileIcons Assessment">
                              <img src={Assessment}></img>
                            </div>{" "}
                            <span>Assessment</span>
                          </NavLink>
                        </NavItem>
                      ) : (
                        <NavItem>
                          <NavLink
                            className={tab == 13 ? "active" : ""}
                            // href={`/assessment/${bcrypt(info.assessment_id)}/summary`}
                            onClick={() => {
                              swal({
                                title: "No assessment assign yet",
                                icon: "warning",
                              });
                            }}
                          >
                            <div className="ProfileIcons Assessment">
                              <img src={Assessment}></img>
                            </div>{" "}
                            <span>Assessment</span>
                          </NavLink>
                        </NavItem>
                      )}
                    </Nav>
                  </div>
                  <TabContent activeTab={tab}>
                    <TabPane className="PrimaryTabs" tabId="1">
                      <div className="pt-20 table-responsive">
                        <Table className="table mb-0">
                          <tbody>
                            <tr>
                              <td> First Name </td>
                              <td> {info.first_name} </td>
                              <td> Last Name </td>
                              <td> {info.last_name} </td>
                            </tr>
                            <tr>
                              <td> Email </td>
                              <td> {info.email} </td>
                              <td> Mobile </td>
                              <td> {info.phone} </td>
                            </tr>
                            <tr>
                              <td> Lead Source </td>
                              <td> {info.source} </td>
                              <td> Lead Id </td>
                              <td> {info.id} </td>
                            </tr>
                            <tr>
                              <td colSpan={4}> ADDRESS INFORMATION </td>
                            </tr>
                            <tr>
                              <td> Address </td>
                              <td> {info.address} </td>
                              <td> City </td>
                              <td> {info.city} </td>
                            </tr>
                            <tr>
                              <td> State </td>
                              <td> {info.state} </td>
                              <td> Zip </td>
                              <td> {info.zip} </td>
                            </tr>
                            <tr>
                              <td> GROUP </td>
                              <td>
                                {info.groups && (
                                  <OverlayTrigger
                                    key={0}
                                    placement="auto"
                                    overlay={
                                      <Tooltip id={`tooltip-auto`}>
                                        <strong>
                                          {info.groups ? info.groups.title : ""}
                                        </strong>
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      className={`btn-sm btn-success btn-round mr-5`}
                                    >
                                      <Badge
                                        bg=""
                                        onClick={() => removeGroupModal(0)}
                                      >
                                        <i className="fas fa-times-circle pr-5"></i>
                                      </Badge>
                                      {info.groups ? info.groups.title : ""}
                                    </Button>
                                  </OverlayTrigger>
                                )}
                                <Modal
                                  isOpen={groupModal}
                                  fade={false}
                                  toggle={() => removeGroupModal()}
                                >
                                  <ModalBody>
                                    <div className="warning-box text-center">
                                      <span className="warning">
                                        <i className="mdi mdi-alert-circle">
                                          {" "}
                                        </i>
                                      </span>
                                      <h3> Are you sure ? </h3>
                                      <p> You want to remove this group ? </p>
                                    </div>
                                  </ModalBody>
                                  <ModalFooter>
                                    <Button
                                      className="btn-sm"
                                      color="info"
                                      onClick={() => removeGroupLead()}
                                    >
                                      Yes, Remove it
                                    </Button>
                                    <Button
                                      className="btn-sm"
                                      color="danger"
                                      onClick={() => removeGroupModal()}
                                    >
                                      Cancel
                                    </Button>
                                  </ModalFooter>
                                </Modal>
                              </td>
                              <td> TAG </td>

                              <td>
                                {info.tags && (
                                  <OverlayTrigger
                                    key={1}
                                    placement="auto"
                                    overlay={
                                      <Tooltip id={`tooltip-auto`}>
                                        <strong>
                                          {info.tags ? info.tags.title : ""}
                                        </strong>
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      className={`btn-sm btn-success btn-round mr-5`}
                                    >
                                      <Badge
                                        bg=""
                                        onClick={() => removeTagModal(0)}
                                      >
                                        <i className="fas fa-times-circle pr-5"></i>
                                      </Badge>
                                      {info.tags ? info.tags.title : ""}
                                    </Button>
                                  </OverlayTrigger>
                                )}
                                <Modal
                                  isOpen={tagModal}
                                  fade={false}
                                  toggle={() => removeTagModal()}
                                >
                                  <ModalBody>
                                    <div className="warning-box text-center">
                                      <span className="warning">
                                        <i className="mdi mdi-alert-circle">
                                          {" "}
                                        </i>
                                      </span>
                                      <h3> Are you sure ? </h3>
                                      <p> You want to remove this Tag ? </p>
                                    </div>
                                  </ModalBody>
                                  <ModalFooter>
                                    <Button
                                      className="btn-sm"
                                      color="info"
                                      onClick={() => removeTagLead()}
                                    >
                                      Yes, Remove it
                                    </Button>
                                    <Button
                                      className="btn-sm"
                                      color="danger"
                                      onClick={() => removeTagModal()}
                                    >
                                      Cancel
                                    </Button>
                                  </ModalFooter>
                                </Modal>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </TabPane>
                    <TabPane className="PrimaryTabs" tabId={"2"}>
                      <AvForm
                        onValidSubmit={AddTags}
                        className="needs-validation"
                      >
                        <Col md="6">
                          <div className="mb-3 mt-5">
                            <Select
                              getOptionLabel={(option) => option.title}
                              getOptionValue={(option) => option.group_id}
                              // value={tags.filter(obj => selectedValue.includes(obj.id))}
                              options={tags}
                              isMulti={false}
                              classNamePrefix="select2-selection"
                              name="tags_id"
                              isClearable
                              onChange={(e) => setTag(e.group_id)}
                              // onChange={handleChange}
                            />
                            <AvField
                              required
                              type="hidden"
                              name="tag_id"
                              value={tag}
                            />
                          </div>
                        </Col>
                        <div className="button-items">
                          <Button
                            className="btn-ld"
                            color="primary"
                            type="submit"
                          >
                            Add
                          </Button>
                        </div>
                      </AvForm>
                    </TabPane>
                    <TabPane className="PrimaryTabs mt-2" tabId={"3"}>
                      {activity.length > 0 ? (
                        activity.map((row, i) => {
                          return (
                            <div key={i} className="activity-info">
                              <div className="activity-body">
                                <div className="activity-bubble">
                                  <div className="activity-content">
                                    <div className="activity_time">
                                      <div className="ActivityTitle">
                                        <span>{row.type}</span>
                                      </div>
                                      {row.created_at}
                                    </div>
                                    <p>{row.activity}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="col-12 col-lg-12 col-xl-12 text-center">
                          No record found
                        </div>
                      )}
                    </TabPane>
                    <TabPane className="PrimaryTabs mt-2" tabId={"4"}>
                      <select
                        value={script}
                        onChange={(e) => setScript(e.target.value)}
                        className="form-select"
                      >
                        <option value="">Select Script Templates</option>
                        {activity.map((row, i) => {
                          return (
                            <option key={i} value={`${i}`}>
                              {row.name}
                            </option>
                          );
                        })}
                      </select>

                      {script != "" && tab == "4" && activity.length > 0 && (
                        <div
                          className="mt-3"
                          dangerouslySetInnerHTML={{
                            __html: activity[script].script_text,
                          }}
                        />
                      )}
                    </TabPane>
                    <TabPane className="PrimaryTabs mt-2" tabId={"5"}>
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <button
                            onClick={() => {
                              setDynamicModal(true);
                              setDynamicModalName("Note");
                            }}
                            className="btn btn-sm btn-primary pull-right m-2 none"
                          >
                            Create Note
                          </button>
                        </div>
                      </div>
                      {activity.map((row, i) => {
                        return (
                          <div key={i} className="note activity-info">
                            <div className="activity-body">
                              <div className="activity-bubble">
                                <div className="activity-content">
                                  <div className="activity_time">
                                    <div className="ActivityTitle">
                                      <span>{row.type}</span>
                                    </div>
                                    {row.created_at}
                                  </div>
                                  <p>{row.activity}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </TabPane>
                    <TabPane className="PrimaryTabs mt-2" tabId={"6"}>
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <button
                            onClick={() => {
                              setpage4Class("slide-in");
                            }}
                            className="btn btn-sm btn-primary pull-right m-2 none"
                          >
                            Create Task
                          </button>
                        </div>
                      </div>
                      <Card className="px-0">
                        <div className="details">
                          <Accordion defaultActiveKey="10" className="border-0">
                            {activity.length > 0 ? (
                              activity.map((task, k) => {
                                return (
                                  <Accordion.Item key={k} eventKey={`${k}`}>
                                    <div className="accordion-head task">
                                      <Accordion.Header>
                                        Task assigned to
                                        {task.user ? task.user.name : ""}
                                      </Accordion.Header>
                                      <button className="btn btn-link">
                                        <i className="fas fa-calendar-alt"></i>{" "}
                                        Due
                                        {task.server_date} at {task.server_time}
                                      </button>
                                    </div>
                                    <Accordion.Body>
                                      <div className="card card-body" key={k}>
                                        <p>
                                          <button
                                            className="btn btn-sm btn-link"
                                            onClick={() => {
                                              setTaskCompleteModal(true);
                                              setTaskID(task.task_id);
                                            }}
                                          >
                                            <i className="fa fa-check " />
                                          </button>
                                          {task.title}
                                        </p>
                                        <ul>
                                          <li>
                                            <b>Due</b> {task.server_date} at
                                            {task.server_time}
                                          </li>
                                          <li>
                                            <b>Email reminder</b>
                                            {task.email_reminder}
                                          </li>
                                          <li>
                                            <b>Sms reminder</b>{" "}
                                            {task.sms_reminder}
                                          </li>
                                        </ul>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                );
                              })
                            ) : (
                              <div className="col-12 col-lg-12 col-xl-12 text-center">
                                No record found
                              </div>
                            )}
                          </Accordion>
                        </div>
                      </Card>
                    </TabPane>

                    {tab7 == "slide-in" && (
                      <div
                        className={`page3 ProfileClient ${tab7} tab-slider custom-scroll contract-details`}
                      >
                        <section className="breakthrough clientProfileHeading ToolKit sub-nav-bg mb-0 text-center">
                          <h5 className="mb-0">Inbox Activity</h5>
                        </section>
                        <button
                          onClick={() => {
                            setTab7("slide-out2");
                          }}
                          className="btn text-dark CloseBtn"
                        >
                          <i className="fas fa-times"></i>
                        </button>
                        <TabPane
                          tabId={"7"}
                          className="secondary-tabs profilePopup p-2 mt-5"
                        >
                          <div className="row">
                            <div className="col-lg-4">
                              <Card className="VariousCalls mb-2">
                                <CardBody>
                                  <section className="breakthrough clientProfileHeading ToolKit sub-nav-bg mb-0 text-center">
                                    <h5 className="mb-0">My Inbox</h5>
                                  </section>
                                  <div className="MonthlyDropDown mb-2">
                                    <select
                                      onChange={(e) => {
                                        setInboxDay(e.target.value);
                                        getInboxActivity("all", e.target.value);
                                      }}
                                      className="form-select"
                                    >
                                      <option value={"today"}>Today</option>
                                      <option value={"weekly"}>Weekly</option>
                                      <option value={"monthly"}>Monthly</option>
                                    </select>
                                  </div>
                                  <div className="border-three">
                                    <Row>
                                      <Col xl="6" lg="6" xs="6">
                                        <Card
                                          onClick={() =>
                                            getInboxActivity("sms")
                                          }
                                          className="Message Email p-2 mb-3"
                                        >
                                          <i className="mdi mdi-comment"></i>
                                          <div className="NumberFunc">
                                            {inbox_activity.sms_count}
                                          </div>
                                        </Card>
                                      </Col>
                                      <Col xl="6" lg="6" xs="6">
                                        <Card
                                          onClick={() =>
                                            getInboxActivity("email")
                                          }
                                          className="Email p-2 mb-3"
                                        >
                                          <i className="ion ion-md-mail"></i>
                                          <div className="NumberFunc">
                                            {inbox_activity.email_count}
                                          </div>
                                        </Card>
                                      </Col>
                                      <Col xl="6" lg="6" xs="6">
                                        <Card
                                          onClick={() =>
                                            getInboxActivity("call")
                                          }
                                          className="ReceivedCalls p-2"
                                        >
                                          <i className="ion ion-md-call"></i>
                                          <div className="NumberFunc">
                                            {inbox_activity.call_count}
                                          </div>
                                        </Card>
                                      </Col>
                                      <Col xl="6" lg="6" xs="6">
                                        <Card
                                          onClick={() =>
                                            getInboxActivity("voice")
                                          }
                                          className="MissedCalls p-2"
                                        >
                                          <i className="ion ion-md-call"></i>
                                          <div className="AlertBadge">
                                            <i className="fas fa-bookmark"></i>
                                            <span>
                                              {
                                                inbox_activity.voice_message_un_listen
                                              }
                                            </span>
                                          </div>
                                          <div className="NumberFunc">
                                            {inbox_activity.voice_count}
                                          </div>
                                        </Card>
                                      </Col>
                                    </Row>
                                  </div>

                                  <div className="RecentRecord ProfileMessages">
                                    {/* <section className="breakthrough clientProfileHeading ToolKit sub-nav-bg mb-0 text-center">
                                      <h5 className="mb-0">My Messages</h5></section> */}

                                    {/* <h4 className="card-title mt-2 mb-3 text-center"> <button onClick={() =>
                                      getInboxActivity('all')
                                    } className="StatusIcon Call btn-link pull-right" title="All Messages"><i className="fa fa-undo"></i></button></h4> */}

                                    <ul>
                                      {inbox_activity.report_results.length >
                                      0 ? (
                                        inbox_activity.report_results.map(
                                          (row, i) => {
                                            return (
                                              <li
                                                className="mb-3 mt-1"
                                                key={"message" + i}
                                              >
                                                <div
                                                  className={`StatusIcon ${row.background}`}
                                                >
                                                  {/* <i className="mdi mdi-comment"></i> */}
                                                  <i
                                                    className={`${row.icon}`}
                                                  ></i>
                                                </div>
                                                <div className="ContactDescription p-2">
                                                  <div className="NameUser">
                                                    <h6 className="card-title">
                                                      {row.client_name}
                                                    </h6>
                                                    <div className="MessageDate ActivityPopup">
                                                      {row.created_at}&nbsp;
                                                      <span className="roadmapDashboard">
                                                        {row.master_type ===
                                                        "Roadmap"
                                                          ? "(Roadmap)"
                                                          : ""}
                                                      </span>{" "}
                                                    </div>
                                                  </div>
                                                  <div className="UserMessage">
                                                    <p>{row.body}</p>
                                                  </div>
                                                </div>
                                              </li>
                                            );
                                          }
                                        )
                                      ) : (
                                        <li
                                          key={"message1"}
                                          className="mt-3 mb-2"
                                        >
                                          <div className="ContactDescription p-2">
                                            <div className="UserMessage text-center no-record">
                                              <p>No record found</p>
                                            </div>
                                          </div>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                </CardBody>
                              </Card>
                            </div>
                            <div className="col-lg-8">
                              <div className="ActivityPanel card card-body">
                                <section className="breakthrough clientProfileHeading ToolKit sub-nav-bg mb-0 text-center">
                                  <h5 className="mb-0">Recent Activity</h5>
                                </section>
                                <div className="ActivitiesList p-3">
                                  {activity.length > 0 ? (
                                    activity.map((row, i) => {
                                      return (
                                        <div
                                          key={"script" + i}
                                          className="activity-info"
                                        >
                                          <div className="activity-body">
                                            <div
                                              className={`activity-bubble ${row.type}`}
                                            >
                                              <div className="activity-content">
                                                <div className="activity_time">
                                                  <div className="ActivityTitle">
                                                    {/* <span> {row.type} </span> */}
                                                    <p
                                                      dangerouslySetInnerHTML={{
                                                        __html: row.activity,
                                                      }}
                                                    ></p>
                                                  </div>
                                                  {row.created_at}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <div className="col-12 col-lg-12 col-xl-12 text-center">
                                      No Record Found
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <Nav tabs>
                              <NavItem>
                                <NavLink
                                  className={btab == 7 ? "active" : ""}
                                  onClick={() => {
                                    btoggle("7");
                                  }}
                                >
                                  Email
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={btab == 8 ? "active" : ""}
                                  onClick={() => {
                                    btoggle("8");
                                  }}
                                >
                                  Sms
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={btab == 9 ? "active" : ""}
                                  onClick={() => {
                                    btoggle("9");
                                  }}
                                >
                                  Call
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={btab == 10 ? "active" : ""}
                                  onClick={() => {
                                    btoggle("10");
                                  }}
                                >
                                  RVM
                                </NavLink>
                              </NavItem>
                            </Nav>

                            <TabContent activeTab={btab}>
                              <TabPane tabId={"7"} className="mt-2">
                                {activity.length > 0 ? (
                                  activity.map((row, i) => {
                                    return (
                                      <div key={i} className="note activity-info">
                                        <div className="activity-body">
                                          <div className="activity-bubble email">
                                            <div className="activity-content">
                                              <div className="activity_time">
                                                <div className="ActivityTitle">
                                                  <span>{row.type}</span>
                                                </div>
                                                <div className="view-date">
                                                  <button
                                                    className="btn btn-link"
                                                    onClick={() => {
                                                      setSentEmailTemplateModal(true);
                                                      setEmailData(
                                                        row.report_sub_log
                                                          ? row.report_sub_log
                                                          : ""
                                                      );
                                                    }}
                                                  >
                                                    View
                                                  </button>
                                                  {row.created_at}
                                                </div>
                                              </div>
                                              <div className="d-flex space-between">
                                                <p>
                                                  <b>From :</b> {row.froms}
                                                </p>
                                                <p>
                                                  <b>To :</b> {row.tos}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div className="col-12 col-lg-12 col-xl-12 text-center">No record found</div>
                                )
                                }
                              </TabPane>
                              <TabPane tabId={"8"} className="mt-3">
                                {activity.length > 0 ? (
                                  activity.map((row, i) => {
                                    return (
                                      <div key={i} className="note activity-info">
                                        <div className="activity-body">
                                          <div className="activity-bubble sms">
                                            <div className="activity-content">
                                              <div className="activity_time">
                                                <div className="ActivityTitle">
                                                  <span>{row.type}</span>
                                                </div>
                                                {row.created_at}
                                              </div>
                                              <p>
                                                {row.report_sub_log
                                                  ? row.report_sub_log.body
                                                  : ""}
                                              </p>
                                              <div className="d-flex space-between">
                                                <p>
                                                  <b>From :</b> {row.froms}
                                                </p>
                                                <p>
                                                  <b>To :</b> {row.tos}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div className="col-12 col-lg-12 col-xl-12 text-center">No record found</div>
                                )
                                }
                              </TabPane>
                              <TabPane tabId={"9"} className="mt-3">
                                {activity.length > 0 ? (
                                  activity.map((row, i) => {
                                    return (
                                      <div key={i} className="note activity-info">
                                        <div className="activity-body">
                                          <div className="activity-bubble call">
                                            <div className="activity-content">
                                              <div className="activity_time">
                                                <div className="ActivityTitle">
                                                  <span>{row.type}</span>
                                                </div>
                                                <div className="view-date">

                                                  {row.report_sub_log
                                                    &&
                                                    <a
                                                      target={"_blank"}
                                                      href={`${row.report_sub_log.body}`}
                                                      className="btn btn-link p0"
                                                    >
                                                      Listen
                                                    </a>
                                                  }

                                                  {row.created_at}
                                                </div>
                                              </div>
                                              <div className="d-flex space-between">
                                                <p>
                                                  <b>From :</b> {row.froms}
                                                </p>
                                                <p>
                                                  <b>To :</b> {row.tos}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div className="col-12 col-lg-12 col-xl-12 text-center">No record found</div>
                                )
                                }
                              </TabPane>
                              <TabPane tabId={"10"} className="mt-3">
                                {activity.length > 0 ? (
                                  activity.map((row, i) => {
                                    return (
                                      <div key={i} className="note activity-info">
                                        <div className="activity-body">
                                          <div className="activity-bubble rvm">
                                            <div className="activity-content">
                                              <div className="activity_time">
                                                <div className="ActivityTitle">
                                                  <span>{row.type}</span>
                                                </div>
                                                <div className="view-date">
                                                  {row.report_sub_log
                                                    &&
                                                    <a
                                                      target={"_blank"}
                                                      href={`${row.report_sub_log.body}`}
                                                      className="btn btn-link p0"
                                                    >
                                                      Listen
                                                    </a>
                                                  }
                                                  {row.created_at}
                                                </div>
                                              </div>
                                              <div className="d-flex space-between">
                                                <p>
                                                  <b>From :</b> {row.froms}
                                                </p>
                                                <p>
                                                  <b>To :</b> {row.tos}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div className="col-12 col-lg-12 col-xl-12 text-center">No record found</div>
                                )
                                }
                              </TabPane>
                            </TabContent> */}
                        </TabPane>
                      </div>
                    )}

                    <TabPane className="PrimaryTabs mt-2" tabId={"11"}>
                      <table className="table">
                        <thead>
                          <tr>
                            <td>Sr</td>
                            <td>Contract Name </td>
                            <td>Assign Date</td>
                            <td>Last Updated</td>
                            <td>Action</td>
                          </tr>
                        </thead>
                        <tbody>
                          {activity.length > 0 ? (
                            activity.map((row, i) => {
                              return (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>
                                    {row.contract ? row.contract.name : ""}
                                  </td>
                                  <td>{row.assign_dated}</td>
                                  <td>
                                    {row.submitted_dated ==
                                    "0000-00-00 00:00:00"
                                      ? "Not Completed"
                                      : row.submitted_dated}
                                  </td>
                                  <td>
                                    {row.submitted_dated ==
                                    "0000-00-00 00:00:00" ? (
                                      <>
                                        <Link
                                          type="button"
                                          color="info"
                                          className="btn btn-link btn-sm mb-1 mr-5"
                                          // target={'_blank'}
                                          to={`/form/details?u=${bcrypt(
                                            row.client_id
                                          )}&meta=${bcrypt(row.id)}&c=${bcrypt(
                                            row.contract_id
                                          )}`}
                                        >
                                          <i className="fas fa-edit"></i>
                                        </Link>
                                      </>
                                    ) : (
                                      <>
                                        <Link
                                          title="Map this form"
                                          type="button"
                                          color="info"
                                          className="btn btn-link btn-sm mb-1 mr-5"
                                          // target={'_blank'}
                                          to={`/show/form?u=${bcrypt(
                                            row.client_id
                                          )}&meta=${bcrypt(row.id)}&c=${bcrypt(
                                            row.contract_id
                                          )}`}
                                        >
                                          <i className="fas fa-eye"></i>
                                        </Link>
                                      </>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr key="1" colSpan="7">
                              <td>
                                <div className="col-12 col-lg-12 col-xl-12 text-center">
                                  No record found
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </TabPane>

                    {tab12 == "slide-in" && (
                      <div
                        className={`page3 ProfileClient ${tab12} tab-slider custom-scroll contract-details`}
                      >
                        <div className="top-heading">
                          <div class="SidePopupsHead">
                            <div class="DashboardHeadingSection mb-4">
                              <h4 class="card-title">Events/Appointments</h4>
                            </div>
                            <button
                              onClick={() => {
                                setTab12("slide-out2");
                              }}
                              className="btn btn-link"
                            >
                              <i className="fas fa-times"></i>
                            </button>
                          </div>
                        </div>

                        <TabPane
                          className="PrimaryTabs mt-2 p-2 pt-0"
                          tabId={"12"}
                        >
                          <div className="row">
                            <div className="col-md-12 text-center">
                              <button
                                onClick={() => {
                                  setpage4Class("slide-in");
                                }}
                                className="btn btn-sm btn-primary pull-right m-2 none"
                              >
                                Create Task
                              </button>
                            </div>
                          </div>
                          <div className="NoShadow custom-scroll px-0">
                            <div className="details">
                              <Accordion
                                defaultActiveKey="10"
                                className="border-0"
                              >
                                {activity.length > 0 ? (
                                  activity.map((row, k) => {
                                    return (
                                      <Accordion.Item key={k} eventKey={`${k}`}>
                                        <div className="accordion-head deal-pipeline remove-arrow">
                                          <Accordion.Header>
                                            {row.master_type == "task" ? (
                                              <Link to={`#`}>
                                                {row.event_title}
                                              </Link>
                                            ) : (
                                              <Link
                                                to={`/view/meeting?u=${bcrypt(
                                                  row.client_id
                                                )}&c_id=${bcrypt(
                                                  row.coach_id
                                                )}&meta=${bcrypt(
                                                  row.meeting_id
                                                )}`}
                                              >
                                                {row.event_title}
                                              </Link>
                                            )}
                                          </Accordion.Header>
                                          <button
                                            disabled={true}
                                            className="btn btn-link"
                                          >
                                            <i className="fas fa-calendar-alt"></i>{" "}
                                            Time {row.server_date}{" "}
                                            {row.from_time}
                                          </button>
                                          {/* {row.type == 'schedule' ? (
                                              <button disabled={true} className="btn btn-link">
                                                <i className="fas fa-calendar-alt"></i> Meeting Time {row.server_date} {row.from_time}
                                              </button>
                                            ) :
                                              (
                                                <button disabled={true} className="btn btn-link">
                                                  <i className="fas fa-calendar-alt"></i> Meeting Time {row.created_at}
                                                </button>
                                              )
                                            } */}
                                        </div>
                                      </Accordion.Item>
                                    );
                                  })
                                ) : (
                                  <div className="col-12 col-lg-12 col-xl-12 text-center">
                                    No record found
                                  </div>
                                )}
                              </Accordion>
                            </div>
                          </div>
                        </TabPane>
                      </div>
                    )}

                    {tab13 == "slide-in" && (
                      <div
                        className={`page3 ProfileClient ${tab13} tab-slider custom-scroll contract-details`}
                      >
                        <div className="modal-head top-heading">
                          <h2>Assessment</h2>
                          <button
                            onClick={() => {
                              setTab13("slide-out2");
                            }}
                            className="btn btn-link text-white"
                          >
                            <i className="fas fa-times"></i>
                          </button>
                        </div>

                        <TabPane className="PrimaryTabs" tabId={"13"}>
                          <div className="row">
                            <div className="col-md-12 text-center">
                              {/* <button
                        onClick={() => {
                          setpage9Class("slide-in");
                        }}
                        className="btn btn-sm btn-primary pull-right m-2"
                      >
                        New Assessment
                      </button> */}
                            </div>
                          </div>
                          <div className="px-2">
                            <div className="details mt-3">
                              <Accordion
                                defaultActiveKey="10"
                                className="border-0"
                              >
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <td>#</td>
                                      <td>Name</td>
                                      <td>Created At</td>
                                      <td>Action</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {activity.length > 0 ? (
                                      activity.map((row, i) => {
                                        return (
                                          <tr key={i}>
                                            <td>{row.assessment_id}</td>
                                            <td>
                                              <Link
                                                to={
                                                  "/assessment/" +
                                                  bcrypt(row.assessment_id) +
                                                  "/summary"
                                                }
                                              >
                                                {row.name ? row.name : ""}
                                              </Link>
                                            </td>
                                            <td>{row.created_at}</td>
                                            <td>
                                              <Link
                                                to={
                                                  "/assessment/" +
                                                  bcrypt(row.assessment_id) +
                                                  "/summary"
                                                }
                                                title="Assessment Calculator"
                                                className="mr-5"
                                              >
                                                <i className="fas fa-calculator"></i>
                                              </Link>
                                              <Link
                                                to={
                                                  "/profit/center/" +
                                                  bcrypt(row.assessment_id) +
                                                  "/summary"
                                                }
                                                title="Profit Center"
                                              >
                                                <i className="far fa-money-bill-alt"></i>
                                              </Link>
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr key="1">
                                        <td colSpan={7}>
                                          <div className="col-md-12 text-center text-center">
                                            No record found
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </Accordion>
                            </div>
                          </div>
                        </TabPane>
                      </div>
                    )}
                    {tab14 == "slide-in" && (
                      <div
                        className={`page3 ProfileClient ${tab14} tab-slider custom-scroll contract-details`}
                      >
                        <div className="top-heading">
                          <div class="SidePopupsHead">
                            <div class="DashboardHeadingSection mb-4">
                              <h4 class="card-title">Invoices</h4>
                            </div>
                            <button
                              onClick={() => {
                                setTab14("slide-out2");
                              }}
                              className="btn btn-link"
                            >
                              <i className="fas fa-times"></i>
                            </button>
                          </div>
                        </div>
                        <TabPane
                          className="PrimaryTabs mt-2 p-2 pt-0"
                          tabId={"14"}
                        >
                          <div className="px-0">
                            <div className="details">
                              <Accordion
                                defaultActiveKey="10"
                                className="border-0"
                              >
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <td> Invoice# </td> <td> Start Date </td>
                                      <td> Expiry Date </td>{" "}
                                      <td> Type(Recurring#) </td>
                                      <td> Total Price </td> <td> Status </td>
                                      <td> Action </td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {activity.length > 0 ? (
                                      activity.map((row, i) => {
                                        console.log(row);
                                        return (
                                          <tr key={"invoice-" + i}>
                                            <td>
                                              #IN -{" "}
                                              {String(row.invoice_id).padStart(
                                                5,
                                                "0"
                                              )}
                                            </td>
                                            <td> {row.start_date} </td>
                                            <td> {row.expire_date} </td>
                                            <td> {row.type} </td>
                                            <td> {row.total_price} </td>
                                            {/* <td> {row.payment_status} </td> */}
                                            <td>
                                              {" "}
                                              {row.payment_status == "Paid" ? (
                                                <p className="badge bg-success">
                                                  {row.payment_status}
                                                </p>
                                              ) : row.payment_status ==
                                                "Unpaid" ? (
                                                <p className="badge bg-danger">
                                                  {row.payment_status}
                                                </p>
                                              ) : (
                                                <p>{row.payment_status}</p>
                                              )}{" "}
                                            </td>
                                            <td>
                                              <Link
                                                to={
                                                  "/invoice/preview/" +
                                                  bcrypt(row.invoice_id)
                                                }
                                                className="btn btn-link"
                                                title="Preview"
                                              >
                                                <i className="fas fa-eye"> </i>
                                              </Link>

                                              {row.type == "Recurring" &&
                                                row.payment_status !=
                                                  "Unpaid" && (
                                                  <Link
                                                    to={
                                                      "/invoice/transactions/" +
                                                      bcrypt(row.invoice_id)
                                                    }
                                                    target=""
                                                    className="btn btn-link"
                                                    title="Transaction History"
                                                  >
                                                    <img
                                                      src={transactionHistory}
                                                      className="tx_history"
                                                    />
                                                    {/* <i class="fas fa-money-bill-alt"></i> */}
                                                  </Link>
                                                )}
                                              {row.payment_status ==
                                                "Unpaid" && (
                                                <Link
                                                  to={
                                                    "/invoice/pay/" +
                                                    bcrypt(row.invoice_id)
                                                  }
                                                  target="_blank"
                                                  className="btn btn-stripe"
                                                  title="Pay Invoice"
                                                >
                                                  <i className="fas fa-credit-card">
                                                    {" "}
                                                  </i>
                                                </Link>
                                              )}

                                              {row.payment_status == "Paid" &&
                                                row.download_link != "" && (
                                                  <>
                                                    {"|"}
                                                    <a
                                                      href={row.download_link}
                                                      target={"_blank"}
                                                      className="btn btn-link"
                                                      title="Download"
                                                    >
                                                      <i className="fas fa-download">
                                                        {" "}
                                                      </i>
                                                    </a>
                                                  </>
                                                )}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr key="1">
                                        <td colSpan={7}>
                                          <div className="col-md-12 text-center text-center">
                                            No record found
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </Accordion>
                            </div>
                          </div>
                        </TabPane>
                      </div>
                    )}

                    {page16Class == "slide-in" && (
                      <div
                        className={`page3 ProfileClient ${page16Class} white-bg tab-slider custom-scroll contract-details`}
                      >
                        <section className="breakthrough clientProfileHeading ToolKit sub-nav-bg mb-0 text-center w-50">
                          <h5 className="mb-0">Task Manager</h5>
                        </section>
                        <button
                          onClick={() => {
                            setpage16Class("slide-out2");
                          }}
                          className="btn text-dark CloseBtn"
                        >
                          <i className="fas fa-times"></i>
                        </button>

                        <div className="ProfileBreakDown PTracker ClientProfile">
                          <div className="MonthlyDropDown mb-1 mt-3">
                            <select
                              onChange={(e) => {
                                getActionStepsResults(e.target.value);
                              }}
                              className="form-select"
                            >
                              <option value={""}>Impact Selector</option>
                              {path_way_progression.length > 0 &&
                                path_way_progression.map((row, i) => {
                                  return (
                                    <option key={i} value={row.cat_key}>
                                      {row.cat_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <ul className="TasksAsign pl-3">
                            {action_results.length > 0 ? (
                              action_results.map((row, i) => {
                                return (
                                  <li key={i} className="pb-3 ">
                                    <div
                                      className={`TaskNumber px-2 ${row.status}`}
                                    >
                                      {row.listing_order}
                                    </div>
                                    <div className="TaskDesription-Ptracker w-100">
                                      <div className="task-client-action">
                                        {row.status == "complete" ? (
                                          <div className="task-complete d-flex">
                                            <div className="task">
                                              <label>
                                                Complete{" "}
                                                <span className="fas fa-carot"></span>
                                              </label>
                                              <button
                                                className="btn btn-success ml-5 px-3"
                                                disabled
                                              >
                                                Verified
                                              </button>
                                            </div>
                                          </div>
                                        ) : row.status == "assigned" ? (
                                          <div className="task-assigned">
                                            <div className="task">
                                              <label>
                                                Assigned{" "}
                                                <span className="fas fa-carot"></span>
                                              </label>
                                              <button
                                                onClick={() =>
                                                  actionStepVerify(row.id)
                                                }
                                                className="btn btn-primary ml-5 px-3"
                                              >
                                                Click to verify
                                              </button>
                                            </div>
                                          </div>
                                        ) : row.status == "in-review" ? (
                                          <div className="task-pending">
                                            <div className="task">
                                              <label>
                                                In Review{" "}
                                                <span className="fas fa-carot"></span>
                                              </label>
                                              {/* <button className="btn btn-primary ml-5 px-3" disabled>{'>>>'}</button> */}
                                            </div>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      <h3>{row.title}</h3>
                                      <p
                                        className="mt-2 html-content-step-client"
                                        dangerouslySetInnerHTML={{
                                          __html: row.description,
                                        }}
                                      />
                                      <div
                                        className="complete-box mt-2"
                                        key={row.id}
                                      >
                                        {row.status == "complete" ? (
                                          <>
                                            <input
                                              type={"checkbox"}
                                              name={"complete_action"}
                                              key={row.id}
                                              checked
                                              disabled
                                            ></input>{" "}
                                            Complete
                                          </>
                                        ) : (
                                          <input
                                            id={`complete_${row.id}`}
                                            disabled
                                            type={"checkbox"}
                                            name={"complete_action"}
                                            key={row.idi}
                                          ></input>
                                        )}
                                      </div>
                                    </div>
                                  </li>
                                );
                              })
                            ) : (
                              <div className="col-12 col-lg-12 col-xl-12 text-center">
                                No Record Found
                              </div>
                            )}
                          </ul>
                        </div>
                      </div>
                    )}
                  </TabContent>
                </CardBody>
              </Card>
            </div>
            <div className="col-lg-4 px-1">
              <Card className="NoShadow custom-scroll Activity UserActivityDetails ProfileClientActivity Events mb-4 mt-3">
                <CardBody>
                  <section className="breakthrough clientProfileHeading ToolKit sub-nav-bg mb-0 text-center">
                    <h5 className="mb-0">Events</h5>
                  </section>
                  <div className="ProfileBreakDown">
                    {calendarReload && (
                      <CalanderView
                        scheduleEvent={scheduleEvent}
                        clientId={idx}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </Col>
      </Row>
      <div className="dialer">
        <Loader loaded={loader}></Loader>
      </div>
      <div className={`page3 ProfileClient ${page3Class} custom-scroll`}>
        <div>
          <div className="modal-head top-heading">
            <h2>Add company to this client</h2>
            <button
              onClick={() => {
                setpage3Class("slide-out2");
              }}
              className="btn btn-link text-white"
            >
              <i className="fas fa-times"></i>
            </button>
          </div>

          <Tabs defaultActiveKey="existing" className="mb-3">
            <Tab eventKey="existing" title="Association existing">
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <div className="col-md-8 offset-2">
                        <div className="form-group relative">
                          <input
                            value={
                              searchClient != "" && searchClient != null
                                ? searchClient
                                : ""
                            }
                            onChange={(e) => setSearchClient(e.target.value)}
                            placeholder="Search clients"
                            name="searh"
                            type={"text"}
                            className={"form-control"}
                          />

                          {searchClient == "" ? (
                            <button className="btn btn-sm searchBtn">
                              <span className="fas fa-search"></span>
                            </button>
                          ) : (
                            <button
                              onClick={() => setSearchClient(null)}
                              className="btn btn-sm searchBtn"
                            >
                              <span className="fas fa-times"></span>
                            </button>
                          )}
                        </div>
                        <p>{existing.length} results</p>

                        {existing.length < 1 && searchClient != "" && (
                          <p className="text-danger">
                            No company match the current search.
                          </p>
                        )}

                        {existing.length > 0 && (
                          <form method="POST" onSubmit={handleExistingClient}>
                            <fieldset className="form-group">
                              {/* onChange={this.handleChange.bind(this)} */}
                              <div className="form-control border-0 p-0 h-auto is-untouched is-pristine av-valid">
                                {existing.map((row, i) => {
                                  return (
                                    <div className="form-check pt-3" key={i}>
                                      <input
                                        name={`company_id[]`}
                                        type="radio"
                                        className=" form-check-input"
                                        value={`${row.client_id}`}
                                      />
                                      <label className="form-check-label">{`${row.first_name} ${row.last_name} (${row.email})`}</label>
                                    </div>
                                  );
                                })}
                              </div>
                            </fieldset>

                            <button
                              className="btn btn-lg btn-primary my-3"
                              type="submit"
                            >
                              Submit
                            </button>
                          </form>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="new" title="Association new">
              {/* <CompanyCreateUpdate
                ref={childClientRef}
                getData={getCompanyClients}
                compannyId={info.client_id}
                from="clientDetails"
              /> */}
            </Tab>
          </Tabs>
        </div>
      </div>

      <Modal
        isOpen={smsModal}
        fade={true}
        size="md"
        toggle={() => {
          setSmsModal(false);
        }}
      >
        <AvForm
          onValidSubmit={handleSendSMSToCoachSubmit}
          // model={this.state.record}
          className="needs-validation"
        >
          <div className="note">
            <div className="modal-head top-heading">
              <h2>Send Sms</h2>
              <button
                onClick={() => {
                  setSmsModal(false);
                }}
                type="button"
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
            <ModalBody>
              <div className="mb-3">
                <AvField
                  className="form-control"
                  type="textarea"
                  name="sms"
                  label=""
                  placeholder="Enter a message"
                  required
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button className="btn-sm" color="info" type="submit">
                Send Sms
              </Button>
              <Button
                className="btn-sm"
                color="danger"
                onClick={() => setSmsModal(false)}
              >
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </AvForm>
      </Modal>

      <Modal
        isOpen={DynamicModal}
        fade={true}
        size="md"
        toggle={() => {
          setDynamicModal(false);
          setSmsBody("");
          setToken("");
          setSubject("");
          setHtml("");
        }}
      >
        <AvForm
          onValidSubmit={handleSubmit}
          // model={this.state.record}
          className="needs-validation"
        >
          {DynamicModalName == "Note" ? (
            <div className="note">
              <div className="modal-head top-heading">
                <h2>Add Note</h2>
                <button
                  onClick={() => {
                    setDynamicModal(false);
                  }}
                  type="button"
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <ModalBody>
                <div className="mb-3">
                  <AvField
                    className="form-control"
                    type="textarea"
                    name="activity"
                    label=""
                    placeholder="Add a note"
                    required
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button className="btn-sm" color="info" type="submit">
                  Add Note
                </Button>
                <Button
                  className="btn-sm"
                  color="danger"
                  onClick={() => setDynamicModal(false)}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          ) : DynamicModalName == "sms" ? (
            <div className="sms">
              <div className="modal-head top-heading">
                <h2>Send Sms</h2>
                <button
                  onClick={() => {
                    setDynamicModal(false);
                  }}
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>

              <ModalBody>
                <div className="mb-3">
                  <Select
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    // value={options.filter(({ id }) => id === this.state.country_code)}
                    isMulti={false}
                    options={templates}
                    classNamePrefix="select2-selection"
                    name="country_id"
                    placeholder={"Select sms template"}
                    onChange={(e) => {
                      setSmsBody(e.body);
                    }}
                  />
                  <AvField type="hidden" name="tos" value={info.mobile} />
                  <AvField type="hidden" name="activity" value={"sms send"} />
                </div>

                <div className="mb-3">
                  <AvField
                    className="select form-select"
                    type="select"
                    name="froms"
                    required
                  >
                    <option value=""> Select Phone Number </option>
                    {phoneNumbers.map((row, i) => {
                      return (
                        <option key={i} value={row.phone_number}>
                          {row.phone_number}
                        </option>
                      );
                    })}
                  </AvField>
                </div>
                <div className="row mb-3">
                  <div className="col-md-8">
                    <Select
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.key}
                      // value={options.filter(({ id }) => id === this.state.country_code)}
                      isMulti={false}
                      options={tokens}
                      classNamePrefix="select2-selection"
                      name="country_id"
                      placeholder={"Select token"}
                      onChange={(e) => {
                        setToken(e.key);
                      }}
                    />
                    {/* <AvField type="hidden" name="country_code" value={token} /> */}
                  </div>
                  <div className="col-md-4">
                    <button
                      onClick={() => setSmsBody(`{${token}} ${smsBody}`)}
                      type="button"
                      className="btn btn-primary"
                    >
                      Insert Token
                    </button>
                  </div>
                </div>

                <div className="mb-3">
                  <AvField
                    className="form-control"
                    type="textarea"
                    name="body"
                    required
                    value={smsBody}
                    placeholder={"Enter body"}
                    onChange={(e) => setSmsBody(e.target.value)}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button className="btn-sm" color="info" type="submit">
                  Send
                </Button>
                <Button
                  className="btn-sm"
                  color="danger"
                  onClick={() => setDynamicModal(false)}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          ) : DynamicModalName == "rvm" ? (
            <div className="rvm">
              <div className="modal-head top-heading">
                <h2>Send RVM</h2>
                <button
                  onClick={() => {
                    setDynamicModal(false);
                  }}
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>

              <ModalBody>
                <div className="mb-3">
                  <Select
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    // value={options.filter(({ id }) => id === this.state.country_code)}
                    isMulti={false}
                    options={templates}
                    classNamePrefix="select2-selection"
                    name="country_id"
                    placeholder={"Select sms template"}
                    onChange={(e) => {
                      setSmsBody(e.id);
                    }}
                  />
                  <AvField
                    type="hidden"
                    name="tos"
                    value={info.mobile ? info.mobile : info.phone}
                  />
                  <AvField type="hidden" name="activity" value={"RVM: send"} />
                  <AvField type="hidden" name="template_id" value={smsBody} />
                </div>

                <div className="mb-3">
                  <AvField
                    className="select form-select"
                    type="select"
                    name="froms"
                    required
                  >
                    <option value=""> Select Phone Number </option>
                    {phoneNumbers.map((row, i) => {
                      return (
                        <option key={i} value={row.phone_number}>
                          {row.phone_number}
                        </option>
                      );
                    })}
                  </AvField>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button className="btn-sm" color="info" type="submit">
                  Send
                </Button>
                <Button
                  className="btn-sm"
                  color="danger"
                  onClick={() => setDynamicModal(false)}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          ) : DynamicModalName == "call" ? (
            <div className="call">
              <div className="modal-head top-heading">
                <h2>Initiate Outbound Call</h2>
                <button
                  onClick={() => {
                    setDynamicModal(false);
                  }}
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>

              <ModalBody>
                <div className="mb-3">
                  {callToken ? (
                    <Phone paramsters={paramsters}></Phone>
                  ) : (
                    <p>Loading...</p>
                  )}
                </div>
              </ModalBody>
            </div>
          ) : DynamicModalName == "meeting" ? (
            <div className="meeting">
              <div className="modal-head top-heading">
                <h2> Schedule an event </h2>
                <button
                  onClick={() => {
                    setDynamicModal(false);
                    setSmsBody("");
                    setToken("");
                    setSubject("");
                    setHtml("");
                  }}
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"> </i>
                </button>
              </div>
              <ModalBody>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <AvField
                      name="event_title"
                      label="Title"
                      className="form-control"
                      required
                      type="text"
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <AvField
                      name="server_date"
                      label="Select Date"
                      className="form-control"
                      required
                      type="date"
                    />
                    <AvInput
                      name="body"
                      value={`A meeting schedule  request sent  to ({client_name}) {link}`}
                      type="hidden"
                    />
                    <AvInput
                      name="calendar_id"
                      value={defaultCalendar}
                      type="hidden"
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <AvField
                      name="server_time"
                      label="Select Time"
                      className="form-control"
                      required
                      type="time"
                    />
                  </div>
                  <Col md={6}>
                    <AvGroup>
                      <label> MainTime Zone </label>
                      <div className="MainTZone MainTime One">
                        <Select
                          value={maintimezone}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.id}
                          isMulti={false}
                          options={maintimezones}
                          classNamePrefix="select2-selection"
                          className={`${
                            maintimezone != ""
                              ? "is-touched is-dirty av-valid"
                              : "is-touched is-pristine av-invalid is-invalid"
                          }`}
                          name="time_zone"
                          onChange={(e) => {
                            settimezone(e);
                          }}
                        />
                      </div>
                      <AvInput
                        type="hidden"
                        required
                        name="time_zone_1"
                        value={maintimezone.id}
                      />
                      <AvFeedback> This is required * </AvFeedback>
                    </AvGroup>
                  </Col>
                  <Col md={6}>
                    <AvGroup>
                      <label> Time Zone </label>
                      <div className="MainTZone MainTime TwoTimeZone">
                        <Select
                          value={timezone}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.id}
                          isMulti={false}
                          options={timeZone_data}
                          classNamePrefix="select2-selection"
                          className={`${
                            timezone != ""
                              ? "is-touched is-dirty av-valid"
                              : "is-touched is-pristine av-invalid is-invalid"
                          }`}
                          name="time_zone1"
                          onChange={(e) => {
                            setTimeZone(e);
                          }}
                        />
                      </div>
                      <AvInput
                        type="hidden"
                        required
                        name="time_zone"
                        value={timezone.id}
                      />
                      <AvFeedback> This is required * </AvFeedback>
                    </AvGroup>
                  </Col>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button className="btn-sm" color="info" type="submit">
                  Schedule
                </Button>
                <Button
                  className="btn-sm"
                  color="danger"
                  onClick={() => setDynamicModal(false)}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          ) : (
            <div className=""></div>
          )}
        </AvForm>
      </Modal>

      <Modal
        isOpen={taskModal}
        fade={true}
        size="md"
        toggle={() => {
          setTaskModal(false);
        }}
      >
        <AvForm onValidSubmit={handleTaskSubmit} className="needs-validation">
          <div className="note">
            <div className="modal-head top-heading">
              <h2> Add Task </h2>
              <button
                onClick={() => {
                  setTaskModal(false);
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <ModalBody className="pb-0">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <AvField
                    name="event_title"
                    label="Task Title"
                    className="form-control"
                    required
                    type="text"
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <AvField
                    name="server_date"
                    label="Select Date"
                    className="form-control"
                    required
                    type="date"
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <AvField
                    name="server_time"
                    label="Select Time"
                    className="form-control"
                    required
                    type="time"
                  />
                </div>
                <Col md={6} className="d-none">
                  <AvGroup>
                    <label> MainTime Zone </label>
                    <div className="MainTZone MainTime One">
                      <Select
                        value={maintimezone}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.id}
                        isMulti={false}
                        options={maintimezones}
                        classNamePrefix="select2-selection"
                        className={`${
                          maintimezone != ""
                            ? "is-touched is-dirty av-valid"
                            : "is-touched is-pristine av-invalid is-invalid"
                        }`}
                        name="time_zone"
                        onChange={(e) => {
                          settimezone(e);
                        }}
                      />
                    </div>
                    <AvInput
                      type="hidden"
                      name="time_zone_1"
                      value={maintimezone.id}
                    />
                    <AvFeedback> This is required * </AvFeedback>
                  </AvGroup>
                </Col>
                <Col md={6} className="d-none">
                  <AvGroup>
                    <label> Time Zone </label>
                    <div className="MainTZone MainTime One Two">
                      <Select
                        value={timezone}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.id}
                        isMulti={false}
                        options={timeZone_data}
                        classNamePrefix="select2-selection"
                        className={`${
                          timezone != ""
                            ? "is-touched is-dirty av-valid"
                            : "is-touched is-pristine av-invalid is-invalid"
                        }`}
                        name="time_zone1"
                        onChange={(e) => {
                          setTimeZone(e);
                        }}
                      />
                    </div>
                    <AvInput
                      type="hidden"
                      name="time_zone"
                      value={timezone.id}
                    />
                    <AvFeedback> This is required * </AvFeedback>
                  </AvGroup>
                </Col>
                <Col md={12}>
                  <div className="MainTZone Task Two">
                    <AvField
                      className="select form-select"
                      type="select"
                      name="assign_to"
                      label="Assign To"
                      classNamePrefix="select2-selection"
                    >
                      <option value={""}> Un Assign </option>
                      <optgroup label="Self">
                        <option key={-1} value={info.linked_user_id}>
                          {info.first_name +
                            " " +
                            info.last_name +
                            " - " +
                            info.email}
                        </option>
                      </optgroup>
                      <optgroup label="Coach">
                        <option key={-2} value={coachUser.user_id}>
                          {coachUser.name + " - " + coachUser.email}
                        </option>
                      </optgroup>
                      <optgroup label="Coach Partner">
                        {coachPartners.map((row, i) => {
                          return (
                            <option key={i} value={row.user_id}>
                              {row.name + " - " + row.email}
                            </option>
                          );
                        })}
                      </optgroup>
                      {/* <optgroup label="Self">
                        {coachClients.map((row, i) => {
                          return (
                            <option key={i} value={row.user_id}>
                              {row.name + " - " + row.email}
                            </option>
                          );
                        })}
                      </optgroup> */}
                    </AvField>
                  </div>
                </Col>
              </div>
              <div className="mb-0">
                <AvField
                  type="hidden"
                  name="client_id"
                  value={info.client_id}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button className="btn-sm" color="info" type="submit">
                Save
              </Button>
              <Button
                className="btn-sm"
                color="danger"
                onClick={() => setTaskModal(false)}
              >
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </AvForm>
      </Modal>

      <Modal
        isOpen={EmailModal}
        fade={true}
        size="lg"
        toggle={() => {
          setEmailModal(false);
          setSmsBody("");
          setToken("");
          setSubject("");
          setHtml("");
        }}
      >
        <AvForm
          onValidSubmit={handleEmailSubmit}
          autoComplete="off"
          className="needs-validation"
        >
          <div className="email">
            <div className="modal-head top-heading">
              <h2>Send Email</h2>
              <button
                onClick={() => {
                  setEmailModal(false);
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>

            <ModalBody>
              <div className="mb-3">
                <Select
                  getOptionLabel={(option) => option.title}
                  getOptionValue={(option) => option.id}
                  // value={options.filter(({ id }) => id === this.state.country_code)}
                  isMulti={false}
                  options={templates}
                  classNamePrefix="select2-selection"
                  placeholder={"Select email template"}
                  name="country_id"
                  onChange={(e) => {
                    setSubject(e.title);
                    getEmailTemplateHtml(e.id);
                  }}
                />
                <AvField type="hidden" name="tos" value={info.email} />
                <AvField type="hidden" name="activity" value={"Email: sent"} />
              </div>

              <div className="mb-3">
                <AvField
                  className="select form-select"
                  type="select"
                  name="froms"
                  required
                >
                  <option value=""> Select email setting </option>
                  {emailSetting.map((row, i) => {
                    return (
                      <option key={i} value={row.other_settings.from_email}>
                        {row.other_settings.from_email}
                      </option>
                    );
                  })}
                </AvField>
              </div>
              <div className="row mb-3 d-none">
                <div className="col-md-12 mb-3">
                  <Select
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.key}
                    // value={options.filter(({ id }) => id === this.state.country_code)}
                    isMulti={false}
                    options={tokens}
                    classNamePrefix="select2-selection"
                    name="country_id"
                    onChange={(e) => {
                      setToken(e.key);
                    }}
                  />
                  {/* <AvField type="hidden" name="country_code" value={token} /> */}
                </div>
                {html == "" && (
                  <div className="col-md-4">
                    <button
                      onClick={() => setSmsBody(`{${token}} ${smsBody}`)}
                      type="button"
                      className="btn btn-primary btn-sm"
                    >
                      Insert Body
                    </button>
                  </div>
                )}
                <div className="col-md-4">
                  <button
                    onClick={() => setSubject(`{${token}} ${subject}`)}
                    type="button"
                    className="btn btn-primary btn-sm"
                  >
                    Insert Subject
                  </button>
                </div>
              </div>

              <div className="mb-3">
                <AvField
                  className="form-control"
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  required
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>

              {html == "" ? (
                <div className="mb-3">
                  <AvField
                    className="form-control"
                    type="textarea"
                    name="body"
                    placeholder="Enter body"
                    required
                    value={smsBody}
                    onChange={(e) => setSmsBody(e.target.value)}
                  />
                </div>
              ) : (
                <>
                  <div
                    id="custom-template"
                    className="height-500 custom-scroll"
                    dangerouslySetInnerHTML={{ __html: html }}
                  ></div>
                  {/* <AvField type="hidden" name="body" value={document.getElementById('custom-template')}/> */}
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button className="btn-sm" color="info" type="submit">
                Send
              </Button>
              <Button
                className="btn-sm"
                color="danger"
                onClick={() => setEmailModal(false)}
              >
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </AvForm>
      </Modal>

      <Modal
        isOpen={sentEmailTemplateModal}
        fade={true}
        size="lg"
        toggle={() => {
          setSentEmailTemplateModal(false);
        }}
      >
        <div className="email">
          <div className="modal-head top-heading">
            <h2>Email</h2>
            <button
              onClick={() => {
                setSentEmailTemplateModal(false);
              }}
              className="btn btn-link text-white"
            >
              <i className="fas fa-times"></i>
            </button>
          </div>

          <ModalBody>
            <div
              className="height-500 custom-scroll"
              dangerouslySetInnerHTML={{ __html: emailData.body }}
            />
          </ModalBody>
        </div>
      </Modal>

      <Modal
        isOpen={taskCompleteModal}
        fade={false}
        toggle={() => setTaskCompleteModal(false)}
      >
        <ModalBody>
          <div className="warning-box text-center">
            <span className="warning">
              <i className="mdi mdi-alert-circle"></i>
            </span>
            <h3>Are you sure?</h3>
            <p>You want to mark this complete?</p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-sm"
            color="info"
            onClick={() => completeTask()}
          >
            Yes, Change it
          </Button>
          <Button
            className="btn-sm"
            color="danger"
            onClick={() => setTaskCompleteModal(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {page4Class == "slide-in" && (
        <div className={`page3 ProfileClient ${page4Class} custom-scroll`}>
          <div>
            <div className="modal-head top-heading">
              <h2>Add Task </h2>
              <button
                onClick={() => {
                  setpage4Class("slide-out2");
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
            <CreateTask
              cancelSlider={cancelTask}
              compannyId={info.client_id}
              from="companyDetails"
            />
          </div>
        </div>
      )}

      {page5Class == "slide-in" && (
        <div
          className={`page3 ProfileClient ${page5Class} custom-scroll deal-assign`}
        >
          <div>
            <div className="modal-head top-heading">
              <h2>Deal Status </h2>
              <button
                onClick={() => {
                  setpage5Class("slide-out2");
                  setPipelineModal(false);
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
            <Card>
              <CardBody>
                {pipelineStatus.length > 0 && (
                  <>
                    <Dropdown className="d-inline mx-2">
                      <Dropdown.Toggle
                        className="btn btn-link text-primary"
                        id="dropdown-autoclose-true"
                      >
                        {pipeline.name} <i className="fas fa-angle-down"></i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {pipelineStatus.map((pstatus, i) => {
                          return (
                            <Dropdown.Item
                              key={i}
                              onClick={() => {
                                setPipeline(pstatus);
                                setPipelineModal(false);
                              }}
                              eventKey={"1"}
                            >
                              {pstatus.name}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                )}
                <div className="pipeline">
                  <ul>
                    {pipeline.pipeline_status.map((row, i) => {
                      return (
                        <li
                          onClick={() => {
                            setSelectedPipeline(row);
                            setPipelineModal(true);
                          }}
                          key={i}
                          className="py-3"
                        >
                          {row.title}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      )}

      {page6Class == "slide-in" && (
        <div
          className={`page3 ProfileClient ${page6Class} custom-scroll attachment-details`}
        >
          <div className="top-heading AttachmentsSec">
            <div className="SidePopupsHead">
              <div class="DashboardHeadingSection mb-4">
                <h4 class="card-title">Attachment</h4>
              </div>
              <button
                onClick={() => {
                  setpage6Class("slide-out2");
                }}
                className="btn btn-link "
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
          </div>
          <Tabs defaultActiveKey="existing" className="mb-1 pt-2 m-0">
            <Tab
              className="ExistingAttachment"
              eventKey="existing"
              title="Existing Attachment"
            >
              <Row>
                <div className="col-12 px-4">
                  <div className="table-responsive">
                    <table className="table table-bordered border-0">
                      <thead>
                        <tr>
                          <td>Title </td>
                          <td>Created At</td>
                          <td>Action</td>
                        </tr>
                      </thead>
                      <tbody>
                        {attachment &&
                          attachment.id &&
                          JSON.parse(attachment.attachments).map((row, k) => {
                            return (
                              <tr key={k}>
                                <td>{row.file_title}</td>
                                <td>{row.created_at}</td>
                                <td>
                                  <a
                                    target="_blank"
                                    href={`${AttachementsUrl}${row.file}`}
                                  >
                                    <i className="fas fa-download"></i>
                                  </a>
                                  <button
                                    className="btn btn-link"
                                    title="remove"
                                    onClick={() =>
                                      removeAttachement(attachment.id, k)
                                    }
                                  >
                                    <i className="fas fa-times"></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Row>
            </Tab>
            <Tab eventKey="new" title="Add New">
              <ModalBody>
                <Col className="col-12">
                  {loader && (
                    <AvForm
                      onValidSubmit={handleAtSubmit}
                      ref={(c) => (this_form = c)}
                      className="needs-validation"
                    >
                      <div className="mb-3">
                        <AvField
                          className="form-control"
                          type="text"
                          name="file_title"
                          label="Title"
                          placeholder="Enter attachment title"
                          required
                          id="attachment-title"
                        />
                      </div>
                      <div className="mb-3">
                        <AvInput
                          name="attachment_file"
                          label="Image"
                          className="form-control"
                          multiple={false}
                          type="file"
                          accept=".gif, .jpg, .png, .doc"
                          onChange={(e) => {
                            setattachmentFile(e.target.files[0]);
                          }}
                          id="attachment-file"
                        />
                        <p className="text-danger">
                          <b>Note:</b>The attachment file must be a file of
                          type: jpeg, png, jpg, pdf, docx
                        </p>
                      </div>
                      <div className="button-items">
                        <Button
                          className="btn-ld btn-sm"
                          color="primary"
                          type="submit"
                        >
                          Add
                        </Button>
                      </div>
                    </AvForm>
                  )}
                </Col>
              </ModalBody>
            </Tab>
          </Tabs>
        </div>
      )}

      {page7Class == "slide-in" && (
        <div
          className={`page3 ProfileClient ${page7Class} custom-scroll contract-details`}
        >
          <div>
            <div className="modal-head top-heading">
              <h2>Contracts</h2>
              <button
                onClick={() => {
                  setpage7Class("slide-out2");
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>

            <ClientContract
              cancelSlider={() => setpage7Class("slide-out2")}
              clientId={info.client_id}
              from="clientDetails"
            />
          </div>
        </div>
      )}
      {page9Class == "slide-in" && (
        <div className={`page3 ProfileClient ${page9Class} custom-scroll`}>
          <div>
            <div className="modal-head top-heading">
              <h2> New Assessment </h2>
              <button
                onClick={() => {
                  setpage9Class("slide-out2");
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <CreateAssessment
              cancelSlider={cancelAssessment}
              client_id={info.client_id}
              from="companyDetails"
            />
          </div>
        </div>
      )}

      {page11Class == "slide-in" && token && (
        <div
          className={`page3 video-chat-call ProfileClient ${page11Class} custom-scroll`}
        >
          <div>
            <div className="modal-head top-heading">
              <h2> Video Call </h2>
              <button
                onClick={() => {
                  setpage11Class("slide-out2");
                  setToken(null);
                  // props.history.push('/client/profile');
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <Room
              roomName={query.get("room")}
              token={token}
              handleLogout={handleLogout}
            />
          </div>
        </div>
      )}

      {page10Class == "slide-in" && (
        <div className={`page3 ProfileClient ${page10Class} custom-scroll`}>
          <div>
            <div className="modal-head top-heading">
              <h2> Edit Assessment </h2>
              <button
                onClick={() => {
                  setpage10Class("slide-out2");
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <EditAssessment
              cancelSlider={cancelAssessment}
              client_id={info.client_id}
              assessment_id={assessment_id}
            />
          </div>
        </div>
      )}
      <Modal
        isOpen={pipelineModal}
        fade={true}
        size="md"
        className="hello"
        toggle={() => {
          setPipelineModal(false);
          setSelectedPipeline({});
        }}
      >
        <AvForm
          onValidSubmit={handleDealSubmit}
          // model={this.state.record}
          className="needs-validation"
        >
          <div className="sms">
            <div className="modal-head top-heading">
              <h2>Opportunity</h2>
              <button
                onClick={() => {
                  setPipelineModal(false);
                  setSelectedPipeline({});
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>

            <ModalBody>
              <div className="mb-3">
                <AvField
                  className="select form-select"
                  type="select"
                  name="pipeline_id"
                  label="Pipeline"
                  required
                  disabled
                  defaultValue={pipeline.pipeline_id}
                >
                  <option value={pipeline.pipeline_id}>{pipeline.name}</option>
                </AvField>
              </div>

              <div className="mb-3">
                <AvField
                  className="select form-select"
                  type="select"
                  name="status_id"
                  label="Deal Status"
                  required
                  disabled
                  defaultValue={selectedPipeline.status_id}
                >
                  <option value={selectedPipeline.status_id}>
                    {selectedPipeline.title}
                  </option>
                </AvField>
              </div>

              <div className="mb-3">
                <AvField
                  className="select form-select"
                  type="select"
                  name="assigned_to"
                  label="Assign Member"
                  required
                >
                  <option value={""}> Select Member </option>
                  {coachPartners.map((row, i) => {
                    return (
                      <option key={i} value={row.user_id}>
                        {row.name}
                      </option>
                    );
                  })}
                </AvField>
              </div>

              <div className="mb-3">
                <AvField
                  type="hidden"
                  name="client_id"
                  value={info.client_id}
                />
              </div>

              <div className="mb-3">
                <AvField
                  className="form-control"
                  type="textarea"
                  name="comments"
                  required
                  placeholder={"Enter comments"}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="info" type="submit">
                Assign
              </Button>
              <Button
                className="btn-sm"
                color="danger"
                onClick={() => {
                  setPipelineModal(false);
                  setSelectedPipeline({});
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
};

export default ClientDetails;
