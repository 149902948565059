import React, { useContext, useRef, useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';
import WayBookContext from './WaybookContext';
import Card from 'react-bootstrap/Card';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { Link } from 'react-router-dom';
import { Collapse } from "reactstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { ApiUrl, AttachementsUrl } from '../../config';
import Knob from '../Campaign/knob';
import { bcrypt, dcrypt, uInfo, uToken } from '../../useToken';
import UrlNextPrev from './UrlNextPrev';
import ExcelSheet from './AdminExcel';
import ReactDatatable from "@ashvin27/react-datatable";
import Loader from "react-loader";

export default function TrainingDashboard(props) {


	const [value_prev, setvalue_prev] = useState(44)
	const [loaded, setLoaded] = useState(false);
	const [records, setRecords] = useState([]);
	const [total_pages, setTotal_pages] = useState(0);
	const handleChangeprev = newValue => {
		setvalue_prev(newValue)
	}

	useEffect(() => {
		fetchData();
	}, []);
	const columns = [
		{ key: 'sr', text: '#', sortable: false },
		{ key: 'step_title', text: 'Title', sortable: false },
		{ key: 'attempts', text: 'Attempted', sortable: false },
		{ key: 'pass_counts', text: 'Passed', sortable: false },
		{ key: 'failed_attempts', text: 'Failed', sortable: false },
		{ key: 'passRate', text: 'Pass Rate (%)', sortable: false },
		{
			key: "action",
			text: "Action",
			cell: (record, index) => {
				return (
					<>
						<button
							color="info"
							className="btn btn-primary btn-sm mr-5"
							onClick={() => props.handleQuizdetailsView(record.cat_id, record.step_id)}
						>
							<i className="mdi mdi-eye"></i>
						</button>

						<button
							className="btn btn-info btn-sm mr-5"
							onClick={() => downloadxlxsFile(record.fileName)}
						>
							<i className="dripicons-alarm dripicons-download"></i>
						</button>

					</>
				);
			},
		}
	];
	const config = {
		page_size: 10,
		length_menu: [10, 20, 50, 100],
		show_filter: false,
		show_pagination: false,
		button: {
			excel: false,
			print: false,
			csv: false,
		},
	};
	const fetchData = (queryString = "") => {
		setLoaded(false);
		fetch(`${ApiUrl}` + "get/waybook/admin/dashboard/quizStats" + queryString, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: `Bearer ` + uToken(),
			},
			body: JSON.stringify({ user_id: uInfo().user_id }),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.status === true) {
					setRecords(response.data.records)
					setTotal_pages(response.data.total)
				}
				setLoaded(true);
			})
			//Then with the error genereted...
			.catch((error) => {
				setLoaded(true);

				console.error("Error:", error);
			});
	};
	const tableChangeHandler = (data) => {
		let queryString = Object.keys(data)
			.map((key) => {
				if (key === "sort_order" && data[key]) {
					return (
						encodeURIComponent("sort_order") +
						"=" +
						encodeURIComponent(data[key].order) +
						"&" +
						encodeURIComponent("sort_column") +
						"=" +
						encodeURIComponent(data[key].column)
					);
				} else {
					return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
				}
			})
			.join("&");
		fetchData("?" + queryString, this.state.filter);
	};
	const downloadxlxsFile = async (filename) => {
		try {
			const response = await fetch(`${ApiUrl}quizstats/xlxs/download/${filename}`, {
				method: 'GET',
				headers: {
					Accept: "application/json",
					'Content-Type': 'application/octet-stream',
					Authorization: `Bearer ` + uToken(),
				},
			});
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const blob = await response.blob();
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', filename);
			document.body.appendChild(link);
			link.click();
			link.parentNode.removeChild(link);
		} catch (error) {
			console.error('Download failed:', error);
		}
	};
	return (
		<div className='default-layout'>
			<div className="dash-welcome-left">
				<div className='row'>
					<div className='col-lg-12'>
						<div className='TraningRightContent DashboardCard'>
							<div className="row">
								<div className="col-lg-12">
									<div className='DashboardHeading mb-3 text-center'>
										<h3>Good Afternoon {uInfo().first_name}! Welcome to <span className='PrimaryClass'>PROFIT</span><span className='SecondryClass'>COACH</span> Business Center™</h3>
									</div>
								</div>
								{/* Start Admin Dashboard */}
								<div className='col-lg-8'>
									<div className='TrainingsContents'>
										{/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> */}
									</div>
								</div>
								<div className='col-lg-4'>
									<div className='TrainingImage'>
										{/* <img src="https://profit-coach-app.s3.us-west-1.amazonaws.com/trainings/8cchG90JJlNtVF03BejORQDOnkY7xPNT85l7lhxl.jpg" alt="Training Image" /> */}
									</div>
								</div>

								<div className='col-lg-12 mt-3'>
									<div class="card mt-3 mb-0">
										<div class="card-body px-2 pb-1">
											<div class="DashboardHeadingSection mb-4"><h4 class="card-title">Quiz Stats</h4></div>
											<div class="row table-body asrt-table-body mt-2">
												<div class="col-md-12">
													<div className="tableFixHead">
														<ReactDatatable
															config={config}
															records={records}
															columns={columns}


															onChange={tableChangeHandler}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* <div className='col-lg-12 mt-4'>
									<ExcelSheet />
								</div> */}
								{/* End Admin Dashboard */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
