import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Button, Modal, ModalHeader, Input, ModalBody, ModalFooter } from "reactstrap"
import ReactDatatable from '@ashvin27/react-datatable';
import Helmet from "react-helmet"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { bcrypt, getPages, getPermission, getRoles, isAllowed, uInfo, uRole, uToken } from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import swal from 'sweetalert';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-bootstrap";

export default class FromEmailSettingRequest extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        text: "ID",
        key: "id",
        sortable: false
      },
      {
        text: "User Details",
        key: "name",
        sortable: true,
        cell: (record, index) => {
          return (
            <Fragment>
              <strong>UserID:</strong> {record.id} <br></br>
              <strong>Name:</strong> {record.name} <br></br>
              <strong>Email:</strong> {record.email} <br></br>
            </Fragment>
          );
        },
      },
      {
        text: "From Details",
        key: "name",
        sortable: true,
        cell: (record, index) => {
          return (
            <Fragment>
              <strong>From Name:</strong> {record.from_name} <br></br>
              <strong>From Email:</strong> {record.from_email} <br></br>
              <strong>From Email Verification Status:</strong>  
                <button
                    disabled={record.from_email_status == '1' ? false : false}
                    className="border-0 p-0 lh-1 ml-3"
                    onClick={this.changeApprovalUser.bind(this, record, index)}
                    title="Click this button to change status"
                  >
                  {record.from_email_status == '1' && (
                    <Badge bg="success">Verified</Badge>
                  )}
                  {record.from_email_status == 2 && (
                    <Badge bg="warning">Pending for Approval</Badge>
                  )}
                  {record.from_email_status == 3 && (
                    <Badge bg="danger">Declined</Badge>
                  )}
                </button>
            </Fragment>
          );
        },
      },
      
      // {
      //   key: "link",
      //   text: "Open Link",
      //   cell: (record, index) => {
      //     return (
      //       <Fragment>
      //         {record.link && (
      //           <a  href={`${record.link}`} target="_blank"  className="btn btn-link btn-sm mr-5"><i className="fas fa-fw fa-link"></i></a>
      //         )}
      //       </Fragment>
      //     );
      //   },
      // }
    ];
    this.config = {
      key_column: 'sr',
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
      button: {
        excel: false,
        print: true,
        csv: true
      },

    }
    this.state = {
      records: [],
      coach_user: [],
      roles: [],
      total_pages: 0,
      modal: false,
      record: {},
      filter: {},
      page_name: "From email configuration requests",
      customDate: true,
      modal_link: false
    }
  }


  componentDidMount() {
    this.getData();
  }
  changeApprovalUser = (record, index) => {
    //console.log(record, 'recordrecordrecord')
    this.setState({
      approvalModal: true,
      approvalUser: record,
      approvalStatus: ''
    })
  }
  changeApprovalStatus = (status) => {
    let record = this.state.approvalUser;
   
    swal({
      title: "Are you sure?",
      text: ' You want to perform this action',
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "approve/fromemail/setting/" + record.id + '/' + status, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                this.getData();
                this.setState({
                  approvalUser: {},
                  approvalModal: false
                })
                this.getData("", "")
              } else {
                toast.error(data.message, { theme: "colored" });
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        } else {
          this.setState({
            approvalUser: {},
            approvalModal: false
          })
        }
      });
  };
  getData = (queryString = "", data) => {
    fetch(`${ApiUrl}` + 'admin/fromemail/verify/requests' + queryString, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + uToken()
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {

        if (response.status === true) {
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
            roles: response.data.roles
          })
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }

      })
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
      });

  }

  handleFilterSubmit = async (event, values) => {
    this.setState({
      filter: values
    });
    this.getData("", values)
  }

  cancelFilter = async () => {
    this.form && this.form.reset();
    this.setState({ filter: {}, customDate: true });
    this.getData("", "")
  }

  tableChangeHandler = data => {
    let queryString = Object.keys(data).map((key) => {
      if (key === "sort_order" && data[key]) {
        return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
      } else {
        return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      }

    }).join('&');
    this.getData('?' + queryString, this.state.filter);
  }



  render() {
    return (
      <React.Fragment >
        <Helmet>
          <title>{this.state.page_name}</title>
        </Helmet>

        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4>{this.state.page_name}</h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">{ProductName}</li>
                <li key={1} className="breadcrumb-item"><Link to="#">{this.state.page_name}</Link></li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>

          </Col>
        </Row>

        <ToastContainer />


        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                {/* <CardTitle className="h4">Default Datatable </CardTitle> */}
                <ReactDatatable
                  key={this.state.id}
                  config={this.config}
                  records={this.state.records}
                  columns={this.columns}
                  dynamic={true}
                  total_record={this.state.total_pages}
                  onChange={this.tableChangeHandler}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.approvalModal}
          fade={false}
          toggle={() => this.setState({ approvalModal: false })}
        >
          <ModalBody>
            <div className="form-group mb-3">
              <label>Select Status</label>
              <select onChange={(e) => this.setState({ approvalStatus: e.target.value })} name="approve_status" className="form-select" >
                <option value={''}>Select option</option>
                <option value={'1'}>Approve</option>
                <option value={'3'}>Declined</option>
              </select>
            </div>
          </ModalBody>
          <ModalFooter>
            {
              this.state.approvalStatus != '' ?
                <Button color="info" onClick={() => this.changeApprovalStatus(this.state.approvalStatus)}>
                  Yes, Change it
                </Button>
                :
                <Button disabled color="info" >
                  Yes, Change it
                </Button>
            }
            <Button color="danger" onClick={() => this.setState({ approvalModal: false })}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment >
    )
  }
}

