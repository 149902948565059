import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Table,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
} from "reactstrap";

import Helmet from "react-helmet";
// availity-reactstrap-validation

import { Link } from "react-router-dom";

import { ApiUrl, ProductName } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { bcrypt, convertPhoneFormat, dcrypt, uInfo, uToken } from "../../../useToken";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Badge from "react-bootstrap/Badge";
import {
  AvForm,
  AvField,
  AvGroup,
  AvFeedback,
} from "availity-reactstrap-validation";
import Select from "react-select";
import { Accordion, Dropdown, Tab, Tabs, Tooltip } from "react-bootstrap";
import Avatar from "react-avatar";
// actions

import CreateUpdate from "./CreateUpdate.js";
import ClientCreateUpdate from "../Clients/ClientCreateUpdate.js";
import CreateTask from "../../Tasks/create.js";

//Dialer
import Phone from "../../InitiateCall/Phone";

// ClientCreateUpdate
//Import Action to copy breadcrumb items from local state to redux state

const ComapanyDetails = (props) => {
  const [page_name] = useState("Company");
  const [idx] = useState(dcrypt(props.match.params.id));
  const [coach_id] = useState(dcrypt(props.match.params.coach_id));
  const [info, setInfo] = useState({});

  const [tab, setTab] = useState("3");
  const [btab, setBTab] = useState("3");

  const [groupModal, setGroupModal] = useState(false);
  const [tagModal, setTagModal] = useState(false);
  const [loginHistory, setLoginHistory] = useState([]);
  const [loginHistoryColumn, setLoginHistoryColumn] = useState([]);
  const [index, setIndex] = useState("");
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState("");
  const [selectedValue, setSelectedValue] = useState([]);
  const [style, setStyle] = useState({ display: "none" });
  const [page2Class, setpage2Class] = useState("");
  const [page3Class, setpage3Class] = useState("");
  const [page4Class, setpage4Class] = useState("");

  const [searchClient, setSearchClient] = useState("");
  const [existing, setExisting] = useState([]);
  const [clients, setClients] = useState([]);
  const [DynamicModal, setDynamicModal] = useState(false);
  const [EmailModal, setEmailModal] = useState(false);
  const [DynamicModalName, setDynamicModalName] = useState("");
  const [activity, setActivity] = useState([]);
  const [script, setScript] = useState("");
  const [templates, setSmsTemplates] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [emailSetting, setEmailSetting] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [smsBody, setSmsBody] = useState("");
  const [token, setToken] = useState("");
  const [subject, setSubject] = useState("");
  const [html, setHtml] = useState("");
  const [sentEmailTemplateModal, setSentEmailTemplateModal] = useState(false);
  const [emailData, setEmailData] = useState("");
  const [taskCompleteModal, setTaskCompleteModal] = useState(false);
  const [taskID, setTaskID] = useState(false);

  const [callToken, setCallToken] = useState(null);
  const [clicked, setClicked] = useState(false);
  const identity = coach_id;
  const [smsloader, setSmsLoader] = useState(true);

  const [phoneno, setphoneno] = useState(null);

  const paramsters = {
    token: callToken,
    from_number: uInfo().outbound_phone,
    phoneno: phoneno,
    record: "false",
    client_id: info.client_id,
    coach_id: coach_id,
  };

  const childRef = useRef();
  const childClientRef = useRef();
  useEffect(() => {
    getData();
    getTag();
    getCompanyClients();
    getActivity("3");
  }, []);

  const getData = () => {
    fetch(`${ApiUrl}` + "company/details/" + idx + "/" + coach_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        // console.log(response, "leaddata");
        if (response.status === true) {
          setInfo(response.data.records);
          if (response.data.records.phone != "") {
            setphoneno(
              response.data.records.country_code + response.data.records.phone
            );
          } else {
            setphoneno(
              response.data.records.country_code + response.data.records.mobile
            );
          }
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const btoggle = (index) => {
    if (btab !== index) {
      setBTab(index);
      setActivity([]);
      getActivity(index);
    }
  };


  const toggle = (index) => {
    if (tab !== index) {
      setActivity([]);
      setTab(index);
      getActivity(index);
    }
  };

  const removeGroupLead = () => {
    fetch(
      `${ApiUrl}` +
      "remove/group/company/" +
      idx +
      "/" +
      index +
      "/" +
      coach_id,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
      }
    )
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        // console.log(response, "Group removed");
        if (response.status === true) {
          setGroupModal(false);
          getData();
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const removeTagLead = () => {
    // console.log(index, "index");
    fetch(
      `${ApiUrl}` + "remove/tag/company/" + idx + "/" + index + "/" + coach_id,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
      }
    )
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          // console.log(response);
          setTagModal(false);
          getData();
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getTag = () => {
    fetch(`${ApiUrl}` + "get/group/tag", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },

      body: JSON.stringify({ manager_id: coach_id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        // console.log(response, "data");
        if (response.status === true) {
          setTags(response.data.tags);
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const removeGroupModal = (i) => {
    setGroupModal(!groupModal);
    setIndex(i);
  };
  const removeTagModal = (i) => {
    setTagModal(!tagModal);
    setIndex(i);
  };

  const AddTags = async (event, values) => {
    // console.log(index, "index");
    setSmsLoader(false);

    fetch(`${ApiUrl}` + "add/tags/company/" + idx + "/" + coach_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setGroupModal(false);
          setTagModal(false);
          getData();
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        setSmsLoader(true);
      })
      //Then with the error genereted...
      .catch((error) => {
        setSmsLoader(true);
        console.error("Error:", error);
      });
  };

  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.id) : []);
  };

  const handleOutCall = () => {
    fetch(`${ApiUrl}` + "voice/token", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ identity: encodeURIComponent(identity) }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setCallToken(response.data.token);
          setClicked(true);
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    if (searchClient != "") {
      fetch(`${ApiUrl}` + "get/clients", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify({ filter_value: searchClient }),
      })
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((response) => {
          // console.log(response, "data");
          if (response.status === true) {
            setExisting(response.data.records);
            // this.setState({
            //   records: response.data.records,
            // });
          } else {
                      
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        //Then with the error genereted...
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setExisting([]);
    }
  }, [searchClient]);

  const getCompanyClients = () => {
    fetch(`${ApiUrl}` + "get/company/clients/" + idx + "/" + coach_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setClients(response.data.records);
          setpage3Class("slide-out2");
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleExistingClient = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    let clientIds = [];
    for (let [key, value] of formData.entries()) {
      clientIds.push(value);
    }

    if (clientIds.length > 0) {
      fetch(`${ApiUrl}` + "update/clients/company", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify({
          clients: clientIds,
          comapny_id: info.client_id,
        }),
      })
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((response) => {
          // console.log(response, "data");
          if (response.status === true) {
            setpage3Class("slide-out2");
            setSearchClient("");
            setExisting([]);
            getCompanyClients();
            toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          } else {
                      
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        //Then with the error genereted...
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const handleSubmit = async (event, values) => {
    values.client_id = info.client_id;
    values.type = DynamicModalName.toLowerCase();

    fetch(`${ApiUrl}` + "company/activity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log(data, "Editing");
        if (data.status === true) {
          getActivity(tab);
          setDynamicModal(false);
          setDynamicModalName("");
          setSmsBody("");
          setToken("");
          setSubject("");
          setHtml("");
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleEmailSubmit = async (event, values) => {
    values.client_id = info.client_id;
    values.type = DynamicModalName.toLowerCase();

    if (html != "") {
      let body = document.getElementById("custom-template").innerHTML;
      values.body = body;
    }

    fetch(`${ApiUrl}` + "company/email/activity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log(data, "Editing");
        if (data.status === true) {
          getActivity(tab);
          setEmailModal(false);
          setDynamicModalName("");
          setSmsBody("");
          setToken("");
          setSubject("");
          setHtml("");
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getActivity = (index) => {
    fetch(`${ApiUrl}` + "get/company/activity/" + idx + "/" + index, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setActivity(response.data.records);
          // setpage3Class('slide-out2');
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  function groupArrayOfObjects(list, key) {
    return list.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  const getEmailTemplate = () => {
    fetch(`${ApiUrl}` + "get/company/email/template", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setSmsTemplates(response.data.templates);
          var tokens_group = [
            {
              label: "Default Field",
              options: response.data.tokens.default_fields,
            },
            {
              label: "Custom Field",
              options: response.data.tokens.custom_fields,
            },
          ];

          setTokens(tokens_group);
          setEmailSetting(response.data.phoneNumbers);
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getSmsTemplate = () => {
    fetch(`${ApiUrl}` + "get/company/sms/template", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          var templates = groupArrayOfObjects(response.data.templates, "type");
          var templates_group = [
            { label: "SMS", options: templates.SMS },
            { label: "MMS", options: templates.MMS },
          ];
          setSmsTemplates(templates_group);

          var tokens_group = [
            {
              label: "Default Field",
              options: response.data.tokens.default_fields,
            },
            {
              label: "Custom Field",
              options: response.data.tokens.custom_fields,
            },
          ];

          setTokens(tokens_group);
          setPhoneNumbers(response.data.phoneNumbers);
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getRVMTemplate = () => {
    fetch(`${ApiUrl}` + "get/company/rvm/template", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setSmsTemplates(response.data.templates);

          setPhoneNumbers(response.data.phoneNumbers);
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getEmailTemplateHtml = (id) => {
    fetch(`${ApiUrl}` + "get/html/" + id + "/" + uInfo().user_id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        setHtml(response.theme);
        // localStorage.setItem("theme", response.theme);
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const cancelTask = () => {
    setpage4Class("slide-out2");
    if (index == "6") {
      getActivity("6");
    }
  };

  const completeTask = () => {
    fetch(`${ApiUrl}` + "update/task/status", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ task_id: taskID, client_id: info.client_id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          getActivity("6");
          setTaskCompleteModal(false);
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getCallSid = (call_sid) => {

  }

  return (
    <React.Fragment>
      <Helmet>
        <title> {page_name} </title>
      </Helmet>
      <ToastContainer />

      <Row className="CompanyDetail CompanyDialer">
        <Col md={3}>
          <div className="NoShadow custom-scroll LeftSideUser">
            <div className="user-profile-pic-area">
              <CardHeader>
                <div className="top-heading WithDrop">
                  <Link className="text-primary" to={"/company/list"}>
                    <i className="fas fa-angle-left pr-5"></i>Companies
                  </Link>
                  <Dropdown className="d-inline">
                    <Dropdown.Toggle
                      className="btn btn-link text-primary"
                      id="dropdown-autoclose-true"
                    >
                      <i className="ion ion-md-flower"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          toggle("1");
                        }}
                        eventKey={"1"}
                      >
                        Basic Info
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          toggle("2");
                        }}
                        eventKey={"2"}
                      >
                        Add Tag
                      </Dropdown.Item>
                      {/* <Dropdown.Item href="#">Menu Item</Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </CardHeader>

              <Card className="CompanyProfileBG">
                <CardBody className="px-0">
                  <div className="company p-3">
                    <button
                      className="btn btn-link btn-md pull-right"
                      onClick={() => {
                        setpage2Class("slide-in");
                        childRef.current.getAlert();
                      }}
                    >
                      <i className="fas fa-pencil-alt"></i>
                    </button>
                    <div className="company-info">
                      <div className="logo">
                        <Avatar
                          name={`${info.first_name}`}
                          size="100"
                          round={true}
                        />
                      </div>
                      <div className="info">
                        <h3>{info.first_name}</h3>
                        <div className="url">
                          <a
                            href={`${info.website}`}
                            target="_blank"
                            className="pr-5"
                          >
                            {info.website}{" "}
                            <i className="fas fa-external-link-alt"></i>
                          </a>

                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id="overlay-example">
                                Copy domain to clipboard
                              </Tooltip>
                            }
                          >
                            <button
                              className="btn btn-link btn-md"
                              onClick={() => {
                                navigator.clipboard.writeText(`${info.website}`);
                              }}
                            >
                              <i className="fas fa-copy"></i>
                            </button>
                          </OverlayTrigger>
                        </div>
                        {convertPhoneFormat(info.phone
                          ? info.phone_extension + info.phone
                          : info.country_code + info.mobile)}
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="overlay-example">
                              Copy phone number to clipboard
                            </Tooltip>
                          }
                        >
                          <button
                            onClick={() => {
                              navigator.clipboard.writeText(`${convertPhoneFormat(info.phone ? info.phone_extension + info.phone
                                : info.country_code + info.mobile)}`);
                            }}
                            className="btn btn-link btn-md"
                          >
                            <i className="fas fa-copy"></i>
                          </button>
                        </OverlayTrigger>
                        <div className="compant-action-btn">

                          <OverlayTrigger
                            placement="auto"
                            overlay={
                              <Tooltip id="overlay-example">Create a note</Tooltip>
                            }
                          >
                            <button
                              onClick={() => {
                                setDynamicModal(true);
                                setDynamicModalName("Note");
                              }}
                              className="btn btn-primary btn-md"
                            >
                              <i className="fas fa-edit"></i>
                            </button>
                          </OverlayTrigger>
                          {(info.mobile != '' || info.phone != '') && (
                            <OverlayTrigger
                              placement="auto"
                              overlay={<Tooltip id="overlay-example">Send Sms</Tooltip>}
                            >
                              <button
                                onClick={() => {
                                  setDynamicModal(true);
                                  setDynamicModalName("sms");
                                  getSmsTemplate();
                                }}
                                className="btn btn-primary btn-md"
                              >
                                <i className="fas fa-comment"></i>
                              </button>
                            </OverlayTrigger>
                          )}
                          {info.email != "" && info.email != null && (
                            <OverlayTrigger
                              placement="auto"
                              overlay={
                                <Tooltip id="overlay-example">Create an email</Tooltip>
                              }
                            >
                              <button
                                onClick={() => {
                                  setEmailModal(true);
                                  setDynamicModalName("email");
                                  getEmailTemplate();
                                }}
                                className="btn btn-primary btn-md"
                              >
                                <i className="fas fa-envelope"></i>
                              </button>
                            </OverlayTrigger>
                          )}
                          {(info.mobile != '' || info.phone != '') && (
                            <OverlayTrigger
                              placement="auto"
                              overlay={
                                <Tooltip id="overlay-example">Make a phone call</Tooltip>
                              }
                            >
                              <button
                                onClick={() => {
                                  setDynamicModal(true);
                                  setDynamicModalName("call");
                                  handleOutCall();
                                }}
                                className="btn btn-primary btn-md"
                              >
                                <i className="fas fa-phone-alt"></i>
                              </button>
                            </OverlayTrigger>
                          )}
                          <OverlayTrigger
                            placement="auto"
                            overlay={<Tooltip id="overlay-example">Task</Tooltip>}
                          >
                            <button
                              onClick={() => {
                                setpage4Class("slide-in");
                              }}
                              className="btn btn-primary btn-md"
                            >
                              <i className="fas fa-tasks"></i>
                            </button>
                          </OverlayTrigger>
                          {(info.mobile != '' || info.phone != '') && (
                            <OverlayTrigger
                              placement="auto"
                              overlay={<Tooltip id="overlay-example">Send RVM</Tooltip>}
                            >
                              <button
                                onClick={() => {
                                  setDynamicModal(true);
                                  setDynamicModalName("rvm");
                                  getRVMTemplate();
                                }}
                                className="btn btn-primary btn-md"
                              >
                                <i className="fas fa-microphone"></i>
                              </button>
                            </OverlayTrigger>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="user-profile-pic-area">
              <CardBody className="px-0">
                <div className="AboutCard 3">
                  <div className="details">
                    <Accordion defaultActiveKey={"0"} className="border-0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>About this company</Accordion.Header>
                        <Accordion.Body>
                          <div className="info info pb-2 border-bottom">
                            <span>Company name</span>
                            <p>{info.first_name} </p>
                          </div>
                          <div className="info info pt-2 pb-2 border-bottom">
                            <span>Company domain</span>
                            <p>{info.website} </p>
                          </div>
                          <div className="info info pt-2 pb-2 border-bottom">
                            <span>Company email</span>
                            <p>{info.email}</p>
                          </div>
                          <div className="info info pt-2 pb-2 border-bottom">
                            <span>Company phone</span>
                            <p> {convertPhoneFormat(info.phone
                              ? info.phone_extension + info.phone
                              : info.country_code + info.mobile)}</p>
                          </div>
                          <div className="info info pt-2 pb-2 border-bottom">
                            <span>Company address</span>
                            <p>{info.address}</p>
                          </div>
                          <div className="info info pt-2 pb-2 border-bottom">
                            <span>Company city</span>
                            <p>{info.city}</p>
                          </div>
                          <div className="info info pt-2 pb-2">
                            <span>Company state</span>
                            <p>{info.state}</p>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      {/* <Accordion.Item eventKey="1">
                    <Accordion.Header>About this company</Accordion.Header>
                    <Accordion.Body>
                      <div className="info">
                        <span>Company name</span>
                        <p>{info.first_name} </p>
                      </div>
                      <div className="info pt-3">
                        <span>Company domain</span>
                        <p>{info.website} </p>
                      </div>
                      <div className="info pt-3">
                        <span>Company email</span>
                        <p>{info.email}</p>
                      </div>
                      <div className="info pt-3">
                        <span>Company phone</span>
                        <p>{info.phone}</p>
                      </div>
                      <div className="info pt-3">
                        <span>Company address</span>
                        <p>{info.address}</p>
                      </div>
                      <div className="info pt-3">
                        <span>Company city</span>
                        <p>{info.city}</p>
                      </div>
                      <div className="info pt-3 pb-4">
                        <span>Company state</span>
                        <p>{info.state}</p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item> */}
                    </Accordion>
                  </div>
                </div>
              </CardBody>
            </div>
          </div>
          <div>
            <div className={`page2 ${page2Class} custom-scroll`}>
              <div>
                <div className="modal-head top-heading">
                  <h2>Edit Detail</h2>
                  <button
                    onClick={() => {
                      setpage2Class("slide-out");
                    }}
                    className="btn btn-link text-white"
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>

                <CreateUpdate
                  ref={childRef}
                  getData={getData}
                  id={info.client_id}
                  coach_id={info.coach_id}
                  from="companyDetails"
                />
              </div>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <Card className="NoShadow custom-scroll Activity">
            <CardBody>
              <Nav tabs>
                {/* <NavItem>
                  <NavLink
                    className={tab == 1 ? "active" : ""}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Info
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={tab == 2 ? "active" : ""}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Add Tag
                  </NavLink>
                </NavItem> */}

                <NavItem>
                  <NavLink
                    className={tab == 3 ? "active" : ""}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    Activity
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={tab == 4 ? "active" : ""}
                    onClick={() => {
                      toggle("4");
                    }}
                  >
                    Scripts
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={tab == 5 ? "active" : ""}
                    onClick={() => {
                      toggle("5");
                    }}
                  >
                    Notes
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={tab == 6 ? "active" : ""}
                    onClick={() => {
                      toggle("6");
                    }}
                  >
                    Tasks
                  </NavLink>
                </NavItem>

              </Nav>
              <TabContent activeTab={tab}>
                <TabPane tabId="1">
                  <div className="pt-20 table-responsive">
                    <Table className="table mb-0">
                      <tbody>
                        <tr>
                          <td> Comapany Name </td> <td> {info.first_name} </td>
                          {/* <td> Last Name </td> <td> {info.last_name} </td> */}
                        </tr>
                        <tr>
                          <td> Email </td> <td> {info.email} </td>
                          <td> </td> <td> </td>
                        </tr>
                        <tr>
                          <td> Mobile </td> <td> {convertPhoneFormat(info.country_code + info.mobile)} </td>
                          <td> Phone </td> <td> {convertPhoneFormat(info.phone_extension + info.phone)} </td>
                        </tr>
                        <tr>
                          <td> Client Source </td> <td> {info.source} </td>
                          <td> Client Id </td> <td> {info.client_id} </td>
                        </tr>
                        <tr>
                          <td colSpan={4}> ADDRESS INFORMATION </td>
                        </tr>
                        <tr>
                          <td> Address </td> <td> {info.address} </td>
                          <td> City </td> <td> {info.city} </td>
                        </tr>
                        <tr>
                          <td> State </td> <td> {info.state} </td>
                          <td> Zip </td> <td> {info.zip} </td>
                        </tr>
                        <tr>
                          <td> TAG </td>

                          <td>
                            {info.tags && (
                              <OverlayTrigger
                                key={1}
                                placement="auto"
                                overlay={
                                  <Tooltip id={`tooltip-auto`}>
                                    <strong>
                                      {info.tags ? info.tags.title : ""}
                                    </strong>
                                  </Tooltip>
                                }
                              >
                                <Button
                                  className={`btn-sm btn-success btn-round mr-5`}
                                >
                                  <Badge
                                    bg=""
                                    onClick={() => removeTagModal(0)}
                                  >
                                    <i className="fas fa-times-circle pr-5"></i>
                                  </Badge>
                                  {info.tags ? info.tags.title : ""}
                                </Button>
                              </OverlayTrigger>
                            )}
                            <Modal
                              isOpen={tagModal}
                              fade={false}
                              toggle={() => removeTagModal()}
                            >
                              <ModalBody>
                                <div className="warning-box text-center">
                                  <span className="warning">
                                    <i className="mdi mdi-alert-circle"> </i>
                                  </span>
                                  <h3> Are you sure ? </h3>
                                  <p> You want to remove this Tag ? </p>
                                </div>
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  color="info"
                                  onClick={() => removeTagLead()}
                                >
                                  Yes, Remove it
                                </Button>
                                <Button
                                  color="danger"
                                  onClick={() => removeTagModal()}
                                >
                                  Cancel
                                </Button>
                              </ModalFooter>
                            </Modal>
                          </td>
                          <td>  </td>
                          <td>
                            {info.groups && (
                              <OverlayTrigger
                                key={0}
                                placement="auto"
                                overlay={
                                  <Tooltip id={`tooltip-auto`}>
                                    <strong>
                                      {" "}
                                      {info.groups
                                        ? info.groups.title
                                        : ""}{" "}
                                    </strong>
                                  </Tooltip>
                                }
                              >
                                <Button
                                  className={`btn-sm btn-success btn-round mr-5`}
                                >
                                  <Badge
                                    bg=""
                                    onClick={() => removeGroupModal(0)}
                                  >
                                    <i className="fas fa-times-circle pr-5"></i>
                                  </Badge>
                                  {info.groups ? info.groups.title : ""}
                                </Button>
                              </OverlayTrigger>
                            )}
                            <Modal
                              isOpen={groupModal}
                              fade={false}
                              toggle={() => removeGroupModal()}
                            >
                              <ModalBody>
                                <div className="warning-box text-center">
                                  <span className="warning">
                                    <i className="mdi mdi-alert-circle"> </i>
                                  </span>
                                  <h3> Are you sure ? </h3>
                                  <p> You want to remove this group ? </p>
                                </div>
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  color="info"
                                  onClick={() => removeGroupLead()}
                                >
                                  Yes, Remove it
                                </Button>
                                <Button
                                  color="danger"
                                  onClick={() => removeGroupModal()}
                                >
                                  Cancel
                                </Button>
                              </ModalFooter>
                            </Modal>
                          </td>

                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </TabPane>
                <TabPane tabId={"2"}>
                  {
                    smsloader && (
                      <AvForm onValidSubmit={AddTags} className="needs-validation">
                        <Col md="6">
                          <div className="mb-3 mt-5">
                            <Select
                              getOptionLabel={(option) => option.title}
                              getOptionValue={(option) => option.group_id}
                              // value={tags.filter(obj => selectedValue.includes(obj.id))}
                              options={tags}
                              isMulti={false}
                              classNamePrefix="select2-selection"
                              name="tags_id"
                              isClearable
                              onChange={data => setTag(data ? data.group_id : "")}
                            // onChange={handleChange}
                            />
                            <AvField
                              required
                              type="hidden"
                              name="tag_id"
                              value={tag}
                            />
                          </div>
                        </Col>
                        <div className="button-items">
                          <Button className="btn-ld" color="primary" type="submit">
                            Add
                          </Button>
                        </div>
                      </AvForm>
                    )
                  }
                </TabPane>
                <TabPane tabId={"3"} className="secondary-tabs">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={btab == 3 ? "active" : ""}
                        onClick={() => {
                          btoggle("3");
                        }}
                      >
                        All
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={btab == 7 ? "active" : ""}
                        onClick={() => {
                          btoggle("7");
                        }}
                      >
                        Emails
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={btab == 8 ? "active" : ""}
                        onClick={() => {
                          btoggle("8");
                        }}
                      >
                        Sms
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={btab == 9 ? "active" : ""}
                        onClick={() => {
                          btoggle("9");
                        }}
                      >
                        Call
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={btab == 10 ? "active" : ""}
                        onClick={() => {
                          btoggle("10");
                        }}
                      >
                        RVM
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={btab}>
                    <TabPane tabId={"3"} className="mt-2">
                      {activity.length > 0 ? (
                        activity.map((row, i) => {
                          return (
                            <div key={i} className="activity-info">
                              <div className="activity-body">
                                <div className={`activity-bubble ${row.type}`}>
                                  <div className="activity-content">
                                    <div className="activity_time">
                                      <div className="ActivityTitle">
                                        {/* <span>{row.type}</span> by {row.first_name} */}
                                        <p>{row.activity}</p>
                                      </div>
                                      {row.created_at}
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="col-12 col-lg-12 col-xl-12 text-center">
                          No Record Found
                        </div>
                      )}
                    </TabPane>
                    <TabPane tabId={"7"} className="mt-2">
                      {activity.map((row, i) => {
                        return (
                          <div key={i} className="note activity-info">
                            <div className="activity-body">
                              <div className="activity-bubble email">
                                <div className="activity-content">
                                  <div className="activity_time">
                                    <div className="ActivityTitle">
                                      <span>{row.type}</span> by {row.first_name}
                                    </div>
                                    <div className="view-date">
                                      <button
                                        className="btn btn-link"
                                        onClick={() => {
                                          setSentEmailTemplateModal(true);
                                          setEmailData(row.body ? row.body : "");
                                        }}
                                      >
                                        View
                                      </button>
                                      {row.created_at}
                                    </div>
                                  </div>

                                  <div className="d-flex space-between">
                                    <p>
                                      {" "}
                                      <b>From :</b> {row.froms}{" "}
                                    </p>
                                    <p>
                                      {" "}
                                      <b>To :</b> {row.tos}{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </TabPane>
                    <TabPane tabId={"8"} className="mt-2">
                      {activity.map((row, i) => {
                        return (
                          <div key={i} className="note activity-info">
                            <div className="activity-body">
                              <div className="activity-bubble sms">
                                <div className="activity-content">
                                  <div className="activity_time">
                                    <div className="ActivityTitle">
                                      {/* <span>{row.type}</span> by {row.first_name} */}
                                      <p>{row.body}</p>
                                    </div>
                                    {row.created_at}
                                  </div>

                                  <div className="d-flex space-between">
                                    <p>
                                      {" "}
                                      <b>From :</b> {row.froms}{" "}
                                    </p>
                                    <p>
                                      {" "}
                                      <b>To :</b> {row.tos}{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </TabPane>
                    <TabPane tabId={"9"}></TabPane>
                    <TabPane tabId={"10"} className="mt-2">
                      {activity.map((row, i) => {
                        return (
                          <div key={i} className="note activity-info">
                            <div className="activity-body">
                              <div className="activity-bubble rvm">
                                <div className="activity-content">
                                  <div className="activity_time">
                                    <div className="ActivityTitle">
                                      <span>{row.type}</span> by {row.first_name}
                                    </div>
                                    <div className="view-date">
                                      <a
                                        target={"_blank"}
                                        href={`${row.body}`}
                                        className="btn btn-link p0"
                                      >
                                        Listen
                                      </a>
                                      {row.created_at}
                                    </div>
                                  </div>
                                  <div className="d-flex space-between">
                                    <p>
                                      {" "}
                                      <b>From :</b> {row.froms}{" "}
                                    </p>
                                    <p>
                                      {" "}
                                      <b>To :</b> {row.tos}{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </TabPane>
                  </TabContent>
                </TabPane>
                <TabPane tabId={"4"} className="mt-3 PrimaryTabs">
                  <select
                    value={script}
                    onChange={(e) => setScript(e.target.value)}
                    className="form-control"
                  >
                    <option value="">Select Script Templates</option>
                    {activity.map((row, i) => {
                      return (
                        <option key={i} value={`${i}`}>
                          {row.name}
                        </option>
                      );
                    })}
                  </select>

                  {script != "" && tab == "4" && activity.length > 0 && (
                    <div
                      className="mt-3"
                      dangerouslySetInnerHTML={{
                        __html: activity[script].script_text,
                      }}
                    />
                  )}
                </TabPane>
                <TabPane tabId={"5"} className="mt-2 PrimaryTabs">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <button
                        onClick={() => {
                          setDynamicModal(true);
                          setDynamicModalName("Note");
                        }}
                        className="btn btn-sm btn-primary pull-right m-2"
                      >
                        Create Note
                      </button>
                    </div>
                  </div>
                  {activity.map((row, i) => {
                    return (
                      <div key={i} className="note activity-info">
                        <div className="activity-body">
                          <div className="activity-bubble note">
                            <div className="activity-content">
                              <div className="activity_time">
                                <div className="ActivityTitle">
                                  {/* <span>{row.type}</span> by {row.first_name} */}
                                  <p>{row.activity}</p>
                                </div>
                                {row.created_at}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </TabPane>
                <TabPane tabId={"6"} className="mt-2 PrimaryTabs">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <button
                        onClick={() => {
                          setpage4Class("slide-in");
                        }}
                        className="btn btn-sm btn-primary pull-right m-2"
                      >
                        Create Task
                      </button>
                    </div>
                  </div>
                  <div className="details">
                    <Accordion defaultActiveKey="10" className="border-0">
                      {activity.map((task, k) => {
                        return (
                          <Accordion.Item eventKey={`${k}`}>
                            <div className="accordion-head task">
                              <Accordion.Header>
                                Task assigned to{" "}
                                {task.user ? task.user.name : ""}
                              </Accordion.Header>
                              <button className="btn btn-link">
                                <i className="fas fa-calendar-alt"></i> Due{" "}
                                {task.server_date} at {task.server_time}
                              </button>
                            </div>
                            <Accordion.Body>
                              <div className="card card-body" key={k}>
                                <p>
                                  {" "}
                                  <button
                                    className="btn btn-sm btn-link"
                                    onClick={() => {
                                      setTaskCompleteModal(true);
                                      setTaskID(task.task_id);
                                    }}
                                  >
                                    <i className="fa fa-check " />
                                  </button>{" "}
                                  {task.title}
                                </p>
                                <ul>
                                  <li>
                                    Due {task.server_date} at{" "}
                                    {task.server_time}{" "}
                                  </li>
                                  <li>
                                    Email reminder {task.email_reminder}
                                  </li>
                                  <li>Sms reminder {task.sms_reminder}</li>
                                </ul>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        );
                      })}
                    </Accordion>
                  </div>
                </TabPane>

              </TabContent>
            </CardBody>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="NoShadow custom-scroll">
            <CardBody className="px-0">
              <div className="details">
                <Accordion defaultActiveKey="10" className="border-0">
                  <Accordion.Item eventKey="0">
                    <div className="accordion-head">
                      <Accordion.Header className="p-1">
                        Clients {clients.length}
                      </Accordion.Header>
                      <button
                        className="btn btn-link"
                        onClick={() => {
                          setpage3Class("slide-in");
                          childClientRef.current.getAlert();
                        }}
                      >
                        <i className="fas fa-cog"> </i>
                      </button>
                    </div>
                    <Accordion.Body className="px-1">
                      {clients.map((client, k) => {
                        return (
                          <div className="card p-1" key={k}>
                            <div className="client-info info">
                              <Link
                                to={
                                  "/client/dialer/" +
                                  bcrypt(client.client_id) +
                                  "/" +
                                  bcrypt(client.coach_id)
                                }
                              >
                                <h4>
                                  {client.first_name} {client.last_name}
                                </h4>
                              </Link>
                              {
                                client.email != '' && (
                                  <div className="url">
                                    <a href="#" className="pr-5">
                                      {client.email}{" "}
                                    </a>
                                    <OverlayTrigger
                                      placement="right"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip id="overlay-example">
                                          Copy email to clipboard
                                        </Tooltip>
                                      }
                                    >
                                      <button
                                        className="btn btn-link btn-md"
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            `${client.email}`
                                          );
                                        }}
                                      >
                                        <i className="fas fa-copy"></i>
                                      </button>
                                    </OverlayTrigger>
                                  </div>
                                )
                              }
                              {
                                client.phone != '' && (
                                  <div className="url">
                                    <a href="#" className="pr-5">
                                      {client.phone}{" "}
                                    </a>
                                    <OverlayTrigger
                                      placement="right"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip id="overlay-example">
                                          Copy phone to clipboard
                                        </Tooltip>
                                      }
                                    >
                                      <button
                                        className="btn btn-link btn-md"
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            `${client.phone}`
                                          );
                                        }}
                                      >
                                        <i className="fas fa-copy"></i>
                                      </button>
                                    </OverlayTrigger>
                                  </div>
                                )
                              }
                            </div>
                          </div>
                        );
                      })}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <div className={`page3 ${page3Class} custom-scroll`}>
        <div>
          <div className="modal-head top-heading">
            <h2>Add clients to this company</h2>
            <button
              onClick={() => {
                setpage3Class("slide-out2");
              }}
              className="btn btn-link text-white"
            >
              <i className="fas fa-times"></i>
            </button>
          </div>

          <Tabs defaultActiveKey="existing">
            <Tab eventKey="existing" title="Association existing">
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <div className="col-md-8 offset-2">
                        <div className="form-group relative">
                          <input
                            value={
                              searchClient != "" && searchClient != null
                                ? searchClient
                                : ""
                            }
                            onChange={(e) => setSearchClient(e.target.value)}
                            placeholder="Search clients"
                            name="searh"
                            type={"text"}
                            className={"form-control"}
                          />

                          {searchClient == "" ? (
                            <button className="btn btn-sm searchBtn">
                              <span className="fas fa-search"></span>
                            </button>
                          ) : (
                            <button
                              onClick={() => setSearchClient(null)}
                              className="btn btn-sm searchBtn"
                            >
                              <span className="fas fa-times"></span>
                            </button>
                          )}
                        </div>
                        <p>{existing.length} results</p>

                        {existing.length < 1 && searchClient != "" && (
                          <p className="text-danger">
                            No client match the current search.
                          </p>
                        )}

                        {existing.length > 1 && (
                          <form method="POST" onSubmit={handleExistingClient}>
                            <fieldset className="form-group">
                              {/* onChange={this.handleChange.bind(this)} */}
                              <div className="form-control border-0 p-0 h-auto is-untouched is-pristine av-valid">
                                {existing.map((row, i) => {
                                  return (
                                    <div className="form-check pt-3" key={i}>
                                      <input
                                        name={`client_id[]`}
                                        type="checkbox"
                                        className=" form-check-input"
                                        value={`${row.client_id}`}
                                      />
                                      <label className="form-check-label">{`${row.first_name} ${row.last_name} (${row.email})`}</label>
                                    </div>
                                  );
                                })}
                              </div>
                            </fieldset>

                            <button
                              className="btn btn-lg btn-primary my-3"
                              type="submit"
                            >
                              Submit
                            </button>
                          </form>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="new" title="Association new">
              <ClientCreateUpdate
                ref={childClientRef}
                getData={getCompanyClients}
                compannyId={info.client_id}
                from="companyDetails"
              />
            </Tab>
          </Tabs>
        </div>
      </div>

      <Modal
        isOpen={DynamicModal}
        fade={true}
        size="md"
        toggle={() => {
          setDynamicModal(false);
          setSmsBody("");
          setToken("");
          setSubject("");
          setHtml("");
        }}
      >
        <AvForm
          onValidSubmit={handleSubmit}
          // model={this.state.record}
          className="needs-validation"
        >
          {DynamicModalName == "Note" ? (
            <div className="note">
              <div className="modal-head top-heading">
                <h2>Add Note</h2>
                <button
                  onClick={() => {
                    setDynamicModal(false);
                    setSmsBody("");
                    setToken("");
                    setSubject("");
                    setHtml("");
                  }}
                  type="button"
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <ModalBody>
                <div className="mb-3">
                  <AvField
                    className="form-control"
                    type="textarea"
                    name="activity"
                    label=""
                    placeholder="Add a note"
                    required
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="info" type="submit">
                  Add Note
                </Button>
                <Button color="danger" onClick={() => {
                  setDynamicModal(false);
                  setSmsBody("");
                  setToken("");
                  setSubject("");
                  setHtml("");
                }}>
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          ) : DynamicModalName == "call" ? (
            <div className="call">
              <div className="modal-head top-heading">
                <h2>Initiate Outbound Call</h2>
                <button
                  onClick={() => {
                    setDynamicModal(false);
                    setSmsBody("");
                    setToken("");
                    setSubject("");
                    setHtml("");
                  }}
                  type="button"
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>

              <ModalBody>
                <div className="mb-3">
                  {callToken ? (
                    <Phone paramsters={paramsters} getCallSid={getCallSid}></Phone>
                  ) : (
                    <p>Loading...</p>
                  )}
                </div>
              </ModalBody>
            </div>
          ) : DynamicModalName == "sms" ? (
            <div className="sms">
              <div className="modal-head top-heading">
                <h2>Send Sms</h2>
                <button
                  onClick={() => {
                    setDynamicModal(false);
                    setSmsBody("");
                    setToken("");
                    setSubject("");
                    setHtml("");
                  }}
                  type="button"
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>

              <ModalBody>
                <div className="mb-3">
                  <Select
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    // value={options.filter(({ id }) => id === this.state.country_code)}
                    isMulti={false}
                    options={templates}
                    classNamePrefix="select2-selection"
                    name="country_id"
                    placeholder={"Select sms template"}
                    onChange={(e) => {
                      setSmsBody(e.body);
                    }}
                  />
                  <AvField type="hidden" name="tos" value={info.phone} />
                  <AvField type="hidden" name="activity" value={"sms send"} />
                </div>

                <div className="mb-3">
                  <AvField
                    className="select form-select"
                    type="select"
                    name="froms"
                    required
                  >
                    <option value=""> Select Phone Number </option>
                    {phoneNumbers.map((row, i) => {
                      return (
                        <option value={row.phone_number}>
                          {convertPhoneFormat(row.phone_number)}
                        </option>
                      );
                    })}
                  </AvField>
                </div>
                <div className="row mb-3">
                  <div className="col-md-8">
                    <Select
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.key}
                      // value={options.filter(({ id }) => id === this.state.country_code)}
                      isMulti={false}
                      options={tokens}
                      classNamePrefix="select2-selection"
                      name="country_id"
                      placeholder={"Select token"}
                      onChange={(e) => {
                        setToken(`{${e.key}}`);
                      }}
                    />
                    {/* <AvField type="hidden" name="country_code" value={token} /> */}
                  </div>
                  <div className="col-md-4">
                    <button
                      onClick={() => setSmsBody(`${smsBody} ${token}`)}
                      type="button"
                      className="btn btn-primary"
                    >
                      Insert Token
                    </button>
                  </div>
                </div>

                <div className="mb-3">
                  <AvField
                    className="form-control"
                    type="textarea"
                    name="body"
                    required
                    value={smsBody}
                    placeholder={"Enter body"}
                    onChange={(e) => setSmsBody(e.target.value)}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="info" type="submit">
                  Send
                </Button>
                <Button color="danger" onClick={() => {
                  setDynamicModal(false);
                  setSmsBody("");
                  setToken("");
                  setSubject("");
                  setHtml("");
                }}>
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          ) : DynamicModalName == "rvm" ? (
            <div className="rvm">
              <div className="modal-head top-heading">
                <h2>Send RVM</h2>
                <button
                  type="button"
                  onClick={() => {
                    setDynamicModal(false);
                    setSmsBody("");
                    setToken("");
                    setSubject("");
                    setHtml("");
                  }}
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>

              <ModalBody>
                <div className="mb-3">
                  <Select
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    // value={options.filter(({ id }) => id === this.state.country_code)}
                    isMulti={false}
                    options={templates}
                    classNamePrefix="select2-selection"
                    name="country_id"
                    placeholder={"Select sms template"}
                    onChange={(e) => {
                      setSmsBody(e.id);
                    }}
                  />
                  <AvField type="hidden" name="tos" value={info.phone} />
                  <AvField type="hidden" name="activity" value={"RVM: send"} />
                  <AvField type="hidden" name="template_id" value={smsBody} />
                </div>

                <div className="mb-3">
                  <AvField
                    className="select form-select"
                    type="select"
                    name="froms"
                    required
                  >
                    <option value=""> Select Phone Number </option>
                    {phoneNumbers.map((row, i) => {
                      return (
                        <option value={row.phone_number}>
                          {convertPhoneFormat(row.phone_number)}
                        </option>
                      );
                    })}
                  </AvField>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="info" type="submit">
                  Send
                </Button>
                <Button color="danger" onClick={() => {
                  setDynamicModal(false);
                  setSmsBody("");
                  setToken("");
                  setSubject("");
                  setHtml("");
                }}>
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          ) : (
            <div className=""></div>
          )}
        </AvForm>
      </Modal>

      <Modal
        isOpen={EmailModal}
        fade={true}
        size="lg"
        toggle={() => {
          setEmailModal(false);
          setSmsBody("");
          setToken("");
          setSubject("");
          setHtml("");
        }}
      >
        <AvForm
          onValidSubmit={handleEmailSubmit}
          autoComplete="off"
          className="needs-validation"
        >
          <div className="email">
            <div className="modal-head top-heading">
              <h2>Send Email</h2>
              <button
                onClick={() => {
                  setEmailModal(false);
                  setSmsBody("");
                  setToken("");
                  setSubject("");
                  setHtml("");
                }}
                type="button"
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>

            <ModalBody>
              <div className="mb-3">
                <Select
                  getOptionLabel={(option) => option.title}
                  getOptionValue={(option) => option.id}
                  // value={options.filter(({ id }) => id === this.state.country_code)}
                  isMulti={false}
                  options={templates}
                  classNamePrefix="select2-selection"
                  placeholder={"Select email template"}
                  name="country_id"
                  onChange={(e) => {
                    setSubject(e.title);
                    getEmailTemplateHtml(e.id);
                  }}
                />
                <AvField type="hidden" name="tos" value={info.email} />
                <AvField type="hidden" name="activity" value={"Email: sent"} />
              </div>

              <div className="mb-3">
                {/* <AvField
                  className="select form-control"
                  type="select"
                  name="froms"
                  required
                >
                  <option value=""> Select email setting </option>
                  {emailSetting.map((row, i) => {
                    return (
                      <option value={row.other_settings.from_email}>
                        {row.other_settings.from_email}
                      </option>
                    );
                  })}
                </AvField> */}

                <AvField
                  className="form-control"
                  type="text"
                  name="froms"
                  value={uInfo().email}
                  placeholder="Enter from email"
                  required
                />
              </div>
              <div className="row mb-3 d-none">
                <div className="col-md-12 mb-3">
                  <Select
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.key}
                    // value={options.filter(({ id }) => id === this.state.country_code)}
                    isMulti={false}
                    options={tokens}
                    classNamePrefix="select2-selection"
                    name="country_id"
                    onChange={(e) => {
                      setToken(`{${e.key}}`);
                    }}
                  />
                  {/* <AvField type="hidden" name="country_code" value={token} /> */}
                </div>
                {html == "" && (
                  <div className="col-md-4">
                    <button
                      onClick={() => setSmsBody(`${smsBody} ${token}`)}
                      type="button"
                      className="btn btn-primary"
                    >
                      Insert Body
                    </button>
                  </div>
                )}
                <div className="col-md-4">
                  <button
                    onClick={() => setSubject(`${subject} ${token}`)}
                    type="button"
                    className="btn btn-primary"
                  >
                    Insert Subject
                  </button>
                </div>
              </div>

              <div className="mb-3">
                <AvField
                  className="form-control"
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  required
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>

              {html == "" ? (
                <div className="mb-3">
                  <AvField
                    className="form-control"
                    type="textarea"
                    name="body"
                    placeholder="Enter body"
                    required
                    value={smsBody}
                    onChange={(e) => setSmsBody(e.target.value)}
                  />
                </div>
              ) : (
                <>
                  <div
                    id="custom-template"
                    className="height-500 custom-scroll"
                    dangerouslySetInnerHTML={{ __html: html }}
                  ></div>
                  {/* <AvField type="hidden" name="body" value={document.getElementById('custom-template')}/> */}
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="info" type="submit">
                Send
              </Button>
              <Button color="danger" onClick={() => {
                setEmailModal(false);
                setSmsBody("");
                setToken("");
                setSubject("");
                setHtml("");
              }}>
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </AvForm>
      </Modal>

      <Modal
        isOpen={sentEmailTemplateModal}
        fade={true}
        size="lg"
        toggle={() => {
          setSentEmailTemplateModal(false);
        }}
      >
        <div className="email">
          <div className="modal-head top-heading">
            <h2>Email</h2>
            <button
              onClick={() => {
                setSentEmailTemplateModal(false);
              }}
              className="btn btn-link text-white"
            >
              <i className="fas fa-times"></i>
            </button>
          </div>

          <ModalBody>
            <div
              className="height-500 custom-scroll"
              dangerouslySetInnerHTML={{ __html: emailData }}
            />
          </ModalBody>
        </div>
      </Modal>

      <Modal
        isOpen={taskCompleteModal}
        fade={false}
        toggle={() => setTaskCompleteModal(false)}
      >
        <ModalBody>
          <div className="warning-box text-center">
            <span className="warning">
              <i className="mdi mdi-alert-circle"></i>
            </span>
            <h3>Are you sure?</h3>
            <p>You want to mark this complete?</p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="info" onClick={() => completeTask()}>
            Yes, Change it
          </Button>
          <Button color="danger" onClick={() => setTaskCompleteModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {page4Class == "slide-in" && (
        <div className={`page3 ${page4Class} custom-scroll`}>
          <div>
            <div className="modal-head top-heading">
              <h2>Add Task </h2>
              <button
                onClick={() => {
                  setpage4Class("slide-out2");
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
            <CreateTask
              cancelSlider={cancelTask}
              compannyId={info.client_id}
              from="companyDetails"
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ComapanyDetails;
