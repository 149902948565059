import React from 'react';
// import DocViewer from 'react-doc-viewer';

function Test() {
  const docs = [
    {
      uri: "https://profit-coach-app.s3.us-west-1.amazonaws.com/drive/user_2/file-sample_1MB.doc",
    },
  ];

  return (
    <div>
      <h1>Document Viewer</h1>
      {/* <DocViewer documents={docs} /> */}
    </div>
  );
}

export default Test;
