import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link, useParams, useHistory } from "react-router-dom";
import { dcrypt, bcrypt, isAllowed, uInfo, uToken } from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import Badge from "react-bootstrap/Badge";
import swal from "sweetalert";
import Select from "react-select";
import Helmet from "react-helmet";
import { currencies } from "../../common/Common-Currency.js";
// import PrintTransaction from "../Settings/Reports/PrintTransaction.js";

// import Dropdownaction from

export default function InvoiceTransactions() {
  const { id } = useParams();
  const [invoiceDetail, setInvoiceDetail] = useState({});
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [addonsList, setAddonsList] = useState([]);
  const [currencySym, setCurrencySym] = useState();
  const [totalAddons, setTotalAddons] = useState(0);
  const [pageName, setPageName] = useState("Invoice Monthly Transactions");
  // const [selectedTransaction, setSelectedTransaction] = useState(null);
  // const handlePrint = (transaction) => {
  //   setSelectedTransaction(transaction);
  // };

  // const closePrintModal = () => {
  //   setSelectedTransaction(null); // Close the print modal
  // };

  const routeHistory = useHistory();

  // const printInvoice = () => {
  //   window.print();
  // };

  const columns = [
    {
      text: "Transaction#",
      key: "transaction_id",
      sortable: false,
      cell: (record, index) => {
        return `TX-${String(record.transaction_id).padStart(5, "0")}`;
      },
    },

    {
      text: "Payment",
      key: "payment_method",
      sortable: false,
    },

    {
      text: "Total Price",
      key: "price",
      sortable: false,
      cell: (addon) => {
        return (
          <p className="float-end">
            {getCurrencySymbol(addon.currency_code) + " " + addon.price}
          </p>
        );
      },
    },
    {
      text: "Amount IN USD",
      key: "amount_in_usd",
      sortable: false,
      cell: (addon) => {
        return <p className="float-end">{addon.amount_in_usd}</p>;
      },
    },

    {
      text: " Billing Date",
      key: "created_at",
      sortable: false,
    },

    {
      text: "Type",
      key: "type",
      cell: (addon, index) => {
        return <p key={index}>{addon.type}</p>;
      },
    },
    {
      text: "billing status",
      key: "status",
      cell: (addon, index) => {
        return (
          <>
            {addon.status == "Success" && (
              <Badge bg="success">{addon.status}</Badge>
            )}
            {addon.status == "Failed" && <Badge bg="danger">Failed</Badge>}
          </>
        );
      },
    },
    // {
    //   key: "action",
    //   text: "Action",
    //   className: "RevenueType",
    //   cell: (record, index) => {
    //     return (
    //       <>
    //         <Link
    //           to="#"
    //           // onClick={printInvoice}
    //           onClick={() => handlePrint(record)}
    //           className="btn btn-success btn-sm"
    //         >
    //           <i className="fa fa-print"></i>
    //         </Link>
    //       </>
    //     );
    //   },
    // },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50, 100],
    show_length_menu: true,
    show_filter: false,
    show_pagination: true,
    show_info: true,
  };

  const getCurrentSubscriptions = (queryString = "") => {
    fetch(`${ApiUrl}invoice/transactions` + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify({ invoice_id: dcrypt(id) }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          console.log("data.data.history", data.data.history);
          setAddonsList(data.data.history);
          setTotalAddons(data.data.total);
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getInvoiceDetail = () => {
    fetch(`${ApiUrl}` + "get/edit/invoice", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },

      body: JSON.stringify({
        id: dcrypt(id),
        coach_id: uInfo().user_id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setInvoiceDetail(response.data.record);
          setInvoiceItems(JSON.parse(response.data.record.items_data));
          getInvoiceCurrency(response.data.record.currency_code);
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        // if (key === "sort_order" && data[key]) {
        //   return `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`;
        // } else {
        //   return `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`;
        // }

        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");

    console.log("query", queryString);

    getCurrentSubscriptions("?" + queryString);
  };

  useEffect(() => {
    getCurrentSubscriptions();
    getInvoiceDetail();
  }, []);

  const getInvoiceCurrency = (code) => {
    let currencysymbol = currencies[code];
    if (currencysymbol) {
      console.log("currencysymbol.symbol", currencysymbol.symbol);
      setCurrencySym(currencysymbol.symbol);
    }
  };
  const getCurrencySymbol = (code) => {
    let currencysymbol = currencies[code];
    if (currencysymbol) {
      return currencysymbol.symbol;
    }
  };

  return (
    <>
      <Helmet>
        <title>{pageName}</title>
      </Helmet>

      <Row>
        <Col sm={6}>
          <div className="page-title-box">
            <h4>{pageName}</h4>
            <ol className="breadcrumb m-0">
              <li key={0} className="breadcrumb-item active">
                {ProductName}
              </li>
              <li key={1} className="breadcrumb-item">
                <Link to="#">{pageName}</Link>
              </li>
            </ol>
          </div>
        </Col>
        <Col sm={6}>
          <div className="page-title-box text-align-right">
            <Button
              className="btn-sm"
              type="button"
              color="secondary"
              onClick={() => routeHistory.goBack()}
            >
              Back
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Card>
            <CardBody>
              <CardTitle className="h4">
                Invoice Detail :
                <b>
                  {" "}
                  #IN-
                  {String(invoiceDetail.invoice_id).padStart(5, "0")}
                </b>
              </CardTitle>
              <div className="table-responsive user-info">
                <Table className="table table-bordered mb-0 border-0">
                  <tbody>
                    <tr>
                      <td>Product</td>
                      <td>{invoiceDetail.invoice_product}</td>
                    </tr>

                    <tr>
                      <td>Pricing Interval</td>
                      <td>{invoiceDetail.renewal_interval}</td>
                    </tr>
                    <tr>
                      <td>Recent Billing Date</td>
                      <td>{addonsList[0]?.last_billing_date}</td>
                    </tr>
                    <tr>
                      <td>Next Billing Date</td>
                      <td>{addonsList[0]?.expiry_date}</td>
                    </tr>
                    <tr>
                      <td>Amount</td>

                      <td>
                        {invoiceItems.map((item, i) =>
                          item.total > 0 ? (
                            <div class="d-flex align-items-center">
                              <span>{item.items}:</span>
                              <b class="ms-2">
                                {currencySym + " " + (item.total)}
                              </b>
                            </div>
                          ) : null
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={6}>
          <Card>
            <CardBody>
              <CardTitle className="h4">User Info</CardTitle>
              <div className="table-responsive user-info">
                <Table className="table table-bordered mb-0 border-0">
                  <tbody>
                    <tr>
                      <td>User Id#</td>
                      <td>{invoiceDetail.createdFor?.client_id}</td>
                    </tr>

                    <tr>
                      <td>Name</td>
                      <td>{invoiceDetail.created_for_name || ""}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{invoiceDetail.createdFor?.email}</td>
                    </tr>
                    <tr>
                      <td>Payment By</td>
                      <td>
                        {invoiceDetail.payedByInfo?.marked_by == "Coach"
                          ? "Manually paid"
                          : "Client"}
                      </td>
                    </tr>
                    {/* <tr>
                      <td>Created at</td>

                      <td>
                        {invoiceItems.map((item, i) =>
                          item.total > 0 ? (
                            <div class="d-flex align-items-center">
                              <span>{item.items}:</span>
                              <b class="ms-2">
                                {currencySym}
                                {Math.round(item.total)}
                              </b>
                            </div>
                          ) : null
                        )}
                      </td>
                    </tr> */}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={12}>
          <Card>
            <CardBody className="py-0">
              {/* <div className="card-body py-0"> */}
              <ReactDatatable
                config={config}
                records={addonsList}
                columns={columns}
                dynamic={true}
                total_record={totalAddons}
                onChange={tableChangeHandler}
              />
              {/* </div> */}
            </CardBody>
          </Card>
        </Col>

        {/* {selectedTransaction && (
          <PrintTransaction
            sub_id={selectedTransaction.subscription_id}
            trans_id={selectedTransaction.transaction_id}
            user_id={selectedTransaction.user_id}
            onClose={closePrintModal}
          />
        )} */}
      </Row>
    </>
  );
}
