import React, { useState, useEffect, useRef } from "react";
import {
    Row, Col, Card, CardBody, CardTitle, Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Button,
} from "reactstrap"

import { ApiUrl, ProductName } from "../../config";
import { uInfo, uToken, dcrypt } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import swal from 'sweetalert';
import * as SignaturePad from 'signature_pad';


const ProposalSignature = (props) => {
    const [data, setData] = useState({});
    const [isSecureRequuest] = useState(props.isSecureRequuest ? props.isSecureRequuest : "No");
    const [relational_id] = useState(props.relational_id ? props.relational_id : 0);
    const [user_id] = useState(props.user_id ? props.user_id : 0);
    const [loading, setLoading] = useState(true);
    const [tab, setTab] = useState("1");
    const [textSig, setTextSig] = useState("");
    const [drawSig, setDrawSig] = useState([]);
    const [imageSig, setImageSig] = useState(null);
    const [signature, setSignature] = useState('Y');
    const [type] = useState(props.type ? props.type : 'signup-proposal');
    useEffect(() => {
        setLoading(false);
        //console.log(relational_id, 'relational_id')
    }, []);


    let signaturePad = null;
    if (document.querySelector('canvas') && tab == '2') {
        var canvas = document.querySelector('canvas');
        signaturePad = new SignaturePad.default(canvas, { backgroundColor: 'rgb(255, 255, 255)', width: '100%' });
        signaturePad.addEventListener("beginStroke", () => {
            setDrawSig(signaturePad.toData());
        }, { once: true });


        document.getElementById('clear').addEventListener('click', function () {
            signaturePad.clear();
            setDrawSig([]);
        });

    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (props.proposal_type != 'Package' && isSecureRequuest != "Yes") {
            swal({
                title: "Alert!",
                text: "Sorry, you cannot accept this proposal publicly. Please login your account and proceed.",
                icon: "warning",
            });
            return;
        }
        if (tab == '2' && drawSig.length < 1) {
            swal({
                title: "Alert!",
                text: "Please Provide your Signature",
                icon: "warning",
            });
            return;
        }
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();

        if (signature == 'Y') {
            if (tab == '1') {
                submitData.append('digital_signature', textSig);
                submitData.append('canvas_jason', '');
                submitData.append('signature_type', 'type_it')
            } else if (tab == '2') {
                submitData.append('canvas_jason', JSON.stringify(drawSig));
                submitData.append('digital_signature_image_url', signaturePad.toDataURL('image/jpeg'));
                submitData.append('signature_type', 'drwa_it');

            }
        }
        submitData.append('type', type);
        submitData.append('relational_id', relational_id);
        submitData.append('user_id', user_id);
        submitData.append('status', 'Active');
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        fetch(`${ApiUrl}` + "proposal/meta/saved/" + props.proposal_id, {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData,
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    props.signUpStep("two");
                    //toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });

    }

    const toggle = (index) => {
        if (tab !== index) {
            setTab(index);
        }
    };



    return (
        <div>
            {
                !loading && (
                    <div className="row w-100">
                        <div className="col-md-12">

                            <Card>
                                <CardBody>
                                    <CardTitle style={{ fontWeight: "bold" }}>I, agree to the terms of this agreement and I agree that my typed name below can be used as a digital representation of my signature to that fact</CardTitle>
                                    <form onSubmit={handleSubmit} method="post" encType="multipart/form-data" className="form-horizontal" id="selection-myform">

                                        {
                                            signature == 'Y' && (
                                                <>
                                                    <Nav tabs style={{ width: "100%", height: "100%", position: "relative", boxShadow: "0 0 0" }}>
                                                        <NavItem>
                                                            <NavLink
                                                                className={tab == 1 ? "active" : ""}
                                                                onClick={() => {
                                                                    toggle("1");
                                                                }}
                                                            >
                                                                Type it
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink
                                                                className={tab == 2 ? "active" : ""}
                                                                onClick={() => {
                                                                    toggle("2");
                                                                }}
                                                            >
                                                                Draw it
                                                            </NavLink>
                                                        </NavItem>
                                                    </Nav>
                                                    <TabContent activeTab={tab}>
                                                        <TabPane tabId="1">
                                                            <div className="pt-20">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label className={`mt-3`}>
                                                                            Type Your Signature (Full Name)
                                                                        </label>
                                                                        <div className={`mb-3 form-group`}>
                                                                            <input
                                                                                id="text-sig"
                                                                                type="text"
                                                                                className="form-control"
                                                                                name="text-sig"
                                                                                required={tab == '1' ? true : false}
                                                                                onChange={(e) => setTextSig(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label className={`mt-3`}>

                                                                        </label>
                                                                        <div className="outer_type_it">
                                                                            <div className="type_it">{textSig}</div>

                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </TabPane>
                                                        <TabPane tabId="2">
                                                            <div className="mt-3">
                                                                <div className="canvas-wrapper">
                                                                    <canvas style={{ border: '2px solid #000' }} width={'1000px'} height={'200px'} ></canvas>
                                                                    <button className="btn btn-link" type="button" id="clear">Clear</button>
                                                                </div>
                                                            </div>
                                                        </TabPane>
                                                    </TabContent>
                                                </>
                                            )
                                        }

                                        <div className="form-group mt-3">
                                            <button className="btn btn-sm btn-block btn-primary" type="submit" id="submit_selection_myform" value="submit">
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </div>

                    </div>
                )
            }
        </div>
    )

}

export default ProposalSignature;
