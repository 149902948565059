import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import { dcrypt, bcrypt, isAllowed, uInfo, uToken } from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import Badge from "react-bootstrap/Badge";
import swal from "sweetalert";
import Select from "react-select";
import Dropdownaction from "./Dropdown";

export default function AddonsSubscription() {
  const [addonsList, setAddonsList] = useState([]);
  const [addonDetail, setAddonDetail] = useState({});
  const [totalAddons, setTotalAddons] = useState(0);
  const [addonData, setAddonData] = useState({});
  const [packages, setPackages] = useState([]);

  const columns = [
    // {
    //   text: "Sr#",
    //   key: "sr",
    //   sortable: false,
    // },
    {
      key: "action",
      text: "Action",
      style: { overflow: "visible" },
      cell: (addon) => {
        if (addon.pricing_interval == "Monthly") {
          return (
            <Dropdownaction
              userid={uInfo().user_id}
              props={addon.subscription_id}
              trans_id={addon.transaction_id}
            />
          );
        }
      },
    },
    {
      text: "Addon",
      key: "title",
      sortable: false,
      cell: (addon, index) => {
        return (
          <>
            {addon.title} <sub> / {addon.pricing_interval}</sub>
          </>
        );
      },
    },

    {
      text: "Payment",
      key: "payment_method",
      sortable: false,
    },

    {
      text: "Amount",
      key: "price",
      sortable: false,
      cell: (addon) => {
        return (
          <p className="float-end" >{addon.price}</p>
        )
      },
    },

    {
      text: "Start Date",
      key: "start_date",
      sortable: false,
    },

    {
      text: "Recent Billing Date",
      key: "last_billing_date",
      sortable: false,
    },
    {
      text: "Next Billing Date",
      key: "expiry_date",
      sortable: false,
    },
    {
      text: "Type",
      key: "subscription_type",
      cell: (addon, index) => {
        if (addon.subscription_type == "Paid") {
          return (
            <p key={index} className="badge bg-success">
              {addon.subscription_type}
            </p>
          );
        } else if (addon.subscription_type == "Unpaid") {
          return (
            <p key={index} className="badge bg-danger">
              {addon.subscription_type}
            </p>
          );
        } else {
          return <p key={index}>{addon.subscription_type}</p>;
        }
      },
    },
    {
      text: "billing status",
      key: "status",
      cell: (addon, index) => {
        return (
          <>
            {addon.status == "Active" && (
              <Badge bg="success">{addon.status}</Badge>
            )}
            {addon.status == "Inactive" && <Badge bg="danger">Inactive</Badge>}
          </>
        );
      },
    },
    // {
    //   key: "action",
    //   text: "Action",
    //   cell: (addon, index) => {
    //     return (
    //       <>
    //         {isAllowed(uInfo(), ["can_edit"]) && (
    //           <>
    //             {/* <Link
    //               className="btn-primary btn-sm mr-5"
    //               to={"/edit/addon/" + bcrypt(addon.id)}
    //               // to={{ pathname: '/edit/addon', state: { addon } }}
    //             >
    //               <i className="mdi mdi-pencil"></i>
    //             </Link> */}
    //             <button
    //               className="btn-primary btn-sm mr-5"
    //               onClick={() => {
    //                 setAddonDetail(addon);
    //               }}
    //             >
    //               <i className="mdi mdi-pencil"></i>
    //             </button>
    //           </>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50, 100],
    show_length_menu: false,
    show_filter: false,
    show_pagination: false,
    show_info: false,
  };

  const getCurrentSubscriptions = (queryString = "?all=1", data) => {
    fetch(`${ApiUrl}addons/current/subscription` + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      // body: JSON.stringify({ type: path }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          setAddonsList(data.data.records);
          setTotalAddons(data.data.total);
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getPackages = () => {
    fetch(`${ApiUrl}` + "packages/list/Coach", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      // body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setPackages(response.data.records);
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const tableChangeHandler = (data) => {
    const queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`;
        } else {
          return `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`;
        }
      })
      .join("&");

    getCurrentSubscriptions("?" + queryString);
  };

  useEffect(() => {
    getCurrentSubscriptions();
    getPackages();
  }, []);

  return (
    <>
      <ReactDatatable
        config={config}
        records={addonsList}
        columns={columns}
        dynamic={true}
        total_record={totalAddons}
        onChange={tableChangeHandler}
      />
    </>
  );
}
