import React, { Component, Fragment } from "react";
import Select from "react-select";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName,ToastAutoClose} from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
// import '../../../node_modules/bootstrap/js/dist/tooltip';

export default class GroupCreateUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      record: {
        title: "",
        status: "Active",
        type: "Group",
      },
      page_name: this.props.match.params.id ? "Edit Group" : "Create Group",
      managers: [],
      permissions: [],
    };
  }

  componentDidMount() {
    this.state.id != null && this.getData();
    this.getManagers();
    this.getpermissions();
  }
  handlePermission = (selectedPermission) => {
    var filter = [];
    if (selectedPermission) {
      selectedPermission.map((fil) => {
        filter.push(fil.user_id);
      });
    }

    this.setState({
      permission: filter.toString(),
      selectedPermission: selectedPermission,
    });
  };
  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/group", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        /* console.log(response, "data"); */
        if (response.status === true) {
          this.setState({
            record: response.data.record,
            selectedPermission: response.data.permissions,
            // inputList: JSON.parse(response.data.record.description)
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getpermissions = () => {
    fetch(`${ApiUrl}` + "get/group/permissions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            permissions: response.data.coachpartners,
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getManagers = () => {
    if (this.props.role.id == 2) {
      fetch(`${ApiUrl}` + "users", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
      })
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((response) => {
          if (response.status === true) {
            this.setState({
              managers: response.data.records,
            });
          } else {
                      
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        //Then with the error genereted...
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      this.setState({
        managers: [],
      });
    }
  };

  handleSubmit = async (event, values) => {
    values.body = this.state.editor;
    if (this.state.id) {
      values.id = this.state.record.group_id ? this.state.record.group_id : -1;
    }
    fetch(`${ApiUrl}` + "edit/group", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          setTimeout(() => this.props.history.push("/clients/groups"), 1000);
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.page_name} </h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#"> {this.state.page_name} </Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                className="btn-sm"
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
        <ToastContainer />
        <Row>
          <Col className="col-12">
            <Card>
              <AvForm
                onValidSubmit={this.handleSubmit}
                model={this.state.record}
                ref={(c) => (this.form = c)}
                className="needs-validation"
              >
                <ModalBody>
                  <div className="mb-3">
                    <AvField
                      name="title"
                      value={this.state.record.title}
                      label="Title"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <AvField
                      className="select form-select"
                      type="select"
                      value={this.state.record.type}
                      name="type"
                      label="Type"
                      required
                    >
                      <option value={"Group"}> Group </option>
                      <option value={"Tag"}> Tag </option>
                    </AvField>
                  </div>
                  {this.state.managers.length > 0 && (
                    <div className="mb-3">
                      <AvField
                        className="select form-select"
                        type="select"
                        name="manager_id"
                        label="Manager"
                        value={this.state.record.user_id}
                        required
                      >
                        <option value={""}> Select Manager </option>
                        {this.state.managers.map((row) => {
                          return (
                            <option key={row.id} value={row.id}>
                              {`${row.name} `}
                            </option>
                          );
                        })}
                      </AvField>
                    </div>
                  )}
                  <div className="mb-3">
                    <AvField
                      className="select form-select"
                      type="select"
                      value={this.state.record.status}
                      id="s"
                      name="status"
                      label="Status"
                      required
                    >
                      <option value={"Active"}> Active </option>
                      <option value={"Inactive"}> Inactive </option>
                    </AvField>
                  </div>
                  <div className="mb-3">
                    <label className="control-label"> Assign to </label>
                    <Select
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.user_id}
                      isMulti={true}
                      value={this.state.selectedPermission}
                      options={this.state.permissions}
                      classNamePrefix="select form-control"
                      style={{ position: "absolute" }}
                      className={"is-touched is-dirty av-valid"}
                      onChange={(e) => {
                        this.handlePermission(e);
                      }}
                    />
                    <AvField
                      // required
                      type="hidden"
                      name="permission"
                      value={this.state.permission}
                    />
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    className="btn-sm"
                    type="button"
                    color="secondary"
                    onClick={() => this.props.history.goBack()}
                  >
                    Cancel
                  </Button>
                  <button
                    className="btn btn-primary waves-effect waves-light btn-sm"
                    type="submit"
                  >
                    Submit
                  </button>
                </ModalFooter>
              </AvForm>
            </Card>
          </Col>
        </Row>
        {/* /////////////////////////////////////////////// */}
      </React.Fragment>
    );
  }
}
