import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,

  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";


import Helmet from "react-helmet";
import {
  AvForm,
  AvField,
} from "availity-reactstrap-validation";
import {
  dcrypt,
  uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose, AttachementsUrl } from "../../config";

import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
// import SunEditor, { buttonList } from 'suneditor-react';
// import 'suneditor/dist/css/suneditor.min.css';
import Loader from "react-loader";

import grapesjs from 'grapesjs'
import 'grapesjs/dist/css/grapes.min.css'
import 'grapesjs/dist/grapes.min.js'
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.css'
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.js'
import Froala from "../Froala";
import swal from 'sweetalert';

export default class ProposalsContentEdit extends Component {
  constructor(props) {
    super(props);
    //console.log(props, 'propsprops');
    this.state = {
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      proposal_id: this.props.match.params.proposal_id
        ? this.props.match.params.proposal_id
        : null,
      record: {
        title: '',
        body: '',
      },
      page_name: "Edit Page Content",

      roles: [],
      inputList: [],
      modal: false,
      loader: true
    };
    this.role = { id: this.props.role.id };
    this.handleChange = this.handleChange.bind(this);
    this.editor = null;
  }
  handleChange(content) {
    this.setState({
      editor: content
    });
  }
  componentDidMount() {
    this.getSelectedPackage();
  }
  getSelectedPackage = () => {
    fetch(`${ApiUrl}` + "proposal/page/edit", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            record: response.data.record,
          });

          this.editor.addComponents(response.data.record.body);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = (event, content = "") => {
    event.preventDefault();
    if(content == ""){
      content = this.state.record.body;
    }
    let id = this.state.id ? this.state.id : -1;
    let title = this.state.record.title;
    fetch(`${ApiUrl}` + "proposal/page/save", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: id, title: title, body: content }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({ loader: true });
          this.props.history.push("/packages/proposal/content/" + this.state.proposal_id);
        } else {
          this.setState({ loader: true });
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        this.setState({ loader: true });
        console.error("Error:", error);
      });
  }


  handleSubmitOld = async (event, projectHtmlEl) => {
    this.setState({ loader: false });
    const submitData = new FormData(event);
    if (this.state.id) {
      submitData.set('id', this.state.id ? this.state.id : -1);
    }
    submitData.set('body', projectHtmlEl);
    //console.log( submitData.get('title'),'bodydddddd')

    // fetch(`${ApiUrl}` + "proposal/page/save", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ` + uToken(),
    //   },
    //   body: submitData,
    // })
    fetch(`${ApiUrl}` + "proposal/page/save", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
            this.setState({
              inputList: [],
            });
          }
          this.setState({ loader: true });
          this.props.history.push("/packages/proposal/content/" + this.state.proposal_id);
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          this.setState({ loader: true });
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };


  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {this.state.page_name}
            Tables
          </title>
        </Helmet>

        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.page_name} </h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#"> {this.state.page_name} </Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                type="button"
                color="secondary"
                className="btn-sm"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>

        <ToastContainer />
        <Loader loaded={this.state.loader}></Loader>
        <Row>
          <Col className="col-12">
            <div className="package-add">
              {/* <AvForm
                onValidSubmit={this.handleSubmit}
                model={this.state.record}
                ref={(c) => (this.form = c)}
                className="needs-validation"
              > */}
              <form id="my-form" onSubmit={this.handleSubmit}>
                  <div className="form-group mb-3">
                    <label> Name </label>
                    <input
                      name="title"
                      defaultValue={this.state.record.title}
                      className="form-control"
                      required
                      onChange={(e) => {
                        this.setState(prevState => {
                          let record = Object.assign({}, prevState.record);
                          record.title = e.target.value;
                          return { record };
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label> Page Content </label>

                    {
                      this.state.loader && this.state.record.body && (
                        <Froala btnId={'edit-proposal-content'} content={this.state.record.body} handleSubmit={this.handleSubmit} use_token={'users'} />
                      )
                    }
                  </div>

                  {/* <div className="mb-3">
                      <label> Page Content </label>
                        <div className="group-addnon">
                        {
                      this.state.record.id ? (
                        <div className="mb-3">
                          <SunEditor
                            setOptions={{
                              height: 400,
                              buttonList: [
                                ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                ['link', 'image', 'fullScreen', 'showBlocks', 'codeView', 'preview']
                              ] // Or Array of button list, eg. [['font', 'align'], ['image']]
                              // plugins: [font] set plugins, all plugins are set by default
                              // Other option
                            }}
                            defaultValue={this.state.record.body}
                            onChange={this.handleChange}
                          />
                        </div>
                      ) :
                        <SunEditor
                          setOptions={{
                            height: 400,
                            buttonList: [
                              ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                              ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                              ['link', 'image', 'fullScreen', 'showBlocks', 'codeView', 'preview']
                            ] // Or Array of button list, eg. [['font', 'align'], ['image']]
                            // plugins: [font] set plugins, all plugins are set by default
                            // Other option
                          }}
                          setContents={this.state.editor}
                          onChange={this.handleChange}
                        />


                    }
                    <AvField
                      name="body"
                      value={this.state.editor}
                      type="hidden"
                      required
                    />
                          </div>
                    </div> */}

                <ModalFooter>
                  <Button
                    type="button"
                    color="secondary"
                    className="btn-sm"
                    onClick={() => this.props.history.goBack()}
                  >
                    Cancel
                  </Button>
                  <button
                    id="edit-proposal-content"
                    className="btn btn-primary waves-effect waves-light ms-2 btn-sm"
                    type="submit"
                  >
                    Submit
                  </button>
                </ModalFooter>
              </form>
              {/* </AvForm> */}
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
