import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";
import DropdownButton from "react-bootstrap/DropdownButton";

import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import ProfileSettings from "../../Authentication/profile-sidebar";
import Badge from "react-bootstrap/Badge";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import swal from "sweetalert";

export default class Billing extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        text: "Transaction#",
        key: "transaction_id",
        sortable: false,
        cell: (record, index) => {
          return `TX-${String(record.transaction_id).padStart(5, "0")}`;
        },
      },
      {
        text: "Name",
        key: "name",
        sortable: false,
        cell: (record, index) => {
          if (record.description != "") {
            return (
              <>
                {record.name}
                <OverlayTrigger
                  key={`auto-`}
                  placement={"auto"}
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      {record.description}
                    </Tooltip>
                  }
                >
                  <i className="fas fa-info pl-3"></i>
                </OverlayTrigger>
              </>
            );
          } else {
            return record.name;
          }
        },
      },
      {
        text: "Transaction Type",
        key: "transaction_type",
        sortable: false,
        cell: (record, index) => {
          return record.transaction_type == 'Payable' ? 'Payable (out)' : 'Receivable (in)'
          // return record.transaction_type == 'out' ? 'Payable (out)' : 'Receivable (in)'
          // return record.transaction_type
        },

      },
      {
        text: "Payment method",
        key: "payment_method",
        sortable: false,
      },
      {
        text: "Status",
        key: "status",
        sortable: false,
        cell: (record, index) => {
          return (
            <Fragment>
              {record.status == "Success" && <Badge bg="success"> Paid </Badge>}
              {record.status == "Failed" &&
                record.transaction_type == "Receivable" && (
                  <button
                    //  disabled={record.status == 'Abandoned' ? true : false}
                    className="border-0 p-0 lh-1"
                    onClick={this.handleChange.bind(this, record, index)}
                  >
                    <Badge bg="danger"> Failed </Badge>
                  </button>
                )}
              {record.status == "Failed" &&
                record.transaction_type == "Payable" && (
                  <Badge bg="danger"> Failed </Badge>
                )}
              {record.status == "Manual" &&
                record.transaction_type == "Receivable" && (
                  <button
                    // disabled={record.status == 'Abandoned' ? true : false}
                    className="border-0 p-0 lh-1"
                    onClick={this.handleChange.bind(this, record, index)}
                  >
                    <Badge bg="danger">Unpaid</Badge>
                  </button>
                )}
              {record.status == "Manual" &&
                record.transaction_type == "Payable" && (
                  <button
                    // disabled={record.status == 'Abandoned' ? true : false}
                    className="border-0 p-0 lh-1"
                    // onClick={this.handleChange.bind(this, record, index)}
                  >
                    <Badge bg="success"> Paid </Badge>
                  </button>
                )}
            </Fragment>
          );
        },
      },
      {
        text: "Price",
        key: "amount_in_usd",
        sortable: false,
        cell: (record, index) => {
          return (
            <p className="float-end">
              {record.amount_in_usd}
            </p>
          );
        },
      },
      {
        text: "Type",
        key: "type",
        sortable: false,
      },
      {
        text: "Dated",
        key: "created_at",
        sortable: false,
      },
    ];
    this.config = {
      key_column: "sr",
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: false,
      show_pagination: true,
      button: {
        excel: false,
        print: false,
        csv: false,
      },
    };
    this.state = {
      index: { btnInfo1: false },
      records: [],
      roles: [],
      total_pages: 0,
      modal: false,
      record: {},
      filter: {},
      page_name: "Invoices",
      customDate: true,
      historyRecord: {},
      total_price: 0,
      stripe_paid: 0,
      stripe_unpaid: 0,
      manual_paid: 0,
      manual_unpaid: 0,
    };
  }
  handleChange = (record, index) => {
    this.setState({
      Modal: true,
      historyRecord: record,
      historyStatus: "",
    });
  };
  changeStatus = (status) => {
    let record = this.state.historyRecord;
    if (status == "") {
      alert("Please select status");
    }

    swal({
      title: "Are you sure?",
      text: "You want to perform this action",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmation) => {
      if (confirmation) {
        fetch(
          `${ApiUrl}` +
            "change/transactionHistory/status/" +
            record.transaction_id +
            "/" +
            status,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.status === true) {
              this.getData();
              this.setState({
                historyRecord: {},
                Modal: false,
              });
            } else {
              toast.error(data.message, { theme: "colored" });
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        this.setState({
          historyRecord: {},
          Modal: false,
        });
      }
    });
  };
  setBtnInfo1(index) {
    console.log(index, "debug");
    this.setState({
      index: { btnInfo1: true },
    });
  }

  componentDidMount() {
    this.getData();
  }
  cancelFilter = async () => {
    this.form && this.form.reset();
    this.setState({ filter: {}, customDate: true });
    this.getData(this.state.type, "");
  };

  handleFilterSubmit = async (event, values) => {
    this.setState({
      filter: values,
    });
    this.getData("", values);
  };

  getData = (queryString = "", data) => {
    fetch(`${ApiUrl}` + "get/user/transaction/list" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          console.log(response, "debug");
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
            roles: response.data.roles,
            total_price: response.data.total_price,
            stripe_paid: response.data.stripe_paid,
            stripe_unpaid: response.data.stripe_unpaid,
            manual_paid: response.data.manual_paid,
            manual_unpaid: response.data.manual_unpaid,
          });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData("?" + queryString, this.state.filter);
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <ToastContainer />
        <Row>
          <Col lg="2" className="pl-0">
            <ProfileSettings menuOpen={false} props={this.props} />
          </Col>
          <Col lg="10">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name} </h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name} </Link>
                    </li>
                  </ol>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xl="12">
                <Card className="mb-2">
                  <CardBody className="pb-0">
                    <AvForm
                      onValidSubmit={this.handleFilterSubmit}
                      ref={(c) => (this.form = c)}
                      className="needs-validation"
                    >
                      <Row>
                        <Col md="2">
                          <div className="mb-3">
                            <AvField
                              className="select form-select"
                              type="select"
                              name="transaction_type"
                              label="Transaction Type"
                            >
                              <option value="">All</option>
                              <option value="in">In</option>
                              <option value="out">Out</option>
                            </AvField>
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <AvField
                              className="select form-select"
                              type="select"
                              name="type"
                              label="Type"
                            >
                              <option value="">All</option>
                              <option value="Subscription">
                                Revenue Subscription
                              </option>
                              <option value="Other">
                                Revenue (Non-Subscription)
                              </option>
                            </AvField>
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <AvField
                              name="start_date"
                              label="Start date"
                              className="form-control"
                              placeholder="Enter Email Address"
                              type="date"
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <AvField
                              name="end_date"
                              label="End date"
                              className="form-control"
                              placeholder="Enter Email Address"
                              type="date"
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <label className=""> {"Action"}</label>
                            <div className="button-items">
                              <Button
                                className="btn-sm"
                                color="primary"
                                type="submit"
                              >
                                Filter
                              </Button>
                              <Button
                                onClick={() => this.cancelFilter()}
                                outline
                                color="secondary"
                                className="waves-effect btn-sm"
                                type="button"
                              >
                                Reset
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      {/* <div className="button-items">
                        <Button
                          className="btn-sm"
                          color="primary"
                          type="submit"
                        >
                          Filter
                        </Button>
                        <Button
                          onClick={() => this.cancelFilter()}
                          outline
                          color="secondary"
                          className="waves-effect btn-sm"
                          type="button"
                        >
                          Reset
                        </Button>
                      </div> */}
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody className="py-0">
                    <CardTitle className="h4 mb-0">
                      <span class="badge bg-info">
                        Total: {this.state.total_price}{" "}
                      </span>
                      &nbsp;
                      <span class="badge bg-success">
                        Stripe Paid: {this.state.stripe_paid}{" "}
                      </span>
                      &nbsp;
                      <span class="badge bg-danger">
                        Stripe Unpaid: {this.state.stripe_unpaid}{" "}
                      </span>
                      &nbsp;
                      <span class="badge bg-success">
                        Manual Paid: {this.state.manual_paid}{" "}
                      </span>
                      &nbsp;
                      <span class="badge bg-danger">
                        Manual Unpaid: {this.state.manual_unpaid}
                      </span>
                      &nbsp;
                    </CardTitle>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody className="pt-0">
                    <ReactDatatable
                      config={this.config}
                      records={this.state.records}
                      columns={this.columns}
                      dynamic={true}
                      total_record={this.state.total_pages}
                      onChange={this.tableChangeHandler}
                    />

                    {/* <ReactDatatable
                      key={this.state.id}
                      config={this.config}
                      records={this.state.records}
                      columns={this.columns}
                      dynamic={true}
                      total_record={this.state.total_pages}
                    /> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.Modal}
          fade={false}
          toggle={() => this.setState({ Modal: false })}
        >
          <ModalBody>
            <div className="form-group mb-3">
              <label>Select Status</label>
              <select
                onChange={(e) =>
                  this.setState({ historyStatus: e.target.value })
                }
                name="from_number"
                className="form-select"
              >
                <option value={""}> Select Status</option>
                <option value={"Success"}> Paid</option>
              </select>
            </div>
          </ModalBody>
          <ModalFooter>
            {this.state.historyStatus != "" ? (
              <Button
                color="info"
                onClick={() => this.changeStatus(this.state.historyStatus)}
              >
                Yes, Change it
              </Button>
            ) : (
              <Button disabled color="info">
                Yes, Change it
              </Button>
            )}
            <Button
              color="danger"
              onClick={() => this.setState({ Modal: false })}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
