import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import Helmet from "react-helmet";
import {
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import Loader from "react-loader";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Froala from "../../Froala";

export default class TrainingContentAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      record: {},
      page_name: "Add Action Step",
      loader: true,
      trackerCategories: []
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = (id) => {
    fetch(`${ApiUrl}` + "get/categories/trackerlist", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            trackerCategories: response.data.trackerCategories
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    this.setState({ loader: false });

    fetch(`${ApiUrl}` + "add/tracker", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          this.props.history.goBack();
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        this.setState({ loader: true });
      })
      //Then with the error genereted...
      .catch((error) => {
        this.setState({ loader: true });

        console.error("Error:", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {this.state.page_name}
            Tables
          </title>
        </Helmet>

        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.page_name} </h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#"> {this.state.page_name} </Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                className="btn-sm"
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
        <ToastContainer />
        <Loader loaded={this.state.loader}></Loader>
        <Row>
          <Col className="col-12">
              <form onSubmit={this.handleSubmit} method="post"
                encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                  <div className="form-group mb-3">
                    <label>Category </label>
                    <select required name="cat_key" className="form-select">
                      <option value=""> Select Option </option>
                      {
                        this.state.trackerCategories.map((row, i) => {
                          return (
                            <option key={i} value={row.cat_key} > {`${row.cat_name}`}</option>
                          );
                        })
                      }
                    </select>
                  </div>

                  <div className="form-group mb-3">
                    <label>Title </label>
                    <input className="form-control" name="title" required />
                  </div>



                  <div className="mb-3" >

                    <Froala nameKey={'description'} insideForm={true} btnId={'submit-tracker'} handleSubmit={() => console.log('hi')} use_token={'users'} />

                    {/* <SunEditor name="description"
                      setOptions={
                        {
                          height: 600,
                          width: '100%',
                          resizeEnable: 'false',
                          buttonList: [
                            ["undo", "redo", "font", "fontSize", "formatBlock"],
                            [
                              "bold",
                              "underline",
                              "italic",
                              "strike",
                              "subscript",
                              "superscript",
                              "removeFormat",
                            ],
                            [
                              "fontColor",
                              "hiliteColor",
                              "outdent",
                              "indent",
                              "align",
                              "horizontalRule",
                              "list",
                              "table",
                            ],
                            [
                              "link",
                              "image",
                              "video",
                              "fullScreen",
                              "showBlocks",
                              "codeView",
                              "preview",
                            ],

                          ],
                          proportion: 'Constrain proportions assasa',
                        }
                      }
                      defaultValue={this.state.record.description}
                      onChange={this.handleChange}
                      appendContents={this.state.appendContents}
                    /> */}

                  </div>


                <ModalFooter className="border-0 py-0">
                  <Button
                    className="btn-sm"
                    type="button"
                    color="secondary"
                    onClick={() => this.props.history.goBack()}
                    disabled={this.state.loader ? false : true}
                  >
                    Cancel
                  </Button>
                  <button
                    className="btn btn-primary waves-effect waves-light btn-sm"
                    type="submit"
                    id="submit-tracker"
                    disabled={this.state.loader ? false : true}
                  >
                    Submit
                  </button>
                </ModalFooter>
              </form>
          </Col>
        </Row>

      </React.Fragment>
    );
  }
}
