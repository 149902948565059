import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  hasRole,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import AvInput from "availity-reactstrap-validation/lib/AvInput";

export default class UsersDatatableTables extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        text: "Sr",
        key: "sr",
        // cell: (record, index) => {
        //     return index + 1;
        // },
      },
      {
        text: "From",
        key: "froms",
      },
      {
        text: "To",
        key: "tos",
      },
      {
        text: "Type",
        key: "type",
      },
      {
        text: "Status",
        key: "status",
      },

      {
        text: "Created Date",
        key: "created_at",
      },
      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          return (
            <Fragment>
              <button
                className="btn btn-link btn-sm"
                onClick={this.viewStatus.bind(this, record, index)}
              >
                View
              </button>
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
      button: {
        excel: false,
        print: true,
        csv: true,
      },
    };
    this.state = {
      records: [],
      roles: [],
      total_pages: 0,
      modal: false,
      record: {},
      filter: {},
      page_name: "Reports",
      customDate: true,
      ClientSubcribeModal: false,
      sentEmailTemplateModal: false,
      modaltitle: '',
      emaildata: {},
      currentClient: {
        id: "",
        email_status: "",
        call_status: "",
        groups: [],
        tags: [],
      },
    };
  }

  componentDidMount() {
    this.getData();
    this.getfilterData();
  }

  viewStatus = (record, index) => {

    if (record.type == 'sms' || record.type == 'email') {
      fetch(`${ApiUrl}` + "get/sub/report/body/" + record.sub_log_id + '/' + record.client_id, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === true) {
            record.body = response.data.body;
            record.subject = response.data.subject;
            this.setState({
              sentEmailTemplateModal: true,
              emaildata: record,
              modaltitle: record.type.charAt(0).toUpperCase() + record.type.slice(1),
            });
          } else {
                      
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      this.setState({
        sentEmailTemplateModal: true,
        emaildata: record,
        modaltitle: record.type.charAt(0).toUpperCase() + record.type.slice(1),
      });
    }
  };

  getData = (queryString = "", data) => {
    fetch(`${ApiUrl}` + "get/aio/reports" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          console.log(response, "test");
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,

          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleFilterSubmit = async (event, values) => {
    this.setState({
      filter: values,
    });
    this.getData("", values);
  };

  cancelFilter = async () => {
    this.form && this.form.reset();
    this.setState({ filter: {}, customDate: true });
    this.getData("", "");
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData("?" + queryString, this.state.filter);
  };

  openClientSubcribeModal = (client, index) => {
    this.setState(
      {
        currentClient: client,
      },
      () =>
        this.setState({
          ClientSubcribeModal: true,
        })
    );
  };

  ClientSubcribeSubmit = async (event, values) => {
    values.id = this.state.currentClient.id;
    values.coach_id = this.state.currentClient.coach_id;
    fetch(`${ApiUrl}` + "client/subscribe/status", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            ClientSubcribeModal: false,
          });
          this.getData();
          this.setState({
            currentClient: {
              id: "",
              email_status: "",
              call_status: "",
            },
          });
         // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });

        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getfilterData = () => {
    fetch(`${ApiUrl}` + "get/group/tag", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ coach_id: uInfo().user_id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            groups: response.data.groups,
            tags: response.data.tags,
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {this.state.page_name}
            Tables
          </title>
        </Helmet>
        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4>{this.state.page_name}</h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#">{this.state.page_name}</Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}></Col>
        </Row>
        <ToastContainer />
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <AvForm
                  onValidSubmit={this.handleFilterSubmit}
                  ref={(c) => (this.form = c)}
                  className="needs-validation"
                >
                  <Row>
                    <Col md="2">
                      <div className="mb-2">
                        <AvField
                          className="select form-select"
                          type="select"
                          name="date_range"
                          label="Date Filter"
                          onChange={(e) =>
                            e.target.value == "Custom Date"
                              ? this.setState({
                                customDate: false,
                              })
                              : this.setState({
                                customDate: true,
                              })
                          }
                        >
                          <option value=""> All Time </option>
                          <option value="Current Week"> Current Week </option>
                          <option value="Last Week"> Last Week </option>
                          <option value="Current Month"> Current Month </option>
                          <option value="Last Month"> Last Month </option>
                          <option value="Last 3 Months"> Last 3 Months </option>
                          <option value="Last 6 Months"> Last 6 Months </option>
                          <option value="Current Year"> Current Year </option>
                          <option value="Last Year"> Last Year </option>
                          <option value="Custom Date"> Custom Date </option>
                        </AvField>
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-2">
                        <AvField
                          name="start_date"
                          label="Start date"
                          className="form-control"
                          placeholder="Enter Email Address"
                          type="date"
                          disabled={this.state.customDate}
                          required={!this.state.customDate}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <AvField
                          name="end_date"
                          label="End date"
                          className="form-control"
                          placeholder="Enter Email Address"
                          type="date"
                          disabled={this.state.customDate}
                          required={!this.state.customDate}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <AvField
                          className="select form-select"
                          type="select"
                          name="type"
                          label="Type"
                        >
                          <option value={""}> Select Type </option>
                          <option value="email"> Email </option>
                          <option value="sms"> SMS </option>
                          <option value="mms"> MMS </option>
                          <option value="rvm"> RVM </option>
                          <option value="call">Call </option>
                          <option value="voice">Voice </option>
                        </AvField>
                      </div>
                    </Col>

                  </Row>
                  <div className="button-items">
                    <Button className="btn-sm" color="primary" type="submit">
                      Filter
                    </Button>
                    <Button
                      onClick={() => this.cancelFilter()}
                      outline
                      color="secondary"
                      className="waves-effect btn-sm"
                      type="button"
                    >
                      Reset
                    </Button>
                  </div>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                {/* <CardTitle className="h4">Default Datatable </CardTitle> */}
                <ReactDatatable
                  config={this.config}
                  records={this.state.records}
                  columns={this.columns}
                  dynamic={true}
                  total_record={this.state.total_pages}
                  onChange={this.tableChangeHandler}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* ////////////////// Email Modal /////////////////////  */}


        <Modal
          isOpen={this.state.sentEmailTemplateModal}
          fade={true}
          size="lg"

        >
          <div className="email">
            <div className="modal-head top-heading">
              <h2> {this.state.modaltitle}</h2>
              <button
                onClick={() => {
                  this.setState({
                    sentEmailTemplateModal: !this.state.sentEmailTemplateModal,
                    emaildata: {}
                  });
                }}
                className="btn btn-link text-white "
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <ModalBody>
              {
                this.state.modaltitle == 'Voice' || this.state.modaltitle == 'Call' || this.state.modaltitle == 'Rvm' ?
                  <audio controls>
                    <source src={this.state.emaildata.body} type="audio/ogg" />
                    <source src={this.state.emaildata.body} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                  :
                  <div
                    className="height-500 custom-scroll"
                    dangerouslySetInnerHTML={{ __html: this.state.emaildata.body }}
                  />

              }
            </ModalBody>
          </div>
        </Modal>
        {/* ////////////////// ClientSubcribeModal //////////////////// */}
      </React.Fragment>
    );
  }
}
