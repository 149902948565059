import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";

import Helmet from "react-helmet";
import "../../assets/css/courses_list.css";
import { ApiUrl, ProductName } from "../../config";
import { uToken, uInfo } from "../../useToken";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/courses_list.css";
import BookmarkedTrainings from "./BookmarkedTrainings.js";

export default function UserBookmarkedTrainings() {
  const [bookmarkedTrainings, setBookmarkedTrainings] = useState([]);
  const [pageName, setPageName] = useState("Bookmarked Trainings");

  const routeHistory = useHistory();

  const is_coach_section = uInfo().type === "Coach" ? "Yes" : "No";

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  var user_types;

  if (is_coach_section === "Yes") {
    user_types = [
      { id: "3", title: "Client" },
      { id: "6", title: "Team Coach" },
    ];
  } else {
    user_types = [
      { id: "1", title: "Coach" },
      { id: "2", title: "Diamond Partner" },
      { id: "3", title: "Client" },
      { id: "4", title: "Team Admin" },
      { id: "6", title: "Team Coach" },
    ];
  }

  const getUserBookmarkedTrainings = (currentPage) => {
    fetch(`${ApiUrl}` + "bookmarked/trainings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        page_number: currentPage,
        page_size: itemsPerPage,
      }),
    })
      .then((response) => response.json())

      .then((response) => {
        if (response.status === true) {
          setBookmarkedTrainings(response.data.records);
          setTotalPages(Math.ceil(response.data.total / itemsPerPage));
        } else {
          setBookmarkedTrainings([]);
          // swal({
          //   title: response.message,
          //   icon: "warning",
          // });
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getUserBookmarkedTrainings();
  }, []);

  useEffect(() => {
    getUserBookmarkedTrainings(currentPage);
  }, [currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const removeTrainingBookmark = (trainingId) => {
    fetch(`${ApiUrl}training/bookmark`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify({
        step_id: trainingId,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.status === true) {
          console.log(response);
          setBookmarkedTrainings((prevTrainings) =>
            prevTrainings.filter((training) => training.step_id !== trainingId)
          );
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <Row>
        <Col lg={12}>
          <div className="pb-0 mb-0 TraningRightContent DashboardCard TraningCoachDash allBookmarkedTrainings">
            {/* <Card className="bookmarksCard">
              <CardBody> */}
                <Row>
                  {bookmarkedTrainings.length > 0 ? (
                    <BookmarkedTrainings
                      trainings={bookmarkedTrainings}
                      onRemoveBookmark={removeTrainingBookmark}
                    />
                  ) : (
                    <h5 className="text-center">
                      You have not bookmarked any Training yet
                    </h5>
                  )}
                </Row>
              {/* </CardBody>
            </Card> */}
            {bookmarkedTrainings.length > 0 && (
              <Pagination className="float-right mt-2">
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink onClick={() => handlePageChange(1)}>
                    First
                  </PaginationLink>
                </PaginationItem>

                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Previous
                  </PaginationLink>
                </PaginationItem>

                <PaginationItem>
                  <PaginationLink>{currentPage}</PaginationLink>
                </PaginationItem>

                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </PaginationLink>
                </PaginationItem>

                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink onClick={() => handlePageChange(totalPages)}>
                    Last
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
}
