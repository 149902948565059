import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    bcrypt,
    getPages,
    getPermission,
    getRoles,
    isAllowed,
    uInfo,
    uRole,
    uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import ErrorBoundary from "../../../components/Common/ErrorBoundary";
import swal from 'sweetalert';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CategoryLists from "../../Waybook/CategoryLists";
import classNames from "classnames";
export default class TrainingCategory extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                text: "SR#",
                key: "sr",
                sortable: false,
            },
            {
                text: "Category",
                key: "cat_key",
                sortable: false,
            },
            {
                text: "Impact Question",
                key: "backend_calculation"
            },
            {
                text: 'Question',
                key: 'question',
                cell: (record, index) => {
                    return record.question.slice(0, 100) + (record.question.length > 100 ? "..." : "");
                }
            },
            // {
            //     text: "Created At",
            //     key: "created_at",
            //     sortable: false,
            // },
            {
                className: "p-0",
                key: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            {isAllowed(uInfo(), ["can_edit"]) && (
                                <>
                                    <button
                                        onClick={() =>
                                            this.setState({
                                                modal: true,
                                                record: record
                                            })
                                        }
                                        className="btn btn-primary btn-sm text-transform-capitalize m-auto my-1"
                                    >
                                        <i className="mdi mdi-pencil"></i>
                                    </button>
                                </>
                            )}
                        </Fragment>

                    );
                },
            },
        ];
        this.config = {
            key_column: "sr",
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: false,
                print: true,
                csv: true,
            },
        };
        this.state = {
            records: [],
            roles: [],
            total_pages: 0,
            modal: false,
            record: {
                type: 'text'
            },
            filter: {},
            page_name: " Impact Strategy & Assessment Questions",
            customDate: true,
            type_key: "",
            currentTab: "list",
            impact_strategy: {
                id: 0,
                meta_value: [],
            },
            modal2: false,
            strategy: {},
            trackerCategories: {}
        };
    }

    componentDidMount() {
        this.getData();
    }

    dropdownOptionsWithOptionGroups = (options) => {
        const groupedOptions = options.reduce((acc, option) => {
            const accOptionGroup = acc[option.optionGroup] || [];
            return {
                ...acc,
                [option.optionGroup]: [...accOptionGroup, option]
            }
        }, {});
        return groupedOptions;
    }
    getData = (queryString = "", data) => {
        fetch(`${ApiUrl}` + "get/assessment/questions" + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {


                    //console.log(groupedOptions)
                    this.setState({
                        total_pages: response.data.total,
                        records: response.data.records,
                        roles: response.data.roles,
                        impact_strategy: response.data.impact_strategy,
                        trackerCategories: this.dropdownOptionsWithOptionGroups(response.data.trackerCategories)
                    });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    handleFilterSubmit = async (event, values) => {
        this.setState({
            filter: values,
        });
        this.getData("", values);
    };

    cancelFilter = async () => {
        this.form && this.form.reset();
        this.setState({ filter: {}, customDate: true });
        this.getData("", "");
    };

    tableChangeHandler = (data) => {
        let queryString = Object.keys(data)
            .map((key) => {
                if (key === "sort_order" && data[key]) {
                    return (
                        encodeURIComponent("sort_order") +
                        "=" +
                        encodeURIComponent(data[key].order) +
                        "&" +
                        encodeURIComponent("sort_column") +
                        "=" +
                        encodeURIComponent(data[key].column)
                    );
                } else {
                    return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
                }
            })
            .join("&");
        this.getData("?" + queryString, this.state.filter);
    };

    handleSubmit = async (event, values) => {
        fetch(`${ApiUrl}` + "update/assessment/question", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {
                    this.setState({
                        modal: false,
                        record: {},
                    });
                    this.getData("", this.state.filter);
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    handleSubmitStrategyDescription = async (event, values) => {
        fetch(`${ApiUrl}` + "update/strategty/description", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {
                    this.setState({
                        modal2: false,
                        strategy: {},
                    });
                    this.getData("", "");
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    render() {
        return (
            <ErrorBoundary>
                <Helmet>
                    <title>
                        {this.state.page_name}
                        Tables
                    </title>
                </Helmet>

                <Row>
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4>{this.state.page_name}</h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#">{this.state.page_name}</Link>
                                </li>
                            </ol>
                        </div>
                    </Col>
                </Row>

                <ToastContainer />

                <Tabs
                    defaultActiveKey="list"
                    className="mb-3"
                    onSelect={(e) => this.setState({ currentTab: e })}
                >
                    <Tab eventKey="list" title="Questions">
                        <Row>
                            <Col xl="10">
                                <Card>
                                    <CardBody className="py-0">
                                        <AvForm
                                            onValidSubmit={this.handleFilterSubmit}
                                            ref={(c) => (this.form = c)}
                                            className="needs-validation"
                                        >
                                            <Row>
                                                <Col md="3">
                                                    <div className="mb-0">
                                                        <AvField
                                                            className="select form-select"
                                                            type="select"
                                                            name="cat_key"
                                                            label="Impact Strategies"
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    type_key: e.target.value,
                                                                })
                                                            }
                                                        >
                                                            <option value=""> All </option>
                                                            {
                                                                Object.keys(this.state.trackerCategories).map((optionGroupName) => (
                                                                    <optgroup key={optionGroupName} label={optionGroupName}>
                                                                        {
                                                                            this.state.trackerCategories[optionGroupName].map(({ cat_key, cat_name }) => (
                                                                                <option key={cat_key} value={cat_key}>{cat_name}</option>
                                                                            ))
                                                                        }
                                                                    </optgroup>
                                                                ))
                                                            }
                                                        </AvField>
                                                    </div>
                                                </Col>
                                                <Col md="2">
                                                    <label className="">Action</label>
                                                    <div className="mb-0">
                                                        <div className="button-items">
                                                            <Button
                                                                className="btn-sm"
                                                                color="primary"
                                                                type="submit"
                                                            >
                                                                Filter
                                                            </Button>
                                                            <Button
                                                                onClick={() => this.cancelFilter()}
                                                                outline
                                                                color="secondary"
                                                                className="waves-effect btn-sm"
                                                                type="button"
                                                            >
                                                                Reset
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={2} md={2}>
                                <div className="page-title-box text-align-right">
                                    {isAllowed(uInfo(), ["can_create"]) && (
                                        <button
                                            onClick={() =>
                                                this.setState({
                                                    modal: true,
                                                    record: {
                                                        type: 'text'
                                                    }
                                                })
                                            }
                                            className="btn btn-info btn-sm"
                                        >
                                            <i className="mdi mdi-plus"> </i> Create
                                        </button>
                                    )}
                                </div>
                            </Col>
                        </Row>

                        {
                            this.state.type_key != '' && (
                                <Link to={`/assessment/questions/view/${this.state.type_key}`} className={'btn btn-info ml-3 mb-2'}>Click here to reorder Question</Link>
                            )
                        }
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody className="pt-0">
                                        {/* <CardTitle className="h4">Default Datatable </CardTitle> */}
                                        <ReactDatatable
                                            id={'assessment_question_datatable'}
                                            key={this.state.id}
                                            config={this.config}
                                            records={this.state.records}
                                            columns={this.columns}
                                            dynamic={true}
                                            total_record={this.state.total_pages}
                                            onChange={this.tableChangeHandler}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Modal
                            isOpen={this.state.modal}
                            role="dialog"
                            centered={true}
                            className="exampleModal"
                            tabIndex="-1"
                            toggle={() => {
                                this.setState({
                                    modal: !this.state.modal,
                                    record: {}
                                });
                            }}
                        >
                            <div className="modal-content">
                                <ModalHeader
                                    toggle={() => {
                                        this.setState({
                                            modal: !this.state.modal,
                                            record: {}
                                        });
                                    }}
                                    className="text-transform-capitalize"
                                >
                                    {this.state.record.cat_key ? `Update question (${this.state.record.cat_key})` : 'Add Question'}
                                </ModalHeader>
                                <AvForm
                                    onValidSubmit={this.handleSubmit}
                                    className="needs-validation"
                                >
                                    <ModalBody className="pb-0">
                                        {
                                            !this.state.record.question_id && (
                                                <div className="mb-3">
                                                    <AvField
                                                        className="select form-select"
                                                        type="select"
                                                        name="cat_key"
                                                        required
                                                    >
                                                        <option value=""> All </option>
                                                        {
                                                            Object.keys(this.state.trackerCategories).map((optionGroupName) => (
                                                                <optgroup key={optionGroupName} label={optionGroupName}>
                                                                    {
                                                                        this.state.trackerCategories[optionGroupName].map(({ cat_key, cat_name }) => (
                                                                            <option key={cat_key} value={cat_key}>{cat_name}</option>
                                                                        ))
                                                                    }
                                                                </optgroup>
                                                            ))
                                                        }
                                                    </AvField>
                                                </div>
                                            )
                                        }
                                        <div className="mb-3">
                                            <AvField
                                                name={'question'}
                                                value={this.state.record.question}
                                                className="form-control"
                                                placeholder={`Question must start with Q.`}
                                                type={'textarea'}
                                                required
                                                rows={5}
                                            />
                                        </div>


                                        <div className="mb-3">
                                            <AvField
                                                name={'type'}
                                                value={this.state.record.type}
                                                className="form-select"
                                                type={'select'}
                                                required
                                                onChange={(e) => {
                                                    this.setState(prevState => {
                                                        let record = Object.assign({}, prevState.record);
                                                        record.type = e.target.value;
                                                        return { record };
                                                    });
                                                }}
                                            >
                                                <option value=""> Select Question Type </option>
                                                <option value="text">Normal</option>
                                                <option value="select">Conditional </option>
                                            </AvField>
                                        </div>


                                        {
                                            this.state.record.type == 'select' && (
                                                <>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name={'options'}
                                                            value={this.state.record.options}
                                                            className="form-control "
                                                            placeholder={`Add comma seperated string e.g one,two `}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="mb-3">

                                                        <AvField
                                                            name={'option_value'}
                                                            value={this.state.record.option_value}
                                                            className="form-control"
                                                            placeholder={`Add value must be include in options`}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name={'additional_question'}
                                                            value={this.state.record.additional_question}
                                                            className="form-control"
                                                            placeholder={`Additional question must start with Q.`}
                                                            type={'textarea'}
                                                            required
                                                            rows={5}
                                                        />

                                                    </div>
                                                </>
                                            )
                                        }

                                        {
                                            this.state.record.additional_question_id && (
                                                <AvField type={'hidden'} name="additional_question_id" required value={this.state.record.additional_question_id} />
                                            )
                                        }
                                        {
                                            this.state.record.question_id && (
                                                <AvField type={'hidden'} name="question_id" required value={this.state.record.question_id} />
                                            )
                                        }
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button
                                            className="btn-sm"
                                            type="button"
                                            color="secondary"
                                            onClick={() => {
                                                this.setState({
                                                    modal: !this.state.modal,
                                                    record: {}
                                                });
                                            }}
                                        >
                                            Close
                                        </Button>
                                        <button
                                            className="btn btn-primary waves-effect waves-light btn-sm"
                                            type="submit"
                                        >
                                            Save
                                        </button>
                                    </ModalFooter>
                                </AvForm>
                            </div>
                        </Modal>
                    </Tab>
                    <Tab eventKey="impact" title=" Impact Strategy">
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <table className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th width={250} className="text-left">Strategy Name</th>
                                                    <th className="text-left">Description</th>
                                                    <th width={100} className="text-left">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.impact_strategy.meta_value.map((row, i) => {
                                                        return <tr>
                                                            <td className="text-left">{row.label}</td>
                                                            <td className="text-left">{row.description}</td>
                                                            <td>
                                                                <button
                                                                    className="btn btn-link btn-sm"
                                                                    onClick={() =>
                                                                        this.setState({
                                                                            modal2: true,
                                                                            strategy: row
                                                                        })
                                                                    }
                                                                >
                                                                    <i className="fas fa-edit"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Modal
                                isOpen={this.state.modal2}
                                role="dialog"
                                centered={true}
                                className="Impact Strategy"
                                tabIndex="-1"
                                toggle={() => {
                                    this.setState({
                                        modal2: false,
                                        strategy: {}
                                    });
                                }}
                            >
                                <div className="modal-content">
                                    <ModalHeader
                                        toggle={() => {
                                            this.setState({
                                                modal2: false,
                                                strategy: {}
                                            });
                                        }}
                                        className="text-transform-capitalize py-2"
                                    >
                                        Update Description ({this.state.strategy.label})
                                    </ModalHeader>
                                    <AvForm
                                        onValidSubmit={this.handleSubmitStrategyDescription}
                                        className="needs-validation"
                                    >
                                        <ModalBody>
                                            <div className="mb-0">
                                                <AvField
                                                    name={'description'}
                                                    value={this.state.strategy.description}
                                                    className="form-control"
                                                    placeholder={`Impact Strategy description.`}
                                                    type={'textarea'}
                                                    required
                                                    rows={5}
                                                />
                                            </div>
                                            <AvField type={'hidden'} name="key" required value={this.state.strategy.key} />
                                            <AvField type={'hidden'} name="id" required value={this.state.impact_strategy.id} />
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button
                                                className="btn-sm"
                                                type="button"
                                                color="secondary"
                                                onClick={() => {
                                                    this.setState({
                                                        modal2: false,
                                                        strategy: {}
                                                    });
                                                }}
                                            >
                                                Close
                                            </Button>
                                            <button
                                                className="btn btn-primary waves-effect waves-light btn-sm"
                                                type="submit"
                                            >
                                                Save
                                            </button>
                                        </ModalFooter>
                                    </AvForm>
                                </div>
                            </Modal>
                        </Row>
                    </Tab>
                </Tabs>


            </ErrorBoundary >
        );
    }
}
