import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    Button,
    ModalBody,
    ModalFooter,
    CardBody,
} from "reactstrap";

import {
    dcrypt,
    bcrypt,
    uToken,
    isValidJSONString,
} from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import CampaignNav from "./nav";

import $ from "jquery"
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { AvField, AvForm } from "availity-reactstrap-validation";
import swal from 'sweetalert';
import countries from "../../common/data/countries";

export default class CampaignInbound extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id
                ? dcrypt(this.props.match.params.id)
                : null,
            page_name: "Campaign Inbound",
            from: this.props.from ? this.props.from : "",
            record: {},
            defaultFields: [{ "label": "Start", "flowId": "" }],
            customFields: [],
            AfterFields: [],
            metaData: {
                callerGroup: [],
                smsTemplates: [],
                voiceTemplates: [],
                ivrFlows: []
            },
            defaultFlow: 0,
            showAfter: false,
            editId: '',
            showSlider: false,
            ivrMenus: [],
            loader: true
        };
    }


    addClick() {
        this.setState(prevState => ({
            customFields: [...prevState.customFields, { [`label`]: "Start", flowId: "", [`key`]: "Start" }]
        }))
    }

    addClickAfter() {
        this.setState(prevState => ({
            AfterFields: [...prevState.AfterFields, { [`label`]: "Start", flowId: "", [`key`]: "Start" }]
        }))
    }


    createUI() {
        return this.state.customFields.map((el, i) => (
            <div className={`border py-3 px-2 ${el.key ? el.key : 'd-none'}`} key={i} id={`element_${i}`}>
                <div className="row">
                    <div className="col-md-1 mb-3">
                        <div className="form-group">
                            <input placeholder="Enter label" name={`business_hourarr[${i}][key]`} required="" id="label" type="text"
                                className="form-control" value={`${i == 0 ? 'Start' : 'Next'}`} readOnly onChange={this.handleChange.bind(this, i)} />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <select className="select form-select"
                                type="select"
                                // name={`flowId`}
                                name={`business_hourarr[${i}][flowId]`}
                                onChange={this.handleChange.bind(this, i)}
                                defaultValue={this.state.customFields[i].flowId || ''}
                                required
                            // value={this.state.customFields[i].flowId}
                            // onChangeCapture={this.handleChange.bind(this, i)}
                            >
                                <option value="">Select Call Flow</option>
                                <optgroup label="IVR Options">
                                    <option value="Forward">Forward To</option>
                                    <option value="Voicemail">Voicemail</option>
                                    <option value="Greeting">Greeting</option>
                                    <option value="SMS">SMS</option>
                                    <option value="Connect_To_Agent">Connect To Agent</option>
                                    <option value="Unsubscribe">Unsubscribe</option>
                                    <option value="Hangup">Hangup/End Call</option>
                                </optgroup>
                                <optgroup label="My Call Flows">
                                    {
                                        this.state.metaData.ivrFlows.map((row, i) => {
                                            return <option key={'ivr_v-' + i} value={row.ivr_id}>{row.ivr_type} - {row.ivr_title}</option>
                                        })
                                    }
                                </optgroup>
                            </select>
                        </div>
                    </div>

                    {this.addContent(i, this.state.customFields[i].flowId)}

                    {
                        i != 0 && (
                            <div className="col-md-1" >
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={this.removeClick.bind(this, i)}>
                                    <i className="ion ion-md-trash"> </i>
                                </button>
                            </div>
                        )
                    }

                </div>
            </div>
        ))
    }

    createUIAfter() {
        return this.state.AfterFields.map((el, i) => (
            <div className={`border py-3 px-2 ${el.key ? el.key : 'd-none'}`} key={i} id={`element_after_${i}`}>
                <div className="row">
                    <div className="col-md-1 mb-3">
                        <div className="form-group">
                            <input placeholder="Enter label" name={`business_after_hourarr[${i}][key]`} required="" id="label" type="text"
                                className="form-control" value={`${i == 0 ? 'Start' : 'Next'}`} readOnly onChange={this.handleChangeAfter.bind(this, i)} />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <select className="select form-select"
                                type="select"
                                // name={`flowId`}
                                name={`business_after_hourarr[${i}][flowId]`}
                                onChange={this.handleChangeAfter.bind(this, i)}
                                defaultValue={this.state.AfterFields[i].flowId || ''}
                                required
                            // value={this.state.AfterFields[i].flowId}
                            // onChangeCapture={this.handleChange.bind(this, i)}
                            >
                                <option value="">Select Call Flow</option>
                                <optgroup label="IVR Options">
                                    <option value="Forward">Forward To</option>
                                    <option value="Voicemail">Voicemail</option>
                                    <option value="Greeting">Greeting</option>
                                    <option value="SMS">SMS</option>
                                    <option value="Connect_To_Agent">Connect To Agent</option>
                                    <option value="Unsubscribe">Unsubscribe</option>
                                    <option value="Hangup">Hangup/End Call</option>
                                </optgroup>
                                <optgroup label="My Call Flows">
                                    {
                                        this.state.metaData.ivrFlows.map((row, i) => {
                                            return <option key={'ivr_v-' + i} value={row.ivr_id}>{row.ivr_type} - {row.ivr_title}</option>
                                        })
                                    }

                                </optgroup>
                            </select>
                        </div>
                    </div>

                    {this.addContentAfter(i, this.state.AfterFields[i].flowId)}

                    {
                        i != 0 && (
                            <div className="col-md-1" >
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={this.removeClickAfter.bind(this, i)}>
                                    <i className="ion ion-md-trash"> </i>
                                </button>
                            </div>
                        )
                    }

                </div>
            </div>
        ))
    }

    handleChange(i, e) {
        const { name, value } = e.target;
        let customFields = [...this.state.customFields];
        customFields[i] = { ...customFields[i], [name]: value };
        customFields[i] = { ...customFields[i], [`flowId`]: value };
        this.setState({ customFields });
    }

    handleChangeAfter(i, e) {
        const { name, value } = e.target;
        let AfterFields = [...this.state.AfterFields];
        AfterFields[i] = { ...AfterFields[i], [name]: value };
        AfterFields[i] = { ...AfterFields[i], [`flowId`]: value };
        this.setState({ AfterFields });
    }

    addContent = (i, type) => {
        if (type == 'Forward') {
            return (
                <>
                    <div className="col-md-1"  >
                        <select defaultValue={this.state.customFields[i].country_code}
                            name={`business_hourarr[${i}][country_code]`} className="form-select">
                            {countries.map((row, i) => {
                                return (
                                    <option value={`${row.code}`}>
                                        {row.code}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-md-2">
                        <input defaultValue={this.state.customFields[i].dial} type="text" className="form-control" name={`business_hourarr[${i}][dial]`} maxLength="10" />
                    </div>
                    <div className="col-md-2" >
                        <div className="select2-container multiselect1 select2-container-active">
                            <select defaultValue={this.state.customFields[i].caller_id} name={`business_hourarr[${i}][caller_id]`} className="form-select">
                                <option value="default">Caller ID of the caller</option>
                                <option value="any">Caller ID of the campaign</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-2" >
                        <div className="input-group">
                            <input defaultValue={this.state.customFields[i].call_time_out} name={`business_hourarr[${i}][call_time_out]`}
                                className="form-control" placeholder="Sec(s)" type="text" pattern="\d*" maxLength="2" max="60" />
                        </div>
                    </div>
                </>
            )
        } else if (type == 'Voicemail') {
            return (
                <>
                    <div className="col-md-3" >
                        <select defaultValue={this.state.customFields[i].voicemail} name={`business_hourarr[${i}][voicemail]`} className="form-select">
                            <option value="">Select Voicemail</option>
                            {
                                this.state.metaData.voiceTemplates.map((row, i) => {
                                    return <option key={'voice_' + i} value={row.id}>{row.name}</option>
                                })
                            }

                        </select>
                    </div>
                </>
            )
        } else if (type == 'Greeting') {
            return (
                <>
                    <div className="col-md-3" >
                        <select defaultValue={this.state.customFields[i].greeting} name={`business_hourarr[${i}][greeting]`} className="form-select">
                            <option value="">Select Greeting</option>
                            {
                                this.state.metaData.voiceTemplates.map((row, i) => {
                                    return <option key={'greeting_' + i} value={row.id}>{row.name}</option>
                                })
                            }

                        </select>
                    </div>
                </>
            )
        } else if (type == 'SMS') {
            return (
                <>
                    <div className="col-md-3" >
                        <select defaultValue={this.state.customFields[i].sms} name={`business_hourarr[${i}][sms]`} className="form-select">
                            <option value="">Select SMS</option>
                            {
                                this.state.metaData.smsTemplates.map((row, i) => {
                                    return <option key={'sms_' + i} value={row.id}>{row.name}</option>
                                })
                            }

                        </select>
                    </div>
                </>
            )
        } else if (type == 'Connect_To_Agent') {
            return (
                <>
                    <div className="col-md-3" >
                        <select defaultValue={this.state.customFields[i].callergroupId} name={`business_hourarr[${i}][callergroupId]`} className="form-select">
                            <option value="">Select Agent</option>
                            {
                                this.state.metaData.callerGroup.map((row, i) => {
                                    return <option key={'agent' + i} value={row.id}>{row.name}</option>
                                })
                            }

                        </select>
                    </div>

                    <div className="col-md-2" >
                        <div className="select2-container multiselect1 select2-container-active">
                            <select defaultValue={this.state.customFields[i].caller_type} name={`business_hourarr[${i}][caller_type]`} className="form-select">
                                <option value="simultaneously">Simultaneously</option>
                                <option value="random">Random</option>
                                <option value="sequential">Sequential</option>

                            </select>
                        </div>
                    </div>
                    <div className="col-md-2" >
                        <div className="input-group">
                            <input defaultValue={this.state.customFields[i].caller_time_out} name={`business_hourarr[${i}][caller_time_out]`}
                                className="form-control" placeholder="Sec(s)" type="text" pattern="\d*" maxLength="2" max="60" />
                        </div>
                    </div>

                </>
            )
        }
    }

    addContentAfter = (i, type) => {
        if (type == 'Forward') {
            return (
                <>
                    <div className="col-md-1"  >
                        <select defaultValue={this.state.AfterFields[i].country_code}
                            name={`business_after_hourarr[${i}][country_code]`} className="form-select">
                            {countries.map((row, i) => {
                                return (
                                    <option value={`${row.code}`}>
                                        {row.code}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-md-2">
                        <input defaultValue={this.state.AfterFields[i].dial} type="text" className="form-control" name={`business_after_hourarr[${i}][dial]`} maxLength="10" />
                    </div>
                    <div className="col-md-2" >
                        <div className="select2-container multiselect1 select2-container-active">
                            <select defaultValue={this.state.AfterFields[i].caller_id} name={`business_after_hourarr[${i}][caller_id]`} className="form-select">
                                <option value="default">Caller ID of the caller</option>
                                <option value="any">Caller ID of the campaign</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-2" >
                        <div className="input-group">
                            <input defaultValue={this.state.AfterFields[i].call_time_out} name={`business_after_hourarr[${i}][call_time_out]`}
                                className="form-control" placeholder="Sec(s)" type="text" pattern="\d*" maxLength="2" max="60" />
                        </div>
                    </div>
                </>
            )
        } else if (type == 'Voicemail') {
            return (
                <>
                    <div className="col-md-3" >
                        <select defaultValue={this.state.AfterFields[i].voicemail} name={`business_after_hourarr[${i}][voicemail]`} className="form-select">
                            <option value="">Select Voicemail</option>
                            {
                                this.state.metaData.voiceTemplates.map((row, i) => {
                                    return <option key={'voice_' + i} value={row.id}>{row.name}</option>
                                })
                            }

                        </select>
                    </div>
                </>
            )
        } else if (type == 'Greeting') {
            return (
                <>
                    <div className="col-md-3" >
                        <select defaultValue={this.state.AfterFields[i].greeting} name={`business_after_hourarr[${i}][greeting]`} className="form-select">
                            <option value="">Select Greeting</option>
                            {
                                this.state.metaData.voiceTemplates.map((row, i) => {
                                    return <option key={'greeting_' + i} value={row.id}>{row.name}</option>
                                })
                            }

                        </select>
                    </div>
                </>
            )
        } else if (type == 'SMS') {
            return (
                <>
                    <div className="col-md-3" >
                        <select defaultValue={this.state.AfterFields[i].sms} name={`business_after_hourarr[${i}][sms]`} className="form-select">
                            <option value="">Select SMS</option>
                            {
                                this.state.metaData.smsTemplates.map((row, i) => {
                                    return <option key={'sms_' + i} value={row.id}>{row.name}</option>
                                })
                            }

                        </select>
                    </div>
                </>
            )
        } else if (type == 'Connect_To_Agent') {
            return (
                <>
                    <div className="col-md-3" >
                        <select defaultValue={this.state.AfterFields[i].callergroupId} name={`business_after_hourarr[${i}][callergroupId]`} className="form-select">
                            <option value="">Select Agent</option>
                            {
                                this.state.metaData.callerGroup.map((row, i) => {
                                    return <option key={'agent' + i} value={row.id}>{row.name}</option>
                                })
                            }

                        </select>
                    </div>

                    <div className="col-md-2" >
                        <div className="select2-container multiselect1 select2-container-active">
                            <select defaultValue={this.state.AfterFields[i].caller_type} name={`business_after_hourarr[${i}][caller_type]`} className="form-select">
                                <option value="simultaneously">Simultaneously</option>
                                <option value="random">Random</option>
                                <option value="sequential">Sequential</option>

                            </select>
                        </div>
                    </div>
                    <div className="col-md-2" >
                        <div className="input-group">
                            <input defaultValue={this.state.AfterFields[i].caller_time_out} name={`business_after_hourarr[${i}][caller_time_out]`}
                                className="form-control" placeholder="Sec(s)" type="text" pattern="\d*" maxLength="2" max="60" />
                        </div>
                    </div>
                </>
            )
        }
    }



    removeClick(i) {
        let customFields = [...this.state.customFields];
        customFields[i] = {};
        $('#element_' + i).html('');
        this.setState({ customFields });
    }

    removeClickAfter(i) {
        let AfterFields = [...this.state.AfterFields];
        AfterFields[i] = {};
        $('#element_after_' + i).html('');
        this.setState({ AfterFields });
    }

    componentDidMount() {
        this.getFormData();
        this.getIvrMenu();
        // this.state.id != null && this.getData();
    }


    getData = () => {
        fetch(`${ApiUrl}` + "get/edit/campaign", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },

            body: JSON.stringify({
                id: this.state.id,
                coach_id: this.state.coach_id,
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    let datam = isValidJSONString(response.data.record.business_hour);
                    let hour = datam[this.state.defaultFlow] || [];

                    let datamNonHour = isValidJSONString(response.data.record.nonbusinesshour_flow);
                    let nonHour = datamNonHour[this.state.defaultFlow] || []
                    if (hour.length > 0) {
                        this.setState({
                            customFields: hour,
                        });
                    } else {
                        this.addClick();
                    }

                    if (nonHour.length > 0) {
                        this.setState({
                            AfterFields: nonHour
                        });
                    } else {
                        this.addClickAfter();
                    }

                    this.setState({
                        record: response.data.record,
                        showAfter: response.data.record.afterhour_callflow == 'Yes' ? true : false
                    });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);


        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        fetch(`${ApiUrl}` + "add/campaign/inbound", {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data, "Editing");
                if (data.status === true) {
                    if (this.state.id == null) {
                        this.form && this.form.reset();
                    }
                    swal({
                        text: 'Action performed successfully',
                        icon: "success",
                    });
                    //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    getFormData = () => {
        fetch(`${ApiUrl}` + "campaign/inbound/metadata", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({
                id: this.state.id,
            }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        metaData: response.data,
                        defaultFlow: response.data.defaultFlow,
                        loader: false
                    });
                    this.getData();
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    getIvrMenu = () => {
        fetch(`${ApiUrl}` + "get/ivr/menu", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({
                campaign_id: this.state.id,
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        ivrMenus: response.data.records,
                    });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    cancelSlider = async () => {
        this.setState({
            page2Class: 'slide-out2',
            editId: ''
            // showSlider: false,
        })
    };

    handleAvrSubmit = async (event, values) => {
        values.campaign_id = this.state.id;
        fetch(`${ApiUrl}` + "add/ivr/menu", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    this.form && this.form.reset();
                    this.cancelSlider();
                    this.getIvrMenu();
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }



    render() {
        return (
            <React.Fragment>

                <Row>
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4> {this.state.page_name} </h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#"> {this.state.page_name} </Link>
                                </li>
                            </ol>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className="page-title-box text-align-right">
                            <Button
                                className="btn-sm"
                                type="button"
                                color="secondary"
                                onClick={() => this.props.history.push('/campaign/list')}
                            >
                                Back
                            </Button>
                        </div>
                    </Col>
                </Row>
                <ToastContainer />
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CampaignNav activeTab={'2'} campaignId={bcrypt(this.state.id)} />

                            <Tabs defaultActiveKey="inboundFlow" id="uncontrolled-tab-example" className="mb-3">
                                <Tab eventKey="inboundFlow" title=" Inbound Flow">
                                    <form encType="multipart/form-data" className="form-horizontal" method="POST" onSubmit={this.handleSubmit}>
                                        <input name="id" value={this.state.id} type="hidden" />
                                        <input name="default_flow" value={this.state.defaultFlow} type="hidden" />
                                        <Card>
                                            <CardBody>
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <div className="business-inbound-title">
                                                            Campaign Hours Flow: {' '}
                                                            <button
                                                                type="button"
                                                                className="btn btn-link btn-sm"
                                                                onClick={this.addClick.bind(this)}>
                                                                <i className="fas fa-plus" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-10">
                                                        {this.createUI()}
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6 offset-2">

                                                        <div className="form-group">
                                                            <input className="custom-checkbox" name="afterhour_callflow" onChange={(e) => {
                                                                this.setState({
                                                                    showAfter: e.target.checked
                                                                })
                                                            }} type={'checkbox'} checked={this.state.showAfter ? true : false} />
                                                            <label className="vertical-align-middle">After Hour Call Flow</label>
                                                        </div>
                                                    </div>
                                                </div>


                                                {(this.state.showAfter) && (
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            <div className="business-inbound-title">Non-Campaign Hours Flow:{''}
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-link btn-sm"
                                                                    onClick={this.addClickAfter.bind(this)}>
                                                                    <i className="fas fa-plus" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-10">
                                                            {this.createUIAfter()}
                                                        </div>
                                                    </div>
                                                )}


                                                <div className="row">
                                                    <div className="col-md-6 offset-2">
                                                        <button
                                                            type="submit"
                                                            disabled={this.state.loader}
                                                            className="btn btn-info mt-4 btn-sm"
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>





                                            </CardBody>
                                        </Card>
                                    </form>
                                </Tab>
                                <Tab eventKey="menus" title="Manage IVR Menus">
                                    <CardBody className="pt-0">
                                        <button onClick={() => { this.setState({ showSlider: true, page2Class: 'slide-in' }) }}
                                            className="btn btn-info btn-sm pull-right mb-3">Build New IVR Menu</button>
                                        

                                        <div className="table-responive">
                                            <table className="table table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Title</th>
                                                        <th>Type</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.ivrMenus.map((row, i) => {
                                                            return <tr key={'ivr' + i}>
                                                                <td>{i + 1}</td>
                                                                <td>{row.ivr_title}</td>
                                                                <td>{row.ivr_type}</td>
                                                                <td><Link to={`/campaign/ivr/update/${bcrypt(this.state.id)}/${bcrypt(row.ivr_id)}`}><i className="fas fa-edit"></i></Link></td>

                                                            </tr>
                                                        })
                                                    }

                                                </tbody>

                                            </table>
                                        </div>
                                    </CardBody>
                                </Tab>
                                {/* <Tab eventKey="services" title="Incoming services">
                                </Tab> */}
                                <Tab eventKey="groups" title={<Link to={`/callergroup/list?c_in=${bcrypt(this.state.id)}`}>Caller Groups </Link>}>
                                </Tab>
                                {/* <Tab eventKey="library" title={<Link to={`/emailtemplates/list?c_in=${bcrypt(this.state.id)}`}> Templates Library </Link>}>
                                </Tab> */}
                            </Tabs>

                        </Card>
                    </Col>
                </Row >
                {
                                            this.state.showSlider && this.state.editId == '' && (
                                                <div className={`page3 ${this.state.page2Class} custom-scroll IVRPopup`} >
                                                    <div className="">
                                                        <div className="modal-head top-heading">
                                                            <h2>Build New IVR</h2>
                                                            <button onClick={() => { this.setState({ page2Class: 'slide-out2', editId: '' }); }} className="btn btn-link text-white">
                                                                <i className="fas fa-times"></i>
                                                            </button>
                                                        </div>
                                                        <div className="card-body">
                                                            <AvForm onValidSubmit={this.handleAvrSubmit}
                                                                // model={this.state.record}
                                                                ref={(c) => (this.form = c)}
                                                                className="needs-validation">
                                                                <div className="col-md-12 mb-3">
                                                                    <AvField
                                                                        name="ivr_title"
                                                                        label="Title"
                                                                        className="form-control"
                                                                        required
                                                                    />
                                                                </div>

                                                                <button
                                                                    className="btn btn-primary waves-effect waves-light btn-sm"
                                                                    type="submit"
                                                                >
                                                                    Submit
                                                                </button>
                                                            </AvForm>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
            </React.Fragment >
        );
    }
}
