import React, { Component } from "react"
import { Row, Col, Button, ModalBody, ModalFooter } from "reactstrap"
import { uToken, dcrypt } from "../../useToken";
import { ApiUrl, ProductName, AttachementsUrl } from "../../config";
import { toast } from "react-toastify";
import ProfileSettings from "../Authentication/profile-sidebar";
import Loader from "react-loader";
import { Link } from "react-router-dom";
import Froala from "../Froala";
import swal from "sweetalert";
import Select from "react-select"

import 'suneditor/dist/css/suneditor.min.css';
export default class EditCmsBlogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id ? dcrypt(this.props.match.params.id) : '',
            records: [],
            categories: [],
            tags: [],
            tag_id: [],
            selectedTags: [],
            loader: true,
            page_name: 'Blog listings',
            row: {
                title: '',
                category_id: '',
                image: 0
            }
        }
    }


    componentDidMount() {
        this.setState({ isMounted: true });
        if (this.state.id) {
            this.getData();
        }
        this.fetchAllCategories();
        this.fetchAllTags();
    }

    componentWillUnmount() {
        this.setState({ isMounted: false });
    }

    fetchAllCategories = () => {
        fetch(`${ApiUrl}get/cms/all/categories`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${uToken()}`,
            },
            body: JSON.stringify({ search: '' }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        categories: response.data.categories
                    });
                } else {
                    toast.error(response.message, { hideProgressBar: true, position: "top-center", transition: "flip", className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    fetchAllTags = () => {
        fetch(`${ApiUrl}get/cms/all/tags`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${uToken()}`,
            },
            body: JSON.stringify({ search: '' }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        tags: response.data.tags
                    });
                } else {
                    toast.error(response.message, { hideProgressBar: true, position: "top-center", transition: "flip", className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    getData = () => {
        fetch(`${ApiUrl}get/edit/post`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${uToken()}`
            },
            body: JSON.stringify({ id: this.state.id }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    const tags = response.data.records.tags;
                    this.setState({
                        records: response.data.records,
                        selectedTags: tags,
                        tags: tags,
                    });
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }



    handleTagChange = (selectedTags) => {
        this.setState({ selectedTags });
    };
    

    submitPage = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('category_id', this.state.records.category_id);
        formData.append('tags', JSON.stringify(this.state.selectedTags.map(tag => tag.id)));

        fetch(`${ApiUrl}cms/posts`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${uToken()}`
            },
            body: formData
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    this.setState({
                        row: {
                            id: '',
                            title: '',
                            category_id: '',
                     
                            image: 0,
                        },
                        selectedTags: [],
                        modal: false
                    });
                    this.getData();
                } else {
                    toast.error(data.message, { hideProgressBar: true, position: "top-center", transition: "flip", className: 'toast-custom-cls error' });
                }
                this.setState({ loader: true });
            })
            .catch((error) => {
                this.setState({ loader: true });
                console.error("Error:", error);
            });
    }


    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="2" className="pl-0">
                        <ProfileSettings menuOpen={false} props={this.props} />
                    </Col>
                    <Col lg="10">
                        <Loader loaded={this.state.loader}></Loader>
                        <Row>
                            <Col sm={6} >
                                <div className="page-title-box">
                                    <h4> {this.state.page_name} </h4>
                                    <ol className="breadcrumb m-0" >
                                        <li key={0} className="breadcrumb-item active"> {ProductName} </li>
                                        <li key={1} className="breadcrumb-item"> < Link to="#"> {this.state.page_name} </Link></li>
                                    </ol>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="page-title-box text-align-right">
                                    <Button
                                    className="btn-sm"
                                        type="button"
                                        color="secondary"
                                        onClick={() => this.props.history.goBack()}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </Col>
                        </Row>


                        <form onSubmit={this.submitPage} method="post"
                            encType="multipart/form-data" className="form-horizontal" id="selection-myform-blog">
                            <ModalBody>
                                <Row>
                                    <Col md="12">
                                        <div className="form-group mb-3">
                                            <label>Post Title</label>
                                            <input
                                                name="title"
                                                defaultValue={this.state.records.title}
                                                className="form-control"
                                                placeholder="Enter title"
                                                required
                                            />
                                            <input
                                                name="id"
                                                defaultValue={this.state.records.id}
                                                type='hidden'
                                            />
                                        </div>
                                    </Col>


                                    <Col md="12">
                                        <div className="form-group mb-3">
                                            <label>Category</label>
                                            <Select
                                                getOptionLabel={option => option.name}
                                                options={this.state.categories}
                                                value={this.state.categories.find(cat => cat.id === this.state.records.category_id)}
                                                onChange={(selectedOption) => this.setState({ records: { ...this.state.records, category_id: selectedOption ? selectedOption.id : '', category_name: selectedOption ? selectedOption.name : '' } })}
                                            />
                                        </div>
                                    </Col>

                                    <Col md="12">
                                        <div className="form-group mb-3">
                                            <label>Tags</label>
                                            {/* <Select
                                                getOptionLabel={option => option.name}
                                                getOptionValue={option => option.id}
                                                isMulti={true}
                                                value={this.state.tags.find(tag => tag.id == this.state.records.tags)}
                                                options={this.state.tags}
                                                classNamePrefix="select2-selection position-relative"
                                                onChange={(selectedOptions) => this.setState({ records: { ...this.state.records, tags: selectedOptions } })}
                                                name="tags"
                                            /> */}
                                            <Select
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                isMulti={true}
                                                value={this.state.selectedTags}
                                                options={this.state.tags}
                                                classNamePrefix="select2-selection"
                                                name="tags"
                                                onChange={this.handleTagChange}
                                            />

                                        </div>

                                    </Col>

                                    <input
                                        defaultValue={this.state.records.slug}
                                        name="slug"
                                        className="form-control"
                                        placeholder="Enter slug"
                                        type="hidden"


                                    />


                                    <Col md="12">
                                        <Col md="12">
                                            <div className="form-group mb-3">
                                                <label>Post Description</label>
                                                <textarea
                                                    defaultValue={this.state.records.post_description}
                                                    name="post_description"
                                                    className="form-control"
                                                    placeholder="Enter post description"
                                                    rows={5}

                                                />
                                            </div>
                                        </Col>


                                        <div className="form-group mb-3">
                                            <label>Post Detail</label>

                                            <Froala content={this.state.records.post_detail ?? ''} nameKey={'post_detail'} insideForm={true} btnId={'submit-blog'} handleSubmit={() => console.log('hi')} use_token={'users'} />


                                        </div>
                                    </Col>
                                    <fieldset>
                                        <Col md="12">
                                            <div className="form-group mb-3">
                                                <label>Meta keywords</label>
                                                <input
                                                    name="meta_keywords"
                                                    defaultValue={this.state.records.meta_keywords}
                                                    className="form-control"
                                                    placeholder="E.g blog, seo, etc"
                                                />
                                            </div>
                                        </Col>

                                        <Col md="12">
                                            <div className="form-group mb-3">
                                                <label>Meta Description</label>
                                                <textarea
                                                    defaultValue={this.state.records.meta_description}
                                                    name="meta_description"
                                                    className="form-control"
                                                    placeholder="Enter meta description"
                                                    rows={5}
                                        
                                                />
                                            </div>
                                        </Col>
                                    </fieldset>
                                    <Col md="12">
                                        <div className="form-group mb-3">
                                            <label>Image</label>
                                            <input
                                                name="image"
                                                className="form-control"
                                                placeholder="Enter title"
                                                type={'file'}
                                            />
                                            {
                                                this.state.records.image != 0 && (
                                                    <img width={"200px"} src={`${AttachementsUrl}/user_${this.state.records.user_id}/posts/${this.state.records.image}`} className="img img-thumbnaikl" />
                                                )
                                            }
                                        </div>
                                    </Col>
                                    {
                                        <Col md="12">
                                            <div className="form-group mb-3">
                                                <label>Status</label>
                                                <select name="status" className="form-select" defaultValue={this.state.records.status}>
                                                    <option value={'Publish'}>Publish</option>
                                                    <option value={'Unpublish'}>Unpublish</option>
                                                </select>
                                            </div>
                                        </Col>
                                    }
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button className="btn-sm" color="secondary" type="button" disabled={this.state.loader ? false : true}>Cancel</Button>
                                <Button className="Green btn-sm" type="submit" id="submit-blog" onClick={() => this.props.history.goBack()} disabled={this.state.loader ? false : true}>Submit</Button>
                            </ModalFooter>
                        </form>
                    </Col>
                </Row>

            </React.Fragment >
        )
    }
}