import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  ModalBody,
  CardBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
} from "reactstrap";

import Helmet from "react-helmet";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { uToken, uInfo, uRole, hasRole } from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import ProfileSettings from "../Authentication/profile-sidebar";
import swal from "sweetalert";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import ReactDatatable from "@ashvin27/react-datatable";

export default class NotificaionSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page_name: "Notifications Setting",
      from: this.props.from ? this.props.from : "",
      task: {
        confirmation_email: false,
        confirmation_sms: false,
        confirmation_system: false,
        reminder: false,
        email_body: "",
        instant_sms_template_id: "",
        instant_email_template_id: "",
        reminders: [],
      },
      event: {
        confirmation_email: false,
        confirmation_sms: false,
        confirmation_system: false,
        reminder: false,
        instant_sms_template_id: "",
        instant_email_template_id: "",
        email_body: "",
        reminders: [],
      },
      action_step: {
        confirmation_email: false,
        confirmation_sms: false,
        sms_template_id: "",
        email_template_id: "",
      },
      training: {
        confirmation_email: false,
        confirmation_sms: false,
        confirmation_system: false,
      },
      leed: {
        confirmation_email: false,
        confirmation_sms: false,
        confirmation_system: false,
        instant_sms_template_id: "",
        instant_email_template_id: "",
      },
      pwa_sms: {
        confirmation_email: false,
        confirmation_sms: false,
        confirmation_system: false,
        instant_sms_template_id: "",
        instant_email_template_id: "",
      },
      appointment: {
        confirmation_email: false,
        confirmation_sms: false,
        confirmation_system: false,
      },
      tab: "1",
      modal: false,
      template: {
        id: "",
        title: "",
        body: "",
        master_type: "event",
        type: "sms",
        token: "",
      },
      templates: [],
      loading: true,
    };
    this.columns = [
      {
        text: "Template Id#",
        key: "id",
      },
      {
        text: "Title",
        key: "title",
      },
      {
        text: "Template For",
        key: "master_type",
      },
      {
        text: "Type",
        key: "type",
      },
      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          return (
            <Fragment>
              <button
                onClick={() => {
                  this.setState({
                    modal: true,
                    template: record,
                  });
                }}
                type="button"
                className="btn btn-link btn-sm mr-5"
              >
                <i className="fas fa-edit"></i>
              </button>
              <button
                type="button"
                className="btn btn-link  btn-sm"
                onClick={this.deleteRecord.bind(this, record, index)}
              >
                <i className="fas fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];

    this.addField = this.addField.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.getTemplate();
  }

  deleteRecord = (record, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmation) => {
      if (confirmation) {
        fetch(`${ApiUrl}delete/notification/template`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
          body: JSON.stringify({ id: record.id }) 
        })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === true) {
            this.getTemplate();
          } else {
            swal({
              title: response.message,
              icon: "warning",
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      }
    });
  };
  
  getData = () => {
    fetch(`${ApiUrl}` + "get/notification/settings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          if (response.data.record.task) {
            this.setState({
              task: response.data.record.task,
            });
          } else {
            this.setState((prevState) => {
              let task = Object.assign({}, prevState.task);
              return { task };
            });
          }
          if (response.data.record.event) {
            this.setState({
              event: response.data.record.event,
            });
          } else {
            this.setState((prevState) => {
              let event = Object.assign({}, prevState.event);
              return { event };
            });
          }
          if (response.data.record.action_step) {
            this.setState({
              action_step: response.data.record.action_step,
            });
          } else {
            this.setState((prevState) => {
              let action_step = Object.assign({}, prevState.action_step);
              return { action_step };
            });
          }
          if (response.data.record.leed) {
            this.setState({
              leed: response.data.record.leed,
            });
          } else {
            this.setState((prevState) => {
              let leed = Object.assign({}, prevState.leed);
              return { leed };
            });
          }
          if (response.data.record.training) {
            this.setState({
              training: response.data.record.training,
            });
          } else {
            this.setState((prevState) => {
              let training = Object.assign({}, prevState.training);
              return { training };
            });
          }

          if (response.data.record.pwa_sms) {
            this.setState({
              pwa_sms: response.data.record.pwa_sms,
            });
          } else {
            this.setState((prevState) => {
              let pwa_sms = Object.assign({}, prevState.pwa_sms);
              return { pwa_sms };
            });
          }
          
          if (response.data.record.appointment) {
            this.setState({
              appointment: response.data.record.appointment,
            });
          } else {
            this.setState((prevState) => {
              let appointment = Object.assign({}, prevState.appointment);
              return { appointment };
            });
          }
          this.setState({
            loading: false,
          });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getTemplate = () => {
    fetch(`${ApiUrl}` + "get/notification/template", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            templates: response.data.records,
          });
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = async (event, errors, values) => {
    // if ((this.state.task.confirmation_email == true || this.state.task.confirmation_sms == true) && this.state.task.reminder == false) {
    //   swal({
    //     text: "You need to enable task reminder settings to send notifications.",
    //     icon: "warning",
    //   });
    //   return;
    // }

    // if ((this.state.event.confirmation_email == true || this.state.event.confirmation_sms == true) && this.state.event.reminder == false) {
    //   swal({
    //     text: "You need to enable event reminder settings to send notifications.",
    //     icon: "warning",
    //   });
    //   return;
    // }

    if (errors.length < 1) {
      values.task = JSON.stringify(this.state.task);
      values.event = JSON.stringify(this.state.event);
      values.action_step = JSON.stringify(this.state.action_step);
      values.training = JSON.stringify(this.state.training);
      values.leed = JSON.stringify(this.state.leed);
      values.pwa_sms = JSON.stringify(this.state.pwa_sms);
      values.appointment = JSON.stringify(this.state.appointment);
      fetch(`${ApiUrl}` + "save/notification/settings", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify(values),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data, "Editing");
          if (data.status === true) {
            swal({
              text: "Action performed successfully.",
              icon: "success",
            });
          } else {
            swal({
              text: "Action performed successfully.",
              icon: "warning",
            });
            // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        //Then with the error genereted...
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      console.log("not fount");
      this.setState({
        warningAlert: true,
      });
    }
  };

  toggle = (index) => {
    if (this.state.tab !== index) {
      this.setState({
        tab: index,
      });
    }
  };

  handleChange = (e) => {
    this.setState((prevState) => {
      let template = Object.assign({}, prevState.template);
      template.body = e;
      return { template };
    });
  };

  addField = (data) => {
    this.setState((prevState) => ({
      scheduling_forms: [
        ...prevState.scheduling_forms,
        {
          [`label`]: data.label,
          key: data.key,
          [`alternative_text`]: data.alternative_text,
          required: false,
        },
      ],
    }));
    this.setState({
      addMore: false,
    });
  };

  removeTaskReminder = (index) => {
    let automation = this.state.task;
    automation.reminders.splice(index, 1);
    this.setState(automation);
  };
  removeEventReminder = (index) => {
    let automation = this.state.event;
    automation.reminders.splice(index, 1);
    this.setState(automation);
  };
  removeOptionClick(k, e) {
    let scheduling_forms = [...this.state.scheduling_forms];
    scheduling_forms.splice(k, 1);
    this.setState({ scheduling_forms });
  }

  handleTemplateSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }

    fetch(`${ApiUrl}` + "submit/notification/template", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          swal({
            title: response.message,
            icon: "success",
          });
          this.getTemplate();
          this.closeModal();
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  closeModal = () => {
    this.setState({
      template: {
        id: "",
        title: "",
        body: "",
        master_type: "event",
        type: "sms",
        token: "",
      },
      modal: false,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.page_name}</title>
        </Helmet>

        <ToastContainer />

        <Row>
          <Col lg="2" className="pl-0">
            <ProfileSettings menuOpen={false} props={this.props} />
          </Col>
          <Col lg="10">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name} </h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name} </Link>
                    </li>
                  </ol>
                </div>
              </Col>
              <Col sm={6}>
                <div className="page-title-box text-align-right">
                  <Button
                    className="btn-sm"
                    type="button"
                    color="secondary"
                    onClick={() => this.props.history.goBack()}
                  >
                    Back
                  </Button>
                </div>
              </Col>
            </Row>
            <ToastContainer />
            <Row>
              <Col className="col-12">
                <Card>
                  <Nav tabs>
                    {hasRole(uInfo(), ["notifications_trainings"]) && (
                      <NavItem>
                        <NavLink
                          className={this.state.tab == 1 ? "active" : ""}
                          onClick={() => {
                            this.toggle("1");
                          }}
                        >
                          Trainings
                        </NavLink>
                      </NavItem>
                    )}
                    {hasRole(uInfo(), ["notifications_tasks"]) && (
                      <NavItem>
                        <NavLink
                          className={this.state.tab == 2 ? "active" : ""}
                          onClick={() => {
                            this.toggle("2");
                          }}
                        >
                          Task
                        </NavLink>
                      </NavItem>
                    )}
                    {hasRole(uInfo(), ["notifications_calendar_events"]) && (
                      <NavItem>
                        <NavLink
                          className={this.state.tab == 3 ? "active" : ""}
                          onClick={() => {
                            this.toggle("3");
                          }}
                        >
                          Calendar Events
                        </NavLink>
                      </NavItem>
                    )}
                    {hasRole(uInfo(), ["notifications_action_steps"]) && (
                      <NavItem>
                        <NavLink
                          className={this.state.tab == 4 ? "active" : ""}
                          onClick={() => {
                            this.toggle("4");
                          }}
                        >
                          Action Steps
                        </NavLink>
                      </NavItem>
                    )}
                    {hasRole(uInfo(), ["notifications_templates"]) && (
                      <NavItem>
                        <NavLink
                          className={this.state.tab == 6 ? "active" : ""}
                          onClick={() => {
                            this.toggle("6");
                          }}
                        >
                          Leads
                        </NavLink>
                      </NavItem>
                    )}
                    {hasRole(uInfo(), ["notifications_trainings"]) && (
                      <NavItem>
                        <NavLink
                          className={this.state.tab == 8 ? "active" : ""}
                          onClick={() => {
                            this.toggle("8");
                          }}
                        >
                          Pwa SMS
                        </NavLink>
                      </NavItem>
                    )}
                    {hasRole(uInfo(), ["notifications_templates"]) && (
                      <NavItem>
                        <NavLink
                          className={this.state.tab == 5 ? "active" : ""}
                          onClick={() => {
                            this.toggle("5");
                          }}
                        >
                          Templates
                        </NavLink>
                      </NavItem>
                    )}


                    
                  </Nav>
                  <AvForm
                    onSubmit={this.handleSubmit}
                    ref={(c) => (this.form = c)}
                    className="needs-validation"
                  >
                    <TabContent activeTab={this.state.tab}>
                      <TabPane tabId={"1"} className="mt-3">
                        <Row>
                          <Col md={12}>
                            <ModalBody>
                              <Row>
                                <Col md={6}>
                                  <div className="border py-3 px-3">
                                    <div className="d-flex space-between">
                                      <div className="email">
                                        <div className="d-flex">
                                          <h5>Email Notification</h5>
                                          <div className="toggle form-check form-switch form-switch-lg ml-3">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              checked={
                                                this.state.training
                                                  .confirmation_email
                                              }
                                              onChange={(e) => {
                                                this.setState((prevState) => {
                                                  let training = Object.assign(
                                                    {},
                                                    prevState.training
                                                  );
                                                  training.confirmation_email =
                                                    e.target.checked;
                                                  return { training };
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <p>
                                          Adjust your settings to receive email
                                          notifications about new training
                                          updates on your profile email.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <div className="border py-3 px-3">
                                    <div className="d-flex space-between">
                                      <div className="email">
                                        <div className="d-flex">
                                          <h5>SMS Notification</h5>
                                          <div className="toggle form-check form-switch form-switch-lg ml-3">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              checked={
                                                this.state.training
                                                  .confirmation_sms
                                              }
                                              onChange={(e) => {
                                                this.setState((prevState) => {
                                                  let training = Object.assign(
                                                    {},
                                                    prevState.training
                                                  );
                                                  training.confirmation_sms =
                                                    e.target.checked;
                                                  return { training };
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <p>
                                          Adjust your settings to receive sms
                                          notifications about new training
                                          updates on your profile phone number.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <div className="border py-3 px-3 mt-2">
                                    <div className="d-flex space-between">
                                      <div className="email">
                                        <div className="d-flex">
                                          <h5>System Notification</h5>
                                          <div className="toggle form-check form-switch form-switch-lg ml-3">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="confirmation_system"
                                              checked={
                                                this.state.training
                                                  .confirmation_system
                                              }
                                              onChange={(e) => {
                                                this.setState((prevState) => {
                                                  let training = Object.assign(
                                                    {},
                                                    prevState.training
                                                  );
                                                  training.confirmation_system =
                                                    e.target.checked;
                                                  return { training };
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <p>
                                          Adjust your settings to receive system
                                          notifications about new training
                                          updates in your system notification
                                          area.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </ModalBody>
                            <ModalFooter className="d-flex justify-content-start">
                              <button
                                className="btn btn-primary btn-sm"
                                type="submit"
                              >
                                Submit
                              </button>
                            </ModalFooter>
                          </Col>
                          <Col md={6}></Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row>
                          <Col md={12}>
                            <ModalBody>
                              <Row>
                                <Col md={6}>
                                  <div className="border py-3 px-3">
                                    <div className="d-flex space-between">
                                      <div className="email">
                                        <div className="d-flex">
                                          <h5>Email Notification</h5>
                                          <div className="toggle form-check form-switch form-switch-lg ml-3">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              checked={
                                                this.state.task
                                                  .confirmation_email
                                              }
                                              onChange={(e) => {
                                                this.setState((prevState) => {
                                                  let task = Object.assign(
                                                    {},
                                                    prevState.task
                                                  );
                                                  task.confirmation_email =
                                                    e.target.checked;
                                                  return { task };
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <p>
                                          Send a confirmation email to attendees
                                          according to pre reminder settings.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={6}>
                                  {this.state.task.confirmation_email && (
                                    <div className="border py-3 px-3">
                                      <AvField
                                        name="instant_email_template_id"
                                        value={
                                          this.state.task
                                            .instant_email_template_id
                                        }
                                        className="form-select"
                                        type="select"
                                        onChange={(e) =>
                                          this.setState((prevState) => {
                                            let task = Object.assign(
                                              {},
                                              prevState.task
                                            );
                                            task.instant_email_template_id =
                                              e.target.value;
                                            return { task };
                                          })
                                        }
                                      >
                                        <option value={""}>
                                          Select Email Template
                                        </option>
                                        {this.state.templates.map((row, i) => {
                                          if (
                                            row.master_type == "task" &&
                                            row.type == "email"
                                          ) {
                                            return (
                                              <option key={i} value={row.id}>
                                                {row.title}
                                              </option>
                                            );
                                          }
                                        })}
                                      </AvField>
                                    </div>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <div className="border py-3 px-3 mt-2">
                                    <div className="d-flex space-between">
                                      <div className="email">
                                        <div className="d-flex">
                                          <h5>Sms Notification</h5>
                                          <div className="toggle form-check form-switch form-switch-lg ml-3">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="confirmation_sms"
                                              checked={
                                                this.state.task.confirmation_sms
                                              }
                                              onChange={(e) => {
                                                this.setState((prevState) => {
                                                  let task = Object.assign(
                                                    {},
                                                    prevState.task
                                                  );
                                                  task.confirmation_sms =
                                                    e.target.checked;
                                                  return { task };
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <p>
                                          Send a sms notification to attendees
                                          according to pre reminder settings.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={6}>
                                  {this.state.task.confirmation_sms && (
                                    <div className="border py-3 px-3">
                                      <AvField
                                        name="instant_sms_template_id"
                                        value={
                                          this.state.task
                                            .instant_sms_template_id
                                        }
                                        className="form-select"
                                        type="select"
                                        onChange={(e) =>
                                          this.setState((prevState) => {
                                            let task = Object.assign(
                                              {},
                                              prevState.task
                                            );
                                            task.instant_sms_template_id =
                                              e.target.value;
                                            return { task };
                                          })
                                        }
                                      >
                                        <option value={""}>
                                          Select Sms Template
                                        </option>
                                        {this.state.templates.map((row, i) => {
                                          if (
                                            row.master_type == "task" &&
                                            row.type == "sms"
                                          ) {
                                            return (
                                              <option key={i} value={row.id}>
                                                {row.title}
                                              </option>
                                            );
                                          }
                                        })}
                                      </AvField>
                                    </div>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <div className="border py-3 px-3 mt-2">
                                    <div className="d-flex space-between">
                                      <div className="email">
                                        <div className="d-flex">
                                          <h5>Pre-task reminder</h5>
                                          <div className="toggle form-check form-switch form-switch-lg ml-3">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="reminder"
                                              checked={this.state.task.reminder}
                                              onChange={(e) => {
                                                this.setState((prevState) => {
                                                  let task = Object.assign(
                                                    {},
                                                    prevState.task
                                                  );
                                                  task.reminder =
                                                    e.target.checked;
                                                  return { task };
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <p className="mb-2">
                                          Send an email or sms notification to
                                          attendees before a task starts.
                                        </p>
                                      </div>
                                    </div>

                                    {this.state.task &&
                                      this.state.task.reminder && (
                                        <>
                                          {this.state.task.reminders.map(
                                            (row, r) => {
                                              return (
                                                <div
                                                  className="row mb-3"
                                                  key={r}
                                                >
                                                  <div className="col-md-2">
                                                    <AvField
                                                      name="interval"
                                                      value={row.interval}
                                                      placeholder={`Enter Interval`}
                                                      className="form-control"
                                                      type="number"
                                                      onChange={(e) =>
                                                        this.setState(
                                                          (prevState) => {
                                                            let reminders =
                                                              Object.assign(
                                                                {},
                                                                prevState.task
                                                                  .reminders
                                                              );
                                                            reminders[
                                                              r
                                                            ].interval =
                                                              e.target.value.replace(
                                                                /\D/g,
                                                                ""
                                                              );
                                                            return {
                                                              reminders,
                                                            };
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="col-md-3">
                                                    <AvField
                                                      name="type"
                                                      value={row.type}
                                                      className="form-select"
                                                      type="select"
                                                      onChange={(e) =>
                                                        this.setState(
                                                          (prevState) => {
                                                            let reminders =
                                                              Object.assign(
                                                                {},
                                                                prevState.task
                                                                  .reminders
                                                              );
                                                            reminders[r].type =
                                                              e.target.value;
                                                            return {
                                                              reminders,
                                                            };
                                                          }
                                                        )
                                                      }
                                                    >
                                                      <option
                                                        value={"day_before"}
                                                      >
                                                        day before
                                                      </option>
                                                      <option
                                                        value={"week_before"}
                                                      >
                                                        week before
                                                      </option>
                                                      <option
                                                        value={"hour_before"}
                                                      >
                                                        hour before
                                                      </option>
                                                      <option
                                                        value={"minute_before"}
                                                      >
                                                        minute before
                                                      </option>
                                                    </AvField>
                                                  </div>
                                                  <div className="col-md-3">
                                                    <AvField
                                                      name="sms_template_id"
                                                      value={
                                                        row.sms_template_id
                                                      }
                                                      className="form-select"
                                                      type="select"
                                                      onChange={(e) =>
                                                        this.setState(
                                                          (prevState) => {
                                                            let reminders =
                                                              Object.assign(
                                                                {},
                                                                prevState.task
                                                                  .reminders
                                                              );
                                                            reminders[
                                                              r
                                                            ].sms_template_id =
                                                              e.target.value;
                                                            return {
                                                              reminders,
                                                            };
                                                          }
                                                        )
                                                      }
                                                    >
                                                      <option value={""}>
                                                        Select Sms Template
                                                      </option>
                                                      {this.state.templates.map(
                                                        (row, i) => {
                                                          if (
                                                            row.master_type ==
                                                              "task" &&
                                                            row.type == "sms"
                                                          ) {
                                                            return (
                                                              <option
                                                                key={i}
                                                                value={row.id}
                                                              >
                                                                {row.title}
                                                              </option>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                    </AvField>
                                                  </div>
                                                  <div className="col-md-3">
                                                    <AvField
                                                      name="email_template_id"
                                                      value={
                                                        row.email_template_id
                                                      }
                                                      className="form-select"
                                                      type="select"
                                                      onChange={(e) =>
                                                        this.setState(
                                                          (prevState) => {
                                                            let reminders =
                                                              Object.assign(
                                                                {},
                                                                prevState.task
                                                                  .reminders
                                                              );
                                                            reminders[
                                                              r
                                                            ].email_template_id =
                                                              e.target.value;
                                                            return {
                                                              reminders,
                                                            };
                                                          }
                                                        )
                                                      }
                                                    >
                                                      <option value={""}>
                                                        Select Email Template
                                                      </option>
                                                      {this.state.templates.map(
                                                        (row, i) => {
                                                          if (
                                                            row.master_type ==
                                                              "task" &&
                                                            row.type == "email"
                                                          ) {
                                                            return (
                                                              <option
                                                                key={i}
                                                                value={row.id}
                                                              >
                                                                {row.title}
                                                              </option>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                    </AvField>
                                                  </div>

                                                  <div className="col-md-1 px-0">
                                                    {r > 0 && (
                                                      <button
                                                        type="button"
                                                        className="btn btn-danger btn-sm"
                                                        onClick={this.removeTaskReminder.bind(
                                                          this,
                                                          r
                                                        )}
                                                      >
                                                        <i className="ion ion-md-trash">
                                                          {" "}
                                                        </i>
                                                      </button>
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                          <button
                                            type="button"
                                            className="btn btn-lignt p0 btn-sm"
                                            onClick={() => {
                                              let task = this.state.task;
                                              task.reminders = [
                                                ...task.reminders,
                                                {
                                                  interval: "1",
                                                  type: "day_before",
                                                },
                                              ];
                                              this.setState({ task });
                                            }}
                                          >
                                            Add other form field
                                          </button>
                                        </>
                                      )}
                                  </div>
                                </Col>
                              </Row>
                            </ModalBody>
                            <ModalFooter className="d-flex justify-content-start">
                              <button
                                className="btn btn-primary btn-sm"
                                type="submit"
                              >
                                Submit
                              </button>
                            </ModalFooter>
                          </Col>
                          <Col md={6}></Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId={"3"}>
                        <Row>
                          <Col md={12}>
                            <ModalBody>
                              <Row>
                                <Col md={6}>
                                  <div className="border py-3 px-3">
                                    <div className="d-flex space-between">
                                      <div className="email">
                                        <div className="d-flex">
                                          <h5>Email Notification</h5>
                                          <div className="toggle form-check form-switch form-switch-lg ml-3">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="confirmation_email"
                                              checked={
                                                this.state.event
                                                  .confirmation_email
                                              }
                                              onChange={(e) => {
                                                this.setState((prevState) => {
                                                  let event = Object.assign(
                                                    {},
                                                    prevState.event
                                                  );
                                                  event.confirmation_email =
                                                    e.target.checked;
                                                  return { event };
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <p>
                                          Send a confirmation email to attendees
                                          according to pre reminder settings.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={6}>
                                  {this.state.event.confirmation_email && (
                                    <div className="border py-3 px-3">
                                      <AvField
                                        name="instant_email_template_id"
                                        value={
                                          this.state.event
                                            .instant_email_template_id
                                        }
                                        className="form-select"
                                        type="select"
                                        onChange={(e) =>
                                          this.setState((prevState) => {
                                            let event = Object.assign(
                                              {},
                                              prevState.event
                                            );
                                            event.instant_email_template_id =
                                              e.target.value;
                                            return { event };
                                          })
                                        }
                                      >
                                        <option value={""}>
                                          Select Email Template
                                        </option>
                                        {this.state.templates.map((row, i) => {
                                          if (
                                            row.master_type == "event" &&
                                            row.type == "email"
                                          ) {
                                            return (
                                              <option key={i} value={row.id}>
                                                {row.title}
                                              </option>
                                            );
                                          }
                                        })}
                                      </AvField>
                                    </div>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <div className="border py-3 px-3 mt-2">
                                    <div className="d-flex space-between">
                                      <div className="email">
                                        <div className="d-flex">
                                          <h5>Sms Notification</h5>
                                          <div className="toggle form-check form-switch form-switch-lg ml-3">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="confirmation_sms"
                                              checked={
                                                this.state.event
                                                  .confirmation_sms
                                              }
                                              onChange={(e) => {
                                                this.setState((prevState) => {
                                                  let event = Object.assign(
                                                    {},
                                                    prevState.event
                                                  );
                                                  event.confirmation_sms =
                                                    e.target.checked;
                                                  return { event };
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <p>
                                          Send a sms notification to attendees
                                          according to pre reminder settings.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={6}>
                                  {this.state.event.confirmation_sms && (
                                    <div className="border py-3 px-3">
                                      <AvField
                                        name="instant_sms_template_id"
                                        value={
                                          this.state.event
                                            .instant_sms_template_id
                                        }
                                        className="form-select"
                                        type="select"
                                        onChange={(e) =>
                                          this.setState((prevState) => {
                                            let event = Object.assign(
                                              {},
                                              prevState.event
                                            );
                                            event.instant_sms_template_id =
                                              e.target.value;
                                            return { event };
                                          })
                                        }
                                      >
                                        <option value={""}>
                                          Select Sms Template
                                        </option>
                                        {this.state.templates.map((row, i) => {
                                          if (
                                            row.master_type == "event" &&
                                            row.type == "sms"
                                          ) {
                                            return (
                                              <option key={i} value={row.id}>
                                                {row.title}
                                              </option>
                                            );
                                          }
                                        })}
                                      </AvField>
                                    </div>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <div className="border py-3 px-3 mt-2">
                                    <div className="d-flex space-between">
                                      <div className="email">
                                        <div className="d-flex">
                                          <h5>Pre-event reminder</h5>
                                          <div className="toggle form-check form-switch form-switch-lg ml-3">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="reminder"
                                              checked={
                                                this.state.event.reminder
                                              }
                                              onChange={(e) => {
                                                this.setState((prevState) => {
                                                  let event = Object.assign(
                                                    {},
                                                    prevState.event
                                                  );
                                                  event.reminder =
                                                    e.target.checked;
                                                  return { event };
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <p className="mb-2">
                                          Send an email or sms notification to
                                          attendees before an appointment
                                          starts.
                                        </p>
                                      </div>
                                    </div>

                                    {this.state.event.reminder && (
                                      <>
                                        {this.state.event.reminders.map(
                                          (row, r) => {
                                            return (
                                              <div className="row mb-3" key={r}>
                                                <div className="col-md-2">
                                                  <AvField
                                                    name="interval"
                                                    value={row.interval}
                                                    placeholder={`Enter Interval`}
                                                    className="form-control"
                                                    type="number"
                                                    onChange={(e) =>
                                                      this.setState(
                                                        (prevState) => {
                                                          let reminders =
                                                            Object.assign(
                                                              {},
                                                              prevState.event
                                                                .reminders
                                                            );
                                                          reminders[
                                                            r
                                                          ].interval =
                                                            e.target.value.replace(
                                                              /\D/g,
                                                              ""
                                                            );
                                                          return { reminders };
                                                        }
                                                      )
                                                    }
                                                  />
                                                </div>
                                                <div className="col-md-3">
                                                  <AvField
                                                    name="type"
                                                    value={row.type}
                                                    className="form-select"
                                                    type="select"
                                                    onChange={(e) =>
                                                      this.setState(
                                                        (prevState) => {
                                                          let reminders =
                                                            Object.assign(
                                                              {},
                                                              prevState.event
                                                                .reminders
                                                            );
                                                          reminders[r].type =
                                                            e.target.value;
                                                          return { reminders };
                                                        }
                                                      )
                                                    }
                                                  >
                                                    <option
                                                      value={"day_before"}
                                                    >
                                                      day before
                                                    </option>
                                                    <option
                                                      value={"week_before"}
                                                    >
                                                      week before
                                                    </option>
                                                    <option
                                                      value={"hour_before"}
                                                    >
                                                      hour before
                                                    </option>
                                                    <option
                                                      value={"minute_before"}
                                                    >
                                                      minute before
                                                    </option>
                                                  </AvField>
                                                </div>

                                                <div className="col-md-3">
                                                  <AvField
                                                    name="sms_template_id"
                                                    value={row.sms_template_id}
                                                    className="form-select"
                                                    type="select"
                                                    onChange={(e) =>
                                                      this.setState(
                                                        (prevState) => {
                                                          let reminders =
                                                            Object.assign(
                                                              {},
                                                              prevState.event
                                                                .reminders
                                                            );
                                                          reminders[
                                                            r
                                                          ].sms_template_id =
                                                            e.target.value;
                                                          return { reminders };
                                                        }
                                                      )
                                                    }
                                                  >
                                                    <option value={""}>
                                                      Select Sms Template
                                                    </option>
                                                    {this.state.templates.map(
                                                      (row, i) => {
                                                        if (
                                                          row.master_type ==
                                                            "event" &&
                                                          row.type == "sms"
                                                        ) {
                                                          return (
                                                            <option
                                                              key={i}
                                                              value={row.id}
                                                            >
                                                              {row.title}
                                                            </option>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                  </AvField>
                                                </div>

                                                <div className="col-md-3">
                                                  <AvField
                                                    name="email_template_id"
                                                    value={
                                                      row.email_template_id
                                                    }
                                                    className="form-select"
                                                    type="select"
                                                    onChange={(e) =>
                                                      this.setState(
                                                        (prevState) => {
                                                          let reminders =
                                                            Object.assign(
                                                              {},
                                                              prevState.event
                                                                .reminders
                                                            );
                                                          reminders[
                                                            r
                                                          ].email_template_id =
                                                            e.target.value;
                                                          return { reminders };
                                                        }
                                                      )
                                                    }
                                                  >
                                                    <option value={""}>
                                                      Select Email Template
                                                    </option>
                                                    {this.state.templates.map(
                                                      (row, i) => {
                                                        if (
                                                          row.master_type ==
                                                            "event" &&
                                                          row.type == "email"
                                                        ) {
                                                          return (
                                                            <option
                                                              key={i}
                                                              value={row.id}
                                                            >
                                                              {row.title}
                                                            </option>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                  </AvField>
                                                </div>

                                                <div className="col-md-1">
                                                  {r > 0 && (
                                                    <button
                                                      type="button"
                                                      className="btn btn-danger btn-sm"
                                                      onClick={this.removeEventReminder.bind(
                                                        this,
                                                        r
                                                      )}
                                                    >
                                                      <i className="ion ion-md-trash">
                                                        {" "}
                                                      </i>
                                                    </button>
                                                  )}
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                        <button
                                          type="button"
                                          className="btn btn-lignt p0 mt-3 mb-3 btn-sm"
                                          onClick={() => {
                                            let event = this.state.event;
                                            event.reminders = [
                                              ...event.reminders,
                                              {
                                                interval: "1",
                                                type: "day_before",
                                              },
                                            ];
                                            this.setState({ event });
                                          }}
                                        >
                                          Add other form field
                                        </button>
                                      </>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </ModalBody>
                            <ModalFooter className="d-flex justify-content-start">
                              <button
                                className="btn btn-primary btn-sm"
                                type="submit"
                              >
                                Submit
                              </button>
                            </ModalFooter>
                          </Col>
                          <Col md={6}></Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId={"4"} className="mt-3">
                        <Row>
                          <Col md={12}>
                            <ModalBody>
                              <Row>
                                <Col md={6}>
                                  <div className="border py-3 px-3">
                                    <div className="d-flex space-between">
                                      <div className="email">
                                        <div className="d-flex">
                                          <h5>Email Notification</h5>
                                          <div className="toggle form-check form-switch form-switch-lg ml-3">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="confirmation_email"
                                              checked={
                                                this.state.action_step
                                                  .confirmation_email
                                              }
                                              onChange={(e) => {
                                                this.setState((prevState) => {
                                                  let action_step =
                                                    Object.assign(
                                                      {},
                                                      prevState.action_step
                                                    );
                                                  action_step.confirmation_email =
                                                    e.target.checked;
                                                  return { action_step };
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <p>
                                          Send a confirmation email to the
                                          client when an action step is
                                          assigned.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={6}>
                                  {this.state.action_step
                                    .confirmation_email && (
                                    <div className="border py-3 px-3 mt-2">
                                      <AvField
                                        name="email_template_id"
                                        value={
                                          this.state.action_step
                                            .email_template_id
                                        }
                                        className="form-select"
                                        type="select"
                                        onChange={(e) =>
                                          this.setState((prevState) => {
                                            let action_step = Object.assign(
                                              {},
                                              prevState.action_step
                                            );
                                            action_step.email_template_id =
                                              e.target.value;
                                            return { action_step };
                                          })
                                        }
                                      >
                                        <option value={""}>
                                          Select Email Template
                                        </option>
                                        {this.state.templates.map((row, i) => {
                                          if (
                                            row.master_type == "action_step" &&
                                            row.type == "email"
                                          ) {
                                            return (
                                              <option key={i} value={row.id}>
                                                {row.title}
                                              </option>
                                            );
                                          }
                                        })}
                                      </AvField>
                                    </div>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <div className="border py-3 px-3 mt-2">
                                    <div className="d-flex space-between">
                                      <div className="email">
                                        <div className="d-flex">
                                          <h5>Sms Notification</h5>
                                          <div className="toggle form-check form-switch form-switch-lg ml-3">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="confirmation_sms"
                                              checked={
                                                this.state.action_step
                                                  .confirmation_sms
                                              }
                                              onChange={(e) => {
                                                this.setState((prevState) => {
                                                  let action_step =
                                                    Object.assign(
                                                      {},
                                                      prevState.action_step
                                                    );
                                                  action_step.confirmation_sms =
                                                    e.target.checked;
                                                  return { action_step };
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <p>
                                          Send sms notification to the client
                                          when an action step is assigned.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={6}>
                                  {this.state.action_step.confirmation_sms && (
                                    <div className="border py-3 px-3">
                                      <AvField
                                        name="sms_template_id"
                                        value={
                                          this.state.action_step.sms_template_id
                                        }
                                        className="form-select"
                                        type="select"
                                        onChange={(e) =>
                                          this.setState((prevState) => {
                                            let action_step = Object.assign(
                                              {},
                                              prevState.action_step
                                            );
                                            action_step.sms_template_id =
                                              e.target.value;
                                            return { action_step };
                                          })
                                        }
                                      >
                                        <option value={""}>
                                          Select Sms Template
                                        </option>
                                        {this.state.templates.map((row, i) => {
                                          if (
                                            row.master_type == "action_step" &&
                                            row.type == "sms"
                                          ) {
                                            return (
                                              <option key={i} value={row.id}>
                                                {row.title}
                                              </option>
                                            );
                                          }
                                        })}
                                      </AvField>
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </ModalBody>
                            <ModalFooter>
                              <button
                                className="btn btn-primary waves-effect waves-light btn-sm"
                                type="submit"
                              >
                                Submit
                              </button>
                            </ModalFooter>
                          </Col>
                          <Col md={6}></Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId={"5"} className="mt-3">
                        <Row>
                          <Col md={12}>
                            <div className="float-right">
                              <button
                                onClick={() =>
                                  this.setState({
                                    modal: true,
                                  })
                                }
                                className="btn btn-info btn-sm"
                                type="button"
                              >
                                Create
                              </button>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-12">
                            <Card>
                              <CardBody>
                                <ReactDatatable
                                  config={this.config}
                                  records={this.state.templates}
                                  columns={this.columns}
                                />
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId={"6"} className="mt-3">
                        <Row>
                          <Col md={12}>
                            <ModalBody>
                              <Row>
                                <Col md={6}>
                                  <div className="border py-3 px-3">
                                    <div className="d-flex space-between">
                                      <div className="email">
                                        <div className="d-flex">
                                          <h5>Email Notification</h5>
                                          <div className="toggle form-check form-switch form-switch-lg ml-3">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              checked={
                                                this.state.leed
                                                  .confirmation_email
                                              }
                                              onChange={(e) => {
                                                this.setState((prevState) => {
                                                  let leed = Object.assign(
                                                    {},
                                                    prevState.leed
                                                  );
                                                  leed.confirmation_email =
                                                    e.target.checked;
                                                  return { leed };
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <p>
                                          Adjust your settings to receive email
                                          notifications about new lead
                                          updates on your profile email.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={6}>
                                  {this.state.leed.confirmation_email && (
                                    <div className="border py-3 px-3">
                                      <AvField
                                        name="instant_email_template_id"
                                        value={
                                          this.state.leed
                                            .instant_email_template_id
                                        }
                                        className="form-select"
                                        type="select"
                                        onChange={(e) =>
                                          this.setState((prevState) => {
                                            let leed = Object.assign(
                                              {},
                                              prevState.leed
                                            );
                                            leed.instant_email_template_id =
                                              e.target.value;
                                            return { leed };
                                          })
                                        }
                                      >
                                        <option value={""}>
                                          Select Email Template
                                        </option>
                                        {this.state.templates.map((row, i) => {
                                          if (
                                            row.master_type == "lead" &&
                                            row.type == "email"
                                          ) {
                                            return (
                                              <option key={i} value={row.id}>
                                                {row.title}
                                              </option>
                                            );
                                          }
                                        })}
                                      </AvField>
                                    </div>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <div className="border py-3 px-3">
                                    <div className="d-flex space-between">
                                      <div className="email">
                                        <div className="d-flex">
                                          <h5>SMS Notification</h5>
                                          <div className="toggle form-check form-switch form-switch-lg ml-3">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              checked={
                                                this.state.leed
                                                  .confirmation_sms
                                              }
                                              onChange={(e) => {
                                                this.setState((prevState) => {
                                                  let leed = Object.assign(
                                                    {},
                                                    prevState.leed
                                                  );
                                                  leed.confirmation_sms =
                                                    e.target.checked;
                                                  return { leed };
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <p>
                                          Adjust your settings to receive sms
                                          notifications about new lead
                                          updates on your profile phone number.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={6}>
                                  {this.state.leed.confirmation_sms && (
                                    <div className="border py-3 px-3">
                                      <AvField
                                        name="instant_sms_template_id"
                                        value={
                                          this.state.leed
                                            .instant_sms_template_id
                                        }
                                        className="form-select"
                                        type="select"
                                        onChange={(e) =>
                                          this.setState((prevState) => {
                                            let leed = Object.assign(
                                              {},
                                              prevState.leed
                                            );
                                            leed.instant_sms_template_id =
                                              e.target.value;
                                            return { leed };
                                          })
                                        }
                                      >
                                        <option value={""}>
                                          Select Sms Template
                                        </option>
                                        {this.state.templates.map((row, i) => {
                                          if (
                                            row.master_type == "lead" &&
                                            row.type == "sms"
                                          ) {
                                            return (
                                              <option key={i} value={row.id}>
                                                {row.title}
                                              </option>
                                            );
                                          }
                                        })}
                                      </AvField>
                                    </div>
                                  )}
                                </Col>
                              </Row>
                              {/* <Row>
                                <Col md={6}>
                                  <div className="border py-3 px-3 mt-2">
                                    <div className="d-flex space-between">
                                      <div className="email">
                                        <div className="d-flex">
                                          <h5>System Notification</h5>
                                          <div className="toggle form-check form-switch form-switch-lg ml-3">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="confirmation_system"
                                              checked={
                                                this.state.leed
                                                  .confirmation_system
                                              }
                                              onChange={(e) => {
                                                this.setState((prevState) => {
                                                  let leed = Object.assign(
                                                    {},
                                                    prevState.leed
                                                  );
                                                  leed.confirmation_system =
                                                    e.target.checked;
                                                  return { leed };
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <p>
                                          Adjust your settings to receive system
                                          notifications about new lead
                                          updates in your system notification
                                          area.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row> */}
                            </ModalBody>
                            <ModalFooter className="d-flex justify-content-start">
                              <button
                                className="btn btn-primary btn-sm"
                                type="submit"
                              >
                                Submit
                              </button>
                            </ModalFooter>
                          </Col>
                          <Col md={6}></Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId={"8"} className="mt-3">
                        <Row>
                          <Col md={12}>
                            <ModalBody>
                              {/* <Row>
                                <Col md={6}>
                                  <div className="border py-3 px-3">
                                    <div className="d-flex space-between">
                                      <div className="email">
                                        <div className="d-flex">
                                          <h5>Email Notification</h5>
                                          <div className="toggle form-check form-switch form-switch-lg ml-3">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              checked={
                                                this.state.pwa_sms
                                                  .confirmation_email
                                              }
                                              onChange={(e) => {
                                                this.setState((prevState) => {
                                                  let pwa_sms = Object.assign(
                                                    {},
                                                    prevState.pwa_sms
                                                  );
                                                  pwa_sms.confirmation_email =
                                                    e.target.checked;
                                                  return { pwa_sms };
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <p>
                                          Adjust your settings to receive email
                                          notifications about new training
                                          updates on your profile email.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={6}>
                                  {this.state.pwa_sms.confirmation_email && (
                                    <div className="border py-3 px-3">
                                      <AvField
                                        name="instant_email_template_id"
                                        value={
                                          this.state.pwa_sms
                                            .instant_email_template_id
                                        }
                                        className="form-select"
                                        type="select"
                                        onChange={(e) =>
                                          this.setState((prevState) => {
                                            let pwa_sms = Object.assign(
                                              {},
                                              prevState.pwa_sms
                                            );
                                            pwa_sms.instant_email_template_id =
                                              e.target.value;
                                            return { pwa_sms };
                                          })
                                        }
                                      >
                                        <option value={""}>
                                          Select Email Template
                                        </option>
                                        {this.state.templates.map((row, i) => {
                                          if (
                                            row.master_type == "pwa_sms" &&
                                            row.type == "email"
                                          ) {
                                            return (
                                              <option key={i} value={row.id}>
                                                {row.title}
                                              </option>
                                            );
                                          }
                                        })}
                                      </AvField>
                                    </div>
                                  )}
                                </Col>
                              </Row> */}
                              <Row>
                                <Col md={6}>
                                  <div className="border py-3 px-3">
                                    <div className="d-flex space-between">
                                      <div className="email">
                                        <div className="d-flex">
                                          <h5>SMS Notification</h5>
                                          <div className="toggle form-check form-switch form-switch-lg ml-3">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              checked={
                                                this.state.pwa_sms
                                                  .confirmation_sms
                                              }
                                              onChange={(e) => {
                                                this.setState((prevState) => {
                                                  let pwa_sms = Object.assign(
                                                    {},
                                                    prevState.pwa_sms
                                                  );
                                                  pwa_sms.confirmation_sms =
                                                    e.target.checked;
                                                  return { pwa_sms };
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <p>
                                          Adjust your settings to receive sms
                                          notifications about new pwa sms
                                          updates on your profile phone number.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={6}>
                                  {this.state.pwa_sms.confirmation_sms && (
                                    <div className="border py-3 px-3">
                                      <AvField
                                        name="instant_sms_template_id"
                                        value={
                                          this.state.pwa_sms
                                            .instant_sms_template_id
                                        }
                                        className="form-select"
                                        type="select"
                                        onChange={(e) =>
                                          this.setState((prevState) => {
                                            let pwa_sms = Object.assign(
                                              {},
                                              prevState.pwa_sms
                                            );
                                            pwa_sms.instant_sms_template_id =
                                              e.target.value;
                                            return { pwa_sms };
                                          })
                                        }
                                      >
                                        <option value={""}>
                                          Select Sms Template
                                        </option>
                                        {this.state.templates.map((row, i) => {
                                          if (
                                            row.master_type == "pwa_sms" &&
                                            row.type == "sms"
                                          ) {
                                            return (
                                              <option key={i} value={row.id}>
                                                {row.title}
                                              </option>
                                            );
                                          }
                                        })}
                                      </AvField>
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </ModalBody>
                            <ModalFooter className="d-flex justify-content-start">
                              <button
                                className="btn btn-primary btn-sm"
                                type="submit"
                              >
                                Submit
                              </button>
                            </ModalFooter>
                          </Col>
                          <Col md={6}></Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId={"7"} className="mt-3">
                        <Row>
                          <Col md={12}>
                            <ModalBody>
                              <Row>
                                <Col md={6}>
                                  <div className="border py-3 px-3">
                                    <div className="d-flex space-between">
                                      <div className="email">
                                        <div className="d-flex">
                                          <h5>Email Notification</h5>
                                          <div className="toggle form-check form-switch form-switch-lg ml-3">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              checked={
                                                this.state.appointment
                                                  .confirmation_email
                                              }
                                              onChange={(e) => {
                                                this.setState((prevState) => {
                                                  let appointment = Object.assign(
                                                    {},
                                                    prevState.appointment
                                                  );
                                                  appointment.confirmation_email =
                                                    e.target.checked;
                                                  return { appointment };
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <p>
                                          Adjust your settings to receive email
                                          notifications about new training
                                          updates on your profile email.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <div className="border py-3 px-3">
                                    <div className="d-flex space-between">
                                      <div className="email">
                                        <div className="d-flex">
                                          <h5>SMS Notification</h5>
                                          <div className="toggle form-check form-switch form-switch-lg ml-3">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              checked={
                                                this.state.appointment
                                                  .confirmation_sms
                                              }
                                              onChange={(e) => {
                                                this.setState((prevState) => {
                                                  let appointment = Object.assign(
                                                    {},
                                                    prevState.appointment
                                                  );
                                                  appointment.confirmation_sms =
                                                    e.target.checked;
                                                  return { appointment };
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <p>
                                          Adjust your settings to receive sms
                                          notifications about new training
                                          updates on your profile phone number.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <div className="border py-3 px-3 mt-2">
                                    <div className="d-flex space-between">
                                      <div className="email">
                                        <div className="d-flex">
                                          <h5>System Notification</h5>
                                          <div className="toggle form-check form-switch form-switch-lg ml-3">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="confirmation_system"
                                              checked={
                                                this.state.appointment
                                                  .confirmation_system
                                              }
                                              onChange={(e) => {
                                                this.setState((prevState) => {
                                                  let appointment = Object.assign(
                                                    {},
                                                    prevState.appointment
                                                  );
                                                  appointment.confirmation_system =
                                                    e.target.checked;
                                                  return { appointment };
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <p>
                                          Adjust your settings to receive system
                                          notifications about new training
                                          updates in your system notification
                                          area.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </ModalBody>
                            <ModalFooter className="d-flex justify-content-start">
                              <button
                                className="btn btn-primary btn-sm"
                                type="submit"
                              >
                                Submit
                              </button>
                            </ModalFooter>
                          </Col>
                          <Col md={6}></Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </AvForm>
                </Card>
                <Modal
                  isOpen={this.state.modal}
                  fade={true}
                  size="lg"
                  toggle={() => this.closeModal()}
                >
                  <form
                    method="post"
                    onSubmit={this.handleTemplateSubmit}
                    encType="multipart/form-data"
                    className="form-horizontal"
                    id="selection-myform"
                  >
                    <ModalHeader> Notification Template </ModalHeader>
                    <ModalBody>
                      <div className="form-group mb-3">
                        <label htmlFor="title" className="form-label">
                          Title
                        </label>
                        <input
                          placeholder="Title"
                          value={this.state.template.title}
                          required
                          className="form-control"
                          type={"text"}
                          onChange={(e) => {
                            this.setState((prevState) => {
                              let template = Object.assign(
                                {},
                                prevState.template
                              );
                              template.title = e.target.value;
                              return { template };
                            });
                          }}
                          name="title"
                        />
                      </div>

                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group mb-3">
                            <label htmlFor="master_type" className="form-label">
                              Template For
                            </label>
                            <select
                              name="master_type"
                              required
                              className="form-select"
                              value={this.state.template.master_type}
                              onChange={(e) => {
                                this.setState((prevState) => {
                                  let template = Object.assign(
                                    {},
                                    prevState.template
                                  );
                                  template.master_type = e.target.value;
                                  return { template };
                                });
                              }}
                            >
                              <option value={"event"}>Event</option>
                              <option value={"lead"}>Lead</option>
                              <option value={"task"}>Task</option>
                               <option value={"pwa_sms"}>Pwa Sms</option>
                              <option value={"action_step"}>Action Step</option>
                            </select>
                          </div>
                        </div>
                        {this.state.template.id && (
                          <input
                            name="id"
                            type={"hidden"}
                            className={"form-control"}
                            required
                            value={this.state.template.id}
                          />
                        )}
                        <div className="col-lg-6">
                          <div className="form-group mb-3">
                            <label htmlFor="type" className="form-label">
                              Type
                            </label>
                            <select
                              name="type"
                              required
                              className="form-select"
                              value={this.state.template.type}
                              onChange={(e) => {
                                this.setState((prevState) => {
                                  let template = Object.assign(
                                    {},
                                    prevState.template
                                  );
                                  template.type = e.target.value;
                                  template.body = "";
                                  template.token = "";
                                  return { template };
                                });
                              }}
                            >
                              <option value={"sms"}>Sms</option>
                              {this.state.template.master_type !==
                                "pwa_sms" && (
                                <option value={"email"}>Email</option>
                              )}
                            </select>
                          </div>
                        </div>
                      </div>

                      <label htmlFor="token" className="form-label">
                        Token
                      </label>
                      <div className="d-flex mb-3 justify-content-between">
                        <div className="form-group w-75 ">
                          <select
                            className="form-select"
                            value={this.state.template.token}
                            onChange={(e) => {
                              this.setState((prevState) => {
                                let template = Object.assign(
                                  {},
                                  prevState.template
                                );
                                template.token = e.target.value;
                                return { template };
                              });
                            }}
                          >
                            <option value={""}>Select Token</option>
                            {this.state.template.master_type == "task" && (
                              <>
                                <option value={"{name}"}>Name</option>
                                <option value={"{task_title}"}>
                                  Task Title
                                </option>
                                <option value={"{date_time}"}>Date Time</option>
                              </>
                            )}

                            {this.state.template.master_type == "event" && (
                              <>
                                <option value={"{client_name}"}>
                                  Client Name
                                </option>
                                <option value={"{event_title}"}>
                                  Event Title
                                </option>
                                <option value={"{date_time}"}>Date Time</option>
                              </>
                            )}

                            {this.state.template.master_type ==
                              "action_step" && (
                              <>
                                <option value={"{client_name}"}>
                                  Client Name
                                </option>
                                <option value={"{task_number}"}>
                                  Task Number
                                </option>
                              </>
                            )}
                            {this.state.template.master_type == "pwa_sms" && (
                              <>
                                <option value={"{coach_name}"}>
                                  Coach Name
                                </option>
                                <option value={"{reschedule_link}"}>
                                  Reschedule link
                                </option>
                              </>
                            )}

{this.state.template.master_type == "lead" && (
                              <>
                                <option value={"{first_name}"}>
                                  Client First Name
                                </option>
                                <option value={"{last_name}"}>
                                 Client Last Name
                                </option>
                                <option value={"{email}"}>
                                Client Email
                                </option>
                              </>
                            )}
                          </select>
                        </div>
                        <button
                          onClick={() => {
                            this.setState((prevState) => {
                              let template = Object.assign(
                                {},
                                prevState.template
                              );
                              template.body =
                                this.state.template.body +
                                " " +
                                this.state.template.token;
                              template.token = "";
                              return { template };
                            });
                          }}
                          className="btn btn-primary btn-sm"
                          type="button"
                        >
                          Insert Token
                        </button>
                      </div>

                      <div className="form-group mb-3">
                        {this.state.template.type == "email" && (
                          <div className="">
                            <SunEditor
                              setOptions={{
                                height: 200,
                                buttonList: [
                                  [
                                    "font",
                                    "fontSize",
                                    "bold",
                                    "underline",
                                    "italic",
                                    "fontColor",
                                    "align",
                                    "codeView",
                                  ],
                                ],
                              }}
                              name="body"
                              onChange={this.handleChange}
                              setContents={this.state.template.body}
                            />
                          </div>
                        )}

                        {this.state.template.type == "sms" && (
                          <div className="form-group">
                            <textarea
                              className="form-control"
                              name="body"
                              required
                              rows={5}
                              value={this.state.template.body}
                              placeholder="Enter text"
                              onChange={(e) => {
                                this.setState((prevState) => {
                                  let template = Object.assign(
                                    {},
                                    prevState.template
                                  );
                                  template.body = e.target.value;
                                  return { template };
                                });
                              }}
                            ></textarea>
                          </div>
                        )}
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        className="btn-sm"
                        color="secondary"
                        onClick={() => this.closeModal()}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="Green btn-sm"
                        // onClick={() =>}
                      >
                        Submit
                      </Button>
                    </ModalFooter>
                  </form>
                </Modal>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
