import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
// import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import Helmet from "react-helmet";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation";

import ProfileSettings from "./profile-sidebar";
import Select from "react-select";
import {
  Asia,
  Africa,
  America,
  Antartica,
  Atlantic,
  Australia,
  Europe,
  Indian,
  Pacific,
} from "../../common/data/timezones";
import { hasRole, uInfo, uToken } from "../../useToken";
import { ApiUrl, AttachementsUrl, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import countries from "../../common/data/countries";
import swal from "sweetalert";
import Loader from "react-loader";
import Froala from "../Froala";
import Badge from "react-bootstrap/Badge";
import FromEmailTabel from "./from-email-list";
import { currencies } from "../../common/Common-Currency";
import { useLocation } from "react-router-dom";

import EmailSettingsList from "../../pages/Settings/EmailSettings/EmailsConfiguration";

const UserProfile = (props) => {
  const formRef = useRef(null);
  const [timeZone_data, setnewzone] = useState(Asia);
  const [user, setUser] = useState("");
  const [timezone, setTimeZone] = useState("");
  const [maintimezone, setMainTimeZone] = useState("");
  // const [tab, setTab] = useState(props.match?.params?.tab != undefined ? props.match?.params?.tab : "1");
  const [numbers, setNumber] = useState([]);
  const [social_links, setSocialLinks] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [country_code, setCountry_code] = useState(0);
  const [currency_code, setCurrency_code] = useState("");
  const [path, setPath] = useState("");
  const [loaded, setLoaded] = useState(true);
  const [emailSignature, setEmailSignature] = useState("");
  const [showCode, setShowCode] = useState(false);
  const [emailLoader, setEmailLoader] = useState(true);
  const [value, setValue] = useState(null);

  // new functionality added for openeing a specicif tab
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const initialTab = props.match?.params?.tab || params.get("tab") || "1";
  const [tab, setTab] = useState(initialTab);

  useEffect(() => {
    const newTab = params.get("tab") || "1";
    setTab(newTab);
  }, [location.search]);
  // new functionality added for openeing a specicif tab

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      console.log("user obj ", obj);
      setUser(obj);

      if (uInfo().image == "" || uInfo().image == null) {
        setPath(`${AttachementsUrl}default/images/avatar_image.jpg`);
      } else {
        setPath(`${AttachementsUrl}user_${uInfo().user_id}/profile.png`);
      }
      let data = obj.time_zone.split("/");
      setTimeZone({ id: obj.time_zone, label: data[1] });
      setMainTimeZone({ id: data[0], label: data[0] });

      if (data[0] == "Australia") {
        setnewzone(Australia);
      } else if (data[0] == "America") {
        setnewzone(America);
      } else if (data[0] == "Asia") {
        setnewzone(Asia);
      } else if (data[0] == "Europe") {
        setnewzone(Europe);
      } else if (data[0] == "Indian") {
        setnewzone(Indian);
      }
    }
    getCurrency();
  }, [!user]);

  useEffect(() => {
    if (uInfo().type == "Coach" || uInfo().type == "CoachPartner") {
      getUserData();
    }
  }, []);

  function toggle(index) {
    setTab(index);
  }

  function settimezone(e) {
    console.log(e, "DEBUG");
    setMainTimeZone(e);

    if (e.label == "Australia") {
      setnewzone(Australia);
    } else if (e.label == "America") {
      setnewzone(America);
    } else if (e.label == "Asia") {
      setnewzone(Asia);
    } else if (e.label == "Europe") {
      setnewzone(Europe);
    } else if (e.label == "Indian") {
      setnewzone(Indian);
    }

    // console.log(timeZone_data,'Zone Change');
  }

  const getCurrency = () => {
    var currency_arr = [];
    Object.keys(currencies).map((item) => {
      //currency_arr.push({ 'label': currencies[item].symbol + '- (' + currencies[item].name_plural + ') (' + currencies[item].code + ')', 'value': item });
      const newItem = {
        label:
          currencies[item].symbol +
          "- (" +
          currencies[item].name_plural +
          ") (" +
          currencies[item].code +
          ")",
        value: item,
      };

      setCurrencyList((prevItems) => [...prevItems, newItem]);
    });
  };
  const getUserData = () => {
    fetch(`${ApiUrl}` + "user/form/data", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setNumber(response.data.phones);
          setSocialLinks(response.data.social_links);
          setEmailSignature(response.data.email_signature);
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const breadcrumbItems = [{ title: "Profile", link: "#" }];
  const maintimezones = [
    { id: "Asia", label: "Asia" },
    { id: "America", label: "America" },
    { id: "Australia", label: "Australia" },
    { id: "Europe", label: "Europe" },
    { id: "Indian", label: "Indian" },
  ];

  function updateUserPhoneSetting(event, values) {
    fetch(`${ApiUrl}` + "update/user/phone/setting", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          let newtoken = {
            direct_number: values.direct_number,
            inbound_call_option: values.inbound_call_option,
            outbound_phone: values.outbound_phone,
            forward_number: values.forward_number,
          };
          const authUser = {
            ...JSON.parse(localStorage.getItem("authUser")),
            ...newtoken,
          };
          localStorage.setItem("authUser", JSON.stringify(authUser));

          setUser(authUser);
          swal({
            text: "Updated successfully.",
            icon: "success",
          });
          //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function handleValidSubmitpassword(event, values) {
    setLoaded(false);
    fetch(`${ApiUrl}` + "change/password", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoaded(true);
        if (data.status === true) {
          swal({
            text: "Updated successfully.",
            icon: "success",
          });
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function handleValidSubmit(event, values) {
    fetch(`${ApiUrl}` + "update/profile", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          console.log(data);
          let newtoken = {
            first_name: data.data.first_name,
            last_name: data.data.last_name,
            email: data.data.email,
            time_zone: data.data.time_zone,
            zip: data.data.zip,
            phone: data.data.phone,
            state: data.data.state,
            address: data.data.address,
            city: data.data.city,
            company_name: data.data.company_name,
            country_code: data.data.country_code,
          };
          const authUser = {
            ...JSON.parse(localStorage.getItem("authUser")),
            ...newtoken,
          };
          localStorage.setItem("authUser", JSON.stringify(authUser));

          setUser(authUser);
          //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          swal({
            text: "Updated successfully.",
            icon: "success",
          });
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function updateUserFromEmailSetting(values, formRef, setEmailLoader) {
    values.setting = "email";
    values.name = user.first_name + " " + user.last_name;
    values.email = user.email;
    fetch(`${ApiUrl}` + "get/from/sms", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          var msg = "Action performed successfully";
          let wrapper = document.createElement("div");
          wrapper.innerHTML = msg;
          swal({
            title: "Success!",
            content: wrapper,
            icon: "success",
          });
          // Reset form
          formRef.current && formRef.current.reset();
          setEmailLoader(true);
        } else {
          setEmailLoader(true);
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        setEmailLoader(true);
        console.error("Error:", error);
      });
  }

  // function updateUserFromEmailSetting(e, values) {
  //   values.setting = 'email';
  //   values.name = user.first_name + " " + user.last_name;
  //   values.email = user.email;
  //   fetch(`${ApiUrl}` + "get/from/sms", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ` + uToken(),
  //     },
  //     body: JSON.stringify(values),
  //   })
  //     .then((response) => response.json())
  //     //Then with the data from the response in JSON...
  //     .then((data) => {
  //       if (data.status === true) {

  //         var msg = 'Action performed successfully';
  //         // if(data.data.is_from_email_updated  === true){
  //         //   setShowCode(true);
  //         //   var msg = 'An activation link has been sent to your email. Please first open the link received in your email and then click the refresh button below to check your sender ID status.';
  //         // }
  //         let wrapper = document.createElement('div');
  //           wrapper.innerHTML = msg;
  //           swal({
  //             title: "Success!",
  //             content: wrapper,
  //             icon: "success"
  //           });
  //           console.log(e.target, 'e.target')
  //           //e.target.reset();
  //           e.target.reset();
  //           // Clear values
  //           values.name = null
  //           setEmailLoader(true);
  //           // formRef.current && formRef.current.resetForm();

  //       } else {
  //         setEmailLoader(true);
  //         toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
  //       }
  //     })
  //     //Then with the error genereted...
  //     .catch((error) => {
  //       setEmailLoader(true);
  //       console.error("Error:", error);
  //     });
  // }

  const verifyCode = (event, values) => {
    setLoaded(false);

    fetch(`${ApiUrl}` + "verify/fromemail/link", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          let newtoken = {
            from_email_status: data.data.from_email_status,
          };
          const authUser = {
            ...JSON.parse(localStorage.getItem("authUser")),
            ...newtoken,
          };
          localStorage.setItem("authUser", JSON.stringify(authUser));
          setUser(authUser);
          setShowCode(false);
          setLoaded(true);
          swal({
            text: data.message,
            icon: "success",
          });
        } else {
          setLoaded(true);
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        setLoaded(true);
        console.error("Error:", error);
      });
  };
  function updateUserSocialLinks(event, values) {
    fetch(`${ApiUrl}` + "update/social/links", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          let newtoken = {
            linkedin_link: data.data.linkedin_link,
            facebook_link: data.data.facebook_link,
            twitter_link: data.data.twitter_link,
            google_link: data.data.google_link,
            instagram_link: data.data.instagram_link,
            other_link: data.data.other_link,
          };

          setSocialLinks(newtoken);
          swal({
            text: "Updated successfully.",
            icon: "success",
          });
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  function resetSetting(event, values) {
    swal({
      title: "Are you sure?",
      text: "You want to reset your verified sender identity. By performing this action, you will not be able to send an email from your account.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmation) => {
      if (confirmation) {
        fetch(`${ApiUrl}` + "reset/fromemail/setting", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
          body: JSON.stringify(values),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status === true) {
              let newtoken = {
                from_email_status: data.data.from_email_status,
              };
              const authUser = {
                ...JSON.parse(localStorage.getItem("authUser")),
                ...newtoken,
              };
              localStorage.setItem("authUser", JSON.stringify(authUser));
              setUser(authUser);
              var msg = "Action performed successfully";
              setShowCode(false);
              let wrapper = document.createElement("div");
              wrapper.innerHTML = msg;
              swal({
                title: "Success!",
                content: wrapper,
                icon: "success",
              });
            } else {
              toast(data.message, {
                hideProgressBar: true,
                position: "top-center",
                transition: Flip,
                className: "toast-custom-cls error",
              });
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    });
  }
  const userProfileImage = (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }

    fetch(`${ApiUrl}` + "update/profile/image", {
      method: "POST",
      headers: {
        // Accept: "application/json",
        // "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          let newtoken = {
            image: "profile.png",
          };
          const authUser = {
            ...JSON.parse(localStorage.getItem("authUser")),
            ...newtoken,
          };
          localStorage.setItem("authUser", JSON.stringify(authUser));

          setUser(authUser);
          //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          swal({
            text: "Updated successfully.",
            icon: "success",
          });
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const changeHandler = (e) => {
    if (e.target.files[0].type.match("image.*")) {
      const preview = document.querySelector("#preview");
      var reader = new FileReader();
      reader.onload = () => {
        preview.src = reader.result;
      };
      reader.readAsDataURL(e.target.files[0]);
      const fileChosen = document.getElementById("file-chosen");

      fileChosen.textContent = e.target.files[0].name;
    } else {
      toast("Olny image allowed", {
        hideProgressBar: true,
        position: "top-center",
        transition: Flip,
        className: "toast-custom-cls error",
      });
    }
  };

  const handleEmailSignature = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }

    fetch(`${ApiUrl}` + "update/email/signature", {
      method: "POST",
      headers: {
        // Accept: "application/json",
        // "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          swal({
            text: data.message,
            icon: "success",
          });
        } else {
          swal({
            text: data.message,
            icon: "warning",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <React.Fragment>
      <Row>
        {uInfo().type == "Client" && (
          <Col lg="12">
            <button
              onClick={() => props.history.goBack()}
              className="btn btn-secondary pull-right mb-3"
            >
              Back
            </button>
          </Col>
        )}
        {uInfo().type != "Client" && (
          <Col lg="2" className="pl-0">
            <ProfileSettings menuOpen={false} props={props} />
          </Col>
        )}
        <Col lg={uInfo().type == "Client" ? "12" : "10"}>
          <Row>
            <Helmet>
              <title> Profile </title>
            </Helmet>
            {/* <Breadcrumb title={"Profile"} breadcrumbItems={breadcrumbItems} /> */}
            {/* <Col lg="3">
              <ToastContainer />
              <Card>
                <CardBody>
                  <Media>
                    <Media body className="align-self-center mx-3">
                      <div className="text-center">
                        <div className="mb-3">
                          <Avatar name={user.name} size="100" round={true} />
                        </div>
                        <h5> {user.name} </h5>
                        <p className="mb-1"> {user.email} </p>
                        <p className="mb-0"> Id no: #{user.user_id} </p>
                      </div>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col> */}
            <Loader loaded={loaded}></Loader>
            <Col lg="12">
              <Card className="mb-2 ">
                <CardBody className="p-2">
                  <Nav tabs className="pt-0 profile-tabs">
                    <NavItem>
                      <NavLink
                        className={tab == 1 ? "active" : ""}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Edit Profile
                      </NavLink>
                    </NavItem>
                  
                    {hasRole(uInfo(), ["personal_phone_settings"]) && (uInfo().type == "Coach" ||
                      uInfo().type == "CoachPartner") && (
                      <NavItem>
                        <NavLink
                          className={tab == 3 ? "active" : ""}
                          onClick={() => {
                            toggle("3");
                          }}
                        >
                          Personal Phone Settings
                        </NavLink>
                      </NavItem>
                    )}
                    {hasRole(uInfo(), ["email_settings"]) && (uInfo().type == "Coach" ||
                      uInfo().type == "CoachPartner") && (
                        <NavItem>
                          <NavLink
                            className={tab == 4 ? "active" : ""}
                            onClick={() => {
                              toggle("4");
                            }}
                          >
                            Email Settings
                          </NavLink>
                        </NavItem>
                      )}

                    {hasRole(uInfo(), ["social_links"]) && uInfo().type == "Coach" && (
                      <NavItem>
                        <NavLink
                          className={tab == 6 ? "active" : ""}
                          onClick={() => {
                            toggle("6");
                          }}
                        >
                          Social Links
                        </NavLink>
                      </NavItem>
                    )}
                    {hasRole(uInfo(), ["email_signature"]) && uInfo().type == "Coach" && (
                      <NavItem>
                        <NavLink
                          className={tab == 7 ? "active" : ""}
                          onClick={() => {
                            toggle("7");
                          }}
                        >
                          Email Signature
                        </NavLink>
                      </NavItem>
                    )}
                    {hasRole(uInfo(), ["coach_photo"]) && uInfo().type == "Coach" && (
                      <NavItem>
                        <NavLink
                          className={tab == 5 ? "active" : ""}
                          onClick={() => {
                            toggle("5");
                          }}
                        >
                          Coach Photo
                        </NavLink>
                      </NavItem>
                    )}
                    {hasRole(uInfo(), ["change_password"]) && (
                    <NavItem>
                      <NavLink
                        className={tab == 2 ? "active" : ""}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        Change Password
                      </NavLink>
                    </NavItem>
                  )}
                  </Nav>
                </CardBody>
              </Card>
              <TabContent activeTab={tab}>
                <TabPane tabId="1">
                  <Row>
                    <Col className="col-12">
                      <Card>
                        {/* <h4 className="card-title mb-4 mt-3 ml-3">
                          Change User Name
                        </h4> */}
                        <CardBody>
                          <AvForm
                            className="form-horizontal"
                            autoComplete="off"
                            onValidSubmit={(e, v) => {
                              handleValidSubmit(e, v);
                            }}
                          >
                            <Row>
                              <Col md={6}>
                                <div className="form-group mb-4">
                                  <AvField
                                    name="first_name"
                                    label="First Name"
                                    value={user.first_name}
                                    className="form-control"
                                    autocomplete="new-off"
                                    placeholder="Enter first name"
                                    type="text"
                                    required
                                  />
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="form-group mb-4">
                                  <AvField
                                    name="last_name"
                                    label="Last Name"
                                    value={user.last_name}
                                    className="form-control"
                                    autocomplete="new-off"
                                    placeholder="Enter last name"
                                    type="text"
                                    required
                                  />
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="form-group mb-4">
                                  <AvField
                                    name="email"
                                    label="Email"
                                    value={user.email}
                                    className="form-control"
                                    autocomplete="new-off"
                                    placeholder="Enter email"
                                    type="text"
                                    required
                                  />
                                </div>
                              </Col>

                              <Col md={6}>
                                <AvGroup>
                                  <label> MainTime Zone </label>
                                  <div className="TimeZoneMain MainTime">
                                    <Select
                                      value={maintimezone}
                                      getOptionLabel={(option) => option.label}
                                      getOptionValue={(option) => option.id}
                                      isMulti={false}
                                      options={maintimezones}
                                      classNamePrefix="select2-selection"
                                      className={`${
                                        maintimezone != ""
                                          ? "is-touched is-dirty av-valid"
                                          : "is-touched is-pristine av-invalid is-invalid"
                                      }`}
                                      autocomplete="new-off"
                                      name="time_zone"
                                      onChange={(e) => {
                                        settimezone(e);
                                      }}
                                    />
                                  </div>
                                  <AvInput
                                    type="hidden"
                                    required
                                    name="time_zone_1"
                                    value={maintimezone.id}
                                  />
                                  <AvFeedback> This is required * </AvFeedback>
                                </AvGroup>
                              </Col>
                              <Col md={6}>
                                <AvGroup>
                                  <label> Time Zone </label>
                                  <div className="TimeZoneMain">
                                    <Select
                                      value={timezone}
                                      getOptionLabel={(option) => option.label}
                                      getOptionValue={(option) => option.id}
                                      isMulti={false}
                                      options={timeZone_data}
                                      classNamePrefix="select2-selection"
                                      className={`${
                                        timezone != ""
                                          ? "is-touched is-dirty av-valid"
                                          : "is-touched is-pristine av-invalid is-invalid"
                                      }`}
                                      autocomplete="new-off"
                                      name="time_zone1"
                                      onChange={(e) => {
                                        setTimeZone(e);
                                      }}
                                    />
                                  </div>
                                  <AvInput
                                    type="hidden"
                                    required
                                    name="time_zone"
                                    value={timezone.id}
                                  />
                                  <AvFeedback> This is required * </AvFeedback>
                                </AvGroup>
                              </Col>

                              <Col md={6}>
                                <label className="control-label">Country</label>
                                <div className="TimeZoneMain">
                                  <Select
                                    getOptionLabel={(option) =>
                                      option.label + " (" + option.code + ")"
                                    }
                                    getOptionValue={(option) => option.code}
                                    value={countries.filter(
                                      ({ code }) => code === user.country_code
                                    )}
                                    isMulti={false}
                                    options={countries}
                                    classNamePrefix="select2-selection"
                                    autocomplete="new-off"
                                    name="country_id"
                                    onChange={(e) => {
                                      setUser((user) => ({
                                        ...user,
                                        country_code: e.code,
                                      }));
                                    }}
                                  />
                                </div>
                                <AvField
                                  type="hidden"
                                  name="country_code"
                                  value={user.country_code}
                                />
                              </Col>

                              <Col md={6} className="mt-3">
                                <div className="form-group">
                                  <AvField
                                    name="city"
                                    label="City"
                                    value={user.city}
                                    className="form-control"
                                    autocomplete="new-off"
                                    placeholder="Enter city"
                                    type="text"
                                  />
                                </div>
                              </Col>

                              <Col md={6} className="mt-3">
                                <div className="form-group">
                                  <AvField
                                    name="state"
                                    label="State"
                                    value={user.state}
                                    className="form-control"
                                    autocomplete="new-off"
                                    placeholder="Enter state"
                                    type="text"
                                  />
                                </div>
                              </Col>

                              <Col md={6} className="mt-3">
                                <div className="form-group">
                                  <AvField
                                    name="zip"
                                    label="Zip"
                                    value={user.zip}
                                    className="form-control"
                                    autocomplete="new-off"
                                    placeholder="Enter zip"
                                    type="text"
                                    // validate={{
                                    //   pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                    // }}
                                  />
                                </div>
                              </Col>

                              <Col md={6} className="mt-3">
                                <div className="form-group">
                                  <AvField
                                    name="company_name"
                                    label="Company Name"
                                    value={user.company_name}
                                    className="form-control"
                                    autocomplete="new-off"
                                    placeholder="Enter company name"
                                    type="text"
                                  />
                                </div>
                              </Col>

                              <Col md={6} className="mt-3">
                                <AvField
                                  name="phone"
                                  label="Phone number"
                                  className="form-control"
                                  autocomplete="off"
                                  type="text"
                                  placeholder="Enter phone number"
                                  required
                                  value={user.phone}
                                />
                              </Col>
                              {/* <Col md={6} className="mt-3">
                                <label className="control-label">Currency</label>
                                <div className="TimeZoneMain">
                                  <Select
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.value}
                                    value={currencyList.filter(
                                      ({ value }) => value === user.currency_code
                                    )}
                                    isMulti={false}
                                    options={currencyList}
                                    classNamePrefix="select2-selection"
                                    autocomplete="new-off"
                                    name="currency_code"
                                    onChange={(e) => {
                                      setUser(user => ({
                                        ...user,
                                        currency_code: e.value
                                      }))
                                    }}
                                  />
                                </div>
                                <AvField
                                  type="hidden"
                                  name="currency_code"
                                  value={user.currency_code}
                                />
                              </Col> */}

                              <Col md={12} className="mt-3">
                                <div className="form-group mb-4">
                                  <AvField
                                    name="address"
                                    label="Address"
                                    value={user.address}
                                    autocomplete="new-off"
                                    className="form-control"
                                    placeholder="Enter Address"
                                    type="textarea"
                                  />
                                </div>
                              </Col>

                              <Col md={12} className="mt-2">
                                <Button type="submit" className="Green btn-sm">
                                  Update
                                </Button>
                              </Col>
                            </Row>
                          </AvForm>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={"2"}>
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <CardBody>
                          <AvForm
                            className="form-horizontal"
                            autoComplete="off"
                            onValidSubmit={(e, v) => {
                              handleValidSubmitpassword(e, v);
                            }}
                          >
                            <div className="form-group mb-3">
                              <AvField
                                name="current_password"
                                label="Old password"
                                className="form-control"
                                placeholder="Enter old password"
                                type="password"
                                required
                              />
                            </div>
                            <div className="form-group mb-3">
                              <AvField
                                name="new_password"
                                label="New password"
                                className="form-control"
                                placeholder="Enter new password"
                                type="password"
                                required
                              />
                            </div>
                            <div className="form-group mb-3">
                              <AvField
                                name="new_confirm_password"
                                label="Confirm password"
                                className="form-control"
                                placeholder="Confirm password"
                                type="password"
                                required
                              />
                            </div>
                            <div className="mt-4">
                              <Button
                                disabled={loaded ? false : true}
                                type="submit"
                                className="Green btn-sm"
                              >
                                Update password
                              </Button>
                            </div>
                          </AvForm>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={"3"}>
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <CardBody>
                          <AvForm
                            className="form-horizontal"
                            autoComplete="off"
                            onValidSubmit={(e, v) => {
                              updateUserPhoneSetting(e, v);
                            }}
                          >
                            <div className="row">
                              <div className="col-md-6 form-group mb-3">
                                <AvField
                                  name="direct_number"
                                  label="Direct number"
                                  className="form-select"
                                  type="select"
                                  value={user.direct_number}
                                >
                                  <option value="">Select Number</option>
                                  {numbers.map((row, i) => {
                                    return (
                                      (row.is_locked == "No" ||
                                        row.phone_number ==
                                          user.direct_number) && (
                                        <option value={row.phone_number}>
                                          {row.phone_number}
                                        </option>
                                      )
                                    );
                                  })}
                                </AvField>
                              </div>
                              <div className="col-md-6 form-group mb-3">
                                <AvField
                                  name="outbound_phone"
                                  label="Default outbound phone"
                                  className="form-select"
                                  type="select"
                                  value={user.outbound_phone}
                                >
                                  <option value="">Select Number</option>
                                  {numbers.map((row, i) => {
                                    return (
                                      <option value={row.phone_number}>
                                        {row.phone_number}
                                      </option>
                                    );
                                  })}
                                </AvField>
                              </div>
                              <div className="col-md-6 form-group mb-3">
                                <AvField
                                  name="inbound_call_option"
                                  label="Inbound call settings"
                                  className="form-select"
                                  placeholder="Enter Direct Number"
                                  type="select"
                                  value={user.inbound_call_option}
                                >
                                  <option value="0">None</option>
                                  <option value="1" selected="selected">
                                    Ring browser only
                                  </option>
                                  <option value="2">Ring Phone only</option>
                                  {/* <option value="5">Ring Phone only (Whisper)</option> */}
                                  <option value="3">
                                    Ring browser and forward calls
                                    simultaneously ** Voicemail disable
                                  </option>
                                  {/* <option value="6">Ring browser and forward calls simultaneously (Whisper) ** Voicemail disable</option> */}
                                </AvField>
                              </div>

                              <div className="col-md-6 form-group mb-3">
                                <AvField
                                  name="forward_number"
                                  label="Forward number"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter forward number"
                                  value={user.forward_number}
                                />
                              </div>
                            </div>

                            <div className="mt-4">
                              <Button type="submit" className="Green btn-sm">
                                Update Setting
                              </Button>
                            </div>
                          </AvForm>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={"4"}>
                  <EmailSettingsList />

                  {/* <Row>
                    <Col className="col-12">
                      <Card>
                        <CardBody>
                          <div className="mb-3">

                          </div>

                          <AvForm
                            className="form-horizontal needs-validation"
                            ref={formRef}
                            onValidSubmit={(e, v) => {
                              setEmailLoader(false);
                              updateUserFromEmailSetting(v, formRef, setEmailLoader);
                            }}

                          >
                            <div className="row">
                              <div className="col-md-4 form-group mb-3">
                                <AvField
                                  name="from_name"
                                  label="From Name"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter from name"
                                  required
                                  value=""
                                />
                              </div>

                              <div className="col-md-4 form-group mb-3">
                                <AvField
                                  name="from_email"
                                  label="From Email"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter from email"
                                  required
                                  value=""
                                />
                              </div>


                              <div className="col-md-4 mt-4">
                                <Button type="submit" className="btn btn-primary">Add New</Button>

                              </div>
                            </div>
                          </AvForm>
                          {emailLoader && (
                            <FromEmailTabel />
                          )}

                        </CardBody>
                      </Card>
                    </Col>
                  </Row> */}
                </TabPane>

                <TabPane tabId={"5"}>
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <CardBody>
                          <form
                            onSubmit={userProfileImage}
                            method="post"
                            encType="multipart/form-data"
                            className="form-horizontal"
                            id="selection-myform"
                          >
                            <div className="row">
                              <div className="col-md-3">
                                <div className="m-1 ImagePreview">
                                  <img
                                    id="preview"
                                    src={path}
                                    height="200"
                                    alt="Image preview"
                                  />
                                </div>
                              </div>
                              <div className="col-md-9">
                                <div className="mb-3 HeadshotImg">
                                  <label htmlFor="actual-btn">
                                    <span>Upload Headshot</span>
                                  </label>
                                  <span className="pl-5" id="file-chosen">
                                    No file chosen
                                  </span>
                                  <input
                                    name="image"
                                    label="Image"
                                    accept="image/*"
                                    className="form-control"
                                    type="file"
                                    id="actual-btn"
                                    onChange={(e) => changeHandler(e)}
                                    hidden
                                    required
                                  />
                                </div>
                                <button
                                  className="btn btn-primary waves-effect waves-light btn-block btn-sm"
                                  type="submit"
                                >
                                  Update Image
                                </button>
                              </div>
                            </div>
                          </form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={"6"}>
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <CardBody>
                          <AvForm
                            className="form-horizontal"
                            autoComplete="off"
                            onValidSubmit={(e, v) => {
                              updateUserSocialLinks(e, v);
                            }}
                          >
                            <div className="row">
                              <div className="col-md-6 form-group mb-3">
                                <AvField
                                  name="linkedin_link"
                                  label="LinkedIn"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter LinkedIn profile link"
                                  value={social_links.linkedin_link}
                                />
                              </div>

                              <div className="col-md-6 form-group mb-3">
                                <AvField
                                  name="facebook_link"
                                  label="Facebook"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Facebook Page Link"
                                  value={social_links.facebook_link}
                                />
                              </div>

                              <div className="col-md-6 form-group mb-3">
                                <AvField
                                  name="twitter_link"
                                  label="Twitter"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Twitter Profile Link"
                                  value={social_links.twitter_link}
                                />
                              </div>
                              <div className="col-md-6 form-group mb-3">
                                <AvField
                                  name="instagram_link"
                                  label="Instagram"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Instagram Profile Link"
                                  value={social_links.instagram_link}
                                />
                              </div>
                              <div className="col-md-6 form-group mb-3">
                                <AvField
                                  name="google_link"
                                  label="Google My Business Page"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Google Business Profile"
                                  value={social_links.google_link}
                                />
                              </div>
                              <div className="col-md-6 form-group mb-3">
                                <AvField
                                  name="other_link"
                                  label="Other Page"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Other Page Link"
                                  value={social_links.other_link}
                                />
                              </div>
                            </div>

                            <div className="mt-4">
                              <Button type="submit" className="Green btn-sm">
                                Update Setting
                              </Button>
                            </div>
                          </AvForm>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={"7"}>
                  <Row>
                    <Col className="col-12">
                      {emailSignature && (
                        <div>
                          <div className="from-user-profile">
                            <form id="my-form" onSubmit={handleEmailSignature}>
                              <Froala
                                insideForm={true}
                                btnId={"submit-signature-content"}
                                content={emailSignature}
                                handleSubmit={() => console.log("hi")}
                                use_token={"users"}
                              />

                              <div className="mt-4">
                                <Button type="submit" className="Green btn-sm">
                                  Update Setting
                                </Button>
                              </div>
                            </form>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserProfile;

// {user.from_email_status == "1" ? (
//   <Button type="button" color="danger"
//     onClick={() => resetSetting()}
//   >
//     Reset
//   </Button>
// ):(
//   <Button type="submit" color="danger">
//     Verify From Email Setting
//   </Button>
// )}
