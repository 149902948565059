import React, { Component, Fragment, useState, useEffect, useRef } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { render } from "react-dom";
import EmailEditor from "react-email-editor";
import { useParams } from "react-router-dom";
import Select from "react-select";
import {
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../../useToken";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { ApiUrl, ProductName, AttachementsUrl, ToastAutoClose } from "../../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Froala from "../../../Froala";
import Loader from "react-loader";

const EmailTemplatescreate = (props) => {
  const [id] = useState(props.match.params ? props.match.params.id : 0);
  const emailEditorRef = useRef(null);
  const [permission, setpermission] = useState("");
  const [selectedpermission, setselectedpermission] = useState("");
  const [loading, setLoading] = useState(false);

  const [email_body, setEmailBody] = useState("");
  const [data, setDate] = useState({
    subject: "",
    body: "",
  });

  useEffect(() => {
    getData();
    getcoachdata();
    setTimeout(() => { }, 2000);
  }, []);
  const [permissions, setpermissions] = useState("");
  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      console.log("exportHtml", html);
    });
  };
  const handleEmailBody = async (event, values) => {
    setEmailBody(values);
  }
  const handleSubmit = async (event, values) => {
      console.log(email_body, 'email_body')
      event.preventDefault();
      values.email_body = email_body;
      fetch(`${ApiUrl}` + "edit/emailtemplate", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify(values),
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          props.history.push("/emailtemplates/list");
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  const getcoachdata = async () => {
    fetch(`${ApiUrl}` + "get/coachdata", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setpermissions(response.data.coachpartners);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handlePermission = (e) => {
    var filter = [];
    if (e) {
      e.map((fil) => {
        filter.push(fil.user_id);
      });
    }
    setpermission(filter.toString());
    setselectedpermission(e);
  };

  const getData = () => {
    fetch(`${ApiUrl}` + "get/DefaultEmailTemplates", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: id}),
    })
      .then((response) => response.json())
      .then((response) => {
          if (response.status === true) {
            setDate(response.data.record);
            if(response.data.email_body !=''){
              setEmailBody(response.data.email_body);
            }
            setLoading(true);
           }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }


  console.log(props, 'propsprops')
  return (
    <div>
      <div>
        {/* <button className="btn btn-primary btn-md" onClick={saveDesign}>
                      Save Design
                    </button> */}
        {/* <button className="btn btn-primary btn-md ml-5" onClick={exportHtml}>
                     
                      Export HTML
                    </button> */}
        <div className="page-title-box text-align-right">
          <button
            className="btn btn-primary btn-sm"
            onClick={() => props.history.push("/emailtemplates/list")}
          >
            Back
          </button>
        </div>
      </div>
      <Loader loaded={loading}></Loader>
      { loading && (
        <Row>
          <Col className="col-12">
            <div>
              <AvForm
                onValidSubmit={handleSubmit}
                className="needs-validation"
                enctype="multipart/form-data"
              >
                  <div className="mb-3">
                    <AvField
                      name="title"
                      value={data.subject}
                      label="Title/Subject"
                      className="form-control"
                      required
                    />
                  </div>
                  {/* <div className="mb-3">
                    <EmailEditor
                      ref={emailEditorRef}
                    />
                  </div> */}
                  <div className="mb-3">
                    <Froala btnId={'add-email-content'} content={email_body}  handleSubmit={handleEmailBody} use_token={'users'} />
                  </div>
                  <div className="col-md-12 mb-3 d-none">
                    <label className="control-label"> Assign to </label>
                    <Select
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.user_id}
                      isMulti={true}
                      value={selectedpermission}
                      options={permissions}
                      classNamePrefix="select form-control"
                      style={{ position: "absolute" }}
                      className={"is-touched is-dirty av-valid"}
                      onChange={(e) => {
                        handlePermission(e);
                      }}
                    />
                    <input type="hidden" name="permission" value={permission} />
                  </div>
                <ModalFooter>
                  <Button
                    className="btn-sm"
                    type="button"
                    color="secondary"
                    onClick={() => props.history.goBack()}
                  >
                    Cancel
                  </Button>
                  <button
                    className="btn btn-primary waves-effect waves-light btn-sm"
                    type="submit"
                    id="add-email-content"
                  >
                    Submit
                  </button>
                </ModalFooter> 
                </AvForm>
                {/* </form> */}
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default EmailTemplatescreate;
