import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Button, Modal, ModalHeader, Input, ModalBody, ModalFooter } from "reactstrap"
import Accordion from 'react-bootstrap/Accordion';
import Helmet from "react-helmet"
import axios from "axios"
import {AvForm, AvField, AvInput, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox, AvGroup, } from "availity-reactstrap-validation"
import { uToken, dcrypt, uInfo } from "../../useToken";
import { getCoachPages, getStaffPages, getCoachPartnerPages } from "../../components/Common/PagesLibrary";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
export default class PermissionCreateUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      record: {
        pages: [],
        type: uInfo().type == 'Coach'  ? 'partner' : 'coach',
        status: 'Active',
      },
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      permission: '',
      page_name: "Add Permission",
      coach_pages_array: uInfo().type == 'Coach' ? getCoachPartnerPages() : getCoachPages(),
      isCheckAll: {},
      isCheck: {},
      isAllSelected: "", 
    }
  }
  componentDidMount() {
    this.state.id != null && this.getData();
  }
  getData = (queryString = "") => {
    fetch(`${ApiUrl}` + 'getEdit/permission/library', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + uToken()
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        //console.log(data, 'data')
        if (data.status === true) {
          this.setState({
            coach_pages_array: data.data.record.type == 'coach' ? getCoachPages() : data.data.record.type == 'partner' ? getCoachPartnerPages() : getStaffPages()
          })
          this.setState({
            record: data.data.record
          })
          //console.log(data.data.record.pages, 'redccc') 
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
      });
  }
  handleMulti = (selectedMulti) => {
    var filter = [];
    if (selectedMulti) {
      selectedMulti.map(fil => {
        filter.push(fil.value);
      })
    }
    this.setState({
      selectedMulti: selectedMulti,
      pages: filter.toString()
    })
  }
  handlePermissionMulti = (selectedPermissionMulti) => {
    var filter = [];
    if (selectedPermissionMulti) {
      selectedPermissionMulti.map(fil => {
        filter.push(fil.value);
      })
    }
    this.setState({
      selectedPermissionMulti: selectedPermissionMulti,
      permission: filter.toString()
    })
  }
  handleSubmit = async (event, values) => {
    if (this.state.record) {
      values.id = this.state.record.id
    }
    fetch(`${ApiUrl}` + 'edit/permission/library', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + uToken()
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          this.props.history.goBack();
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
      });
  }
  handleTopSelectAll = (checked) => {
    const { coach_pages_array } = this.state;
    const allKeys = new Set();
    coach_pages_array.forEach((tab) => {
      if (tab.key) allKeys.add(tab.key);
      tab.sub_tabs?.forEach((subTab) => {
        if (subTab.key) allKeys.add(subTab.key);
        subTab.sub_items?.forEach((item) => {
          if (item.key) allKeys.add(item.key);
        });
      });
    });

    this.setState((prevState) => {
      const updatedPages = new Set(checked ? Array.from(allKeys) : []);
      return {
        isAllSelected: checked,
        isCheckAll: {},
        record: {
          ...prevState.record,
          pages: Array.from(updatedPages),
        },
      };
    });
  };
  handleSelectAll = (checked, sub_tabs = [], key) => {
    if (!Array.isArray(sub_tabs) || sub_tabs.length === 0) {
      return;
    }
  
    this.setState((prevState) => {
      const newIsCheckAll = {
        ...prevState.isCheckAll,
        [key]: checked,
      };
  
      let updatedRecordPages = [...prevState.record.pages];
      const updatedIsCheck = { ...prevState.isCheck };
  
      if (checked) {
        // Add selected pages from sub_tabs and their sub_items
        sub_tabs.forEach((sub_tab) => {
          if (sub_tab.key) {
            updatedRecordPages.push(sub_tab.key);
            updatedIsCheck[sub_tab.key] = true;
          }
  
          if (sub_tab.sub_items && Array.isArray(sub_tab.sub_items)) {
            sub_tab.sub_items.forEach((item) => {
              if (item.key) {
                updatedRecordPages.push(item.key);
                updatedIsCheck[item.key] = true;
              }
            });
          }
        });
      } else {
        // Remove pages from sub_tabs and their sub_items
        sub_tabs.forEach((sub_tab) => {
          if (sub_tab.key) {
            updatedRecordPages = updatedRecordPages.filter((page) => page !== sub_tab.key);
            updatedIsCheck[sub_tab.key] = false;
          }
  
          if (sub_tab.sub_items && Array.isArray(sub_tab.sub_items)) {
            sub_tab.sub_items.forEach((item) => {
              if (item.key) {
                updatedRecordPages = updatedRecordPages.filter((page) => page !== item.key);
                updatedIsCheck[item.key] = false;
              }
            });
          }
        });
      }
  
      // Ensure no duplicates in the updated pages
      updatedRecordPages = [...new Set(updatedRecordPages)];
  
      return {
        isCheckAll: newIsCheckAll,
        record: {
          ...prevState.record,
          pages: updatedRecordPages,
        },
        isCheck: updatedIsCheck,
      };
    });
  };
  handleSelectAllSubItems = (checked, sub_items, sub_tab_key) => {
    this.setState((prevState) => ({
      isCheckAll: {
        ...prevState.isCheckAll,
        [sub_tab_key]: checked,
      },
    }));
  
    if (checked) {
      const selectedPages = sub_items.map((item) => item.key);
      this.setState((prevState) => ({
        record: {
          ...prevState.record,
          pages: [...new Set([...prevState.record.pages, ...selectedPages])],
        },
      }));
    } else {
      this.setState((prevState) => ({
        record: {
          ...prevState.record,
          pages: prevState.record.pages.filter(
            (page) => !sub_items.map((item) => item.key).includes(page)
          ),
        },
      }));
    }
  };
  handleSubClick = (e, key) => {
    if (!this.state.isCheckAll[key]) {
      this.setState(prevState => ({
        isCheckAll: {                   // object that we want to update
          ...prevState.isCheckAll,    // keep all other key-value pairs
          [key]: !this.state.isCheckAll[key]    // update the value of specific key
        }
      }))
    }
  }

  
  
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.page_name} Tables </title>
        </Helmet>
        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.id == null ? "Add Permission Library" : "Edit Permission Library"}</h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">{ProductName}</li>
                <li key={1} className="breadcrumb-item"><Link to="#">{this.state.page_nam}</Link></li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button type="button" color="secondary" className="btn-sm" onClick={() => this.props.history.goBack()}>Back</Button>
            </div>
          </Col>
        </Row>
        <ToastContainer />
        <Row>
          <Col className="col-12">
            <Card className="package-add">
              <AvForm onValidSubmit={this.handleSubmit} className="needs-validation">
                <ModalBody className="pb-0">
                  <div className="row">
                  <div className="mb-3 col-lg-4">
                  <AvField
                    name="name"
                    label="Name"
                    value={this.state.record.name}
                    className="form-control"
                    placeholder="Enter Name"
                    required
                    readOnly={this.state.record.user_id && this.state.record.user_id !== uInfo().user_id} // Conditional readOnly
                  />
                  </div>
                  <div className={`mb-3 col-lg-4 ${
                      this.state.record.user_id && this.state.record.user_id !== uInfo().user_id ? 'd-none' : ''
                    }`}
                  >
                    <AvField name="type" label="Role for" value={this.state.record.type} className="form-control" type="select" onChange={(e) => this.setState({
                      coach_pages_array: e.target.value == 'coach' ? getCoachPages() : e.target.value == 'partner' ? getCoachPartnerPages() : getStaffPages()
                    })}
                    >
                    {
                      uInfo().type == 'Coach' ? (
                        <option value={'partner'}>Team</option>
                      ) : (
                        <>
                          <option value={'coach'}>Coach</option>
                          <option value={'staff'}>Team Admin</option>
                        </>
                      )
                    }
                    </AvField>
                  </div>
                  <div
                    className={`mb-3 col-lg-4 ${
                      this.state.record.user_id && this.state.record.user_id !== uInfo().user_id ? 'd-none' : ''
                    }`}
                  >
                    <AvField className="select form-select" type="select" id="s" name="status" label="Status" required value={this.state.record.status}>
                      <option value={'Active'}>Active</option>
                      <option value={'Inactive'}>Inactive</option>
                    </AvField>
                  </div>
                  </div>
                  <div className="mb-3 PermissionPage">
                    <label className="control-label">Pages Permission</label>
                    <div className="mb-3">
                      <AvGroup check className="mb-0">
                        <AvInput
                        checked={""}
                        type="checkbox"
                        name={`master_pages`}
                        onClick={(e) => this.handleTopSelectAll(e.target.checked)}
                        />
                          <Label check htmlFor="selectAll">
                            Select All
                          </Label>
                        </AvGroup>
                    </div>
                    <div className="row">
                      {this.state.coach_pages_array && (
                        this.state.coach_pages_array.map((row, key) => {
                          return (
                            <Accordion key={key}>
                              <Accordion.Item eventKey={key}>
                                <div className="CollapsHead StepOne">
                                <div className="d-flex align-items-center px-1">
                                    <AvGroup check className="mb-0">
                                      <AvInput
                                        checked={
                                          this.state.record.pages.indexOf(row.key) > -1 ||
                                          this.state.isCheckAll[row.key]
                                            ? "checked"
                                            : ""
                                        }
                                        type="checkbox"
                                        name={`master_pages[${row.key}]`}
                                        onClick={(e) =>
                                          this.handleSelectAll(e.target.checked, row.sub_tabs, row.key)
                                        }
                                      />
                                    </AvGroup>
                                    <Label check htmlFor="checkbox" className="mb-0"></Label>
                                  </div>
                                  <Accordion.Header>
                                    <label className="mb-0">{row.label}</label>
                                  </Accordion.Header>
                                  </div>
                                  <Accordion.Body>
                                    <div className="row">
                                      {row.sub_tabs && row.sub_tabs.length > 0 &&
                                        row.sub_tabs.map((sub_row, i) => (
                                          <Accordion key={`${key}-${i}`} className="ps-4 pe-5">
                                            <Accordion.Item eventKey={i}>
                                              <div className="CollapsHead StepTwo">
                                                <div className="d-flex align-items-center">
                                                  <AvGroup check className="mb-0">
                                                  <AvInput
                                                  checked={
                                                  this.state.record.pages.indexOf(sub_row.key) >
                                                  -1
                                                  ? "checked"
                                                  : ""
                                                  }
                                                  type="checkbox"
                                                  name={`sub_pages[${sub_row.key}]`}
                                                  onClick={(e) =>
                                                  this.handleSelectAllSubItems(
                                                  e.target.checked,
                                                  sub_row.sub_items || [],  // Ensure it is always an array
                                                  sub_row.key
                                                  )
                                                  }
                                                  />
                                                  </AvGroup>
                                                  <Label check htmlFor="checkbox" className="mb-0"></Label>
                                                </div>
                                                <Accordion.Header>
                                                  <label className="mb-0">{sub_row.label}</label>
                                                </Accordion.Header>
                                              </div>
                                              {/* <Accordion.Body> */}
                                                <div className="row ps-4">
                                                  {sub_row.sub_items && sub_row.sub_items.length > 0 ? (
                                                    sub_row.sub_items.map((sub_item, j) => (
                                                      <div className="col-md-12 mb-1 ps-3" key={`${i}-${j}`}>
                                                        <AvGroup check>
                                                          <AvInput
                                                          checked={
                                                          this.state.record.pages.indexOf(sub_item.key) >
                                                          -1
                                                          ? "checked"
                                                          : ""
                                                          }
                                                          type="checkbox"
                                                          name={`sub_items[${sub_item.key}]`}
                                                          onClick={(e) =>
                                                          this.handleSubClick(e.target.value, sub_row.key)
                                                          }
                                                          />
                                                          <Label check htmlFor="checkbox">
                                                            {sub_item.label}
                                                          </Label>
                                                        </AvGroup>
                                                      </div>
                                                      ))
                                                    ) : (
                                                      <p className="d-none">No sub-items available.</p>
                                                  )}
                                                </div>
                                                {/* </Accordion.Body> */}
                                              </Accordion.Item>
                                            </Accordion>
                                          ))}
                                        </div>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                );
                              })
                            )}
                            </div>
                          </div>
                        </ModalBody>
                        <ModalFooter className="border-0 py-0">
                          <button className="btn btn-primary waves-effect waves-light btn-sm" type="submit">Save</button>
                        </ModalFooter>
                      </AvForm>
                    </Card>
                  </Col>
                </Row>
              </React.Fragment >
            )
          }
        }

