import React, { Component } from "react"
import { Row, Col, Button, ModalBody, ModalFooter } from "reactstrap"
import { uToken } from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast } from "react-toastify";
import ProfileSettings from "../Authentication/profile-sidebar";
import Loader from "react-loader";
import { Link } from "react-router-dom";
import Froala from "../Froala";
import Select from "react-select"

import 'suneditor/dist/css/suneditor.min.css';
export default class AddCmsBlogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            records: [],
            categories: [],
            tags: [],
            selectedTags: [],
            loader: true,
            page_name: 'Blog listings',
            row: {
                id: '',
                title: '',
                category_id: '',
                image: 0
            }
        }
    }


    componentDidMount() {
        this.fetchAllCategories();
        this.fetchAllTags();
    }

    fetchAllCategories = () => {
        fetch(`${ApiUrl}get/cms/all/categories`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${uToken()}`,
            },
            body: JSON.stringify({ search: '' }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        categories: response.data.categories
                    });
                } else {
                    toast.error(response.message, { hideProgressBar: true, position: "top-center", transition: "flip", className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    fetchAllTags = () => {
        fetch(`${ApiUrl}get/cms/all/tags`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${uToken()}`,
            },
            body: JSON.stringify({ search: '' }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        tags: response.data.tags
                    });
                } else {
                    toast.error(response.message, { hideProgressBar: true, position: "top-center", transition: "flip", className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    handleTagChange = (selectedTags) => {
        this.setState({ selectedTags });
    };

    submitPage = (event) => {
        event.preventDefault();
        const { row, selectedTags } = this.state;
        const formData = new FormData(event.target);
        formData.append('category_id', row.category_id);
        formData.append('tags', JSON.stringify(selectedTags.map(tag => tag.id)));
        fetch(`${ApiUrl}cms/posts`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${uToken()}`
            },
            body: formData
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    this.setState({
                        row: {
                            id: '',
                            title: '',
                            category_id: '',
                            image: 0,
                        },
                        selectedTags: []
                    });
                    this.getData();
                } else {
                    toast.error(data.message, { hideProgressBar: true, position: "top-center", transition: "flip", className: 'toast-custom-cls error' });
                }
                this.setState({ loader: true });
            })
            .catch((error) => {
                this.setState({ loader: true });
                console.error("Error:", error);
            });
    };



    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="2" className="pl-0">
                        <ProfileSettings menuOpen={false} props={this.props} />
                    </Col>
                    <Col lg="10">
                        <Loader loaded={this.state.loader}></Loader>
                        <Row>
                            <Col sm={6} >
                                <div className="page-title-box">
                                    <h4> {this.state.page_name} </h4>
                                    <ol className="breadcrumb m-0" >
                                        <li key={0} className="breadcrumb-item active"> {ProductName} </li>
                                        <li key={1} className="breadcrumb-item"> < Link to="#"> {this.state.page_name} </Link></li>
                                    </ol>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="page-title-box text-align-right">
                                    <Button
                                    className="btn-sm"
                                        type="button"
                                        color="secondary"
                                        onClick={() => this.props.history.goBack()}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </Col>
                        </Row>


                        <form onSubmit={this.submitPage} method="post"
                            encType="multipart/form-data" className="form-horizontal" id="selection-myform-blog">
                            <ModalBody className="pb-0">
                                <Row>
                                    <Col md="12">
                                        <div className="form-group mb-3">
                                            <label>Post Title</label>
                                            <input
                                                name="title"
                                                className="form-control"
                                                placeholder="Enter title"
                                                required
                                            />
                                            <input
                                                name="id"
                                                type='hidden'
                                            />
                                        </div>
                                    </Col>


                                    <Col md="12">
                                        <div className="form-group mb-3">
                                            <label>Category</label>
                                            <Select
                                                getOptionLabel={option => option.name}
                                                options={this.state.categories}
                                                value={this.state.categories.find(cat => cat.id === this.state.row.category_id)}
                                                onChange={(selectedOption) => this.setState({ row: { ...this.state.row, category_id: selectedOption ? selectedOption.id : '', category_name: selectedOption ? selectedOption.name : '' } })}
                                            />
                                        </div>
                                    </Col>


                                    <Col md="12">
                                        <div className="form-group mb-3">
                                            <label>Tags</label>
                                            {/* <input
                                                name="tags"
                                                className="form-control"
                                                placeholder="Enter tags (comma-separated)"
                                            /> */}
                                            <Select
                                                getOptionLabel={option => option.name}
                                                getOptionValue={option => option.id}
                                                isMulti={true}
                                                options={this.state.tags}
                                                value={this.state.selectedTags}
                                                onChange={this.handleTagChange}
                                                classNamePrefix="select2-selection position-relative"
                                                name="tags"
                                            />

                                        </div>
                                    </Col>


                                    <input
                                        name="slug"
                                        className="form-control"
                                        placeholder="Enter slug"
                                        type="hidden"


                                    />

                                    <Col md="12">
                                        <Col md="12">
                                            <div className="form-group mb-3">
                                                <label>Post Description</label>
                                                <textarea
                                                    name="post_description"
                                                    className="form-control"
                                                    placeholder="Enter post description"
                                                    rows={6}
                                                    maxLength={600}
                                                />
                                            </div>
                                        </Col>
                                        <div className="form-group mb-3">
                                            <label>Post Detail</label>
                                            <Froala nameKey={'post_detail'} insideForm={true} btnId={'submit-blog'} handleSubmit={() => console.log('hi')} use_token={'users'}  />


                                        </div>
                                    </Col>

                                    <fieldset>
                                        <Col md="12">
                                            <div className="form-group mb-3">
                                                <label>Meta keywords</label>
                                                <input
                                                    name="meta_keywords"
                                                    className="form-control"
                                                    placeholder="E.g blog, seo, etc"
                                                />
                                            </div>
                                        </Col>


                                        <Col md="12">
                                            <div className="form-group mb-3">
                                                <label>Meta Description</label>
                                                <textarea
                                                    name="meta_description"
                                                    className="form-control"
                                                    placeholder="Enter meta description"
                                                    rows={5}
                                                />
                                            </div>
                                        </Col>
                                    </fieldset>




                                    <Col md="12">
                                        <div className="form-group mb-3">
                                            <label>Image</label>
                                            <input
                                                name="image"
                                                className="form-control"
                                                placeholder="Enter title"
                                                type={'file'}
                                            />

                                        </div>
                                    </Col>
                                    {
                                        <Col md="12">
                                            <div className="form-group mb-3">
                                                <label>Status</label>
                                                <select name="status" className="form-select" >
                                                    <option value={'Publish'}>Publish</option>
                                                    <option value={'Unpublish'}>Unpublish</option>
                                                </select>
                                            </div>
                                        </Col>
                                    }
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button className="btn-sm" color="secondary" type="button" disabled={this.state.loader ? false : true}>Cancel</Button>
                                <Button className="Green btn-sm" type="submit" id="submit-blog" onClick={() => this.props.history.goBack()} disabled={this.state.loader ? false : true}>Submit</Button>
                            </ModalFooter>
                        </form>
                    </Col>
                </Row>

            </React.Fragment >
        )
    }
}