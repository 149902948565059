import React, { useEffect, useState } from "react";
import Loader from "react-loader";
import swal from "sweetalert";
import { ApiUrl, AttachementsUrl } from "../../config";
import { bcrypt, uToken } from "../../useToken";
import WayBookContext from "./WaybookContext";
import Dropdown from "react-bootstrap/Dropdown";
import { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function CategoryLists(props) {
  const params = React.useContext(WayBookContext);
  const { search } = useLocation();
  const url_params = new URLSearchParams(search);
  const is_coach_section = url_params.get("sec") ? "Yes" : "No";
  const [lists, setLists] = useState([]);
  const [list, setList] = useState({});
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    getWayBookLists();
  }, []);

  const getWayBookLists = () => {
    fetch(`${ApiUrl}` + "get/waybook/subcategory/" + params.category, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        is_coach_section: is_coach_section,
        can_edit: params.can_edit,
        can_edit_coach: params.can_edit_coach,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          console.log('response.data.records' , response.data.records);
          setLists(response.data.records);
          setList(response.data.record);
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
        setLoaded(true);
      })
      //Then with the error genereted...
      .catch((error) => {
        setLoaded(true);

        console.error("Error:", error);
      });
  };

  const updateWayBookSubjectListOrder = (newlists) => {
    fetch(`${ApiUrl}` + "update/waybook/subjects/order/" + params.category, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        can_edit: params.can_edit || params.can_edit_coach ? true : false,
        lists: newlists,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === false) {
          swal({
            title: response.message,
            icon: "warning",
          });
          getWayBookLists();
        } else {
          params.updatetWayBookLists();
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const dragItem = useRef();
  const dragOverItem = useRef();
  const dragStart = (e, position) => {
    // e.prevenDefault(); remove this for reordering

    if (!params.can_edit && !params.can_edit_coach) {
      e.prevenDefault();
    }
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    // e.preventDefault();
    dragOverItem.current = position;
    // console.log(e.target.innerHTML);
  };

  const dropTwo = (e, i) => {
    const copyListItems = [...lists];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;

    if (!copyListItems.some((element) => element === undefined)) {
      setLists(copyListItems);
      updateWayBookSubjectListOrder(copyListItems);
    }
    console.log(copyListItems);
  };

  return (
    <div>
      <Loader loaded={loaded}></Loader>
      <div className="row">
        <div className="col-lg-12">
          <div className="TraningRightContent">
            <div className="way-subject-header">
              <div className="modal-header ps-0 border-0 pb-0">
                <h5>
                  {list.image && (
                    <img
                      className="me-1"
                      height={20}
                      width={20}
                      src={`${AttachementsUrl}/user_${list.user_id}/training/${list.image}`}
                      alt=""
                    />
                  )}
                  {list.title}
                  {(params.can_edit ||
                    (params.can_edit_coach &&
                      is_coach_section === "Yes" &&
                      list.cat_id > 0)) && (
                    <button
                      className="btn btn-sm btn-link"
                      onClick={() =>
                        params.subjectOrDocumentAction(
                          "Category",
                          list.cat_id,
                          list.title,
                          "Update",
                          list.training_for,
                          list.tag_training
                        )
                      }
                    >
                      <i className="fas fa-edit"></i>
                    </button>
                  )}
                </h5>
                {(params.can_edit ||
                  (params.can_edit_coach && is_coach_section === "Yes")) && (
                  <div className="NextBackBtn edit-step-action">
                    <button
                      onClick={() => {
                        params.getTagLists("subject", list.cat_id);
                        params.Action(2, list.cat_id);
                      }}
                      className="BackBtn btn btn-secondary btn-sm"
                    >
                      {" "}
                      Create New Module
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="subject-document-lists">
              <table className="table table-stripped content-document-card-item">
                <tbody>
                  {console.log('lists' , lists)}
                  {lists.map((row, i) => {
                    const queryParam =
                      is_coach_section === "Yes" ? "?sec=Y29hY2g=" : "";
                    const url = `/trainings/${bcrypt(params.category)}/${bcrypt(
                      row.cat_id
                    )}${queryParam}`;

                    return (
                      <tr
                        key={i}
                        onDragEnd={(e) => dropTwo(e, i)}
                        onDragEnter={(e) => dragEnter(e, i)}
                        className="content-page-documents-list-item"
                      >
                        {(params.can_edit ||
                          (params.can_edit_coach &&
                            is_coach_section === "Yes")) && (
                          <td
                            onDragStart={(e) => dragStart(e, i)}
                            draggable
                            className="draggable drag-handle document-drag-handle has-tooltip"
                          >
                            <i className="fas fa-grip-vertical"></i>
                          </td>
                        )}
                        <td>
                          <Link to={url}>{row.title}</Link>
                        </td>
                        <td>
                          {row.image && (
                            <img
                              className="mx-1"
                              title="Module Image"
                              height={15}
                              width={15}
                              src={`${AttachementsUrl}/user_${row.user_id}/training/${row.image}`}
                              alt="Module Image"
                            />
                          )}{" "}
                        </td>
                        <td>
                          {row.badge_image && (
                            <img
                              className="mx-1"
                              title="Badge Image"
                              height={15}
                              width={15}
                              src={`${AttachementsUrl}/user_${row.user_id}/training/${row.badge_image}`}
                              alt="Badge Image"
                            />
                          )}{" "}
                        </td>
                        <td>{row.coaching_points}</td>

                        {!(
                          params.can_edit ||
                          (params.can_edit_coach && is_coach_section === "Yes")
                        ) && (
                          <td>
                            <span className="d-flex align-items-center gap-1">
                              <OverlayTrigger
                                placement="left"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                  <Tooltip id="button-tooltip">
                                    {row.percentage}%
                                  </Tooltip>
                                }
                              >
                                <ProgressBar
                                  now={row.percentage}
                                  className="w-100"
                                />
                              </OverlayTrigger>{" "}
                              {row.percentage}%
                            </span>
                          </td>
                        )}

                        {(params.can_edit ||
                          (params.can_edit_coach &&
                            is_coach_section === "Yes")) && (
                          <td>
                            {row.status == "Publish" ? "Published" : row.status}
                          </td>
                        )}
                        {(params.can_edit ||
                          (params.can_edit_coach &&
                            is_coach_section === "Yes")) && (
                          <td>
                            <Dropdown
                              onSelect={(e) =>
                                params.subjectOrDocumentAction(
                                  "Subject",
                                  row.cat_id,
                                  row.title,
                                  e,
                                  row.training_for,
                                  row.tag_training,
                                  row.image,
                                  row.user_id,
                                  "left",
                                  row.master_id,
                                  row.badge_image,
                                  row.badge_description,
                                  row.coaching_points,
                                  "",
                                  row.training_certifications
                                )
                              }
                            >
                              <Dropdown.Toggle variant="link">
                                <i className="fas fa-ellipsis-h"></i>
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {row.status == "Publish" ? (
                                  <Dropdown.Item eventKey="Draft">
                                    Switch to Unpublish
                                  </Dropdown.Item>
                                ) : (
                                  <Dropdown.Item eventKey="Publish">
                                    Switch to Publish
                                  </Dropdown.Item>
                                )}
                                <Dropdown.Item eventKey="Update">
                                  Rename
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="Move">
                                  Move
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="Delete">
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoryLists;
