import React, { Component } from "react";
import UploadService from "./upload-files.service";
import $ from "jquery";
export default class UploadFiles extends Component {
  constructor(props) {
    console.log(props);
    super(props);
    this.selectFiles = this.selectFiles.bind(this);
    this.upload = this.upload.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);

    this.state = {
      selectedFiles: undefined,
      progressInfos: [],
      message: [],

      fileInfos: [],
    };
  }

  componentDidMount() {
    // UploadService.getFiles().then((response) => {
    //     this.setState({
    //         fileInfos: response.data,
    //     });
    // });
    
    // $("input[type=file]").trigger("click");
    $("#frola-file").trigger("click");
  }

  selectFiles(event) {
    event.preventDefault();
    this.setState({
      progressInfos: [],
      selectedFiles: event.target.files,
    });
  }

  upload(idx, file) {
    let _progressInfos = [...this.state.progressInfos];

    UploadService.upload(
      file,
      this.props.userId,
      this.props.folderId,
      file.name,
      (event) => {
        _progressInfos[idx].percentage = Math.round(
          (100 * event.loaded) / event.total
        );
        this.setState({
          _progressInfos,
        });
      }
    )
      .then((response) => {
        if (response.data.status == true) {
          this.setState((prev) => {
            let nextMessage = [
              ...prev.message,
              {
                type: "success",
                message: "Uploaded the file successfully: " + file.name,
              },
            ];
            return {
              message: nextMessage,
            };
          });

          // if (typeof this.props.closeFileModal === "function") {
          //   setTimeout(this.props.closeFileModal(this.props.folderId), 10000);
          // }
        } else {
          _progressInfos[idx].percentage = 0;
          this.setState((prev) => {
            let nextMessage = [
              ...prev.message,
              {
                type: "error",
                message: "Could not upload the file: " + file.name,
              },
            ];
            return {
              progressInfos: _progressInfos,
              message: nextMessage,
            };
          });
        }

        // return UploadService.getFiles();
      })
      // .then((files) => {
      //     this.setState({
      //         fileInfos: files.data,
      //     });
      // })
      .catch((error) => {
        _progressInfos[idx].percentage = 0;
        this.setState((prev) => {
          let nextMessage = [
            ...prev.message,
            {
              type: "error",
              message: "Could not upload the file: " + file.name,
            },
          ];
          return {
            progressInfos: _progressInfos,
            message: nextMessage,
          };
        });
        console.error("Error:", error);
      });
  }

  uploadFiles() {
    const selectedFiles = this.state.selectedFiles;

    let _progressInfos = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
    }

    this.setState(
      {
        progressInfos: _progressInfos,
        message: [],
      },
      () => {
        for (let i = 0; i < selectedFiles.length; i++) {
          this.upload(i, selectedFiles[i]);
        }
      }
    );
  }

  render() {
    const { selectedFiles, progressInfos, message, fileInfos } = this.state;

    return (
      <div>
        {progressInfos &&
          progressInfos.map((progressInfo, index) => (
            <div className="mb-2" key={index}>
              <span>{progressInfo.fileName}</span>
              <div className="progress">
                <div
                  className="progress-bar progress-bar-info"
                  role="progressbar"
                  aria-valuenow={progressInfo.percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: progressInfo.percentage + "%" }}
                >
                  {progressInfo.percentage}%
                </div>
              </div>
            </div>
          ))}

        <div className="row my-3">
          <div className="col-lg-12 mb-3">
            <input
              id="frola-file"
              type="file"
              multiple
              onChange={this.selectFiles}
              className="form-control"
            />
          </div>

          <div className="modal-footer p-0">
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="pull-right">
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={!selectedFiles}
                    onClick={this.uploadFiles}
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {message.length > 0 && (
          <div className="alert alert-secondary" role="alert">
            <ul>
              {message.map((item, i) => {
                return (
                  <li
                    key={i}
                    className={`${
                      item.type == "success" ? "text-success" : "text-danger"
                    }`}
                  >
                    {item.message}
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        {/* <div className="card">
                    <div className="card-header">List of Files</div>
                    <ul className="list-group list-group-flush">
                        {fileInfos &&
                            fileInfos.map((file, index) => (
                                <li className="list-group-item" key={index}>
                                    <a href={file.url}>{file.name}</a>
                                </li>
                            ))}
                    </ul>
                </div> */}
      </div>
    );
  }
}
