import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

//Import images
import avatar3 from "../../../assets/images/users/user-3.jpg";
import avatar4 from "../../../assets/images/users/user-4.jpg";

//i18n
import { withTranslation } from "react-i18next";
import { ApiUrl } from "../../../config";
import { hasRole, uInfo, uRole, uToken } from "../../../useToken";
import { toast } from "react-toastify";

const NotificationDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [records, setRecords] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [notificationSetting, setNoficationSetting] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getData();
    getTrainingNotification();
    setIsLoading(true);
  }, [!records]);

  const getData = () => {
    fetch(`${ApiUrl}` + "get/notification", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setRecords(response.data.records);

          let sum = 0;

          response.data.records.forEach((row) => {
            if (row.type == 3) {
              if (hasRole(uInfo(), ["reporting"])) {
                sum += parseInt(row.count);
              }
            } else if (row.type == 4) {
              if (hasRole(uInfo(), ["inbox"])) {
                sum += parseInt(row.count);
              }
            } else if (row.type == 6) {
              sum += parseInt(row.count);
            } else {
              sum += parseInt(row.count);
            }
          });

          setTotalCount(sum);
          setIsLoading(true);
        } else {
          toast.error(response.message, { theme: "colored" });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getTrainingNotification = () => {
    fetch(`${ApiUrl}` + "get/notification/settings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
        
          if (response.data.record.training) {
            setNoficationSetting(response.data.record.training);
            setIsLoading(true);
          }
        } else {
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // console.log("notificationSetting" , notificationSetting);

  // console.log("records==>" , records);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block ms-1 SystemNotification"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
          title="System Notification"
        >
          <i className="ti-bell"></i>
          <span className="badge bg-danger rounded-pill">{totalCount}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h5 className="m-0"> {props.t("Notifications")}</h5>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ maxHeight: "160px" }}>
            {records.map((row, i) => {
              if (row.type == 3) {
                if (hasRole(uInfo(), ["reporting"])) {
                  return (
                    <a
                      href={`/${row.link}`}
                      key={i}
                      className="text-reset notification-item"
                    >
                      <div className="media">
                        <div className="avatar-xs me-3">
                          <span className="avatar-title border-danger rounded-circle ">
                            {row.count}
                          </span>
                        </div>
                        <div className="media-body">
                          <h6 className="mt-0 mb-1">{row.message}</h6>
                        </div>
                      </div>
                    </a>
                  );
                }
              } else if (row.type == 4) {
                if (hasRole(uInfo(), ["inbox"])) {
                  return (
                    <a
                      href={`/${row.link}`}
                      key={i}
                      className="text-reset notification-item"
                    >
                      <div className="media">
                        <div className="avatar-xs me-3">
                          <span className="avatar-title border-danger rounded-circle ">
                            {row.count}
                          </span>
                        </div>
                        <div className="media-body">
                          <h6 className="mt-0 mb-1">{row.message}</h6>
                        </div>
                      </div>
                    </a>
                  );
                }
              } else if (row.type == 6 && isLoading) {
                if (notificationSetting.confirmation_system == true)
                  return (
                    <a
                      href={`/${row.link}`}
                      key={i}
                      className="text-reset notification-item sysnotif"
                    >
                      <div className="media">
                        <div className="avatar-xs me-3">
                          <span className="avatar-title border-danger rounded-circle ">
                            {row.count}
                          </span>
                        </div>
                        <div className="media-body">
                          <h6 className="mt-0 mb-1">{row.message}</h6>
                        </div>
                      </div>
                    </a>
                  );
              } else {
                return (
                  <a
                    href={`/${row.link}`}
                    key={i}
                    className="text-reset notification-item"
                  >
                    <div className="media">
                      <div className="avatar-xs me-3">
                        <span className="avatar-title border-danger rounded-circle ">
                          {row.count}
                        </span>
                      </div>
                      <div className="media-body">
                        <h6 className="mt-0 mb-1">{row.message}</h6>
                      </div>
                    </div>
                  </a>
                );
              }
            })}
          </SimpleBar>
          {/* <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="#"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>
              {" "}
              {props.t("View all")}{" "}
            </Link>
          </div> */}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
