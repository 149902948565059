import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";

export default class LoginHistory extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        text: "SR#",
        key: "sr",
        sortable: false,
      },
      {
        text: "Name",
        key: "name",
        sortable: false,
        cell: (record, index) => {
          return (
            <Fragment>
              {isAllowed(uInfo(), ["can_view"]) ? (
                <button
                  onClick={() => this.props.history.push("/user-profile/" + bcrypt(record.user_id))}
                  className="waves-effect border-0 NameBtns px-0"
                >
                  {record.name}
                </button>
              ) : (
                record.name
              )}
            </Fragment>
          );
        },
      },
      {
        text: "Email",
        key: "email",
        sortable: false,
      },
      {
        text: "Login 1 (IP/Time)",
        key: "h0",
        sortable: false,
        cell: (record, index) => {
          return (
            <Fragment>
              <a
                target={"_blank"}
                href={`https://www.ip2location.com/demo/${record.h0}`}
                className="waves-effect border-0 pl-0"
              >
                {record.h0}
              </a>
              {/* <br /> */}
              <small>{record.t0}</small>
            </Fragment>
          );
        },
      },
      {
        text: "Login 2 (IP/Time)",
        key: "h1",
        sortable: false,
        cell: (record, index) => {
          return (
            <Fragment>
              <a
                target={"_blank"}
                href={`https://www.ip2location.com/demo/${record.h1}`}
                className="waves-effect border-0 pl-0"
              >
                {record.h1}
              </a>
              {/* <br /> */}
              <small>{record.t1}</small>
            </Fragment>
          );
        },
      },
      {
        text: "Login 3 (IP/Time)",
        key: "h2",
        sortable: false,
        cell: (record, index) => {
          return (
            <Fragment>
              <a
                target={"_blank"}
                href={`https://www.ip2location.com/demo/${record.h2}`}
                className="waves-effect border-0 pl-0"
              >
                {record.h2}
              </a>
              {/* <br /> */}
              <small>{record.t2}</small>
            </Fragment>
          );
        },
      },
      {
        text: "Login 4 (IP/Time)",
        key: "h3",
        sortable: false,
        cell: (record, index) => {
          return (
            <Fragment>
              <a
                target={"_blank"}
                href={`https://www.ip2location.com/demo/${record.h3}`}
                className="waves-effect border-0 pl-0"
              >
                {record.h3}
              </a>
              {/* <br /> */}
              <small>{record.t3}</small>
            </Fragment>
          );
        },
      },
      {
        text: "Login 5 (IP/Time)",
        key: "h4",
        sortable: false,
        cell: (record, index) => {
          return (
            <Fragment>
              <a
                target={"_blank"}
                href={`https://www.ip2location.com/demo/${record.h4}`}
                className="waves-effect border-0 pl-0"
              >
                {record.h4}
              </a>
              {/* <br /> */}
              <small>{record.t4}</small>
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      key_column: "sr",
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
      button: {
        excel: false,
        print: true,
        csv: true,
      },
    };
    this.state = {
      date: this.props.match.params.date,
      type: this.props.match.params.path,
      records: [],
      roles: [],
      total_pages: 0,
      modal: false,
      record: {},
      filter: {},
      page_name: this.props.match.params.path + " Login History",
      customDate: true,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = (queryString = this.state.date, data) => {
    fetch(
      `${ApiUrl}` + "get/" + this.state.type + "/login/history/" + queryString,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
            roles: response.data.roles,
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleFilterSubmit = async (event, values) => {
    this.setState({
      filter: values,
    });
    this.getData(this.state.date, values);
  };

  cancelFilter = async () => {
    this.form && this.form.reset();
    this.setState({ filter: {}, customDate: true });
    this.getData(this.state.date, "");
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData("?" + queryString, this.state.filter);
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.page_name}</title>
        </Helmet>

        <Row>
          <Col sm={6}>
            <div className="page-title-box text-capitalize">
              <h4>{this.state.page_name}</h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#">{this.state.page_name}</Link>
                </li>
              </ol>
            </div>
          </Col>
        </Row>

        <ToastContainer />

        <Row>
          <Col xl="12">
            <Card>
              <CardBody className="pb-0">
                <AvForm
                  onValidSubmit={this.handleFilterSubmit}
                  ref={(c) => (this.form = c)}
                  className="needs-validation"
                >
                  <Row>
                    <Col md="2">
                      <div className="mb-0">
                        <AvField
                          className="select form-select"
                          type="select"
                          name="date_range"
                          label="Date Filter"
                          onChange={(e) =>
                            e.target.value == "Custom Date"
                              ? this.setState({
                                customDate: false,
                              })
                              : this.setState({
                                customDate: true,
                              })
                          }
                          value={this.state.date}
                        >
                          <option value="">All Time</option>
                          <option value="Current Week">Current Week</option>
                          <option value="Last Week"> Last Week </option>
                          <option value="Current Month">
                            Current Month
                          </option>
                          <option value="Last Month"> Last Month </option>
                          <option value="Last 3 Months">
                            Last 3 Months
                          </option>
                          <option value="Last 6 Months">
                            Last 6 Months
                          </option>
                          <option value="Current Year">Current Year</option>
                          <option value="Last Year"> Last Year </option>
                          <option value="Custom Date"> Custom Date </option>
                        </AvField>
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <AvField
                          name="start_date"
                          label="Start date"
                          className="form-control"
                          placeholder="Enter Email Address"
                          type="date"
                          disabled={this.state.customDate}
                          required={!this.state.customDate}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <AvField
                          name="end_date"
                          label="End date"
                          className="form-control"
                          placeholder="Enter Email Address"
                          type="date"
                          disabled={this.state.customDate}
                          required={!this.state.customDate}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <label className=""> {"Action"}</label>
                        <div className="button-items">
                          <Button
                            className="btn-sm"
                            color="primary"
                            type="submit"
                          >
                            Filter
                          </Button>
                          <Button
                            onClick={() => this.cancelFilter()}
                            outline
                            color="secondary"
                            className="waves-effect btn-sm"
                            type="button"
                          >
                            Reset
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {/* <div className="button-items">
                    <Button className="btn-sm" color="primary" type="submit">
                      Filter
                    </Button>
                    <Button
                      onClick={() => this.cancelFilter()}
                      outline
                      color="secondary"
                      className="waves-effect btn-sm"
                      type="button"
                    >
                      Reset
                    </Button>
                  </div> */}
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="pt-0">
                {/* <CardTitle className="h4">Default Datatable </CardTitle> */}
                <ReactDatatable
                  key={this.state.id}
                  config={this.config}
                  records={this.state.records}
                  columns={this.columns}
                  dynamic={false}
                  total_record={this.state.total_pages}
                  onChange={this.tableChangeHandler}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
