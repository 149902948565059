import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
} from "availity-reactstrap-validation";
import {
  currentDateTime,
  dcrypt,
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
  convertPhoneFormat,
} from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
// import '../../../node_modules/bootstrap/js/dist/tooltip';
import Select from "react-select";
import countries from "../../common/data/countries";
import CampaignNav from "./nav";
import { Tab, Tabs } from "react-bootstrap";
import { Asia, TimezoneMain, America, Australia, Europe, Indian } from "../../common/data/timezones";
import swal from 'sweetalert';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
export default class CreateUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      page_name: this.props.match.params.id
        ? "Edit Campaign"
        : "Create Campaign",
      from: this.props.from ? this.props.from : "",
      record: {
        'from_email': uInfo().email,
      },
      groups: [],
      permissions: [],
      tags: [],
      holidays: {},
      managers: [],
      group_id: [],
      tag_id: [],
      emails: [],
      phoneNumbers: [],
      permission: [],
      country_code: [],
      CustomField: [],
      customFieldData: {},
      tab: "1",
      group_status: 'No',
      incoming_SMS_Notification: false,
      incomingNumber: '',
      voicemail_Votification_SMS: false,
      voicemail_Notification_EMAIL: false,
      voicemail_SMS: '',
      voicemail_EMAIL: '',
      group_status_id: '',
      main: Intl.DateTimeFormat().resolvedOptions().timeZone ? Intl.DateTimeFormat().resolvedOptions().timeZone.split('/').shift() : 'America',
      Timezone: TimezoneMain,
      zones: America,
      zone: Intl.DateTimeFormat().resolvedOptions().timeZone ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'America/Los_Angeles',
      verifiedFromEmail: [],
      verifiedFromEmailId:"",
      verifiedFromName: "",
      default_from_email_id:"",
    };
  }

  componentDidMount() {
    this.state.id != null && this.getData();
    this.getFormData();
    this.getVerifiedFromEmail();
  }

  getFormData = () => {
    fetch(`${ApiUrl}` + "get/campaign/formdata", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            groups: response.data.groups,
            tags: response.data.tags,
            holidays: response.data.holidays,
            emails: response.data.emails,
            phoneNumbers: response.data.phoneNumbers,
            permissions: response.data.coachPartners,
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/campaign", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },

      body: JSON.stringify({
        id: this.state.id,
        coach_id: this.state.coach_id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            record: response.data.record,
            selectedTags: response.data.record.tag_ids,
            selectedGroup: response.data.record.group_ids,
            group_id: response.data.record.group_id,
            tag_id: response.data.record.tag_id,
            selectedPermission: response.data.record.permissions,
            permission: response.data.record.permission,
            group_status: response.data.record.group_status,
            group_status_id: response.data.record.group_status_id,
            main: response.data.record.main_zone,
            zone: response.data.record.time_zone,
            incoming_SMS_Notification: response.data.record.notify_incoming_sms == 'Yes' ? true : false,
            voicemail_Votification_SMS: response.data.record.notify_sms == 'Yes' ? true : false,
            voicemail_Notification_EMAIL: response.data.record.notify_email == 'Yes' ? true : false,
            voicemail_SMS: response.data.record.sms_number,
            voicemail_EMAIL: response.data.record.email_address,
            incomingNumber: response.data.record.notify_incoming_number,
          });

          this.changeMainZone(response.data.record.main_zone);
          this.changeTimeZone(response.data.record.time_zone);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmitOtherSetting = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.record.campaign_id
        ? this.state.record.campaign_id
        : -1;
    }

    values.notify_email = values.notify_email ? 'Yes' : 'No';
    values.notify_sms = values.notify_sms ? 'Yes' : 'No';
    values.notify_incoming_sms = values.notify_incoming_sms ? 'Yes' : 'No';
    fetch(`${ApiUrl}` + "add/campaign/other/setting", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data, "Editing");
        if (data.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          swal({
            text: 'Action performed successfully',
            icon: "success",
          });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  handleSubmitConstraint = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.record.campaign_id
        ? this.state.record.campaign_id
        : -1;
    }

    if (values.holidays && values.skip_weak_days) {
      values.holidays = values.holidays.toString();
      values.skip_weak_days = values.skip_weak_days.toString();
    }

    fetch(`${ApiUrl}` + "update/campaign/constraint", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          swal({
            text: 'Action performed successfully',
            icon: "success",
          });
          //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.record.campaign_id
        ? this.state.record.campaign_id
        : -1;
    }

    if (values.holidays && values.skip_weak_days) {
      values.holidays = values.holidays.toString();
      values.skip_weak_days = values.skip_weak_days.toString();
    }

    fetch(`${ApiUrl}` + "add/campaign", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data, "Editing");
        if (data.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          swal({
            text: 'Action performed successfully',
            icon: "success",
          });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleTag = (selectedTags) => {
    var filter = [];
    if (selectedTags) {
      selectedTags.map((fil) => {
        filter.push(fil.group_id);
      });
    }

    this.setState({
      tag_id: filter.toString(),
      selectedTags: selectedTags,
    });
  };

  handleGroup = (selectedGroup) => {
    var filter = [];
    if (selectedGroup) {
      selectedGroup.map((fil) => {
        filter.push(fil.group_id);
      });
    }

    this.setState({
      group_id: filter.toString(),
      selectedGroup: selectedGroup,
    });
  };

  handlePermission = (selectedPermission) => {
    var filter = [];
    if (selectedPermission) {
      selectedPermission.map((fil) => {
        filter.push(fil.user_id);
      });
    }

    this.setState({
      permission: filter.toString(),
      selectedPermission: selectedPermission,
    });
  };

  toggle = (index) => {
    if (this.state.tab !== index) {
      if (index == "7") {
        this.props.history.push("/campaign/automation/list/" + bcrypt(this.state.id));
      } else {
        this.setState({
          tab: index,
        });
      }
    }
  };

  changeTimeZone = (zone) => {
    this.setState({
      zone: zone
    })
    // setZone(zone);
    // setRecord(currValue => ({
    //     ...currValue,
    //     time_zone: zone
    // }))
  }

  changeMainZone = (zone) => {
    if (zone == 'Asia') {
      this.setState({
        zones: Asia,
        zone: Asia[0].id
      })
    } else if (zone == 'America') {
      this.setState({
        zones: America,
        zone: America[0].id
      })
    } else if (zone == 'Australia') {
      this.setState({
        zones: Australia,
        zone: Australia[0].id
      })
    } else if (zone == 'Europe') {
      this.setState({
        zones: Europe,
        zone: Europe[0].id
      })
    } else if (zone == 'Indian') {
      this.setState({
        zones: Indian,
        zone: Indian[0].id
      })
    }
    this.setState({
      main: zone,
    })
  }
  getVerifiedFromEmail = () => {
    fetch(`${ApiUrl}` + "verify/email/list", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
            const verifiedFromEmail = response.data.records
           
          this.setState({
            verifiedFromEmail: verifiedFromEmail,
          });
          

        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

};
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.page_name} </h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#"> {this.state.page_name} </Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                className="btn-sm"
                type="button"
                color="secondary"
                onClick={() => this.props.history.push('/campaign/list')}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
        <ToastContainer />
        <Row>
          <Col className="col-12">
            <Card>
              <CampaignNav activeTab={'1'} campaignId={bcrypt(this.state.id)} />

              <Tabs defaultActiveKey="info" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="info" title="Basic Settings">
                  <AvForm
                    onValidSubmit={this.handleSubmit}
                    // model={this.state.record}
                    ref={(c) => (this.form = c)}
                    className="needs-validation"
                  >
                    <ModalBody className="row">
                      <div className="col-md-6 mb-3">
                        <AvField
                          name="title"
                          value={this.state.record.title}
                          label="Title"
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <label>Main Zone</label>
                        <div className="MainTZone Campaign One">
                          <Select
                            getOptionLabel={(option) => option}
                            getOptionValue={(option) => option}
                            options={this.state.Timezone}
                            value={this.state.Timezone.filter((id) => id == this.state.main)}
                            isMulti={false}
                            classNamePrefix="select2-selection"
                            name="zone"
                            onChange={(e) => this.changeMainZone(e)}
                          />
                        </div>
                        <AvField required type="hidden" name="main_zone" value={this.state.main} />
                      </div>

                      <div className="col-md-3 mb-3">
                        <label>Time Zone</label>
                        <div className="MainTZone Campaign Two">
                          <Select
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.id}
                            options={this.state.zones}
                            value={this.state.zones.filter(({ id }) => id === this.state.zone)}
                            isMulti={false}
                            classNamePrefix="select2-selection"
                            name="timezone"
                            onChange={(e) => this.changeTimeZone(e.id)}
                          />
                        </div>
                        <AvField required type="hidden" name="time_zone" value={this.state.zone} />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="control-label"> Group </label>
                        <div className="MainTZone Campaign Three">
                          <Select
                            getOptionLabel={(option) => option.title}
                            getOptionValue={(option) => option.group_id}
                            isMulti={true}
                            value={this.state.selectedGroup}
                            options={this.state.groups}
                            classNamePrefix="select2-selection"
                            name="groups_id"
                            onChange={(e) => {
                              this.handleGroup(e);
                            }}
                          />
                        </div>
                        <AvField
                          required
                          type="hidden"
                          name="group_id"
                          value={this.state.group_id}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="control-label"> Tags </label>
                        <div className="MainTZone Campaign Four">
                          <Select
                            getOptionLabel={(option) => option.title}
                            getOptionValue={(option) => option.group_id}
                            isMulti={true}
                            value={this.state.selectedTags}
                            options={this.state.tags}
                            classNamePrefix="select2-selection"
                            name="country_id"
                            onChange={(e) => {
                              this.handleTag(e);
                            }}
                          />
                        </div>
                        <AvField
                          type="hidden"
                          name="tag_id"
                          value={this.state.tag_id}
                        />
                      </div>
                      <div className="col-md-6">
                          {/* <label>From Email</label> */}
                          {/* <Select
                              getOptionLabel={(option) => `${option.from_name}  -  ${option.from_email}`}
                              getOptionValue={(option) => option.id}
                              isMulti={false}
                              options={this.state.verifiedFromEmail}
                              classNamePrefix="select2-selection"
                              placeholder={"Select From Email"}
                              autocomplete="off"
                              name="from_email_id"
                              value={this.state.defaultFromEmail}
                              onChange={(option) => {
                                  this.setState({
                                      verifiedFromEmailId: option.from_email
                                  })
                                  this.getVerifiedFromEmail(option.from_email);
                                  
                              }}
                          /> */}
                        <AvField
                            className="select form-select"
                            type="select"
                            name="from_email_id"
                            label="From Email"
                            value={this.state.record.from_email_id}
                        >
                          <option value=""> Select From Email </option>
                          {
                              this.state.verifiedFromEmail.map((row, i) => {
                                  return (
                                          <option key={i} value={row.setting_id} > {`${row.from_name}  -  ${row.from_email}  -  ${row.type}`}</option>
                                    

                                  );
                              })
                          }
                      </AvField>
                     </div>
                      <div className="col-md-3 mb-3 d-none">
                        <AvField
                          name="from_name"
                          value={this.state.record.from_name}
                          label="From Name"
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-3 mb-3 d-none">
                        <AvField
                          name="from_email"
                          value={this.state.record.from_email}
                          label="From Email"
                          className="form-control"
                        />
                        {/* <AvField
                          className="select form-control"
                          type="select"
                          name="from_email"
                          label="From Email (Optional)"
                          value={this.state.record.from_email}
                        >
                          <option value=""> Select From Email </option>
                          {this.state.emails.map((row, i) => {
                            return (
                              <option
                                key={i}
                                value={row.other_settings.from_email}
                              >
                                {row.other_settings.from_email}
                              </option>
                            );
                          })}
                        </AvField> */}
                      </div>
                      <div className="col-md-6 mb-3">
                        <AvField
                          className="select form-select"
                          type="select"
                          name="phone_number"
                          label=" Phone Number (Optional)"
                          value={this.state.record.phone_number}
                        >
                          <option value=""> Select Phone Number </option>
                          {this.state.phoneNumbers.map((row, i) => {
                            return (
                              (row.is_locked == 'No' || row.phone_number == this.state.record.phone_number) && (
                                <option key={i} value={row.phone_number}>
                                  {convertPhoneFormat(row.phone_number)}
                                </option>
                              )
                            );
                          })}
                        </AvField>
                      </div>
                      <div className="col-md-6 mb-3">
                        <AvField
                          className="select form-select"
                          type="select"
                          name="throttle_duration"
                          label="Throttle duration"
                          required
                          value={this.state.record.throttle_duration}
                        >
                          <option value="15"> 15 minutes </option>
                          <option value="30"> 30 minutes </option>
                          <option value="60"> 60 minutes </option>
                        </AvField>
                      </div>
                      <div className="col-md-6 mb-3">
                        <AvField
                          className="select form-select"
                          type="select"
                          name="throttle_value"
                          label="Throttle value"
                          required
                          value={this.state.record.throttle_value}
                        >
                          <option value="5"> 5 </option>
                          <option value="10"> 10 </option>
                          <option value="15"> 15 </option>
                          <option value="25"> 25 </option>
                          <option value="50"> 50 </option>
                          <option value="100"> 100 </option>
                          <option value="150"> 150 </option>
                          <option value="200"> 200 </option>
                          <option value="250"> 250 </option>
                          <option value="300"> 300 </option>
                          <option value="350"> 350 </option>
                          <option value="400"> 400 </option>
                          <option value="450"> 450 </option>
                          <option value="500"> 500 </option>
                        </AvField>
                      </div>
                      {/* offset-2 */}

                      <div className="col-md-4 mb-3">
                        <label>Ignore Followup Tag <OverlayTrigger
                          key={`0`}
                          placement={'auto'}
                          overlay={
                            <Tooltip>
                              Select the Yes option if you want this message to send regardless of whether or not the lead has responded to this campaign. Leads that respond via any method are automatically removed from all future campaign messaging
                            </Tooltip>
                          }
                        >
                          <i className="fas fa-info text-dark ml-3"></i>
                        </OverlayTrigger></label>
                        <AvField
                          className="select form-select"
                          type="select"
                          name="ignore_followup_tag"
                          value={this.state.record.ignore_followup_tag}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </AvField>
                      </div>

                      <div className="col-md-4 mb-3">
                        <AvField
                          name="status"
                          value={this.state.record.status}
                          label="Status"
                          className="form-select"
                          id="rs"
                          required
                          type="select"
                        >
                          <option value={''}>Select Status</option>
                          <option value={'Active'}>Active</option>
                          <option value={'Inactive'}>Inactive</option>
                          {/* <option value={'Hidden'}>Hidden</option> */}

                        </AvField>
                      </div>

                      <div className="col-md-4 mb-3">
                        <label className="control-label"> Assign to </label>
                        <div className="MainTZone Campaign Five">
                          <Select
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.user_id}
                            isMulti={true}
                            value={this.state.selectedPermission}
                            options={this.state.permissions}
                            classNamePrefix="select2-selection"
                            onChange={(e) => {
                              this.handlePermission(e);
                            }}
                          />
                        </div>
                        <AvField
                          type="hidden"
                          name="permission"
                          value={this.state.permission}
                        />
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                      className="btn-sm"
                        type="button"
                        color="secondary"
                        onClick={() => this.props.history.goBack()}
                      >
                        Cancel
                      </Button>
                      <button
                        className="btn btn-primary waves-effect waves-light btn-sm"
                        type="submit"
                      >
                        Submit
                      </button>
                    </ModalFooter>
                  </AvForm>
                </Tab>
                <Tab eventKey="constraint" title="Constraint Settings">
                  <CardBody>
                    <AvForm
                      onValidSubmit={this.handleSubmitConstraint}
                      // model={this.state.record}
                      className="needs-validation"
                    >
                      <ModalBody className="row pt-0">
                        <div className="col-md-8 pl-25 mb-3">
                          <p> Current Date / Time: {currentDateTime()} </p>
                          <div className="row time-constraint">
                            <div className="col-md-12 mb-3">
                              <AvField
                                name="block_days"
                                value={this.state.record.block_days}
                                label="Block Days"
                                className="form-control"
                                placeholder="For Example: 2022-04-16,2022-04-17"
                              />
                              <div className="note">
                                Enter comma seperated dates i.e (yyyy-mm-dd,yyyy-mm-dd)
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <AvField
                                name="from_time"
                                value={this.state.record.from_time}
                                label="From Time"
                                className="form-control"
                                required
                                type="time"
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <AvField
                                name="to_time"
                                value={this.state.record.to_time}
                                label="To Time"
                                className="form-control"
                                required
                                type="time"
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <AvField
                                className="select form-select"
                                type="select"
                                name="skip_weak_days"
                                label="Skip Week Days"
                                multiple
                                value={this.state.record.skip_weak_days}
                              >
                                <option value="Monday"> Monday </option>
                                <option value="Tuesday"> Tuesday </option>
                                <option value="Wednesday"> Wednesday </option>
                                <option value="Thursday"> Thursday </option>
                                <option value="Friday"> Friday </option>
                                <option value="Saturday"> Saturday </option>
                                <option value="Sunday"> Sunday </option>
                              </AvField>
                            </div>
                            {this.state?.zone?.includes("America") ? 

                            <div className="col-md-6 mb-3">
                              <AvField
                                className="select form-select"
                                type="select"
                                value={this.state.record.holidays}
                                name="holidays"
                                label="USA Holidays"
                                multiple
                              >
                                {Object.entries(this.state.holidays).map(
                                  ([key, value]) => {
                                    return <option value={key}> {value} </option>;
                                  }
                                )}
                              </AvField>
                            </div>
                            : ''}
                            
                          </div>
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <button
                          className="btn btn-primary waves-effect waves-light btn-sm"
                          type="submit"
                        >
                          Submit
                        </button>
                      </ModalFooter>
                    </AvForm>

                  </CardBody>
                </Tab>
                <Tab eventKey="other" title="Other Settings">
                  <CardBody>

                    <AvForm
                      onValidSubmit={this.handleSubmitOtherSetting}
                      // model={this.state.record}
                      className="needs-validation"
                    >
                      <ModalBody className="row pt-0">



                        <div className="col-md-12 mb-3">
                          <h5>Inbound Callers Group Add Option</h5>
                          <AvRadioGroup value={this.state.group_status} inline
                            name="status"
                            label=""
                            onChange={(e) => this.setState({ group_status: e.target.value })}
                            required>
                            <AvRadio label="All Groups" value="All-Groups" />
                            <AvRadio label="No - Groups" value="No" />
                            <AvRadio label="Yes - Select Group" value="Yes" />
                          </AvRadioGroup>
                        </div>


                        {
                          this.state.group_status == 'Yes' ?
                            <p>Inbound respondants will be added to this specific group</p>
                            : this.state.group_status == 'No' ?
                              <p>Inbound respondants will not be added to any campaign groups</p>
                              : <p>Inbound respondants to the campaign will be automatically added to all groups.
                                <span className="text-danger"> Warning*</span> This will trigger any Drips you have set up <br />on groups
                                where the lead does not exist, this option is not reccomended.
                              </p>
                        }

                        {this.state.group_status == 'Yes' && (
                          <div className="col-md-12 mb-3">

                            <AvField
                              className="select form-select"
                              type="select"
                              name="group_id"
                              label="Select Group"
                              required
                              value={this.state.group_status_id}
                            >
                              <option value=""> Select Group </option>
                              {
                                this.state.groups.map((row, i) => {
                                  return <option key={i} value={row.group_id}> {row.title} </option>
                                })
                              }
                            </AvField>
                          </div>
                        )}


                        <div className="form-group">
                          <h5 className="control-label-more-settings col-md-3 mt-3" >
                            Incoming SMS Notification</h5>
                          <div className="col-md-7">
                            <div className="row">
                              <div className="col-md-2">
                                <AvGroup check>
                                  <AvInput checked={this.state.incoming_SMS_Notification ? 'checed' : ''}
                                    onChange={(e) => this.setState({ incoming_SMS_Notification: e.target.checked })}
                                    type="checkbox" name="notify_incoming_sms" />
                                  <Label check htmlFor="checkbox"> SMS</Label>
                                </AvGroup>

                              </div>
                              {
                                this.state.incoming_SMS_Notification && (
                                  <div className="col-md-10">
                                    <AvInput required type="text" className="form-control" value={this.state.incomingNumber}
                                      onChange={(e) => this.setState({ incomingNumber: e.target.value })}
                                      name="notify_incoming_number"
                                      placeholder="add one/more notification number separated by comma" autocomplete="off" data-lpignore="true" />
                                  </div>
                                )
                              }

                            </div>
                          </div>
                        </div>



                        <div className="form-group mt-3">
                          <h5 className="control-label-more-settings col-md-3" >
                            Voicemail Notification</h5>
                          <div className="col-md-7">
                            <div className="row">
                              <div className="col-md-2">


                                <AvGroup check>
                                  <AvInput checked={this.state.voicemail_Notification_EMAIL ? 'checed' : ''} onChange={(e) => this.setState({ voicemail_Notification_EMAIL: e.target.checked })} type="checkbox" name="notify_email" />
                                  <Label check htmlFor="checkbox"> Email</Label>
                                </AvGroup>

                              </div>
                              {
                                this.state.voicemail_Notification_EMAIL && (
                                  <div className="col-md-10">
                                    <AvInput required type="text" className="form-control" value={this.state.voicemail_EMAIL} onChange={(e) => this.setState({ voicemail_EMAIL: e.target.value })} name="email_address" placeholder="add one/more notification emails separated by comma" autocomplete="off" data-lpignore="true" />
                                  </div>
                                )
                              }
                              <div className="col-md-12" >&nbsp;</div>
                              <div className="col-md-2">
                                <AvGroup check>
                                  <AvInput checked={this.state.voicemail_Votification_SMS ? 'checed' : ''} onChange={(e) => this.setState({ voicemail_Votification_SMS: e.target.checked })} type="checkbox" name="notify_sms" />
                                  <Label check htmlFor="checkbox"> SMS</Label>
                                </AvGroup>
                              </div>
                              {
                                this.state.voicemail_Votification_SMS && (
                                  <div className="col-md-10">
                                    <AvInput required type="text" className="form-control" value={this.state.voicemail_SMS} onChange={(e) => this.setState({ voicemail_SMS: e.target.value })} name="sms_number" placeholder="add one/more numbers with country code separated by comma" autocomplete="off" data-lpignore="true" />
                                  </div>
                                )
                              }
                            </div>
                          </div>
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <button
                          className="btn btn-primary waves-effect waves-light btn-sm"
                          type="submit"
                        >
                          Submit
                        </button>
                      </ModalFooter>
                    </AvForm>

                  </CardBody>
                </Tab>
              </Tabs>



            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
