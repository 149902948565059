import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import Helmet from "react-helmet";
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import {
  currentDateTime,
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
  convertDateFormat,
  logout,
  NumberWithCommas,
} from "../../useToken";
import {
  ApiUrl,
  AttachementsUrl,
  ProductName,
  logoLightPng,
  ToastAutoClose,
} from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import Select from "react-select";
import Loader from "react-loader";
import PrivateProposal from "../Authentication/PrivateProposal";
import swal from "sweetalert";
import PaymentComponent from "../../components/Custom/PaymentComponent";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { currencies } from "../../common/Common-Currency.js";
export default class publicPaymentForm extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(props.location.search);
    this.state = {
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      page_id: query.get("page") ? query.get("page") : 0,
      page_name: "Invoice Preview",
      from: this.props.from ? this.props.from : "",
      record: {},
      clients: [],
      coachPartners: [],
      customFields: [{ items: "", quantity: "0", price: "0", total: "0" }],
      formData: {
        start_date: currentDateTime(),
        expire_date: "",
        type: "one_time",
        invoice_for: "Member",
        is_user_exist: "No",
        renewal_interval: "Weekly",
        items_data: [],
        email_subject: "",
        notes: "",
        confirmation_email: "",
        created_for_id: 0,
        activity: "0",
        relational_id: 0,
        logo: 0,
      },
      filter_value: "",
      createdFor: {},
      createdFrom: {},
      loaded: true,
      pay_now: "Yes",
      publishableKey: null,
      currency: "",
    };
    this.childRef = React.createRef();
  }

  // componentDidMount() {
  //   logout();

  //   this.state.id != null && this.getData();
  //   window.onafterprint = function () {
  //     window.location.reload();
  //   };
  //   this.isProposalAccepted("No");
  // }

  componentDidMount() {
    this.setState({ loading: true });
    logout();

    if (this.state.id != null) {
      // this.getData();
      setTimeout(() => {
        this.getData();
      }, 1000);
    } else {
      console.error("No ID found in query params");
    }

    window.onafterprint = () => window.location.reload();
    this.isProposalAccepted("No");
  }

  isProposalAccepted = (status) => {
    this.setState({
      pay_now: status,
    });
  };
  getData = () => {
    this.setState({ loaded: false });
    
    fetch(`${ApiUrl}` + "public/get/edit/invoice", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.getInvoiceCurrency(response.data.record.currency_code);
          this.setState({
            formData: response.data.record,
            customFields: JSON.parse(response.data.record.items_data),
            createdFor: response.data.record.createdFor,
            createdFrom: response.data.record.createdFrom,
            pay_now: response.data.record.proposal_id > 0 ? "No" : "Yes",
            publishableKey: response.data.record.stripe_key,
            loaded: true,
            loading: false,
          });
          console.log(response.data.record, "ididididididididid");
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        this.setState({ loaded: true });
        this.setState({ loading: false });
      });
  };

  getInvoiceCurrency = (code) => {
    let currencysymbol = currencies[code];
    if (currencysymbol) {
      this.setState({ currency: currencysymbol.symbol });
    }
  };

  handleSubmit = async (event, values) => {
    if (this.state.id) {
      values.invoice_id = this.state.id ? this.state.id : -1;
    }

    if (this.childRef.current) {
      const result = await this.childRef.current.handlePayment(event);
      if (result.error == 1) {
        toast(result.message, {
          hideProgressBar: true,
          position: "top-center",
          transition: Flip,
          className: "toast-custom-cls error",
        });
        return;
      } else {
        values.token = result.token;
      }
    }

    values.cardnumber = "";
    values.cvc = "";
    values.expirationdateMM = "";
    values.expirationdateYYYY = "";

    this.setState({ loaded: false });
    fetch(`${ApiUrl}` + "public/pay/invoice", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        // this.setState({ loaded: true });
        if (data.status === true) {
          swal({
            text: data.message,
            icon: "success",
          });
          setTimeout(() => this.props.history.push("/login"), 1000);
          //   swal({
          //         title: "Success!",
          //         text: "The invoice has been paid successfully. Please login to your PROFITCOACH account to view invoice details.",
          //         type: "success"
          //       },
          //     function(){
          //       this.props.history.push("/client/profile");
          //     }
          // );
          //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
        this.setState({ loaded: true });
      })
      .catch((error) => {
        console.error("Error:", error);
        this.setState({ loaded: true });
      });
  };
  defaultUI() {
    return this.state.customFields.map(
      (el, i) =>
        el.total > 0 && (
          <div className="border px-1 py-1" key={i}>
            <div className="row">
              <div className="col-sm-6 col-md-6 mb-3">{el.items}</div>
              {/* <div className="col-sm-3 col-md-3 mb-3">{el.quantity}</div>
          <div className="col-sm-3 col-md-3 mb-3">$ {el.price}</div> */}
              <div className="col-sm-6 col-md-6">
                {this.state.currency}
                {NumberWithCommas(Math.round(el.total))}
              </div>
            </div>
          </div>
        )
    );
  }

  render() {
    const stripePromise = this.state.publishableKey
      ? loadStripe(this.state.publishableKey)
      : null;
    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <ToastContainer />

        {this.state.loading && <Loader loaded={false}></Loader>}

        {/* <div className="row">
          <div className="col-md-8 offset-2">
            <div className="pb-10 pull-right">
              <Button
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
                className="mr-3"
              >
                Back
              </Button>
            </div>
          </div>
        </div> */}
        <div className="container-fluid">
          <div className="ediditor-proposl-view">
            {this.state.formData.proposal_id > 0 && (
              <Row>
                <Col className="col-12">
                  <PrivateProposal
                    user_id={this.state.formData.coach_id}
                    isProposalAccepted={this.isProposalAccepted}
                    invoice_id={this.state.formData.invoice_id}
                    proposal_id={this.state.formData.proposal_id}
                    page_id={this.state.page_id}
                  />
                </Col>
              </Row>
            )}
            {this.state.pay_now == "Yes" && (
              <Row>
                <Col className="col-12">
                  <Card>
                    <AvForm
                      onValidSubmit={this.handleSubmit}
                      ref={(c) => (this.form = c)}
                      className="needs-validation"
                    >
                      <div className="row">
                        <div className="col-md-8 col-sm-12 offset-2">
                          <Card id="print-data">
                            <CardBody>
                              <div className="invoice-title d-flex space-between">
                                <h3 className="mt-0">
                                  <img
                                    src={this.state.formData.logo}
                                    alt="logo"
                                    height="50"
                                  />
                                </h3>
                                <div className="font-size-16">
                                  <p>
                                    {convertDateFormat(
                                      this.state.formData.start_date
                                    )}
                                  </p>
                                  <p>
                                    #IN-
                                    {String(
                                      this.state.formData.invoice_id
                                    ).padStart(5, "0")}
                                  </p>
                                  <p>
                                    {" "}
                                    {this.state.formData.type.replace(
                                      "_",
                                      " "
                                    )}{" "}
                                    {this.state.formData.type == "recurring" &&
                                      this.state.formData.renewal_interval}
                                  </p>
                                </div>
                              </div>
                              <hr />
                              <Row>
                                <Col xs="6" className="mt-4">
                                  <div className="invoice-meta">
                                    <p className="for">TO</p>
                                    {this.state.formData.invoice_for ==
                                    "Client" ? (
                                      <div className="invoice-data">
                                        <h6>
                                          {this.state.createdFor.first_name}{" "}
                                          {this.state.createdFor.last_name}
                                        </h6>
                                        <p className="address">
                                          {this.state.createdFor.address}
                                        </p>
                                        <p className="mobile">
                                          {this.state.createdFor.mobile}
                                        </p>
                                        <p className="email">
                                          {this.state.createdFor.email}
                                        </p>
                                      </div>
                                    ) : (
                                      <div className="invoice-data">
                                        <h6>{this.state.createdFor.name}</h6>
                                        <p className="address">
                                          {this.state.createdFor.address}
                                        </p>
                                        <p className="email">
                                          {this.state.createdFor.email}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </Col>
                                <Col xs="6" className="mt-4">
                                  <div className="invoice-meta">
                                    <p className="for">From</p>
                                    <div className="invoice-data">
                                      <h6>{this.state.createdFrom.name}</h6>
                                      <p className="email">
                                        {this.state.createdFrom.email}
                                      </p>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <div className="row mt-4">
                                <div className="col-sm-6 col-md-6 mb-3">
                                  Product
                                </div>
                                {/* <div className="col-sm-6 col-md-6 mb-3">Quantity</div>
                                <div className="col-sm-6 col-md-6 mb-3">
                                  Unit Price($)
                                </div> */}
                                <div className="col-sm-6 col-md-6 mb-3">
                                  Amount ({this.state.currency})
                                </div>
                              </div>
                              {this.defaultUI()}
                              <div className="row">
                                <div className="col-sm-6 col-md-6 mb-3"></div>
                                {/* <div className="col-sm-6 col-md-6 mb-3"></div>
                                <div className="col-sm-6 col-md-6 mb-3"></div> */}
                                <div className="col-sm-6 col-md-6 mb-3">
                                  Total Amount Due: {this.state.currency}
                                  {this.state.formData.total_price}
                                </div>
                              </div>
                              <ModalBody className="row">
                                {this.state.formData.is_user_exist &&
                                  this.state.formData.is_user_exist == "No" && (
                                    <>
                                      {/* <CardTitle className="text-center">
                                    Set password For login to system
                                  </CardTitle>
                                  <div className="col-md-6 mb-3">
                                    <label className="control-label">
                                      {" "}
                                      Enter Password{" "}
                                    </label>
                                    <AvField
                                      name="password"
                                      className="form-control"
                                      type="password"
                                      Placeholder="Enter Password"
                                      value={this.state.record.password}
                                      required
                                    />
                                  </div>
                                  <div className="col-md-6 mb-3">
                                    <label className="control-label"> Confirm Password</label>
                                    <AvField
                                      name="confirm_password"
                                      className="form-control"
                                      type="password"
                                      validate={{ match: { value: 'password' } }}
                                      Placeholder="Confirm Password"
                                      value={this.state.record.confirm_password}
                                      required
                                    />
                                  </div>
                                  <hr /> */}
                                    </>
                                  )}
                                <CardTitle className="text-left mb-3">
                                  Payment Form
                                </CardTitle>
                                <div className="col-md-12 mb-3">
                                  {stripePromise && (
                                    <Elements stripe={stripePromise}>
                                      <PaymentComponent ref={this.childRef} />
                                    </Elements>
                                  )}
                                </div>
                                {/* <div className="col-md-6 mb-3">
                                  <label className="control-label">
                                    {" "}
                                    Card Number{" ---"}
                                  </label>
                                  <AvField
                                    name="cardnumber"
                                    className="form-control"
                                    type="number"
                                    Placeholder="Enter Card Number"
                                    value={this.state.record.cardnumber}
                                    required
                                  />
                                </div> */}
                                {/* <div className="col-md-6 mb-3">
                                  <label className="control-label"> CVC</label>
                                  <AvField
                                    name="cvc"
                                    className="form-control"
                                    type="number"
                                    Placeholder="CVC"
                                    value={this.state.record.cvc}
                                    required
                                  />
                                </div> */}
                                {/* <div className="col-md-6 mb-3">
                                  <AvField
                                    name="expirationdateMM"
                                    label="MM"
                                    className="form-control"
                                    type="number"
                                    Placeholder="MM"
                                    value={this.state.record.expirationdateMM}
                                    required
                                  />
                                </div> */}
                                {/* <div className="col-md-6 mb-3">
                                  <AvField
                                    name="expirationdateYYYY"
                                    label="YYYY"
                                    className="form-control"
                                    type="number"
                                    Placeholder="YYYY"
                                    value={this.state.record.expirationdateYYYY}
                                    required
                                  />
                                </div> */}
                                <div className="col-md-6 mb-3">
                                  <AvField
                                    name="address_line1"
                                    label="Billing Address"
                                    className="form-control"
                                    type="text"
                                    Placeholder="Billing Addresss"
                                    value={this.state.record.address_line1}
                                  />
                                </div>
                                <div className="col-md-6 mb-3">
                                  <AvField
                                    name="address_city"
                                    label="Billing City"
                                    className="form-control"
                                    type="text"
                                    Placeholder="Billing City"
                                    value={this.state.record.address_city}
                                  />
                                </div>
                                <div className="col-md-6 mb-3">
                                  <AvField
                                    name="address_state"
                                    label="Billing State"
                                    className="form-control"
                                    type="text"
                                    Placeholder="Billing State"
                                    value={this.state.record.address_state}
                                  />
                                </div>
                                <div className="col-md-6 mb-3">
                                  <AvField
                                    name="address_zip"
                                    label="Billing Zip"
                                    className="form-control"
                                    type="text"
                                    Placeholder="Billing Zip"
                                    value={this.state.record.address_zip}
                                  />
                                </div>
                              </ModalBody>
                              <Loader
                                loaded={this.state.loaded}
                                className="spinner"
                              >
                                <ModalFooter>
                                  <Button
                                    className="btn-sm"
                                    type="button"
                                    color="secondary"
                                    onClick={() => this.props.history.goBack()}
                                  >
                                    Cancel
                                  </Button>
                                  <button
                                    className="btn btn-primary waves-effect waves-light btn-sm"
                                    type="submit"
                                  >
                                    Submit
                                  </button>
                                </ModalFooter>
                              </Loader>
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                    </AvForm>
                  </Card>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
