import React, { useState, useEffect, useRef } from "react";
import {
    Row,
    Col,
    Card,
    CardTitle,
    Modal,
    ModalBody,
    ModalFooter,
    Button,
} from "reactstrap";
import Loader from "react-loader";
import { ApiUrl } from "../../config";
import { uToken } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import swal from "sweetalert";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {
    AvForm,
    AvField,
    AvGroup,
    AvFeedback,
    AvInput,
} from "availity-reactstrap-validation";
const GoalAchivenmnet = (props) => {

    const [achievementType, setAchievementType] = useState('Personal');
    const [todoLists, setTodoLists] = useState([]);
    const [todoModal, setTodoModal] = useState(false);
    const [todoMonth, setTodoMonth] = useState('01');
    const [todo, setTodo] = useState({});



    useEffect(() => {
        getTodoList('Personal');
    }, []);


    const handleTodoSubmit = async (event, values) => {
        values.assessment_id = props.assessment;
        values.cat_key = props.slug;
        values.type = achievementType;
        values.month = todoMonth;
        fetch(`${ApiUrl}` + "add/todo", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {
                    setTodoModal(false);
                    setTodo({});
                    getTodoList(achievementType, todoMonth);
                } else {
                    swal({
                        title: data.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const getTodoList = (type, month = "01") => {
        fetch(`${ApiUrl}` + "get/todo/" + props.assessment, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({
                cat_key: props.slug,
                type: type,
                month: month
            }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    setAchievementType(type);
                    setTodoMonth(month);
                    setTodoLists(response.data.todoTists);
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const todoRemove = (id) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this action",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "todo/delete/" + id, {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((response) => {
                            if (response.status === true) {
                                getTodoList(achievementType, todoMonth);
                            } else {


                                toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                            }
                        })
                        //Then with the error genereted...
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    };

    const todoComplete = (id) => {
        swal({
            title: "Are you sure?",
            text: "You want to perform this action",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "todo/complete/" + id, {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((response) => {
                            if (response.status === true) {
                                getTodoList(achievementType, todoMonth);
                            } else {


                                toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                            }
                        })
                        //Then with the error genereted...
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    };


    const closeConversationModal = () => {
    }

    return (
        <React.Fragment>
            <div>

                {
                    props.from == 'ClinetProfile' ?

                        (
                            <div className="card card-body">
                                <section className="breakthrough clientProfileHeading sub-nav-bg mb-0 text-center">
                                    <h5 className="mb-0">Monthly Achievements</h5>
                                </section>
                                <div className={`MonthlyDropDown  `} >
                                    <select id="monthSelection" onChange={(e) => getTodoList(achievementType, e.target.value)} className="form-control">
                                        <option disabled value={'0'}>Select Month</option>
                                        <option value={'01'}>January</option>
                                        <option value={'02'}>February</option>
                                        <option value={'03'}>March</option>
                                        <option value={'04'}>April</option>
                                        <option value={'05'}>May</option>
                                        <option value={'06'}>June</option>
                                        <option value={'07'}>July</option>
                                        <option value={'08'}>August</option>
                                        <option value={'09'}>September</option>
                                        <option value={'10'}>October</option>
                                        <option value={'11'}>November</option>
                                        <option value={'12'}>December</option>

                                    </select>
                                </div>
                                <div className="goalAchivenmnetBox">
                                    <Tabs
                                        defaultActiveKey="personal"
                                        className="mb-1 p-0 w-100"
                                        fill
                                        onSelect={(e) => getTodoList(e, todoMonth)}
                                    >

                                        <Tab eventKey="personal" title="Personal">
                                            <div className="d-flex justify-content-between mt-2 mb-1">
                                                <h6 className="text-black">{props.name}'s {todoLists.length} Personal Achievements:</h6>
                                                <button disabled={!props.canEdit ? true : false} onClick={() => { setTodoModal(true); setTodo({}) }} className="btn code-green btn-sm" ><i className="fas fa-plus"></i></button>
                                            </div>
                                            <ul className="list-group todo-list tracker-todo">
                                                {
                                                    todoLists.map((list, i) => {
                                                        return (
                                                            <li className="mb-2" key={i}>
                                                                <div className="d-flex justify-content-between todotask">
                                                                    <div className="list-todo">
                                                                        {
                                                                            list.status == 1 ?
                                                                                <button disabled={!props.canEdit ? true : false} title="UnMark" onClick={() => todoComplete(list.id)} className="btn btn-outline-success boder-green rounded-100"><i className="fas fa-check"></i></button>
                                                                                :
                                                                                <button disabled={!props.canEdit ? true : false} title="Mark as complete" onClick={() => todoComplete(list.id)} className="bwaves-effect waves-light btn btn-outline-dark rounded-100"></button>
                                                                        }
                                                                        <div class="TaskNumber px-1 code-green">{i + 1}</div>
                                                                        <p className="notes text-dark px-1 py-2">
                                                                            <span className="font-weight-bold text-dark">{list.created_at}</span>
                                                                            <hr />
                                                                            {list.description}
                                                                        </p>
                                                                    </div>

                                                                    {
                                                                        list.status == 1 && (
                                                                            <button disabled={!props.canEdit ? true : false} title="Remove" onClick={() => todoRemove(list.id)} className="btn btn-link Remove-Action"><i className="fas fa-trash-alt"></i></button>
                                                                        )
                                                                    }
                                                                    <button disabled={!props.canEdit ? true : false} title="Edit" onClick={() => { setTodoModal(true); setTodo(list) }} className="btn btn-link Remove-Action ml-1"><i className="fas fa-edit"></i></button>

                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </Tab>
                                        <Tab eventKey="professional" title="Professional">
                                            <div className="d-flex justify-content-between mt-2 mb-1">
                                                <h6 className="text-black">{props.name}'s {todoLists.length} Professional Achievements:</h6>
                                                <button disabled={!props.canEdit ? true : false} onClick={() => setTodoModal(true)} className="btn code-green btn-sm" ><i className="fas fa-plus"></i></button>
                                            </div>
                                            <ul className="list-group todo-list tracker-todo">
                                                {
                                                    todoLists.map((list, i) => {
                                                        return (
                                                            <li className="mb-2" key={i}>
                                                                <div className="d-flex justify-content-between todotask">
                                                                    <div className="list-todo">
                                                                        {
                                                                            list.status == 1 ?
                                                                                <button disabled={!props.canEdit ? true : false} title="UnMark" onClick={() => todoComplete(list.id)} className="btn btn-outline-success boder-green rounded-100"><i className="fas fa-check"></i></button>
                                                                                :
                                                                                <button disabled={!props.canEdit ? true : false} title="Mark as complete" onClick={() => todoComplete(list.id)} className="bwaves-effect waves-light btn btn-outline-dark rounded-100"></button>
                                                                        }
                                                                        <div class="TaskNumber px-1 code-green">{i + 1}</div>
                                                                        <p className="notes text-dark px-1 py-2">
                                                                            <span className="font-weight-bold text-dark">{list.created_at}</span>
                                                                            <hr />
                                                                            {list.description}
                                                                        </p>
                                                                    </div>

                                                                    {
                                                                        list.status == 1 && (
                                                                            <button disabled={!props.canEdit ? true : false} title="Remove" onClick={() => todoRemove(list.id)} className="btn btn-link Remove-Action"><i className="fas fa-trash-alt"></i></button>
                                                                        )
                                                                    }
                                                                    <button disabled={!props.canEdit ? true : false} title="Edit" onClick={() => { setTodoModal(true); setTodo(list) }} className="btn btn-link Remove-Action ml-1"><i className="fas fa-edit"></i></button>

                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </Tab>
                                        <Tab eventKey="financial" title="Financial">
                                            <div className="d-flex justify-content-between mt-2 mb-1">
                                                <h6 className="text-black">{props.name}'s {todoLists.length} Financial Achievements:</h6>
                                                <button disabled={!props.canEdit ? true : false}  onClick={() => setTodoModal(true)} className="btn code-green btn-sm" ><i className="fas fa-plus"></i></button>
                                            </div>
                                            <ul className="list-group todo-list tracker-todo">
                                                {
                                                    todoLists.map((list, i) => {
                                                        return (
                                                            <li className="mb-2" key={i}>
                                                                <div className="d-flex justify-content-between todotask">
                                                                    <div className="list-todo">
                                                                        {
                                                                            list.status == 1 ?
                                                                                <button disabled={!props.canEdit ? true : false}  title="UnMark" onClick={() => todoComplete(list.id)} className="btn btn-outline-success boder-green rounded-100"><i className="fas fa-check"></i></button>
                                                                                :
                                                                                <button disabled={!props.canEdit ? true : false}  title="Mark as complete" onClick={() => todoComplete(list.id)} className="bwaves-effect waves-light btn btn-outline-dark rounded-100"></button>
                                                                        }
                                                                        <div class="TaskNumber px-1 code-green">{i + 1}</div>
                                                                        <p className="notes text-dark px-1 py-2">
                                                                            <span className="font-weight-bold text-dark">{list.created_at}</span>
                                                                            <hr />
                                                                            {list.description}
                                                                        </p>
                                                                    </div>

                                                                    {
                                                                        list.status == 1 && (
                                                                            <button disabled={!props.canEdit ? true : false} title="Remove" onClick={() => todoRemove(list.id)} className="btn btn-link Remove-Action"><i className="fas fa-trash-alt"></i></button>
                                                                        )
                                                                    }
                                                                    <button disabled={!props.canEdit ? true : false}  title="Edit" onClick={() => { setTodoModal(true); setTodo(list) }} className="btn btn-link Remove-Action ml-1"><i className="fas fa-edit"></i></button>

                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </Tab>

                                    </Tabs>
                                </div>
                            </div>

                        )
                        :
                        (
                            <>
                                <section className="conversation breakthrough sub-nav-bg mb-0 text-center">
                                    <div className={`MonthlyDropDown  `} >
                                        <select id="monthSelection" onChange={(e) => getTodoList(achievementType, e.target.value)} className="form-control">
                                            <option disabled value={'0'}>Select Month</option>
                                            <option value={'01'}>January</option>
                                            <option value={'02'}>February</option>
                                            <option value={'03'}>March</option>
                                            <option value={'04'}>April</option>
                                            <option value={'05'}>May</option>
                                            <option value={'06'}>June</option>
                                            <option value={'07'}>July</option>
                                            <option value={'08'}>August</option>
                                            <option value={'09'}>September</option>
                                            <option value={'10'}>October</option>
                                            <option value={'11'}>November</option>
                                            <option value={'12'}>December</option>

                                        </select>
                                    </div>
                                    <h5 className="mb-0">Monthly Achievements</h5>
                                    <button className="conversation-break-down--edit-btn btn btn-link btn-sm text-white btn-block invisible"><i className="fas fa-edit"></i></button>

                                </section>
                                <div className="card card-body system-custom-border goalAchivenmnetBox">
                                    <div className="row ">
                                        <div className="col-lg-12">
                                            <div className="">

                                                <Tabs
                                                    defaultActiveKey="personal"
                                                    className="mb-3"
                                                    fill
                                                    onSelect={(e) => getTodoList(e, todoMonth)}
                                                >

                                                    <Tab eventKey="personal" title="Personal">
                                                        <div className="d-flex justify-content-between mt-2 mb-1">
                                                            <h6 className="text-black">{props.name}'s {todoLists.length} Personal Achievements:</h6>
                                                            <button disabled={!props.canEdit ? true : false} onClick={() => setTodoModal(true)} className="btn code-green btn-sm" ><i className="fas fa-plus"></i></button>
                                                        </div>
                                                        <ul className="list-group todo-list tracker-todo">
                                                            {
                                                                todoLists.map((list, i) => {
                                                                    return (
                                                                        <li className="mb-2" key={i}>
                                                                            <div className="d-flex justify-content-between todotask">
                                                                                <div className="list-todo">
                                                                                    {
                                                                                        list.status == 1 ?
                                                                                            <button disabled={!props.canEdit ? true : false} title="UnMark" onClick={() => todoComplete(list.id)} className="btn btn-outline-success boder-green rounded-100"><i className="fas fa-check"></i></button>
                                                                                            :
                                                                                            <button disabled={!props.canEdit ? true : false} title="Mark as complete" onClick={() => todoComplete(list.id)} className="bwaves-effect waves-light btn btn-outline-dark rounded-100"></button>
                                                                                    }
                                                                                    <div class="TaskNumber px-2 code-green">{i + 1}</div>
                                                                                    <p className="notes text-dark p-2">
                                                                                        <span className="font-weight-bold text-dark">{list.created_at}</span>
                                                                                        <hr className="my-1" />
                                                                                        {list.description}
                                                                                    </p>
                                                                                </div>

                                                                                {
                                                                                    list.status == 1 && (
                                                                                        <button  disabled={!props.canEdit ? true : false} title="Remove" onClick={() => todoRemove(list.id)} className="btn btn-link Remove-Action"><i className="fas fa-trash-alt"></i></button>
                                                                                    )
                                                                                }
                                                                                <button  disabled={!props.canEdit ? true : false} title="Edit" onClick={() => { setTodoModal(true); setTodo(list) }} className="btn btn-link Remove-Action ms-2"><i className="fas fa-edit"></i></button>

                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </Tab>
                                                    <Tab eventKey="professional" title="Professional">
                                                        <div className="d-flex justify-content-between mt-2 mb-1">
                                                            <h6 className="text-black">{props.name}'s {todoLists.length} Professional Achievements:</h6>
                                                            <button  disabled={!props.canEdit ? true : false} onClick={() => setTodoModal(true)} className="btn code-green btn-sm" ><i className="fas fa-plus"></i></button>
                                                        </div>
                                                        <ul className="list-group todo-list tracker-todo">
                                                            {
                                                                todoLists.map((list, i) => {
                                                                    return (
                                                                        <li className="mb-2" key={i}>
                                                                            <div className="d-flex justify-content-between todotask">
                                                                                <div className="list-todo">
                                                                                    {
                                                                                        list.status == 1 ?
                                                                                            <button disabled={!props.canEdit ? true : false}  title="UnMark" onClick={() => todoComplete(list.id)} className="btn btn-outline-success boder-green rounded-100"><i className="fas fa-check"></i></button>
                                                                                            :
                                                                                            <button  disabled={!props.canEdit ? true : false} title="Mark as complete" onClick={() => todoComplete(list.id)} className="bwaves-effect waves-light btn btn-outline-dark rounded-100"></button>
                                                                                    }
                                                                                    <div class="TaskNumber px-2 code-green">{i + 1}</div>
                                                                                    <p className="notes text-dark p-2">
                                                                                        <span className="font-weight-bold text-dark">{list.created_at}</span>
                                                                                        <hr className="my-1" />
                                                                                        {list.description}
                                                                                    </p>
                                                                                </div>

                                                                                {
                                                                                    list.status == 1 && (
                                                                                        <button  disabled={!props.canEdit ? true : false} title="Remove" onClick={() => todoRemove(list.id)} className="btn btn-link Remove-Action"><i className="fas fa-trash-alt"></i></button>
                                                                                    )
                                                                                }
                                                                                <button  disabled={!props.canEdit ? true : false} title="Edit" onClick={() => { setTodoModal(true); setTodo(list) }} className="btn btn-link Remove-Action ms-2"><i className="fas fa-edit"></i></button>

                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </Tab>
                                                    <Tab eventKey="financial" title="Financial">
                                                        <div className="d-flex justify-content-between mt-2 mb-1">
                                                            <h6 className="text-black">{props.name}'s {todoLists.length} Financial Achievements:</h6>
                                                            <button  disabled={!props.canEdit ? true : false} onClick={() => setTodoModal(true)} className="btn code-green btn-sm" ><i className="fas fa-plus"></i></button>
                                                        </div>
                                                        <ul className="list-group todo-list tracker-todo">
                                                            {
                                                                todoLists.map((list, i) => {
                                                                    return (
                                                                        <li className="mb-2" key={i}>
                                                                            <div className="d-flex justify-content-between todotask">
                                                                                <div className="list-todo">
                                                                                    {
                                                                                        list.status == 1 ?
                                                                                            <button disabled={!props.canEdit ? true : false} title="UnMark" onClick={() => todoComplete(list.id)} className="btn btn-outline-success boder-green rounded-100"><i className="fas fa-check"></i></button>
                                                                                            :
                                                                                            <button disabled={!props.canEdit ? true : false} title="Mark as complete" onClick={() => todoComplete(list.id)} className="bwaves-effect waves-light btn btn-outline-dark rounded-100"></button>
                                                                                    }
                                                                                    <div class="TaskNumber px-2 code-green">{i + 1}</div>
                                                                                    <p className="notes text-dark p-2">
                                                                                        <span className="font-weight-bold text-dark">{list.created_at}</span>
                                                                                        <hr className="my-1" />
                                                                                        {list.description}
                                                                                    </p>
                                                                                </div>

                                                                                {
                                                                                    list.status == 1 && (
                                                                                        <button  disabled={!props.canEdit ? true : false} title="Remove" onClick={() => todoRemove(list.id)} className="btn btn-link Remove-Action"><i className="fas fa-trash-alt"></i></button>
                                                                                    )
                                                                                }
                                                                                <button  disabled={!props.canEdit ? true : false}  title="Edit" onClick={() => { setTodoModal(true); setTodo(list) }} className="btn btn-link Remove-Action ms-2"><i className="fas fa-edit"></i></button>

                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </Tab>

                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                }
                <Modal
                    isOpen={todoModal}
                    fade={true}
                    size="md"
                    toggle={() => {
                        setTodoModal(false);
                        setTodo({})
                    }}
                >
                    <div className="note">
                        <div className="modal-head top-heading">
                            <h2> {todo.id ? 'Edit' : 'Add'} Achievements </h2>
                            <button
                                onClick={() => {
                                    setTodoModal(false);
                                    setTodo({})

                                }}
                                className="btn btn-link text-white"
                            >
                                <i className="fas fa-times"> </i>
                            </button>
                        </div>
                        <AvForm onValidSubmit={handleTodoSubmit}
                            // model={this.state.record}
                            className="needs-validation">
                            <ModalBody className="pb-0">
                                <div className="mb-3">
                                    <AvField className="form-control" type="textarea" name="description" label="" placeholder="Add a goal" required value={todo.description} rows={5}/>
                                    {todo.id && (
                                        <AvField name="id" type="hidden" value={todo.id} required></AvField>
                                        )
                                    }
                                </div>
                            </ModalBody>
                            <ModalFooter className="border-0 pt-0">
                                <Button className="btn-sm" color="secondary" onClick={() => { setTodoModal(false); setTodo({});}}>Cancel</Button>
                                <Button className="Green btn-sm" type="submit">Save</Button>
                            </ModalFooter>
                        </AvForm>
                    </div>
                </Modal>
            </div>

        </React.Fragment >
    );
};

export default GoalAchivenmnet;
