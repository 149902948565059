import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  ModalFooter,
  TabContent,
  TabPane,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import Badge from 'react-bootstrap/Badge';
//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import { hasRole, isAllowed, uInfo, uRole, uToken } from "../../../useToken";
import { ApiUrl, ProductName } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";

export default class DatatableTables extends Component {
  constructor(props) {
    super(props);
    this.columnstable = [
      {
        text: "Sr#",
        key: "sr",
        sortable: false,
      },
      {
        text: "Name",
        key: "prefix_name",
        sortable: false,
      },
      // {
      //   text: "Total Manager",
      //   key: "total_manager",
      //   sortable: false,
      // },
      {
        text: "Total lead",
        key: "total_lead",
        sortable: false,
      },
      {
        text: "Created At",
        key: "created_at",
      },
    ];
    this.columns = [
      {
        text: "Sr#",
        key: "sr_1",
        sortable: false,
      },
      {
        text: "Name",
        key: "name",
        sortable: false,
      },
      {
        text: "Email",
        key: "email",
        sortable: false,
      },
      {
        text: "Role",
        key: "role_name",
        sortable: false,
      },
      {
        text: "Status",
        key: "status",
        sortable: false,
        cell: (record, index) => {
          return (
            <Fragment>
               {record.status=='Active' && (
              <Badge bg="success">{record.status}</Badge>
               )}
                {record.status=='Inactive' && (
              <Badge bg="danger">{record.status}</Badge>
               )}
            </Fragment>
          );
        },
      },
      {
        key: "swap",
        text: "Lead",
        cell: (record, index) => {
          return (
            <Fragment>
              <select
                value={record.lead_table}
                onChange={(e) => this.leadsUpdate(e, record)}
                className="form-control"
                name="table_lead"
              >
                {this.state.leads.map((lead) => {
                  return (
                    <option value={lead.name}> {lead.prefix_name} </option>
                  );
                })}
              </select>
            </Fragment>
          );
        },
      },
    ];
    this.configtable = {
      key_column: 'sr',
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
      sort: { column: "total_lead", order: "desc" },
      button: {
        excel: false,
        print: true,
        csv: true,
      },
    };
    this.config = {
      key_column: 'sr_1',
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
      sort: { column: "total_lead", order: "desc" },
      button: {
        excel: false,
        print: true,
        csv: true,
      },
    };
    this.state = {
      tab: "1",
      records: [],
      recordsinfo: [],
      total_pages_info: 0,
      total_pages: 0,
      record: {},
      page_name: "Clients Table ",
      filter: {},
      customDate: true,
      leads: [],
    };
    this.leadsUpdate = this.leadsUpdate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitaddtable = this.handleSubmitaddtable.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.getDatainfo();
  }

  leadsUpdate = (leads, record) => {
    leads.preventDefault();
    if (window.confirm("Are you sure you want to create new table?")) {
      fetch(`${ApiUrl}` + "update/user/lead", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify({
          user_id: record.id,
          lead_table: leads.target.value,
        }),
      })
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((data) => {
          if (data.status === true) {
            this.getData();
            toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          } else {
            toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        //Then with the error genereted...
        .catch((error) => {
          console.error("Error:", error);
        });
      leads.target.value = leads.target.value;
    } else {
      leads.target.value = record.lead_table;
    }
  };
  toggle(index) {
    this.setState({
      tab: index,
    });
  }
  getDatainfo = (queryString = "") => {
    fetch(`${ApiUrl}` + "info" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          this.setState({
            total_pages_info: data.data.total,
            recordsinfo: data.data.records,
          });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getData = (queryString = "", data) => {
    fetch(`${ApiUrl}` + "leadsetting" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log(data, "Leads data");
        if (data.status === true) {
          this.setState({
            total_pages: data.data.total,
            records: data.data.records,
            leads: data.data.leads,
          });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = async () => {
    // console.log(this, 'assd');
    fetch(`${ApiUrl}` + "add/table", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          this.getData();
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleFilterSubmit = async (event, values) => {
    this.setState({
      filter: values,
    });
    this.getData("", values);
  };
  cancelFilter = async () => {
    this.form && this.form.reset();
    this.setState({ filter: {}, customDate: true });
    this.getData("", "");
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
          // return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData("?" + queryString, this.state.filter);
  };
  tableChangeHandler2 = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
          // return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getDatainfo("?" + queryString);
  };

  handleSubmitaddtable = async () => {
    fetch(`${ApiUrl}` + "add/table", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log(data, "ADD Table");
        if (data.status === true) {
          this.getDatainfo();
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {this.state.page_name}
           
          </title>
        </Helmet>

        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.page_name}</h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#"> {this.state.page_name}</Link>
                </li>
              </ol>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="col-lg-12">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={this.state.tab == 1 ? "active" : ""}
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  Client's Table
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={this.state.tab == 2 ? "active" : ""}
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  Tables List
                </NavLink>
              </NavItem>
            </Nav>
          </Col>

          <TabContent activeTab={this.state.tab}>
            <TabPane tabId="1">
              <Row>
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <AvForm
                        onValidSubmit={this.handleFilterSubmit}
                        ref={(c) => (this.form = c)}
                        className="needs-validation"
                      >
                        <Row>
                          <Col md="4">
                            <div className="mb-3">
                              <AvField
                                className="select form-select"
                                type="select"
                                name="date_range"
                                label="Date Filter"
                                onChange={(e) =>
                                  e.target.value == "Custom Date"
                                    ? this.setState({
                                        customDate: false,
                                      })
                                    : this.setState({
                                        customDate: true,
                                      })
                                }
                              >
                                <option value=""> All Time </option>
                                <option value="Current Week">
                                  {" "}
                                  Current Week{" "}
                                </option>
                                <option value="Last Week"> Last Week </option>
                                <option value="Current Month">
                                  {" "}
                                  Current Month{" "}
                                </option>
                                <option value="Last Month"> Last Month </option>
                                <option value="Last 3 Months">
                                  {" "}
                                  Last 3 Months{" "}
                                </option>
                                <option value="Last 6 Months">
                                  {" "}
                                  Last 6 Months{" "}
                                </option>
                                <option value="Current Year">
                                  {" "}
                                  Current Year{" "}
                                </option>
                                <option value="Last Year"> Last Year </option>
                                <option value="Custom Date">
                                  {" "}
                                  Custom Date{" "}
                                </option>
                              </AvField>
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <AvField
                                name="start_date"
                                label="Start date"
                                className="form-control"
                                placeholder="Enter Email Address"
                                type="date"
                                disabled={this.state.customDate}
                                required={!this.state.customDate}
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <AvField
                                name="end_date"
                                label="End date"
                                className="form-control"
                                placeholder="Enter Email Address"
                                type="date"
                                disabled={this.state.customDate}
                                required={!this.state.customDate}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="4">
                            <div className="mb-3">
                              <AvField
                                className="select form-select"
                                type="select"
                                name="lead_table"
                                label="Lead table"
                              >
                                <option value={""}> Select Lead </option>
                                {this.state.leads.map((lead) => {
                                  return (
                                    <option value={lead.name}>
                                      {lead.prefix_name}
                                    </option>
                                  );
                                })}
                              </AvField>
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <AvField
                                className="select form-select"
                                type="select"
                                id="s"
                                name="status"
                                label="Status"
                              >
                                <option value={""}> Select Status </option>
                                <option value={"Active"}> Active </option>
                                <option value={"Inactive"}> Inactive </option>
                              </AvField>
                            </div>
                          </Col>
                        </Row>
                        <div className="button-items">
                          <Button
                            className="btn-sm"
                            color="primary"
                            type="submit"
                          >
                            Filter
                          </Button>
                          <Button
                            onClick={() => this.cancelFilter()}
                            outline
                            color="secondary"
                            className="waves-effect btn-sm"
                            type="button"
                          >
                            Reset
                          </Button>
                        </div>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <ToastContainer />
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <ReactDatatable
                        config={this.config}
                     
                        records={this.state.records}
                        columns={this.columns}
                        dynamic={true}
                        total_record={this.state.total_pages}
                        onChange={this.tableChangeHandler}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>

            <TabPane tabId={"2"}>
              <ToastContainer />
              <Row>
                <Col className="col-xl-12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col sm={6}></Col>
                        <Col sm={6}>
                          <div
                            className="page-title-box text-align-right"
                            style={{ marginTop: "10px" }}
                          >
                            {isAllowed(uInfo(), ["can_create"]) && (
                              <Button
                                type="button"
                                color="info"
                                onClick={(e) => {
                                  if (
                                    window.confirm(
                                      "Are you sure you want to create new table?"
                                    )
                                  )
                                    this.handleSubmitaddtable(e);
                                }}
                              >
                                Create
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <ReactDatatable
                        config={this.configtable}
                      
                        records={this.state.recordsinfo}
                        columns={this.columnstable}
                        dynamic={true}
                        total_record={this.state.total_pages_info}
                        onChange={this.tableChangeHandler2}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Row>
      </React.Fragment>
    );
  }
}
