import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
  CardSubtitle,
} from "reactstrap";
import Helmet from "react-helmet";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { dcrypt, bcrypt, isAllowed, uInfo, uToken } from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Select from "react-select";

export default function EditAddon() {
  const { id } = useParams();
  const addonId = dcrypt(id);
  const [addonData, setAddonData] = useState({});
  const [packages, setPackages] = useState([]);

  const routeHistory = useHistory();

  const getAddonDetail = () => {
    fetch(`${ApiUrl}addons/detail`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify({ id: addonId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          setAddonData(data.data.record);
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getPackages = () => {
    fetch(`${ApiUrl}` + "packages/list/Coach", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      //   body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setPackages(response.data.records);
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    getAddonDetail();
    getPackages();
  }, []);

  const saveAddonDetail = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }

    fetch(`${ApiUrl}` + "addons/edit", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())

      .then((response) => {
        if (response.status === true) {
          setAddonData(response.data);
          //   setEdit(false);
          swal({
            title: response.message,
            icon: "success",
          });
        } else {
          setAddonData([]);
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        swal({
          title: "An error occurred",
          icon: "error",
        });
      });
  };

  const handlePackageChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option.id);
    setAddonData((prevState) => ({
      ...prevState,
      addon_packages: selectedIds,
    }));
  };

  return (
    <>
      <Helmet>
        <title>Edit Addon</title>
      </Helmet>

      <Row>
        <Col sm={6}>
          <div className="page-title-box">
            <h4> Edit Addon </h4>
            <ol className="breadcrumb m-0">
              <li key={0} className="breadcrumb-item active">
                {ProductName}
              </li>
              <li key={1} className="breadcrumb-item">
                <Link to="#"> Edit Addon </Link>
              </li>
            </ol>
          </div>
        </Col>
        <Col sm={6}>
          <div className="page-title-box text-align-right">
            <Button
              type="button"
              color="secondary"
              onClick={() => routeHistory.goBack()}
            >
              Back
            </Button>
          </div>
        </Col>
      </Row>

      <form
        method="post"
        onSubmit={saveAddonDetail}
        encType="multipart/form-data"
        className="form-horizontal"
        id="selection-myform"
      >
        <Row className="mt-4">
          <Col md="6">
            <div className="form-group mb-3">
              <label>Title</label>
              <input
                name="title"
                defaultValue={addonData.title}
                className="form-control"
                placeholder="Enter addon title"
                required
              />

              <input
                name="id"
                defaultValue={addonData.id || ""}
                type="hidden"
              />
            </div>
          </Col>

          <Col md="6">
            <div className="form-group mb-3">
              <label>Price</label>
              <div className="group-addnon">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      id="validationTooltipUsernamePrepend"
                    >
                      {" "}
                      ${" "}
                    </span>
                  </div>
                  <input
                    name="price"
                    defaultValue={addonData.price}
                    className="form-control w-100"
                    placeholder="Enter addon price"
                    required
                    type={"number"}
                    step={"any"}
                    min={1}
                  />
                </div>
              </div>
            </div>
          </Col>

          <Col md="6">
            <div className="form-group mb-3">
              <label>Interval</label>
              <select
                name="pricing_interval"
                defaultValue={addonData.pricing_interval}
                className="form-select "
                required
              >
                <option value="Monthly">Monthly</option>
                <option value="OneTime">One time</option>
              </select>
            </div>
          </Col>

          <Col md="6">
            <div className="form-group mb-3">
              <label>Status</label>
              <select
                name="status"
                defaultValue={addonData.status}
                className="form-select "
                required
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group mb-3">
              <label>Addon For</label>
              <Select
                // options={courseOptions}
                // onChange={handleCourseSelect}
                // value={selectedCourse}
                placeholder="Choose options"
                className="basic-single"
                classNamePrefix="select"
              />
            </div>
          </Col>
          <Col md="6">
            <div className="form-group mb-3">
              <label>Addon Packages</label>
              <Select
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                isMulti={true}
                value={packages.filter(
                  (obj) =>
                    addonData.addon_packages &&
                    addonData.addon_packages.includes(obj.id)
                )}
                options={packages}
                onChange={handlePackageChange}
                classNamePrefix="select2-selection position-releative"
                name="addon_packages[]"
              />
            </div>
          </Col>

          <Col md="12">
            <div className="form-group mb-3">
              <label>Description</label>
              <textarea
                name="description"
                defaultValue={addonData.description}
                className="form-control"
                placeholder="Enter addon description"
              />
            </div>
          </Col>

          <Col md="12">
            <div className="d-flex justify-content-end">
              {/* {edit && ( */}
              <Button
                color="secondary"
                type="button"
                onClick={() => routeHistory.goBack()}
              >
                Cancel
              </Button>
              {/* )} */}
              <Button className="ms-3 Green" type="submit">
                Submit
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
}
