import React, { useState } from 'react';
import { ApiUrl } from '../../../config';
import { uInfo, uToken } from '../../../useToken';
import Phone from '../../InitiateCall/Phone';
import swal from 'sweetalert';

const CallWidget = ({ info, className }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [callToken, setCallToken] = useState(null);
    const [onCall, setonCall] = useState(false);

    const identity = "proglobal";

    const handleClick = () => {
        if (showPopup) {
            handlePopUp();
        } else {
            setShowPopup(true);
            if (callToken == null || callToken == '') {
                handleOutCall();
            }
        }
    };

    const paramsters = {
        token: callToken,
        from_number: uInfo().outbound_phone,
        phoneno: info.phoneno,
        record: "false",
        client_id: info.client_id,
        coach_id: info.coach_id,
        phone: info.phone,
        mobile: info.mobile,
    };

    const handleOutCall = () => {
        fetch(`${ApiUrl}` + "voice/token", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ identity: encodeURIComponent(identity) }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setCallToken(response.data.token);
                } else {
                    swal({
                        title: "Error",
                        text: response.message,
                        icon: "warning",
                    })

                    // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const getCallSid = (call_sid) => {
        if (call_sid) {
            setonCall(true);
        } else {
            setonCall(false);
        }
    };

    const handlePopUp = () => {
        setShowPopup(false);
        // swal({
        //   title: "Are you sure?",
        //   text: "Close the popup. Do not close it when you are on a call; first hang up.",
        //   icon: "warning",
        //   buttons: true,
        //   dangerMode: true,
        // }).then((confirmation) => {
        //   if (confirmation) {
        //     setShowPopup(false);
        //   }
        // });
    };

    return (
        <>
            <button className={`call-widget-button ${className}`} onClick={handleClick}>
                <i className="fas fa-phone-alt"></i>
            </button>
            {showPopup && (
                <div
                    className="call-widget open-widget-right-bottom "
                    style={{
                        position: 'fixed',
                        bottom: '20px',
                        right: '20px',
                        padding: '10px',
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        color: '#fff',
                        borderRadius: '5px',
                        zIndex: '1',
                        width: '230px'
                    }}
                >
                    {callToken ? (
                        <div className='compant-action-btn'>
                            <Phone paramsters={paramsters} getCallSid={getCallSid}></Phone>
                        </div>
                    ) : (
                        <p>Loading...</p>
                    )}

                    {
                        !onCall && (
                            <button
                                title="Close the popup. Do not close it when you are on a call; first hang up."
                                className="btn btn-danger btn-md"
                                type="button"
                                onClick={() => setShowPopup(false)}
                            >
                                <i className="fas fa-times"></i>
                            </button>
                        )
                    }
                </div>
            )}
        </>
    );
};

export default CallWidget;
