import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
// import InvoiceTransactions from "./InvoiceTransactions.js";
//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import {
  currentDateTime,
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
  convertDateFormat,
  NumberWithCommas,
} from "../../useToken";
import { ApiUrl, ProductName, logoLightPng } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
// import '../../../node_modules/bootstrap/js/dist/tooltip';
import Select from "react-select";
import countries from "../../common/data/countries";
import { currencies } from "../../common/Common-Currency.js";
export default class CreateUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      page_name: "Invoice Preview",
      from: this.props.from ? this.props.from : "",
      record: {},
      clients: [],
      coachPartners: [],
      customFields: [{ items: "", quantity: "0", price: "0", total: "0" }],
      formData: {
        start_date: currentDateTime(),
        expire_date: "",
        type: "one_time",
        invoice_for: "Member",
        renewal_interval: "Weekly",
        items_data: [],
        email_subject: "",
        notes: "",
        confirmation_email: "",
        created_for_id: 0,
        activity: "0",
        logo: "",
        payment_status: "",
      },
      filter_value: "",
      createdFor: {},
      currency: "",
    };
  }

  componentDidMount() {
    this.state.id != null && this.getData();
    window.onafterprint = function () {
      window.location.reload();
    };
  }

  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/invoice", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },

      body: JSON.stringify({
        id: this.state.id,
        coach_id: this.state.coach_id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          console.log("response", response);
          this.getInvoiceCurrency(response.data.record.currency_code);
          this.setState({
            formData: response.data.record,
            customFields: JSON.parse(response.data.record.items_data),
            createdFor: response.data.record.createdFor,
          });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getInvoiceCurrency = (code) => {
    let currencysymbol = currencies[code];
    if (currencysymbol) {
      this.setState({ currency: currencysymbol.symbol });
    }
  };

  defaultUI() {
    return this.state.customFields.map(
      (el, i) =>
        el.total > 0 && (
          <div className="border-top-2 border-bottom-2 px-1 py-1" key={i}>
            <div className="row">
              <div className="col-sm-6 col-md-6 d-flex align-items-center">
                {el.items}
              </div>
              {/* <div className="col-sm-3 col-md-3 d-flex align-items-center">
                        {el.quantity}
                    </div>
                    <div className="col-sm-3 col-md-3 d-flex align-items-center">  {el.price}

                    </div> */}
              <div className="col-sm-6 col-md-6 d-flex align-items-center">
                {this.state.currency}
                {NumberWithCommas(Math.round(el.total))}
              </div>
            </div>
          </div>
        )
    );
  }

  PrintElem() {
    var printContents = document.getElementById("print-data").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <ToastContainer />
        <div className="row">
          <div className="col-md-8 offset-2">
            <div className="pb-10 pull-right">
              <Button
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
                className="mr-3 btn-sm"
              >
                Back
              </Button>
              {/* <Button
                                type="button"
                                color="info"
                                onClick={() => this.PrintElem()}
                                className="mr-3"
                            >
                                Print
                            </Button> */}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8 col-sm-12 offset-2">
            <Card id="">
              <CardBody>
                <div className="invoice-title d-flex space-between">
                  <h3 className="mt-0">
                    <img
                      src={this.state.formData.logo}
                      alt="logo"
                      height="50"
                    />
                  </h3>
                  <div className="font-size-16">
                    <p>{convertDateFormat(this.state.formData.start_date)}</p>
                    <p>
                      #IN-
                      {String(this.state.formData.invoice_id).padStart(5, "0")}
                    </p>
                    <p>
                      {" "}
                      {this.state.formData.type.replace("_", " ")}{" "}
                      {this.state.formData.type == "recurring" &&
                        this.state.formData.renewal_interval}
                    </p>
                  </div>
                </div>
                <hr />
                <Row>
                  <Col xs="6" className="mt-4">
                    <div className="invoice-meta">
                      <p className="for">TO</p>
                      {this.state.formData.invoice_for == "Client" ? (
                        <div className="invoice-data">
                          <h6>
                            {this.state.createdFor.first_name}{" "}
                            {this.state.createdFor.last_name}
                          </h6>
                          <p className="address">
                            {this.state.createdFor.address}
                          </p>
                          <p className="mobile">
                            {this.state.createdFor.mobile}
                          </p>
                          <p className="email">{this.state.createdFor.email}</p>
                        </div>
                      ) : (
                        <div className="invoice-data">
                          {this.state.createdFor ? (
                            <>
                              <h6>{this.state.createdFor.name || ""}</h6>
                              <p className="address">
                                {this.state.createdFor.address || ""}
                              </p>
                              <p className="email">
                                {this.state.createdFor.email || ""}
                              </p>
                            </>
                          ) : (
                            <p>No data available</p>
                          )}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col xs="6" className="mt-4">
                    <div className="invoice-meta">
                      <p className="for">From</p>
                      <div className="invoice-data">
                        <h6>{this.state.formData?.createdBy?.name}</h6>
                        <p className="email">
                          {this.state.formData?.createdBy?.email}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="row mt-4">
                  <div className="col-sm-6 col-md-6 mb-1">
                    <b>Product</b>
                  </div>
                  {/* <div className="col-sm-3 col-md-3 mb-1"><b>Quantity</b></div> */}
                  {/* <div className="col-sm-3 col-md-3 mb-1"><b>Unit Price($)</b></div> */}
                  <div className="col-sm-6 col-md-6 mb-1">
                    <b>Amount</b>
                  </div>
                </div>
                {this.defaultUI()}
                <div className="row">
                  <div className="col-sm-6 col-md-6 mb-3"></div>
                  {/* <div className="col-sm-6 col-md-6 mb-3"></div>
                                    <div className="col-sm-6 col-md-6 mb-3"></div> */}
                  <div className="col-sm-6 col-md-6 mb-3 p-2">
                    <b>
                      {" "}
                      Total Amount Due: {this.state.currency}
                      {NumberWithCommas(this.state.formData.total_price)}
                    </b>
                  </div>
                </div>

                {this.state.formData.notes && (
                  <div className="form-group">
                    <label>Note</label>
                    <p className="notes">{this.state.formData.notes}</p>
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        </div>

        {/* {this.state.formData.payment_status != "Unpaid" && (
          <Row>
            <Col className="col-md-8 col-sm-12 offset-2">
              <Card>
                <CardBody className="mt-0 pt-0">
                  <InvoiceTransactions />
                </CardBody>
              </Card>
            </Col>
          </Row>
        )} */}
      </React.Fragment>
    );
  }
}
