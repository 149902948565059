import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Table,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Progress
} from "reactstrap";
import Helmet from "react-helmet";
import { Link, Redirect, useLocation } from "react-router-dom";
import Phone from "../../InitiateCall/Phone";
import {
  ApiUrl,
  AttachementsUrl,
  HostUrl,
  ProductName,
} from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { bcrypt, convertPhoneFormat, dcrypt, isObjectEmpty, uInfo, uToken } from "../../../useToken";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Badge from "react-bootstrap/Badge";
import "audio-react-recorder/dist/index.css";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import {
  AvForm,
  AvField,
  AvGroup,
  AvFeedback,
  AvInput,
} from "availity-reactstrap-validation";
import AvRadioGroup from "availity-reactstrap-validation/lib/AvRadioGroup";
import AvRadio from "availity-reactstrap-validation/lib/AvRadio";
import Select from "react-select";
import { Accordion, Dropdown, Tab, Tabs, Tooltip } from "react-bootstrap";
import Avatar from "react-avatar";
import CreateUpdate from "../Clients/ClientCreateUpdate.js";
import CreateTask from "../../Tasks/create.js";

import $ from "jquery";
import { useHistory } from "react-router-dom";
import Loader from "react-loader";
import swal from 'sweetalert';
import {
  Asia,
  Africa,
  America,
  Antartica,
  Atlantic,
  Australia,
  Europe,
  Indian,
  Pacific,
} from "../../../common/data/timezones";
const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const ChatInbox = (props) => {
  const [page_name] = useState("Lead Dialer");
  const [lead_id, setLeadId] = useState(dcrypt(props.match.params.id) > 0 ? dcrypt(props.match.params.id) : 0);

  const [coach_id, setCoachId] = useState(dcrypt(props.match.params.coach_id) > 0 ? dcrypt(props.match.params.coach_id) : 0);
  const queryParms = useQuery();
  const [dayStatus, setDayStatus] = useState(queryParms.has('day_status') ? queryParms.get('day_status') : 'monthly');
  const [openStatus, setOpenStatus] = useState(queryParms.has('open_status') ? queryParms.get('open_status') : '0');

  const [info, setInfo] = useState({});
  const [website, setWebsite] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [assignCallStatus, setAssignCallStatus] = useState({});
  const [tab, setTab] = useState("3");
  const [btab, setBTab] = useState("3");
  const [groupModal, setGroupModal] = useState(false);
  const [tagModal, setTagModal] = useState(false);
  const [addTagModal, setAddTagModal] = useState(false);


  const [index, setIndex] = useState("");
  const [tags, setTags] = useState([]);
  const [alltags, setAllTags] = useState([]);
  const [groups, setGroups] = useState([]);
  const [tag, setTag] = useState("");
  const [selectedValue, setSelectedValue] = useState([]);

  const [page2Class, setpage2Class] = useState("");
  const [page3Class, setpage3Class] = useState("");
  const [page4Class, setpage4Class] = useState("");

  const [callToken, setCallToken] = useState(null);
  const [searchClient, setSearchClient] = useState("");
  const [searchClientHistory, setSearchClientHistory] = useState("");
  const [existing, setExisting] = useState([]);
  const [clients, setClients] = useState([]);
  const [DynamicModal, setDynamicModal] = useState(false);
  const [EmailModal, setEmailModal] = useState(false);
  const [DynamicModalName, setDynamicModalName] = useState("");
  const [activity, setActivity] = useState([]);
  const [script, setScript] = useState("");
  const [templates, setSmsTemplates] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [emailSetting, setEmailSetting] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [smsBody, setSmsBody] = useState("");
  const [token, setToken] = useState("");
  const [subject, setSubject] = useState("");
  const [html, setHtml] = useState("");
  const [calendarReload, setCalendarReload] = useState(true);
  const [sentEmailTemplateModal, setSentEmailTemplateModal] = useState(false);
  const [emailData, setEmailData] = useState("");
  const [taskCompleteModal, setTaskCompleteModal] = useState(false);
  const [taskID, setTaskID] = useState(false);
  const [attachment, setAttachment] = useState({});
  const [pipeline, setPipeline] = useState({});
  const [pipelineStatus, setPipelineStatus] = useState([]);
  const [masterPipelines, setMasterPipelines] = useState([]);
  const [masterPipelineId, setMasterPipelineId] = useState(0);

  const [todoLists, setTodoLists] = useState([]);
  const [todoModal, setTodoModal] = useState(false);
  const [selectedPipeline, setSelectedPipeline] = useState({});
  const [pipelineModal, setPipelineModal] = useState(false);
  const [coachPartners, setCoachPartners] = useState([]);
  // const [coachClients, setCoachClients] = useState([]);
  const [coachUser, setCoachUser] = useState({});
  const [taskModal, setTaskModal] = useState(false);
  const [taskData, setTaskData] = useState({});
  const [eventData, setEventData] = useState({});


  const [UpPermission, setUpPermission] = useState({
    permission: 0,
    remaining_coach: 0,
    packages: []
  });
  const [invoices, setInvoices] = useState([]);
  const [defaultCalendar, setDefaultCalendar] = useState(0);
  const identity = "proglobal";
  const childRef = useRef();
  const [phoneno, setphoneno] = useState(null);
  const [attachmentFile, setattachmentFile] = useState("");
  const [assessment_id, setAssessmentId] = useState("");
  const [training, setTraining] = useState({
    userTraining: [],
    allTraining: [],
    userResource: [],
    allResource: [],
    subCategories: []
  });
  const [loader, setLoader] = useState(false);
  const [smsloader, setSmsLoader] = useState(true);
  const [timeZone_data, setnewzone] = useState(Asia);
  const [timezone, setTimeZone] = useState("");
  const [maintimezone, setMainTimeZone] = useState("");
  const [reminderModal, setReminderModal] = useState(false);
  const [reminders, setReminders] = useState([]);
  const [template_id, setTemplateId] = useState(0);
  const [toggleSwitch, settoggleSwitch] = useState(true);
  const [notes, setNotes] = useState([]);

  const [recordState, setRecordState] = useState(null);
  const [selected_file, SetSelectedFile] = useState("");
  const [file_type_validate, SetFileTypeValidate] = useState("");
  const [file_type, setFileType] = useState("template-section");
  const [audioData, setAudioData] = useState("");


  const [points_contact, setPointsContact] = useState({
    calendar_events: 0,
    call: 0,
    email: 0,
    sms: 0,
    total: 0
  });

  const [inbox_activity, setInboxActivity] = useState({
    report_results: [],
    sms_count: 0,
    email_count: 0,
    call_count: 0,
    voice_count: 0,
    voice_message_un_listen: 0
  });

  const [task_record, setTaskRecord] = useState({
    task_log: [],
    today: 0,
    weekly: 0,
    monthly: 0,
    unassigned: 0
  });
  const [inboxDay, setInboxDay] = useState("today");
  let this_form = useRef(null);
  const paramsters = {
    token: callToken,
    from_number: uInfo().outbound_phone,
    phoneno: phoneno,
    record: "false",
    client_id: info.client_id,
    coach_id: coach_id,
  };
  let history = useHistory();
  const childClientRef = useRef();
  useEffect(() => {
    searchClients("", openStatus, dayStatus);
    getActivity("3");
    getTag();
    getCoachUsers();
    getTaskStatistics("monthly");
    getClientPipeline();
    getActivity("5");
    getInboxActivity('all', dayStatus, "");
  }, []);

  const moveToAction = (is_closed) => {
    fetch(`${ApiUrl}` + "change/isClone/Action", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ client_id: lead_id, is_closed: is_closed }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          searchClients("", is_closed, "monthly");
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        setCalendarReload(true);
      })
      .catch((error) => {
        setCalendarReload(true);
        console.error("Error:", error);
      });
  }

  const getCoachUsers = () => {
    fetch(`${ApiUrl}` + "get/coach/users", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setCoachPartners(response.data.coachPartners);
          setCoachUser(response.data.coachUser);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getInboxActivity = (type = 'all', day = inboxDay, filter_value = "") => {
    fetch(`${ApiUrl}` + "get/chat/inbox", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ day: day, 'type': type, 'client_id': lead_id, filter_value: filter_value }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setInboxActivity(response.data.records);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getTaskStatistics = (task_interval = 'today') => {
    fetch(`${ApiUrl}` + "dashboard/task/statistics", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ task_interval: task_interval, client_id: lead_id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          //console.log(response.data.record, 'recccc')
          setTaskRecord(response.data.record);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const maintimezones = [
    { id: "Asia", label: "Asia" },
    { id: "America", label: "America" },
    { id: "Australia", label: "Australia" },
    { id: "Europe", label: "Europe" },
    { id: "Indian", label: "Indian" },
  ];
  function settimezone(e) {
    setMainTimeZone(e);
    if (e.label == "Australia") {
      setnewzone(Australia);
    } else if (e.label == "America") {
      setnewzone(America);
    } else if (e.label == "Asia") {
      setnewzone(Asia);
    } else if (e.label == "Europe") {
      setnewzone(Europe);
    } else if (e.label == "Indian") {
      setnewzone(Indian);
    }
    setTimeZone({});
    // console.log(timeZone_data,'Zone Change');
  }
  const handleAttachementRequestSubmit = async (event, values) => {
    values.client_id = info.client_id;
    values.email = info.email;
    fetch(`${ApiUrl}` + "send/attachment/request", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        this_form && this_form.reset();
        if (data.status === true) {
          setSubject("");
          setSmsBody("");
          setToken('');
          getActivity(tab);
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleAtSubmit = async (event, values) => {
    // values.client_id = info.client_id;
    // values.attachment_file = attachmentFile;
    setLoader(false);
    const formData = new FormData();
    formData.append("client_id", info.client_id);
    formData.append("attachment_file", attachmentFile);
    formData.append("file_title", values.file_title);
    formData.append("type", uInfo().type);
    fetch(`${ApiUrl}` + "client/attachment", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: formData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log(data, "Add new attach");
        if (data.status === true) {
          this_form && this_form.reset();
          getActivity(tab);
          // getCompanyClients();
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        setLoader(true);

      })
      //Then with the error genereted...
      .catch((error) => {
        setLoader(true);

        console.error("Error:", error);
      });
  };

  const handleTodoSubmit = async (event, values) => {
    values.client_id = info.client_id;
    fetch(`${ApiUrl}` + "add/todo", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          setTodoModal(false);
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }



  const getCompanyClients = () => {
    fetch(`${ApiUrl}` + "get/client/information/" + lead_id + "/" + coach_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setClients(response.data.records);
          setpage3Class("slide-out2");
          setAttachment(response.data.attachments);
          setUpPermission(response.data.coachPermission);
          setTraining(response.data.training);
          var tokens_group = [
            {
              label: "Default Field",
              options: response.data.tokens.default_fields,
            },
            {
              label: "Custom Field",
              options: response.data.tokens.custom_fields,
            },
          ];
          setTokens(tokens_group);
          setInvoices(response.data.invoices);

        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getClientPipeline = (master_id = 0) => {
    fetch(`${ApiUrl}` + "get/client/pipeline/" + lead_id + "/" + coach_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ master_id: master_id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setMasterPipelineId(master_id);
          setPipelineStatus(response.data.allPipelines);
          setMasterPipelines(response.data.masterPipelines);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleExistingClient = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    let companyIds = [];
    for (let [key, value] of formData.entries()) {
      companyIds.push(value);
    }
    if (companyIds.length > 0) {
      fetch(`${ApiUrl}` + "update/company/client", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify({
          clients: info.client_id,
          comapny_id: companyIds[0],
        }),
      })
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((response) => {
          // console.log(response, "data");
          if (response.status === true) {
            setpage3Class("slide-out2");
            setSearchClient("");
            setExisting([]);
            getCompanyClients();
            toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          } else {


            toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        //Then with the error genereted...
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  const getData = (lead_id, coach_id) => {

    fetch(`${ApiUrl}` + "lead/details/" + lead_id + "/" + coach_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          var client_info = response.data.records;
          // console.log(client_info, 'client_info')
          setInfo(client_info);

          var phone_number = client_info.phone
            ? client_info.country_code + client_info.phone
            : client_info.country_code + client_info.mobile;
          setphoneno(phone_number);

          setGroups(response.data.groups_data);
          setTags(response.data.tag_data);
          setAssignCallStatus(response.data.call_status);
          setPointsContact(response.data.points_of_contact);
          getTaskStatistics("monthly", client_info.client_id);
          getSmsTemplate();
          if (client_info.website != "") {
            let http_status = client_info.website.replace(/(^\w+:|^)\/\//, '');
            let website_url = "https://" + http_status;
            setWebsiteUrl(website_url);


            let websiteInfo = client_info.website.replace(/(^\w+:|^)\/\//, '');
            setWebsite(websiteInfo);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  const btoggle = (index) => {
    if (btab !== index) {
      setBTab(index);
      getActivity(index);
      setActivity([]);
    }
  };

  const toggle = (index) => {
    if (tab !== index) {
      setTab(index);
      getActivity(index);
      setActivity([]);
    }
  };

  const removeGroupLead = () => {
    //console.log(lead_id, 'lead_idlead_idlead_idlead_idlead_id')
    fetch(
      `${ApiUrl}` +
      "remove/group/lead/" +
      lead_id +
      "/" +
      index +
      "/" +
      coach_id,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
      }
    )
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        // console.log(response, "Group removed");
        if (response.status === true) {
          setGroupModal(false);
          getData(info.client_id, info.coach_id);
          //toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const removeTagLead = () => {
    fetch(
      `${ApiUrl}` + "remove/tag/lead/" + info.client_id + "/" + index + "/" + coach_id,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
      }
    )
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          // console.log(response);
          setTagModal(false);
          getData(info.client_id, info.coach_id);
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getTag = () => {
    fetch(`${ApiUrl}` + "get/group/tag", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },

      body: JSON.stringify({ manager_id: coach_id }),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response, "data");
        if (response.status === true) {
          setAllTags(response.data.tags);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const removeGroupModal = (i) => {
    setGroupModal(!groupModal);
    setIndex(i);
  };
  const removeTagModal = (i) => {
    setTagModal(!tagModal);
    setIndex(i);
  };

  const sendLoginDetails = async (event, values) => {
    setLoader(false);
    values.client_id = info.client_id;
    values.coach_id = info.coach_id;
    fetch(`${ApiUrl}` + "send/client/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        setLoader(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const AddTags = async (event, values) => {
    setSmsLoader(false);
    // console.log(index, "index");
    fetch(`${ApiUrl}` + "add/tags/lead/" + info.client_id + "/" + coach_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setTag(0)
          setGroupModal(false);
          setTagModal(false);
          getData(info.client_id, info.coach_id);
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        setSmsLoader(true);
      })
      //Then with the error genereted...
      .catch((error) => {
        setSmsLoader(true);

        console.error("Error:", error);
      });
  };
  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.id) : []);
  };


  const searchClients = async (searchClient = "", is_closed = "0", interval = "monthly") => {
    fetch(`${ApiUrl}` + "get/contacted/leads", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ filter_value: searchClient, lead_id: lead_id, is_closed: is_closed, interval: interval }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setExisting(response.data.records);
          var l_info = response.data.lead_record;
          // console.log(l_info.client_id, 'l_info.lead_id')
          setLeadId(l_info.client_id);
          setCoachId(l_info.coach_id);

          if (lead_id == 0) {

            if (l_info.client_id > 0) {
              props.history.push('/chat/inbox/' + bcrypt(l_info.client_id) + "/" + bcrypt(l_info.coach_id));
            }
          }
          getData(l_info.client_id, l_info.coach_id);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    if (searchClient == "") {
      setExisting([]);
    }
  }
  const deleteConversation = async () => {
    swal({
      title: "Are you sure?",
      text: "You want to this lead conversation.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "delete/lead/conversation", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ client_id: lead_id }),
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.status === true) {
                getInboxActivity('all', 'monthly', "");
              } else {
                toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
  }
  const handleSubmitSmsSend = async (event, values) => {
    values.client_id = info.client_id;
    values.type = 'sms';
    values.body = smsBody;
    setLoader(false);
    setSmsLoader(false);
    fetch(`${ApiUrl}` + "lead/activity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          getInboxActivity('all', 'monthly', "");
          getActivity("8");
          setDynamicModal(false);
          setDynamicModalName("sms");
          setSmsBody("");
          setToken("");
          setSubject("");
          setHtml("");
          setTemplateId(0);
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        setLoader(true);
        setSmsLoader(true);
      })
      //Then with the error genereted...
      .catch((error) => {
        setLoader(true);
        setSmsLoader(true);

        console.error("Error:", error);
      });
  }
  const handleTaskSubmit = async (event, values) => {
    setCalendarReload(false);
    values.client_id = info.client_id;
    fetch(`${ApiUrl}` + "add/task/activity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          setTaskModal(false);
          getTaskStatistics("monthly");
          setCalendarReload(true);
          setTaskData({});
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  const handleSubmit = async (event, values) => {
    setCalendarReload(false);
    values.client_id = info.client_id;
    values.type = DynamicModalName.toLowerCase();
    values.body = smsBody;
    values.media_file = selected_file;
    values.file_type_validate = file_type_validate;
    setLoader(false);
    fetch(`${ApiUrl}` + "lead/activity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        setCalendarReload(true);
        if (data.status === true) {
          if (tab == "5") {
            getActivity("5");
          } else if (tab == "12") {
            getActivity("12");
          } else {
            getActivity("3");
          }
          setDynamicModal(false);
          // setDynamicModalName("sms");
          setSmsBody("");
          setToken("");
          setSubject("");
          setHtml("");
          setTemplateId(0);
          setEventData({})
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        setLoader(true);
      })
      //Then with the error genereted...
      .catch((error) => {
        setLoader(true);
        console.error("Error:", error);
      });
  };
  const handleDealSubmit = async (pipeline_id, status_id, client_id, final_pipeline) => {
    fetch(`${ApiUrl}` + "assinged_pipeline", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        'pipeline_id': pipeline_id,
        'status_id': status_id,
        'client_id': client_id,
        'final_pipeline': final_pipeline
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log(data, "Editing");
        if (data.status === true) {
          getClientPipeline(masterPipelineId);
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleEmailSubmit = async (event, values) => {
    values.client_id = info.client_id;
    values.type = DynamicModalName.toLowerCase();

    if (html != "") {
      let body = document.getElementById("custom-template").innerHTML;
      values.body = body;
    }

    fetch(`${ApiUrl}` + "company/email/activity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log(data, "Editing");
        if (data.status === true) {
          getActivity(tab);
          setEmailModal(false);
          setDynamicModalName("");
          setHtml('');
          setSmsBody("");
          setToken("");
          setSubject("");


          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });

        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getCallSid = (call_sid) => {

  }

  const getActivity = (index) => {
    setLoader(false);
    fetch(`${ApiUrl}` + "get/activity/" + lead_id + "/" + index, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setBTab(index);
          setActivity(response.data.records);
          setDefaultCalendar(response.data.calendarId);
          setDynamicModalName("");
          setHtml('');
          setSmsBody("");
          setToken("");
          setSubject("");

          setLoader(true);

        } else {
          setLoader(true);



          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        setLoader(true);

        console.error("Error:", error);
      });
  };

  function groupArrayOfObjects(list, key) {
    return list.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  const getEmailTemplate = () => {
    fetch(`${ApiUrl}` + "get/email/template", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setSmsTemplates(response.data.templates);
          var tokens_group = [
            {
              label: "Default Field",
              options: response.data.tokens.default_fields,
            },
            {
              label: "Custom Field",
              options: response.data.tokens.custom_fields,
            },
          ];

          setTokens(tokens_group);
          setEmailSetting(response.data.phoneNumbers);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getSmsTemplate = () => {
    fetch(`${ApiUrl}` + "get/sms/template", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          var templates = groupArrayOfObjects(response.data.templates, "type");
          var templates_group = [
            { label: "SMS", options: templates.SMS },
            { label: "MMS", options: templates.MMS },
          ];
          setSmsTemplates(templates_group);

          var tokens_group = [
            {
              label: "Default Field",
              options: response.data.tokens.default_fields,
            },
            {
              label: "Custom Field",
              options: response.data.tokens.custom_fields,
            },
          ];

          setTokens(tokens_group);
          setPhoneNumbers(response.data.phoneNumbers);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getRVMTemplate = () => {
    fetch(`${ApiUrl}` + "get/rvm/template", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setSmsTemplates(response.data.templates);

          setPhoneNumbers(response.data.phoneNumbers);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getEmailTemplateHtml = (id) => {
    fetch(`${ApiUrl}` + "get/html/" + id + "/" + uInfo().user_id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        setHtml(response.theme);
        // localStorage.setItem("theme", response.theme);
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const cancelTask = () => {
    setpage4Class("slide-out2");
    getActivity("6");
  };

  const completeTask = () => {
    fetch(`${ApiUrl}` + "update/task/status", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ task_id: taskID, client_id: info.client_id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          getActivity("6");
          setTaskCompleteModal(false);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleOutCall = () => {
    fetch(`${ApiUrl}` + "voice/token", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ identity: encodeURIComponent(identity) }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setCallToken(response.data.token);
          setClicked(true);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };



  const deleteTaskAndEvents = (meeting_id) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          setCalendarReload(false);
          fetch(`${ApiUrl}` + "delete/meeting", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: meeting_id }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                setTaskModal(false);
                setTaskData({});
                getTaskStatistics("monthly");

                setEventData({});
                setDynamicModal(false);
                setDynamicModalName("");
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
              setCalendarReload(true);
            })
            //Then with the error genereted...
            .catch((error) => {
              setCalendarReload(true);
              console.error("Error:", error);
            });
        }
      });
  };
  const start = () => {
    console.log(RecordState.START, 'DEBUGGINGG');
    setRecordState(RecordState.START);
  };

  const stop = () => {
    setRecordState(RecordState.STOP);
  };

  const onStop = (audioData) => {
    setAudioData(audioData);
    let selected_file = [];
    console.log("audioData", audioData);
    var reader = new FileReader();
    reader.readAsDataURL(audioData.blob);
    reader.onloadend = function () {
      selected_file.push(reader.result);
    }


    SetSelectedFile(selected_file);
    SetFileTypeValidate("wav");
  };
  const onChangefileTypeAction = (value) => {
    var x = document.getElementById("record-section");
    var y = document.getElementById("template-section");
    if (value == "record-section") {
      setFileType("record-section");
      x.style.display = "block";
      y.style.display = "none";
    } else {
      setFileType("template-section");
      x.style.display = "none";
      y.style.display = "block";
    }
  };
  return (
    <React.Fragment>
      <Helmet>
        <title> {page_name} </title>
      </Helmet>
      <ToastContainer />
      <Row className="CompanyDetail ClientDialer ClientProfileDetails NewLeadDialer INBOXLEAD">
        <Col md={2} className="px-0">
          <CardHeader>
            <div className="top-heading WithDrop">
              <Dropdown className="d-inline">
                <Dropdown.Toggle
                  className="btn btn-link text-primary text-capitalize"
                  id="dropdown-autoclose-true"
                >
                  {/* <i className="fas fa-filter"></i> */}

                  {dayStatus == 'show-all' ? 'All' : dayStatus}


                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setDayStatus('monthly');
                      searchClients("", openStatus, 'monthly');
                      getInboxActivity('all', 'monthly', "");
                    }}
                    eventKey={"1"}
                  >
                    Monthly
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setDayStatus('today');
                      searchClients("", openStatus, 'today');
                      getInboxActivity('all', 'today', "");
                    }}
                    eventKey={"2"}
                  >
                    Today
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setDayStatus('weekly');
                      searchClients("", openStatus, 'weekly');
                      getInboxActivity('all', 'weekly', "");
                    }}
                    eventKey={"2"}
                  >
                    Weekly
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setDayStatus('show-all');
                      searchClients("", openStatus, 'show-all');
                      getInboxActivity('all', 'show-all', "");
                    }}
                    eventKey={"2"}
                  >
                    All
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="button-group">
              <button
                className="btn btn-sm searchBtn"
                onClick={() => {
                  setOpenStatus(0);
                  searchClients("", "0", dayStatus);
                }}
                title={"Active/Open Leads"}
              >
                <span className={`fas fa-check-circle ${openStatus == 0 && ('text-success')}`}> </span>
              </button>
              <button
                className="btn btn-sm searchBtn"
                onClick={() => {
                  setOpenStatus(1)
                  searchClients("", "1", dayStatus);
                }}
                title={"Closed Leads"}
              >
                <span className={`fas fa-door-closed ${openStatus == 1 && ('text-success')}`}> </span>
              </button>
            </div>
          </CardHeader>
          <Card className="NoShadow custom-scroll">
            <CardBody className="px-0">
              <div className="col-md-12">
                <div className="form-group relative px-2 mt-2">
                  <input
                    value={
                      searchClient != "" && searchClient != null
                        ? searchClient
                        : ""
                    }
                    onChange={(e) => { setSearchClient(e.target.value); searchClients(e.target.value, openStatus, dayStatus) }}
                    placeholder="Search leads"
                    name="searh"
                    type={"text"}
                    className={"form-control"}
                  />
                  {searchClient == "" ? (
                    <button className="btn btn-sm searchBtn">
                      <span className="fas fa-search"> </span>
                    </button>
                  ) : (
                    <button
                      onClick={() => { setSearchClient(null); searchClients("", openStatus, dayStatus) }}
                      className="btn btn-sm searchBtn"
                    >
                      <span className="fas fa-times"> </span>
                    </button>
                  )}
                </div>
                {existing.length < 1 && (
                  <p className="text-danger text-center mt-3">
                    No record found.
                  </p>
                )}
                {existing.length > 0 && (
                  <form method="POST" onSubmit={handleExistingClient}>
                    <fieldset className="form-group">
                      <div className="form-control border-0 p-0 h-auto is-untouched is-pristine av-valid">
                        <div className="chat-user-list custom-scroll">
                          {existing.map((row, i) => {
                            return (
                              <div className="parent" key={i}>
                                <div id={row.cliint_id}>
                                  <Link
                                    to={`/chat/inbox/${bcrypt(row.client_id)}/${bcrypt(row.coah_id)}?day_status=${dayStatus}&open_status=${openStatus}`}
                                    key={`${row.client_id}-${i}`}
                                    className="list-group-item list-group-item-action border-0 px-0"
                                  >

                                    <div className="d-flex align-items-center justify-content-between w-100 px-2">
                                      <div className="d-flex align-items-center">
                                        <Avatar
                                          name={`${row.first_name != '' ? row.first_name + " " + row.last_name : "No Name"}`}
                                          size="20"
                                          round={true}
                                        />
                                        <div className="ml-2 BreakWords flex-shrink-0">
                                          {`${row.first_name} ${row.last_name}`}
                                          <div className="small">{row.email ? row.email : row.country_code + row.mobile} </div>
                                        </div>
                                      </div>
                                      <div
                                        className="badge bg-success float-right mt-2"

                                        id={`count_removed${row.client_id}`}
                                      >
                                        {row.unread > 0 ? row.unread : ""}
                                      </div>
                                      <div>
                                        {row.is_closed == "0" ? (
                                          <button
                                            className="btn btn-sm"
                                            onClick={() => {
                                              moveToAction("1");
                                            }}
                                            title={"Move to Close"}
                                          >
                                            <span className="fas fa-door-closed"> </span>
                                          </button>
                                        ) : (
                                          <button
                                            className="btn btn-sm"
                                            onClick={() => {
                                              moveToAction("0");
                                            }}
                                            title={"Move back to open"}
                                          >
                                            <span className="fas fa-check-circle"> </span>
                                          </button>

                                        )}

                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            );
                          })}
                        </div>



                      </div>
                    </fieldset>
                  </form>
                )}
              </div>
            </CardBody>
          </Card>

        </Col>
        <Col md={7} className="px-1">
          <Row className="mb-1">
            <Col lg={12} className="px-1">
              <Card className="VariousCalls mb-0 pb-0 mt-0 pt-3  boxescard border-top-0 border-bottom-0 border rounded-0">
                <CardBody className=" InboxBusiness ClientDailer px-0">
                  <section className="breakthrough clientProfileHeading ToolKit mb-0 text-right">

                    <div className={`input-group compant-action-btn d-flex justify-content-between ${!info.client_id && ('d-none')}`}>
                      <div>
                        <OverlayTrigger
                          placement="auto"
                          overlay={<Tooltip id="overlay-example"> Tags </Tooltip>}
                        >
                          <button
                            onClick={() => {
                              setAddTagModal(true);
                            }}
                            className="btn btn-primary btn-md"
                          >
                            <i className="fas fa-tags"> </i>
                          </button>
                        </OverlayTrigger>

                        <OverlayTrigger
                          placement="auto"
                          overlay={<Tooltip id="overlay-example"> Task </Tooltip>}
                        >
                          <button
                            onClick={() => {
                              setTaskModal(true);
                            }}
                            className="btn btn-primary btn-md"
                          >
                            <i className="fas fa-tasks"> </i>
                          </button>
                        </OverlayTrigger>

                        <OverlayTrigger
                          placement="auto"
                          overlay={<Tooltip id="overlay-example"> Calendar </Tooltip>}
                        >
                          <button
                            onClick={() => {
                              setDynamicModal(true);
                              setDynamicModalName("meeting");
                            }}
                            className="btn btn-primary btn-md"
                          >
                            <i className="fas fa-calendar-alt"> </i>
                          </button>
                        </OverlayTrigger>

                        {(info.mobile != '' || info.phone != '') && (
                          <OverlayTrigger
                            placement="auto"
                            overlay={
                              <Tooltip id="overlay-example"> Send RVM </Tooltip>
                            }
                          >
                            <button
                              onClick={() => {
                                setDynamicModal(true);
                                setDynamicModalName("rvm");
                                getRVMTemplate();
                              }}
                              className="btn btn-primary btn-md"
                            >
                              <i className="fas fa-microphone"> </i>
                            </button>
                          </OverlayTrigger>

                        )}
                      </div>
                      <div className="d-flex">
                        <input
                          value={
                            searchClientHistory != "" && searchClientHistory != null
                              ? searchClientHistory
                              : ""
                          }
                          onChange={(e) => { setSearchClientHistory(e.target.value); getInboxActivity('all', dayStatus, e.target.value); }}
                          placeholder="Search Message"
                          name="searh"
                          type={"text"}
                          className={"form-control"}
                        />
                        <button
                          className="btn btn-sm searchBtn"
                          onClick={() => {
                            deleteConversation();
                          }}
                          title={"Delete Conversation"}
                        >
                          <span className="fas fa-trash"> </span>
                        </button>
                      </div>
                    </div>


                  </section>
                  <ul className="border-bottom me-2">
                    {inbox_activity.report_results.length > 0 ? (
                      inbox_activity.report_results.map((row, i) => {
                        return (
                          <li className={`mt-3  activity-note-${row.direction} in-out-class`} key={"message" + i}>

                            <div className="desp-box">
                              <div className="d-flex  align-items-center flex-wrap justify-content-start">
                                <div className="ContactDescription p-2">
                                  <div className="NameUser"><h6 className="card-title">{row.client_name}</h6>
                                  </div>
                                  <div className="UserMessage"><p dangerouslySetInnerHTML={{ __html: row.body }} />
                                    <div className="MessageDate">{row.created_at}</div>

                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={`StatusIcon ${row.background}`}>
                              <i className={`${row.icon}`}></i>
                            </div>
                          </li>
                        );
                      })
                    ) : (
                      <li key={"message1"} className="mt-2">
                        <div className="ContactDescription p-2">
                          <div className="UserMessage text-center no-record"><p>No record found</p></div>
                        </div>
                      </li>
                    )}
                  </ul>

                  {(info.mobile != '' || info.phone != '') && (inbox_activity.type == 'all' || inbox_activity.type == 'sms') && (
                    <AvForm
                      onValidSubmit={handleSubmitSmsSend}
                      className="needs-validation"
                      ref={(c) => (this_form = c)}
                    >
                      {smsloader && existing.length > 0 && (
                        <>
                          <div className="flex-grow-0  SendMessage">
                            <AvField type="hidden" name="tos" value={info.mobile} />
                            <AvField type={'hidden'} name="froms" value={uInfo().outbound_phone} />
                            <AvField type="hidden" name="activity" value={"sms send"} />
                            <div className="row mt-2 mx-0">
                              <div className="col-md-12 input-group">
                                <AvInput
                                  onChange={(e) => setSmsBody(e.target.value)}
                                  value={smsBody}
                                  type="textarea"
                                  name="body"
                                  className="form-control"
                                  required
                                  placeholder="Type Message..."
                                />
                                <button
                                  disabled={""}
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                </button>

                              </div>
                            </div>
                            <div className="row mx-0">
                              <div className="col-lg-4">
                                <div className="my-2">
                                  <div className="MainTZone SendSMS One">
                                    <Select
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.id}
                                      // value={options.filter(({ id }) => id ===country_code)}
                                      isMulti={false}
                                      options={templates}
                                      classNamePrefix="select2-selection"
                                      name="country_id"
                                      placeholder={"Select sms template"}
                                      onChange={(e) => {
                                        setSmsBody(e.body);
                                        setTemplateId(e.id);
                                      }}
                                    />
                                  </div>
                                  <AvField type="hidden" name="template_id" value={template_id} />

                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="my-2">
                                  <AvField
                                    className="select form-select"
                                    type="select"
                                    name="froms"
                                    required
                                  >
                                    <option disabled value=""> Select Phone Number </option>
                                    {phoneNumbers.map((row, i) => {
                                      return (
                                        <option key={"phone-" + i} value={row.phone_number}>
                                          {convertPhoneFormat(row.phone_number)}
                                        </option>
                                      );
                                    })}
                                  </AvField>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </AvForm>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col md={3} className="pe-0">
          <div className="NoShadow custom-scroll LeftSideUser" style={{ zIndex: "0" }}>
            <div className="user-profile-pic-area mt-2">
              <Card className="UserProfileBG">
                <CardBody className="px-0">
                  <div className="company p-3">
                    {
                      info.client_id && (
                        <button
                          className="btn btn-link btn-md pull-right"
                          onClick={() => {
                            setpage2Class("slide-in");
                            childRef.current.getAlert();
                          }}
                        >
                          <i className="fas fa-pencil-alt"> </i>
                        </button>
                      )
                    }

                    <div className="company-info">
                      <div className="logo">
                        <Avatar
                          name={`${info.first_name} ${info.last_name}`}
                          size="76"
                          round={true}
                        />
                      </div>
                      <div className="info">
                        <h3></h3>
                        {(info.client_id && website != "" && website != null) && (
                          <div className="url">
                            <a
                              href={`${websiteUrl}`}
                              target="_blank"
                              className="pr-5"
                            >
                              {website}

                              <i className="fas fa-external-link-alt"> </i>
                            </a>
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="overlay-example">
                                  Copy domain to clipboard
                                </Tooltip>
                              }
                            >
                              <button
                                className="btn btn-link btn-md"
                                onClick={() => {
                                  navigator.clipboard.writeText(`${website}`);
                                }}
                              >
                                <i className="fas fa-copy"> </i>
                              </button>
                            </OverlayTrigger>
                          </div>
                        )}

                        {(info.client_id && info.phone != "" && info.mobile != "" && info.phone != null && info.mobile != null) && (
                          <>
                            <Link to={"#"}>

                              {convertPhoneFormat(info.phone ? info.phone_extension + info.phone
                                : info.country_code + info.mobile)}
                            </Link>
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="overlay-example">
                                  Copy phone number to clipboard assad
                                </Tooltip>
                              }
                            >
                              <button
                                onClick={() => {
                                  navigator.clipboard.writeText(`${convertPhoneFormat(info.phone ? info.phone_extension + info.phone
                                    : info.country_code + info.mobile)}`);
                                }}
                                className="btn btn-link btn-md"
                              >
                                <i className="fas fa-copy"> </i>
                              </button>
                            </OverlayTrigger>
                          </>
                        )}
                        <h4>{info.first_name} {info.last_name}</h4>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="user-profile-pic-area">

              <div className="AboutCard 3">
                <div className="details mt-3">
                  {
                    info.client_id && (
                      <div className="info info pb-2 TagBadges">
                        <h6 className="ProfileSecHeading p-1 mb-1"> Group(s) Name: </h6>
                        {groups.length > 0 && (
                          <div className="">
                            {groups.map((row, i) => {
                              if (row.group_id) {
                                return (
                                  <OverlayTrigger
                                    key={"group-" + i}
                                    placement="auto"
                                    overlay={
                                      <Tooltip id={`tooltip-auto`}>
                                        <strong> {groups ? row.title : ""} </strong>
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      className={`btn-sm btn-success mb-1 btn-round mr-5`}
                                    >
                                      <Badge title="Remove Group" bg="" onClick={() => removeGroupModal(row.group_id)}>
                                        <i className="fas fa-times-circle pr-5"></i>
                                      </Badge>
                                      {groups ? row.title : ""}
                                    </Button>
                                  </OverlayTrigger>
                                );
                              }
                            })}
                          </div>
                        )}
                      </div>
                    )
                  }
                  {
                    info.client_id && (
                      <div className="info info pb-2 TagBadges">
                        <h6 className="ProfileSecHeading p-1 mb-1"> Tag(s) Name: </h6>
                        {tags.length > 0 && (
                          <div className="mb-3">
                            {tags.map((row, i) => {
                              if (row.group_id) {
                                return (
                                  <OverlayTrigger
                                    key={"tag-" + i}
                                    placement="auto"
                                    overlay={
                                      <Tooltip id={`tooltip-auto`}>
                                        <strong> {tags ? row.title : ""} </strong>
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      className={`btn-sm btn-success btn-round mr-5`}
                                    >
                                      <Badge title="Remove tag" bg="" onClick={() => removeTagModal(row.group_id)}>
                                        <i className="fas fa-times-circle pr-5"></i>
                                      </Badge>
                                      {tags ? row.title : ""}
                                    </Button>
                                  </OverlayTrigger>
                                );
                              }
                            })}
                          </div>
                        )}
                      </div>
                    )
                  }
                  {info.email}
                  {info.client_id && info.email != '' && (
                    <div className="info info pb-2">
                      <button className="border-0 rounded-1"
                        onClick={() => {
                          setEmailModal(true);
                          setDynamicModalName("email");
                          getEmailTemplate();
                        }}
                      >
                        <i className="fas fa-envelope"> </i> {info.email}
                      </button>
                    </div>
                  )}

                  {
                    (info.client_id && (info.mobile != '' || info.phone != '')) && (
                      <div className="info info mt-2 mb-2">
                        <button className="border-0 rounded-1"
                          onClick={() => {
                            setDynamicModal(true);
                            setDynamicModalName("call");
                            handleOutCall();
                          }}
                        >
                          <i className="fas fa-phone-alt"> </i> {info.mobile ? info.mobile : info.phone}
                        </button>
                      </div>
                    )
                  }
                </div>

                {info.client_id && (
                  <div className="details">
                    <Accordion className="border-0">
                      <Accordion.Item eventKey="1">
                        <Accordion.Header> Showing Lead Notes</Accordion.Header>
                        <Accordion.Body className="pt-1">
                          <div className="row">
                            <div className="col-md-12 text-center">
                              <button
                                onClick={() => {
                                  setDynamicModal(true);
                                  setDynamicModalName("Note");
                                }}
                                className="btn btn-sm btn-primary pull-right m-2"
                              >
                                Create Note
                              </button>
                            </div>
                          </div>
                          {activity.map((row, i) => {
                            return (
                              <div key={i} className="note activity-info">
                                <div className="activity-body">
                                  <div className="activity-bubble note">
                                    <div className="activity-content">
                                      <div className="activity_time">
                                        <div className="ActivityTitle">
                                          {/* <span>{row.type}</span> by {row.first_name} */}
                                          <p>{row.activity}</p>
                                        </div>
                                        {row.created_at}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Accordion.Body>
                      </Accordion.Item>

                    </Accordion>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className={`page2 ${page2Class} custom-scroll`}>
              <div>
                <div className="modal-head top-heading">
                  <h2> Edit Detail </h2>
                  <button
                    onClick={() => {
                      setpage2Class("slide-out");
                      getData();
                      // getActivity("3");
                    }}
                    className="btn btn-link text-white"
                  >
                    <i className="fas fa-times"> </i>
                  </button>
                </div>
                <CreateUpdate
                  ref={childRef}
                  // getData={getData}
                  id={info.client_id}
                  coach_id={info.coach_id}
                  from="companyDetails"
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Modal
        isOpen={addTagModal}
        fade={false}
        toggle={() => setAddTagModal(false)}
      >
        <ModalHeader>Add / Remove Tags {info.client_id}</ModalHeader>
        <AvForm onValidSubmit={AddTags} className="needs-validation">
          <ModalBody>
            <Col md="12">
              <div className="mb-3">
                <Select
                  getOptionLabel={(option) => option.title}
                  getOptionValue={(option) => option.group_id}
                  // value={tags.filter(obj => selectedValue.includes(obj.id))}
                  options={alltags}
                  isMulti={false}
                  classNamePrefix="select2-selection"
                  name="tags_id"
                  isClearable
                  onChange={(e) => setTag(e.group_id)}
                // onChange={handleChange}
                />
                <AvField
                  required
                  type="hidden"
                  name="tag_id"
                  value={tag}
                />
              </div>
            </Col>

            {tags.length > 0 && (
              <div className="mb-3">
                {tags.map((row, i) => {
                  return (
                    <OverlayTrigger
                      key={"tag-" + i}
                      placement="auto"
                      overlay={
                        <Tooltip id={`tooltip-auto`}>
                          <strong> {tags ? row.title : ""} </strong>
                        </Tooltip>
                      }
                    >
                      <Button
                        className={`btn-sm btn-success btn-round mr-5 mb-2`}
                        type="button"
                      >
                        <Badge type="button" bg="" onClick={() => removeTagModal(row.group_id)}>
                          <i className="fas fa-times-circle pr-5"></i>
                        </Badge>
                        {tags ? row.title : ""}
                      </Button>
                    </OverlayTrigger>
                  );
                })}
              </div>
            )}
          </ModalBody>
          <ModalFooter>

            <Button
              className="btn-sm"
              color="info"
              type="submit"
            >
              Add
            </Button>
            <Button
              className="btn-sm"
              color="danger"
              type="button"
              onClick={() => setAddTagModal(false)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
      <Modal
        isOpen={groupModal}
        fade={false}
        toggle={() => removeGroupModal()}
      >
        <ModalBody>
          <div className="warning-box text-center">
            <span className="warning">
              <i className="mdi mdi-alert-circle"> </i>
            </span>
            <h3> Are you sure ? </h3>
            <p> You want to remove this group ? </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-sm"
            color="info"
            onClick={() => removeGroupLead()}
          >
            Yes, Remove it
          </Button>
          <Button
            className="btn-sm"
            color="danger"
            onClick={() => removeGroupModal()}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={tagModal}
        fade={false}
        toggle={() => removeTagModal()}
      >
        <ModalBody>
          <div className="warning-box text-center">
            <span className="warning">
              <i className="mdi mdi-alert-circle"> </i>
            </span>
            <h3> Are you sure ? </h3>
            <p> You want to remove this Tag ? </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-sm"
            color="info"
            onClick={() => removeTagLead()}
          >
            Yes, Remove it
          </Button>
          <Button
            className="btn-sm"
            color="danger"
            onClick={() => removeTagModal()}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={taskModal}
        fade={true}
        size="md"
        toggle={() => {
          setTaskModal(false);
          setTaskData({});
        }}
      >
        <AvForm
          onValidSubmit={handleTaskSubmit}
          className="needs-validation"
        >
          <div className="note">
            <div className="modal-head top-heading">
              <h2> {taskData.meeting_id ? 'Manage' : 'Add'}  Task </h2>
              <button
                onClick={() => {
                  setTaskModal(false);
                  setTaskData({});
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <ModalBody className="pb-0">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <AvField
                    name="event_title"
                    label="Title"
                    className="form-control"
                    required
                    type="text"
                    value={taskData.event_title}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <AvField
                    name="server_date"
                    label="Select Date"
                    className="form-control"
                    required
                    type="date"
                    value={taskData.server_date}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <AvField
                    name="server_time"
                    label="Select Time"
                    className="form-control"
                    required
                    type="time"
                    value={taskData.server_time}
                  />
                </div>
                <Col md={6}>
                  <AvGroup>
                    <label> MainTime Zone </label>
                    <div className="MainTZone MainTime One">
                      <Select
                        value={maintimezone}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.id}
                        isMulti={false}
                        options={maintimezones}
                        classNamePrefix="select2-selection"
                        className={`${maintimezone != ""
                          ? "is-touched is-dirty av-valid"
                          : "is-touched is-pristine av-invalid is-invalid"
                          }`}
                        name="time_zone"
                        onChange={(e) => {
                          settimezone(e);
                        }}
                      />
                    </div>
                    <AvInput
                      type="hidden"
                      required
                      name="time_zone_1"
                      value={maintimezone.id}
                    />
                    <AvFeedback> This is required * </AvFeedback>
                  </AvGroup>
                </Col>
                <Col md={6}>
                  <AvGroup>
                    <label> Time Zone </label>
                    <div className="MainTZone MainTime TwoTimeZone">
                      <Select
                        value={timezone}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.id}
                        isMulti={false}
                        options={timeZone_data}
                        classNamePrefix="select2-selection"
                        className={`${timezone != ""
                          ? "is-touched is-dirty av-valid"
                          : "is-touched is-pristine av-invalid is-invalid"
                          }`}
                        name="time_zone1"
                        onChange={(e) => {
                          setTimeZone(e);
                        }}
                      />
                    </div>
                    <AvInput
                      type="hidden"
                      required
                      name="time_zone"
                      value={timezone.id}
                    />
                    <AvFeedback> This is required * </AvFeedback>
                  </AvGroup>
                </Col>
                <Col md={12} className="mt-3">
                  <div className="MainTZone Task Two">
                    <AvField
                      className="select form-select"
                      type="select"
                      name="assign_to"
                      label="Assign To"
                      classNamePrefix="select2-selection"
                      value={taskData.assign_to}
                    >
                      <option value={""}> Un Assign </option>
                      <optgroup label="This Lead">
                        <option key={1} value={info.linked_user_id}>{info.first_name + " " + info.last_name + " - " + info.email}</option>
                      </optgroup>
                      <optgroup label="Coach">
                        <option key={2} value={coachUser.user_id}>{coachUser.name + " - " + coachUser.email}</option>
                      </optgroup>
                      <optgroup label="Coach Partner">
                        {coachPartners.map((row, i) => {
                          return (
                            <option key={i} value={row.user_id}>
                              {row.name + " - " + row.email}
                            </option>
                          );
                        })}
                      </optgroup>

                    </AvField>
                  </div>
                </Col>
              </div>
              <div className="mb-0">
                <AvField
                  type="hidden"
                  name="client_id"
                  value={info.client_id}
                />
              </div>
              {
                taskData.meeting_id && (
                  <AvField
                    type="hidden"
                    name="meeting_id"
                    value={taskData.meeting_id}
                  />
                )
              }
            </ModalBody>
            <ModalFooter>
              {
                taskData.meeting_id && (
                  <Button className="btn-sm" color="warning" onClick={() => { deleteTaskAndEvents(taskData.meeting_id) }}>
                    Delete this task
                  </Button>
                )
              }
              <Button className="btn-sm" color="info" type="submit">
                Save
              </Button>
              <Button className="btn-sm" color="danger" onClick={() => { setTaskModal(false); setTaskData({}); }}>
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </AvForm>
      </Modal>
      <div className="dialer">
        <Loader loaded={loader}></Loader>
      </div>
      <div className={`page3 ${page3Class} custom-scroll`}>
        <div>
          <div className="modal-head top-heading">
            <h2> Add company to this lead</h2>
            <button
              onClick={() => {
                setpage3Class("slide-out2");
              }}
              className="btn btn-link text-white"
            >
              <i className="fas fa-times"> </i>
            </button>
          </div>

        </div>
      </div>

      <Modal
        isOpen={todoModal}
        fade={true}
        size="md"
        toggle={() => {
          setTodoModal(false);
        }}
      >
        <div className="note">
          <div className="modal-head top-heading">
            <h2> Add list </h2>
            <button
              onClick={() => {
                setTodoModal(false);
              }}
              className="btn btn-link text-white"
            >
              <i className="fas fa-times"> </i>
            </button>
          </div>
          <ModalBody>
            <AvForm
              onValidSubmit={handleTodoSubmit}
              // model={this.state.record}
              className="needs-validation"
            >
              <div className="mb-3">
                <AvField
                  className="form-control"
                  type="textarea"
                  name="description"
                  label=""
                  placeholder="Add a note"
                  required
                  rows={5}
                />
              </div>
              <ModalFooter>
                <Button className="btn-sm" color="info" type="submit">
                  Save
                </Button>
                <Button className="btn-sm" color="danger" onClick={() => setTodoModal(false)}>
                  Cancel
                </Button>
              </ModalFooter>
            </AvForm>
          </ModalBody>
        </div>
      </Modal>
      <Modal
        isOpen={DynamicModal}
        fade={true}
        size="md"
        toggle={() => {
          setDynamicModal(false);
          setSmsBody("");
          setToken("");
          setSubject("");
          setHtml("");
          setTemplateId(0);
          setEventData({});
        }}
      >
        <AvForm
          onValidSubmit={handleSubmit}
          // model={this.state.record}
          className="needs-validation"
        >
          {DynamicModalName == "Note" ? (
            <div className="note">
              <div className="modal-head top-heading">
                <h2> Add/View  Note </h2>
                <button
                  onClick={() => {
                    setDynamicModal(false);
                    setSmsBody("");
                    setToken("");
                    setSubject("");
                    setHtml("");
                  }}
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"> </i>
                </button>
              </div>
              <ModalBody>
                <div className="mb-3">
                  <AvField
                    className="form-control"
                    type="textarea"
                    name="activity"
                    label=""
                    placeholder="Add a note"
                    required
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button className="btn-sm" color="info" type="submit">
                  Add Note
                </Button>
                <Button className="btn-sm" color="danger" onClick={() => setDynamicModal(false)}>
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          ) : DynamicModalName == "BasicInfo" ? (
            <div className="note">
              <div className="modal-head top-heading">
                <h2> Basic Info </h2>
                <button
                  onClick={() => {
                    setDynamicModal(false);
                  }}
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"> </i>
                </button>
              </div>
              <ModalBody>
                <div className="pt-20 table-responsive">
                  <Table className="table mb-0">
                    <tbody>
                      <tr>
                        <td> Comapany Name </td> <td> {info.first_name} </td>
                        {/* <td> Last Name </td> <td> {info.last_name} </td> */}
                      </tr>
                      <tr>
                        <td> Email </td> <td> {info.email} </td>
                        <td> </td> <td> </td>
                      </tr>
                      <tr>
                        <td> Mobile </td> <td> {convertPhoneFormat(info.country_code + info.mobile)} </td>
                        <td> Phone </td> <td> {convertPhoneFormat(info.phone_extension + info.phone)} </td>
                      </tr>
                      <tr>
                        <td> Client Source </td> <td> {info.source} </td>
                        <td> Client Id </td> <td> {info.client_id} </td>
                      </tr>
                      <tr>
                        <td colSpan={4}> ADDRESS INFORMATION </td>
                      </tr>
                      <tr>
                        <td> Address </td> <td> {info.address} </td>
                        <td> City </td> <td> {info.city} </td>
                      </tr>
                      <tr>
                        <td> State </td> <td> {info.state} </td>
                        <td> Zip </td> <td> {info.zip} </td>
                      </tr>
                      <tr>
                        <td> TAG </td>

                        <td>
                          {info.tags && (
                            <OverlayTrigger
                              key={1}
                              placement="auto"
                              overlay={
                                <Tooltip id={`tooltip-auto`}>
                                  <strong>
                                    {info.tags ? info.tags.title : ""}
                                  </strong>
                                </Tooltip>
                              }
                            >
                              <Button
                                className={`btn-sm btn-success btn-round mr-5`}
                              >
                                <Badge
                                  bg=""
                                  onClick={() => removeTagModal(0)}
                                >
                                  <i className="fas fa-times-circle pr-5"></i>
                                </Badge>
                                {info.tags ? info.tags.title : ""}
                              </Button>
                            </OverlayTrigger>
                          )}
                          <Modal
                            isOpen={tagModal}
                            fade={false}
                            toggle={() => removeTagModal()}
                          >
                            <ModalBody>
                              <div className="warning-box text-center">
                                <span className="warning">
                                  <i className="mdi mdi-alert-circle"> </i>
                                </span>
                                <h3> Are you sure ? </h3>
                                <p> You want to remove this Tag ? </p>
                              </div>
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                className="btn-sm"
                                color="info"
                                type="button"
                                onClick={() => removeTagLead()}
                              >
                                Yes, Remove it
                              </Button>
                              <Button
                                className="btn-sm"
                                color="danger"
                                type="button"
                                onClick={() => removeTagModal()}
                              >
                                Cancel
                              </Button>
                            </ModalFooter>
                          </Modal>
                        </td>
                        <td>  </td>
                        <td>
                          {info.groups && (
                            <OverlayTrigger
                              key={0}
                              placement="auto"
                              overlay={
                                <Tooltip id={`tooltip-auto`}>
                                  <strong>
                                    {" "}
                                    {info.groups
                                      ? info.groups.title
                                      : ""}{" "}
                                  </strong>
                                </Tooltip>
                              }
                            >
                              <Button
                                className={`btn-sm btn-success btn-round mr-5`}
                              >
                                <Badge
                                  bg=""
                                  onClick={() => removeGroupModal(0)}
                                >
                                  <i className="fas fa-times-circle pr-5"></i>
                                </Badge>
                                {info.groups ? info.groups.title : ""}
                              </Button>
                            </OverlayTrigger>
                          )}

                        </td>

                      </tr>
                    </tbody>
                  </Table>
                </div>
              </ModalBody>
            </div>
          ) : DynamicModalName == "sms" ? (
            <div className="sms">
              <div className="modal-head top-heading">
                <h2> Send SMS</h2>
                <button
                  onClick={() => {
                    setDynamicModal(false);
                    setSmsBody("");
                    setToken("");
                    setSubject("");
                    setHtml("");
                    setTemplateId(0);
                  }}
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"> </i>
                </button>
              </div>
              <ModalBody>
                <div className="mb-3">
                  <div className="MainTZone SendSMS One">
                    <Select
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      // value={options.filter(({ id }) => id ===country_code)}
                      isMulti={false}
                      options={templates}
                      classNamePrefix="select2-selection"
                      name="country_id"
                      placeholder={"Select sms template"}
                      onChange={(e) => {
                        setSmsBody(e.body);
                        setTemplateId(e.id);
                      }}
                    />
                  </div>
                  <AvField type="hidden" name="template_id" value={template_id} />
                  <AvField type="hidden" name="tos" value={info.mobile} />
                  <AvField type="hidden" name="activity" value={"sms send"} />
                </div>
                <div className="mb-3">
                  <AvField
                    className="select form-select"
                    type="select"
                    name="froms"
                    required
                  >
                    <option value=""> Select Phone Number </option>
                    {phoneNumbers.map((row, i) => {
                      return (
                        <option key={"phone-" + i} value={row.phone_number}>
                          {convertPhoneFormat(row.phone_number)}
                        </option>
                      );
                    })}
                  </AvField>
                </div>
                <div className="row mb-3">
                  <div className="col-md-8">
                    <div className="MainTZone SendSMS Two">
                      <Select
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.key}
                        // value={options.filter(({ id }) => id ===country_code)}
                        isMulti={false}
                        options={tokens}
                        classNamePrefix="select2-selection"
                        name="country_id"
                        placeholder={"Select token"}
                        onChange={(e) => {
                          setToken(`{${e.key}}`);
                        }}
                      />
                    </div>
                    {/* <AvField type="hidden" name="country_code" value={token} /> */}
                  </div>
                  <div className="col-md-4">
                    <button
                      onClick={() => setSmsBody(`${smsBody} ${token}`)}
                      type="button"
                      className="btn btn-primary"
                    >
                      Insert Token
                    </button>
                  </div>
                </div>
                <div className="mb-3">
                  <AvField
                    className="form-control"
                    type="textarea"
                    name="body"
                    required
                    value={smsBody}
                    placeholder={"Enter body"}
                    onChange={(e) => setSmsBody(e.target.value)}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button className="btn-sm" color="info" type="submit">
                  Send
                </Button>
                <Button className="btn-sm" color="danger" onClick={() => setDynamicModal(false)}>
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          ) : DynamicModalName == "rvm" ? (
            <div className="rvm">
              <div className="modal-head top-heading">
                <h2> Send RVM </h2>
                <button
                  onClick={() => {
                    setDynamicModal(false);
                    setSmsBody("");
                    setToken("");
                    setSubject("");
                    setHtml("");
                  }}
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"> </i>
                </button>
              </div>
              <ModalBody>

                <div className="mb-3">
                  <AvField
                    className="select form-select"
                    type="select"
                    name="froms"
                    required
                  >
                    <option value=""> Select Phone Number </option>
                    {phoneNumbers.map((row, i) => {
                      return (
                        <option key={"num-" + i} value={row.phone_number}>
                          {convertPhoneFormat(row.phone_number)}
                        </option>
                      );
                    })}
                  </AvField>
                </div>
                <div className="mb-3" >
                  <AvRadioGroup
                    inline name="file_type"
                    value={file_type}
                    onChange={
                      (e) =>
                        onChangefileTypeAction(e.target.value)
                    } >
                    <AvRadio label="Select Template"
                      value="template-section" />
                    <AvRadio label="Record"
                      value="record-section" />
                  </AvRadioGroup>
                </div>
                <div id="record-section" style={{ display: "none" }}>
                  <div className="row">
                    <div className="col-12" >
                      <AudioReactRecorder style={{ height: 100 }} state={recordState}
                        onStop={onStop}
                        backgroundColor="rgb(255,255,255)"
                      />
                      <audio id="audio"
                        controls src={
                          audioData ? audioData.url : null
                        }>
                      </audio>
                    </div >
                    <div className="col-12 mt-2" >
                      <Button type="button" className="m-2"
                        color="secondary"
                        onClick={start} >
                        Start </Button>
                      <Button type="button"
                        color="secondary"
                        onClick={stop} >
                        Stop </Button>
                    </div >
                  </div>
                </div>

                <div className="mb-3" id="template-section" style={{ display: "block" }}>
                  <div className="MainTZone SendRVM One">
                    <Select
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      // value={options.filter(({ id }) => id ===country_code)}
                      isMulti={false}
                      options={templates}
                      classNamePrefix="select2-selection"
                      name="country_id"
                      placeholder={"Select rvm template"}
                      onChange={(e) => {
                        setSmsBody(e.id);
                      }}
                    />
                  </div>
                  <AvField
                    type="hidden"
                    name="tos"
                    value={info.mobile ? info.mobile : info.phone}
                  />
                  <AvField type="hidden" name="activity" value={"RVM: send"} />
                  <AvField type="hidden" name="template_id" value={smsBody} />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button className="btn-sm" color="info" type="submit">
                  Send
                </Button>
                <Button className="btn-sm" color="danger" onClick={() => setDynamicModal(false)}>
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          ) : DynamicModalName == "call" ? (
            <div className="call">
              <div className="modal-head top-heading">
                <h2> Initiate Outbound Call </h2>
                <button
                  onClick={() => {
                    setDynamicModal(false);
                    setSmsBody("");
                    setToken("");
                    setSubject("");
                    setHtml("");
                  }}
                  className="btn btn-link text-white"
                  type="button"
                >
                  <i className="fas fa-times"> </i>
                </button>
              </div>
              <ModalBody>
                <div className="mb-3">
                  {callToken ? (
                    <Phone paramsters={paramsters} getCallSid={getCallSid}> </Phone>
                  ) : (
                    <p> Loading... </p>
                  )}
                </div>
              </ModalBody>
            </div>
          ) : DynamicModalName == "meeting" ? (
            <div className="meeting">
              <div className="modal-head top-heading">
                <h2> Schedule an event </h2>
                <button
                  onClick={() => {
                    setDynamicModal(false);
                    setSmsBody("");
                    setToken("");
                    setSubject("");
                    setHtml("");
                    setEventData({});
                  }}
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"> </i>
                </button>
              </div>
              <ModalBody>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <AvField
                      name="event_title"
                      label="Title"
                      className="form-control"
                      required
                      type="text"
                      value={eventData.event_title}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <AvField
                      name="server_date"
                      label="Select Date"
                      className="form-control"
                      required
                      type="date"
                      value={eventData.server_date}
                    />
                    <AvInput
                      name="body"
                      value={`A meeting schedule  request sent  to ({client_name}) {link}`}
                      type="hidden"
                    />
                    <AvInput
                      name="calendar_id"
                      value={defaultCalendar}
                      type="hidden"
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <AvField
                      name="server_time"
                      label="Select Time"
                      className="form-control"
                      required
                      type="time"
                      value={eventData.from_time}
                    />
                  </div>
                  <Col md={6}>
                    <AvGroup>
                      <label> MainTime Zone </label>
                      <div className="MainTZone MainTime One">
                        <Select
                          value={maintimezone}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.id}
                          isMulti={false}
                          options={maintimezones}
                          classNamePrefix="select2-selection"
                          className={`${maintimezone != ""
                            ? "is-touched is-dirty av-valid"
                            : "is-touched is-pristine av-invalid is-invalid"
                            }`}
                          name="time_zone"
                          onChange={(e) => {
                            settimezone(e);
                          }}
                        />
                      </div>
                      <AvInput
                        type="hidden"
                        required
                        name="time_zone_1"
                        value={maintimezone.id}
                      />
                      <AvFeedback> This is required * </AvFeedback>
                    </AvGroup>
                  </Col>
                  <Col md={6}>
                    <AvGroup>
                      <label> Time Zone </label>
                      <div className="MainTZone MainTime TwoTimeZone">
                        <Select
                          value={timezone}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.id}
                          isMulti={false}
                          options={timeZone_data}
                          classNamePrefix="select2-selection"
                          className={`${timezone != ""
                            ? "is-touched is-dirty av-valid"
                            : "is-touched is-pristine av-invalid is-invalid"
                            }`}
                          name="time_zone1"
                          onChange={(e) => {
                            setTimeZone(e);
                          }}
                        />
                      </div>
                      <AvInput
                        type="hidden"
                        required
                        name="time_zone"
                        value={timezone.id}
                      />
                      <AvFeedback> This is required * </AvFeedback>
                    </AvGroup>
                    {
                      eventData.meeting_id && (
                        <AvField
                          type="hidden"
                          name="meeting_id"
                          value={eventData.meeting_id}
                        />
                      )
                    }
                  </Col>
                </div>
              </ModalBody>
              <ModalFooter>
                {
                  eventData.meeting_id && (
                    <Button className="btn-sm" color="warning" onClick={() => { deleteTaskAndEvents(eventData.meeting_id) }}>
                      Delete this schedule
                    </Button>
                  )
                }
                <Button className="btn-sm" color="info" type="submit">
                  Schedule
                </Button>
                <Button className="btn-sm" color="danger" onClick={() => setDynamicModal(false)}>
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          ) : (
            <div className=""> </div>
          )}
        </AvForm>
      </Modal>
      <Modal
        isOpen={EmailModal}
        fade={true}
        size="lg"
        toggle={() => {
          setEmailModal(false);
          setSmsBody("");
          setToken("");
          setSubject("");
          setHtml("");
        }}
      >
        <AvForm
          onValidSubmit={handleEmailSubmit}
          autoComplete="off"
          className="needs-validation"
        >
          <div className="email">
            <div className="modal-head top-heading">
              <h2> Send Email</h2>
              <button
                onClick={() => {
                  setEmailModal(false);
                  setSmsBody("");
                  setToken("");
                  setSubject("");
                  setHtml("");
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <ModalBody>
              <div className="mb-3">
                <div className="MainTZone ClientDetails One">
                  <Select
                    getOptionLabel={(option) => option.title}
                    getOptionValue={(option) => option.id}
                    // value={options.filter(({ id }) => id ===country_code)}
                    isMulti={false}
                    options={templates}
                    classNamePrefix="select2-selection"
                    placeholder={"Select email template"}
                    name="country_id"
                    onChange={(e) => {
                      setSubject(e.title);
                      getEmailTemplateHtml(e.id);
                    }}
                  />
                </div>
                <AvField type="hidden" name="tos" value={info.email} />
                <AvField type="hidden" name="activity" value={"Email: sent"} />
              </div>
              <div className="mb-3">
                <AvField
                  className="form-control"
                  type="text"
                  name="froms"
                  value={uInfo().email}
                  required
                  placeholder="Enter from email"
                />
              </div>
              <div className="row mb-3">
                <div className="col-md-12 mb-3">
                  <div className="MainTZone ClientDetails Two 3">
                    <Select
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.key}
                      // value={options.filter(({ id }) => id ===country_code)}
                      isMulti={false}
                      options={tokens}
                      classNamePrefix="select2-selection"
                      name="country_id"
                      onChange={(e) => {
                        setToken(`{${e.key}}`);
                      }}
                    />
                  </div>
                  {/* <AvField type="hidden" name="country_code" value={token} /> */}
                </div>
                {html == "" && (
                  <div className="col-md-4 col-6">
                    <button
                      onClick={() => setSmsBody(`${smsBody} ${token}`)}
                      type="button"
                      className="btn btn-primary"
                    >
                      Insert Body
                    </button>
                  </div>
                )}
                <div className="col-md-4 col-6">
                  <button
                    onClick={() => setSubject(`${subject} ${token}`)}
                    type="button"
                    className="btn btn-primary"
                  >
                    Insert Subject
                  </button>
                </div>
              </div>
              <div className="mb-3">
                <AvField
                  className="form-control"
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  required
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>
              {html == "" ? (
                <div className="mb-3">
                  <AvField
                    className="form-control"
                    type="textarea"
                    name="body"
                    placeholder="Enter body"
                    required
                    value={smsBody}
                    onChange={(e) => setSmsBody(e.target.value)}
                  />
                </div>
              ) : (
                <>
                  <div
                    id="custom-template"
                    className="height-500 custom-scroll"
                    dangerouslySetInnerHTML={{ __html: html }}
                  ></div>
                  {/* <AvField type="hidden" name="body" value={document.getElementById('custom-template')}/> */}
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button className="btn-sm" color="info" type="submit">
                Send
              </Button>
              <Button className="btn-sm" color="danger" onClick={() => {
                setEmailModal(false);
                setSmsBody("");
                setToken("");
                setSubject("");
                setHtml("");
              }}>
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </AvForm>
      </Modal>
      <Modal
        isOpen={sentEmailTemplateModal}
        fade={true}
        size="lg"
        toggle={() => {
          setSentEmailTemplateModal(false);
        }}
      >
        <div className="email">
          <div className="modal-head top-heading">
            <h2> Email </h2>
            <button
              onClick={() => {
                setSentEmailTemplateModal(false);
              }}
              className="btn btn-link text-white"
            >
              <i className="fas fa-times"> </i>
            </button>
          </div>
          <ModalBody>
            <h3><span>Subject:</span> {emailData.subject}</h3>
            <div
              className="height-500 custom-scroll"
              dangerouslySetInnerHTML={{ __html: emailData.body }}
            />
          </ModalBody>
        </div>
      </Modal>
      <Modal
        isOpen={taskCompleteModal}
        fade={false}
        toggle={() => setTaskCompleteModal(false)}
      >
        <ModalBody>
          <div className="warning-box text-center">
            <span className="warning">
              <i className="mdi mdi-alert-circle"> </i>
            </span>
            <h3> Are you sure ? </h3> <p> You want to mark this complete ? </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="btn-sm" color="info" onClick={() => completeTask()}>
            Yes, Change it
          </Button>
          <Button className="btn-sm" color="danger" onClick={() => setTaskCompleteModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={reminderModal}
        fade={true}
        size="lg"
        toggle={() => {
          setReminderModal(false);
        }}
      >
        <div className="email">
          <div className="modal-head top-heading">
            <h2> Appointment Reminder List </h2>
            <button
              onClick={() => {
                setReminderModal(false);
              }}
              className="btn btn-link text-white"
            >
              <i className="fas fa-times"> </i>
            </button>
          </div>
          <ModalBody>
            <table className="table table-striped  table-bordered">
              <thead>
                <tr>
                  <th>Appointment ID</th>
                  <th>Status</th>
                  <th>Reminder For</th>
                  <th>Reminder Time</th>
                </tr>
              </thead>
              <tbody>
                {
                  reminders.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td>{row.reminder_id}</td>
                        <td>{row.status}</td>
                        <td>{row.reminder_for}</td>
                        <td>{row.reminder_datetime}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </ModalBody>
        </div>
      </Modal>
      {page4Class == "slide-in" && (
        <div className={`page3 ${page4Class} custom-scroll`}>
          <div>
            <div className="modal-head top-heading">
              <h2> Add Task </h2>
              <button
                onClick={() => {
                  setpage4Class("slide-out2");
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <CreateTask
              cancelSlider={cancelTask}
              compannyId={info.client_id}
              from="companyDetails"
            />
          </div>
        </div>
      )}


      <Modal
        isOpen={pipelineModal}
        fade={true}
        size="md"
        className="hello"
        toggle={() => {
          setPipelineModal(false);
          setSelectedPipeline({});
        }}
      >
        <AvForm
          onValidSubmit={handleDealSubmit}
          className="needs-validation"
        >
          <div className="sms">
            <div className="modal-head top-heading">
              <h2> Opportunity </h2>
              <button
                onClick={() => {
                  setPipelineModal(false);
                  setSelectedPipeline({});
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <ModalBody>
              <div className="mb-3">
                <AvField
                  className="select form-select"
                  type="select"
                  name="pipeline_id"
                  label="Pipeline"
                  required
                  disabled
                  defaultValue={pipeline.pipeline_id}
                >
                  <option value={pipeline.pipeline_id}>{pipeline.name}</option>
                </AvField>
              </div>
              <div className="mb-3">
                <AvField
                  className="select form-select"
                  type="select"
                  name="status_id"
                  label="Deal Status"
                  required
                  disabled
                  defaultValue={selectedPipeline.status_id}
                >
                  <option value={selectedPipeline.status_id}>
                    {selectedPipeline.title}
                  </option>
                </AvField>
              </div>
              <div className="mb-3 d-none">
                <AvField
                  className="select form-select"
                  type="select"
                  name="assigned_to"
                  label="Assign Member"
                  required
                >
                  <option value={""}> Select Member </option>
                  {coachPartners.map((row, i) => {
                    return (
                      <option key={"member-" + i} value={row.user_id}>
                        {row.name}
                      </option>
                    );
                  })}
                </AvField>
              </div>
              <div className="mb-3">
                <AvField
                  type="hidden"
                  name="client_id"
                  value={info.client_id}
                />
              </div>
              <div className="mb-3">
                <AvField
                  className="form-control"
                  type="textarea"
                  name="comments"
                  required
                  placeholder={"Enter comments"}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button className="btn-sm" color="info" type="submit">
                Assign
              </Button>
              <Button
                className="btn-sm"
                color="danger"
                onClick={() => {
                  setPipelineModal(false);
                  setSelectedPipeline({});
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
};
export default ChatInbox;