import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import Helmet from "react-helmet";

import ReactDatatable from "@ashvin27/react-datatable";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import {
  dcrypt,
  bcrypt,
  isAllowed,
  uInfo,
  uToken,
  currentDate,
  NumberWithCommas,
  hasRole,

} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import Badge from "react-bootstrap/Badge";
import swal from "sweetalert";
import Select from "react-select";

import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

export default function CoachAddonsList() {
  const [pageName, setPageName] = useState("Welcome to the Add-on Library");
  const [activeAddons, setActiveAddons] = useState([]);
  const [availableAddons, setAvailableAddons] = useState([]);
  const [inactiveAddons, seInactiveAddons] = useState([]);
  const [requestModal, setRequestModal] = useState(false);
  const [addonDetail, setAddonDetail] = useState({});

  const [packages, setPackages] = useState([]);

  const routeHistory = useHistory();

  const getCoachAddons = (packageId = null) => {
    const formData = JSON.stringify({
      available_for_plans: packageId || "",
      included_in_plans: packageId || "",
    });

    fetch(`${ApiUrl}addons/library`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          setActiveAddons(data.data.records.active);
          if (data.data.records.available) {
            setAvailableAddons(data.data.records.available);
          }
          if (data.data.records.inactive) {
            seInactiveAddons(data.data.records.inactive);
          }
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getPackages = () => {
    fetch(`${ApiUrl}` + "packages/list/Coach", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ package_type: "default" }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setPackages(response.data.records);
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    getCoachAddons();
    getPackages();
  }, []);

  const requestAddon = (addonId) => {
    fetch(`${ApiUrl}addons/request`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify({ id: addonId }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          // if (!addonDetail.id) {
          //   setActiveAddons((prevAddons) => [
          //     ...prevAddons,
          //     response.data.record,
          //   ]);
          // } else {
          //   setActiveAddons((prevAddons) =>
          //     prevAddons.map((addon) =>
          //       addon.id === addonDetail.id ? response.data.record : addon
          //     )
          //   );
          // }

          swal({
            title: response.message,
            icon: "success",
          });
          setRequestModal(false);
        } else {
          setAddonDetail([]);
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        swal({
          title: "An error occurred",
          icon: "error",
        });
      });
  };

  const createAddonInvoice = (addon) => {
    fetch(`${ApiUrl}` + "add/invoice", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        addon_id: addon.id, //addonDetail.id,
        package_id: "0",
        product_id: "0",
        master_type: "addon",
        created_for_id: uInfo().user_id,
        invoice_currency_code: "USD",
        invoice_for: "Coach",
        items_data: [
          {
            items: addon.title,
            price: addon.price,
            quantity: "1",
            readOnly: "0",
            total: addon.price,
          },
        ],
        payment_method: "stripe",
        renewal_interval:
          addon.pricing_interval == "Monthly" ? "Monthly" : "None",
        start_date: currentDate(),
        total_price: addon.price,
        type: addon.pricing_interval == "Monthly" ? "recurring" : "one_time",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          console.log(data.data.payment_link);
          routeHistory.push(data.data.payment_link);
          // setRequestModal(false);
          // this.getData();
          // swal({
          //   title:
          //     "Invoice has been sent to your email, once you pay it then relevant features will be enabled",
          //   icon: "success",
          // });
          //   if (this.state.id != null) {
          //     this.props.history.goBack()
          //   } else {
          //     this.props.history.push('/invoice/list')
          //   }
        } else {
          // setRequestModal(false);
          swal({
            title: data.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getPackageName = (packageIds) => {
    var packageIds = String(packageIds);

    const ids = packageIds.split(",").map((id) => parseInt(id.trim(), 10));

    const foundPackages = ids
      .map((id) => {
        const pkg = packages.find((pkg) => pkg.package_id === id);
        return pkg ? pkg.name : null;
      })
      .filter((name) => name !== null);

    return foundPackages.join(", ") || "";
  };

  const AddonCards = ({ addons, type }) => {
    return (
      <>
        <div className="mt-3">
          <div className="ps-2 pb-1 pe-1">
            <Row className="my-3 mx-1">
              <Col lg="1" className="text-center">
                {/* {availableAddons &&
                availableAddons.length > 0 &&
                (type == "active" ? (
                  <h5 className="fw-bold" style={{color:'#008037'}}>Active </h5>
                ) : (
                  <h5 className="fw-bold" style={{color:'#00002a'}}>Add-ons </h5>
                ))} */}

                {availableAddons &&
                  availableAddons.length > 0 &&
                  (type === "active" ? (
                    <h5 className="fw-bold" style={{ color: "#008037" }}>
                      Active
                    </h5>
                  ) : type === "available" ? (
                    <h5 className="fw-bold" style={{ color: "#00002a" }}>
                      Add-ons
                    </h5>
                  ) : type === "inactive" ? (
                    <h5 className="fw-bold" style={{ color: "#dc3545" }}>
                      Inactive
                    </h5>
                  ) : null)}
              </Col>
              <Col lg="11">
                <Row>
                  {addons.length > 0 ? (
                    addons
                      .filter((addon) =>
                        hasRole(uInfo(), [addon.required_permission])
                      )
                      .map((addon) => (
                        // addons.map((addon) => (

                        <Col lg="2" className="mb-3" key={addon.id}>
                          <div className="certification_sec AddonsPage">
                            <Card
                              className="h-100 d-flex flex-column"
                              style={{
                                borderTop: `8px solid ${
                                  type === "active"
                                    ? "#008037"
                                    : type === "available"
                                    ? "#00002A"
                                    : "#dc3545"
                                }`,
                                borderBottom: `8px solid ${
                                  type === "active"
                                    ? "#008037"
                                    : type === "available"
                                    ? "#00002A"
                                    : "#dc3545"
                                }`,
                              }}
                            >
                              <div className="RecentlyViewed text-center d-flex py-3 justify-content-center">
                                <div className="mb-2 px-3">
                                  <h5 className="text-start color-dark fw-bold">
                                    {addon.title}
                                  </h5>
                                  <p className="text-start">
                                    {addon.description}
                                  </p>
                                  <div className="text-start FixPosition">
                                    <h6 className="color-dark">
                                      Price:{" "}
                                      {addon.price && addon.price != 0
                                        ? `$${NumberWithCommas(
                                            Math.round(addon.price)
                                          )}`
                                        : // ${
                                          //   addon.pricing_interval == "Monthly"
                                          //     ? "/month"
                                          //     : ""
                                          // }`
                                          "N/A"}
                                      <smal className="fw-light fst-italic">
                                        {addon.price &&
                                        addon.price != 0 &&
                                        addon.pricing_interval == "Monthly"
                                          ? "/month"
                                          : ""}
                                      </smal>
                                    </h6>
                                  </div>
                                  {type == "active" && (
                                    <div className="me-3 position-absolute end-0 bottom-0">
                                      {addon.included_in_plans
                                        .split(",")
                                        .map(Number)
                                        .includes(uInfo().package_id) ? (
                                        <OverlayTrigger
                                          placement="top"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={
                                            <Tooltip id="button-tooltip">
                                              {`This is included in the ${getPackageName(
                                                uInfo().package_id
                                              )} Package`}
                                            </Tooltip>
                                          }
                                        >
                                          <h6 className="text-end color-dark fst-italic cursor-pointer">
                                            Included
                                          </h6>
                                        </OverlayTrigger>
                                      ) : (
                                        <h6
                                          className="text-end color-dark fst-italic"
                                          style={{ color: "#008037" }}
                                        >
                                          Purchased
                                        </h6>
                                      )}
                                    </div>
                                  )}
                                  {type == "available" && (
                                    <div className="me-3 position-absolute end-0 bottom-0">
                                      {addon.available_for_plans
                                        .split(",")
                                        .map(Number)
                                        .includes(uInfo().package_id) ? (
                                        <OverlayTrigger
                                          placement="top"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={
                                            <Tooltip id="button-tooltip">{`You need to purchase this add-on. Click here to buy now.`}</Tooltip>
                                          }
                                        >
                                          {/* <Link
                                          className=""
                                          to={
                                            "/addons/purchase/" +
                                            bcrypt(addon.id)
                                          }
                                        > */}
                                          <Button
                                            type="button"
                                            className="ms-3 mb-2 Green btn btn-secondary btn-sm"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              // setRequestModal(true);
                                              // setAddonDetail(addon);
                                              setAddonDetail(addon);
                                              createAddonInvoice(addon);
                                            }}
                                          >
                                            Get
                                          </Button>
                                          {/* </Link> */}
                                        </OverlayTrigger>
                                      ) : (
                                        <OverlayTrigger
                                          placement="top"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={
                                            <Tooltip id="button-tooltip">
                                              {`Available for ${getPackageName(
                                                addon.available_for_plans
                                              )}. Upgrade is required`}
                                            </Tooltip>
                                          }
                                        >
                                          <div className="d-inline-block">
                                            <Button
                                              type="button"
                                              className="ms-3 mb-2 Green btn btn-secondary cursor-pointer btn-sm"
                                              disabled
                                            >
                                              Get
                                            </Button>
                                          </div>
                                        </OverlayTrigger>
                                      )}
                                    </div>
                                  )}
                                  {type == "inactive" && (
                                    <div className="me-3 position-absolute end-0 bottom-0">
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                          <Tooltip id="button-tooltip">
                                            {`Subscription cancelled (payment failed), purchase this addon again`}
                                          </Tooltip>
                                        }
                                      >
                                        <div className="d-inline-block cursor-pointer">
                                          <Link
                                            className=""
                                            // to={
                                            //   "/addons/purchase/" +
                                            //   bcrypt(addon.id)
                                            // }
                                            onClick={(e) => {
                                              e.preventDefault();
                                              // setRequestModal(true);
                                              // setAddonDetail(addon);
                                              setAddonDetail(addon);
                                              createAddonInvoice(addon);
                                            }}
                                          >
                                            <h6
                                              className="text-end fst-italic"
                                              style={{ color: "#dc3545" }}
                                            >
                                              Past Due
                                            </h6>
                                          </Link>
                                        </div>
                                      </OverlayTrigger>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Card>
                          </div>
                        </Col>
                      ))
                  ) : (
                    <h5 className="text-center">No Addons are available</h5>
                  )}
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{pageName}</title>
      </Helmet>

      <Row>
        <Col sm={6}>
          <div className="page-title-box">
            <h4>{pageName}</h4>
            <ol className="breadcrumb m-0">
              <li key={0} className="breadcrumb-item active">
                {ProductName}
              </li>
              <li key={1} className="breadcrumb-item">
                <Link to="#">{pageName}</Link>
              </li>
            </ol>
          </div>
        </Col>
        <Col sm={6}>
          <div className="page-title-box text-align-right">
            <Button
              className="btn-sm"
              type="button"
              color="secondary"
              onClick={() => routeHistory.goBack()}
            >
              Back
            </Button>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg="4" className="d-none">
          <div className="ms-3 mb-3">
            <div className="CourseSelect">
              <Select
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                options={[
                  { label: "Active", value: "active" },
                  { label: "Inactive", value: "inactive" },
                  { label: "Cancelled", value: "cancelled" },
                ]}
                // onChange={handleStatusSelect}
                // value={selectedStatus}
                placeholder="Choose Status"
                className="basic-single"
                classNamePrefix="select"
              />
            </div>
          </div>
        </Col>

        <Col lg="12" className="DashboardCard mb-1">
          <AddonCards addons={activeAddons} type="active" />

          {availableAddons && availableAddons.length > 0 && (
            <AddonCards addons={availableAddons} type="available" />
          )}

          {inactiveAddons && inactiveAddons.length > 0 && (
            <AddonCards addons={inactiveAddons} type="inactive" />
          )}
        </Col>
      </Row>

      <Modal
        isOpen={requestModal}
        fade={false}
        toggle={() => setRequestModal(false)}
        backdrop={"static"}
      >
        <ModalHeader>Add-on Detail</ModalHeader>
        <ModalBody>
          <h5 className="color-dark">{addonDetail.title}</h5>
          <p>{addonDetail.description}</p>
          <h6 className="color-dark mt-2">
            Price:{" "}
            {addonDetail.price && addonDetail.price != 0
              ? `$${addonDetail.price}${
                  addonDetail.pricing_interval == "Monthly" ? "/month" : ""
                }`
              : "N/A"}
          </h6>
        </ModalBody>
        <ModalFooter>
          <p>
            You need to request this add-on. Once your request is approved, you
            will be able to access the add-on.
          </p>
          <Button
            color="secondary"
            type="button"
            onClick={() => setRequestModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="Green"
            type="button"
            // disabled={!this.state.loader}
            onClick={(e) => {
              e.preventDefault();
              // requestAddon(addonDetail.id);
              createAddonInvoice(e);
            }}
          >
            Request Add-on
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
