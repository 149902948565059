import React, {
  useEffect,
  useRef,
  useState,
  useReducer,
  useCallback,
} from "react";
import Accordion from "react-bootstrap/Accordion";
import { Link, useHistory, useLocation } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { bcrypt, uToken, uInfo } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import swal from "sweetalert";
import Select from "react-select";
import {
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import UrlNextPrev from "./UrlNextPrev";
import Froala from "../Froala";
import PaymentModel from "./PaymentModel";
import WayBookContext from "./WaybookContext";

const TraningQuiz = (props) => {
  const params = React.useContext(WayBookContext);

  const history = useHistory();
  const [drp_link, setdrp_link] = useState(false);
  const [cat_id] = useState(
    props.params.documents ? props.params.documents : null
  );
  const [step_id, setStep_id] = useState(
    props.params.step ? props.params.step : null
  );
  const [title, setTitle] = useState(props.title ? props.title : null);
  const [questions, setQuestions] = useState([]);
  const [questionsCoaches, setQuestionsCoaches] = useState([]);
  const [activeKeys, setActiveKeys] = useState([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  ]);
  const [userAnswers, setUserAnswers] = useState({});
  const [submitedDate, setSubmitedDate] = useState("");
  const [open, setOpen] = useState(false);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [totalScore, setTotalScore] = useState(0);
  const [passScoreRate, setPassScoreRate] = useState(0);
  const [datalogID, setDatalogId] = useState(0);
  const [alert, setAlert] = useState(false);
  const [numbers, setNumbers] = useState([]);
  const [defualtnumbers, setDefualtNumbers] = useState({});
  const [updateDivOpen, setUpdateDivOpen] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isStart, setIsStart] = useState(false);
  const [isTimeUp, setIsTimeUp] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [isAddquestionModel, setIsAddquestionModel] = useState(false);
  const [selectedQuestionType, setSelectedQuestionType] = useState("");
  const [quizStartTime, setQuizStartTime] = useState(null);
  const [quizEndTime, setQuizEndTime] = useState(null);
  const [forceRender, setForceRender] = useState(0);
  const [removedOption, setRemovedOption] = useState([]);
  const [removedQuestions, setRemovedQuestions] = useState([]);
  const [isUnlockedModel, setIsUnlockedModel] = useState(false);
  const [content, setContent] = useState("ssss");
  const [canEditCocah, setCanEditCocah] = useState(
    uInfo().type === "Coach"
      ? JSON.parse(localStorage.getItem("can_edit_coach"))
      : false
  );
  const [quizAdminSetting, setQuizAdminSetting] = useState({
    passRate: 0,
    total_allow_attempts: 0,
    allow_attempts: 0,
    time_limit: 0,
    lock: false,
    temporarylock: false,
  });
  const [parentCategory, setParentCategory] = useState({});
  const { search } = useLocation();
  const url_params = new URLSearchParams(search);
  const is_coach_section = url_params.get("sec") ? "Yes" : "No";
  const [bookmark, setBookmark] = useState(
    props.bookmark ? props.bookmark : false
  );
  const editorRefs = useRef([]);
  useEffect(() => {
    setStep_id(props.params.step);
    setDatalogId(props.list.log_id);
    //console.log("props.params.can_edit_coach ---->   ", props.params.can_edit_coach)
    //console.log("is_coach_section ---->   ", is_coach_section)
    //console.log("props.params.can_edit ---->   ", props.params.can_edit)
    // console.log("uInfo().user_id ---->   ", uInfo().user_id)
    //console.log("canEditCocah   ", canEditCocah)
    if (uInfo().type === "Client") {
      setParentCategory(props.parentCategory);
    }
  }, [props]);
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    console.log(userAnswers);
  }, [userAnswers]);

  useEffect(() => {
    //console.log(parentCategory)
  }, [parentCategory]);
  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0; // Returns true if object is empty
  };
  const handlepaidorfreecatId = (categories, targetCatId) => {
    for (const category of categories) {
      if (category.cat_id == targetCatId) {
        return category;
      }
      if (category.sub_cat) {
        for (const subCategory of category.sub_cat) {
          if (subCategory.cat_id == targetCatId) {
            return category;
          }
          for (const subCat of subCategory.sub_cat) {
            if (subCat.cat_id == targetCatId) {
              return category;
            }
          }
        }
      }
    }

    return null;
  };
  // useEffect(() => {

  //     console.log(questions)
  //     if (questions) {
  //         const allKeys = questions ? questions.map((_, index) => index) : [];
  //         setActiveKeys(allKeys);
  //     }
  // }, [questions]);

  useEffect(() => {
    setTitle(props.title);
  }, [props.title]);

  useEffect(() => {
    setBookmark(props.bookmark);
  }, [props.bookmark]);

  useEffect(() => {
    // console.log(props.list)
  }, [props]);

  useEffect(() => {
    // console.log(quizAdminSetting)
  }, [quizAdminSetting]);
  useEffect(() => {
    //console.log(defualtnumbers)
  }, [defualtnumbers]);

  useEffect(() => {
    numberSelect();
  }, [passScoreRate]);

  const numberSelect = () => {
    const options = Array.from({ length: 100 }, (v, k) => ({
      value: 100 - k,
      label: `${100 - k}%`,
    }));
    setNumbers(options);
    setDefualtNumbers({ label: `${passScoreRate}%`, value: passScoreRate });
    setQuizAdminSetting((prevState) => ({
      ...prevState,
      ["passRate"]: passScoreRate,
    }));
  };
  const forceUpdate = () => {
    setForceRender((prev) => prev + 1);
  };

  const getData = async () => {
    await fetch(`${ApiUrl}` + "training/quiz/question/" + cat_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ step_id: step_id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          //console.log(response, 'debugging');
          setQuestions(response.data.records);
          setQuestionsCoaches(response.data.records);
          setTotalQuestions(response.data.total);
          setTotalScore(response.data.traingscoreEarn);
          setSubmitedDate(response.data.submitedDate);
          setQuizAdminSetting(response.data.quiz_passing_details);
          setIsLocked(response.data.quiz_passing_details.quiz_lock);
          //setTimeLeft(response.data.quiz_passing_details.time_limit)
          //console.log(response.data.quiz_passing_percentage)
          setPassScoreRate(
            response.data.quiz_passing_details.passRate
              ? parseInt(response.data.quiz_passing_details.passRate)
              : 100
          );
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleQuestionTypeChange = (event) => {
    setSelectedQuestionType(event.target.value);
  };
  // const handleNewChange = (index, option) => {
  //     const newQuestions = [...questions];
  //     newQuestions[index].answer = [option];
  //     setQuestions(newQuestions);
  // };

  const handlePercentageChange = (e) => {
    // console.log(e.value)
    setPassScoreRate(e.value);
    setDefualtNumbers(e);
    setQuizAdminSetting((prevState) => ({
      ...prevState,
      ["passRate"]: e.value,
    }));
  };

  const handleInputChangeAdminsetting = (e) => {
    const { name, value } = e.target;
    setQuizAdminSetting((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleShowAlert = (percentage = 0) => {
    let msg = "";
    if (percentage >= passScoreRate) {
      // console.log('inside true')
      props.params.markAsComplete(cat_id, step_id);
      msg = `You have successfully completed this step with a score of ${percentage}%`;
    } else {
      msg = `Your current score is ${percentage}%.
             Note: Once your score reaches ${passScoreRate}%, this quiz will be marked as complete`;
    }
    swal({
      title: "Your quiz have been submitted",
      text: msg,
      icon: "success",
    });
  };

  const sendhandleShowAlert = (msg = 0, refresh = false) => {
    swal({
      title: "Your data has been submitted",
      icon: "success",
    }).then(function () {
      if (refresh === true) {
        window.location.reload();
      }
    });
  };

  const handleQuestionChange = (questionIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].question = value;
    setQuestions(newQuestions);
  };

  const handleQuestionContentChange = (
    questionIndex = null,
    question_content
  ) => {
    const openEndedQuestions = questions.filter(
      (question) => question.type !== "open-ended"
    );
    const matchedQuestion = openEndedQuestions[questionIndex];
    const matchedIndex = questions.findIndex(
      (question) => question.quiz_id === matchedQuestion.quiz_id
    );
    const newQuestions = [...questions];
    newQuestions[matchedIndex].question_content = question_content;
    setQuestions(newQuestions);
  };

  // function handleOptionChange(questionIndex, optionIndex, e) {
  //     const { name, value } = e.target;
  //     setQuestions(prevQuestions => {
  //         const updatedQuestions = [...prevQuestions];
  //         //console.log(updatedQuestions)
  //         updatedQuestions[questionIndex].options[optionIndex].option = value;
  //         return updatedQuestions;
  //     });

  // }

  const addMoreQuestions = (type) => {
    setQuestions((prevQuestions) => [
      ...prevQuestions,
      {
        question: "",
        type: type,
        options:
          type === "radio" ? [{ option: "" }, { option: "" }] : undefined,
        answer: type === "radio" ? "" : type === "true-false" ? "false" : "",
        question_content: "",
      },
    ]);
    setSelectedQuestionType("");
    setdrp_link(false);
  };

  const removeOption = async (questionIndex, optionIndex, quiz_id) => {
    const updatedQuestions = [...questions];
    const questionToUpdate = { ...updatedQuestions[questionIndex] };
    const deletedOption = questionToUpdate.options[optionIndex];
    const objectToSave = {
      questionIndex,
      quiz_id,
      removedOption: deletedOption,
    };

    setRemovedOption((prevOptions) => [...prevOptions, objectToSave]);
    const element = document.getElementById(
      `optionIndex_${optionIndex} questionIndex_${questionIndex}`
    );
    if (element) {
      element.remove();
    }

    // const updatedQuestions = [...questions];
    // updatedQuestions[questionIndex].options.splice(optionIndex, 1);
    // console.log(updatedQuestions);
    // setQuestions(updatedQuestions);
    // // Optionally force re-render if needed
    // forceUpdate();
  };

  const removeQuestion = (questionIndex, quiz_id) => {
    const updatedQuestions = [...questions];
    const questionToUpdate = { ...updatedQuestions[questionIndex] };

    const objectToSave = {
      questionIndex,
      quiz_id,
    };

    setRemovedQuestions((prevQuestions) => [...prevQuestions, objectToSave]);
    const element = document.getElementById(`questionIndex_${questionIndex}`);
    if (element) {
      element.remove();
    }
  };

  const savechangesHandler = async (cat_id, refresh = true) => {
    let updatedQuestions = [...questions];
    removedOption.forEach((removal) => {
      const { questionIndex, quiz_id, removedOption } = removal;
      if (updatedQuestions[questionIndex]?.quiz_id === quiz_id) {
        updatedQuestions[questionIndex].options = updatedQuestions[
          questionIndex
        ].options.filter((option) => option.option !== removedOption.option);
      }
    });
    // console.log('after updatedOptions ', updatedQuestions)

    const updatedQuestionsafterremoval = updatedQuestions.filter(
      (question, index) => {
        return !removedQuestions.some(
          (removed) =>
            removed.quiz_id === question.quiz_id ||
            removed.questionIndex === index
        );
      }
    );
    console.log("after updated Answer ", updatedQuestionsafterremoval);

    // let length = updatedQuestions.length;
    // console.log(length);
    // return false;
    for (const question of updatedQuestionsafterremoval) {
      let bodyParams = {
        id: question.quiz_id,
        cat_id: cat_id,
        step_id: step_id,
        question: question.question,
        question_content: question.question_content,
        type: question.type,
        options: question.options,
        answer: question.answer,
        admincanedit: props.params.can_edit,
        coachcanedit: props.params.can_edit_coach,
      };

      try {
        const response = await fetch(`${ApiUrl}` + "add/quiz/question", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
          body: JSON.stringify(bodyParams),
        });

        const responseData = await response.json();

        if (responseData.status === true) {
          toast.success(responseData.message, {
            hideProgressBar: true,
            position: "Top left",
            transition: Flip,
            className: "toast-custom-cls success",
          });
        } else {
          toast(responseData.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    deleteRecord(removedQuestions);
    if (refresh) {
      sendhandleShowAlert("Your data has been submitted!", true);
    }
  };

  const savechangesHandlerSetting = async (cat_id, step_id) => {
    let bodyParams = {
      passRate: quizAdminSetting.passRate,
      total_allow_attempts: quizAdminSetting.total_allow_attempts,
      time_limit: quizAdminSetting.time_limit,
      cat_id: cat_id,
      step_id: step_id,
    };
    fetch(`${ApiUrl}` + "add/quiz/questionSetting", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(bodyParams),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          sendhandleShowAlert("Your data has been submitted", false);
          setUpdateDivOpen(!updateDivOpen);
          toast.success(response.message, {
            hideProgressBar: true,
            position: "Top left",
            transition: Flip,
            className: "toast-custom-cls success",
          });
          getData();
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(questions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    //console.log(items);
    fetch(`${ApiUrl}` + "update/quiz/order", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ cat_id: cat_id, items_arr: items }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls success",
          });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setQuestions(items);
  };

  const handleAnswerChange = (e, index, option) => {
    let name = "";
    if (e?.target) {
      name = e.target.name;
    } else {
      name = e;
    }
    // console.log(e)
    // console.log(option)
    setUserAnswers((prevAnswers) => {
      const newAnswers = { [name]: option };
      return { ...prevAnswers, ...newAnswers };
    });
  };
  const handleOpenEndedAnswerChange = (e, index, option) => {
    const openEndedQuestions = questions.filter(
      (question) => question.type === "open-ended"
    );
    const matchedQuestion = openEndedQuestions[index];
    const matchedIndex = questions.find(
      (question) => question.quiz_id === matchedQuestion.quiz_id
    );
    const name = `answer-${matchedIndex.quiz_id}`;
    setUserAnswers((prevAnswers) => {
      const newAnswers = { [name]: option };
      return { ...prevAnswers, ...newAnswers };
    });
  };

  const answersQuizQuestionResultAttemps = async () => {
    let bodyParamsforResult = {
      step_id: step_id,
      cat_id: cat_id,
    };
    await fetch(`${ApiUrl}` + "training/quiz/anwers/results/attempts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(bodyParamsforResult),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          //console.log(response.data.result);

          getData();
          // handleShowAlert(response.data.result)
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      });
  };

  const handleSubmitAnswers = async (e) => {
    setIsStart(false);
    // setIsActive(false);
    //setQuizEndTime(new Date());
    const quizEndTime = new Date();
    const timeTaken = (quizEndTime - quizStartTime) / 1000;

    const hours = Math.floor(timeTaken / 3600);
    const minutes = Math.floor((timeTaken % 3600) / 60);
    const seconds = Math.floor(timeTaken % 60);

    const time_Taken = `${hours}:${minutes}:${seconds}`;

    //console.log('User Answers:', userAnswers);
    //e.preventDefault();
    let bodyParamsforResult = {
      step_id: step_id,
      cat_id: cat_id,
    };

    // console.log(bodyParamsforResult);
    //return false;

    if (Object.keys(userAnswers).length == 0) {
      await fetch(`${ApiUrl}` + "training/quiz/anwers/results", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify(bodyParamsforResult),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === true) {
            //console.log(response.data.result);

            //getData();
            handleShowAlert(response.data.result);
          } else {
            toast(response.message, {
              hideProgressBar: true,
              position: "top-center",
              transition: Flip,
              className: "toast-custom-cls error",
            });
          }
        });
      //toast("You've not selected any options", { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
    } else {
      const tifOptions = Object.keys(userAnswers).map(async (key) => {
        // console.log('key ', key)
        let bodyParams = {
          id: key.split("-")[1],
          cat_id: cat_id,
          step_id: step_id,
          answer: userAnswers[key],
        };

        await fetch(`${ApiUrl}` + "training/quiz/anwers", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
          body: JSON.stringify(bodyParams),
        })
          .then((response) => response.json())
          .then((response) => {
            //console.log(response.status)
            if (response.status === true) {
              //toast.success("Your's quiz have been submitted", { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
              // getData();
              fetch(`${ApiUrl}` + "training/quiz/anwers/results", {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ` + uToken(),
                },
                body: JSON.stringify(bodyParamsforResult),
              })
                .then((response) => response.json())
                .then((response) => {
                  if (response.status === true) {
                    // getData();
                    handleShowAlert(response.data.result);
                  } else {
                    toast(response.message, {
                      hideProgressBar: true,
                      position: "top-center",
                      transition: Flip,
                      className: "toast-custom-cls error",
                    });
                  }
                });
            } else {
              toast(response.message, {
                hideProgressBar: true,
                position: "top-center",
                transition: Flip,
                className: "toast-custom-cls error",
              });
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      });
    }
    await answersQuizQuestionResultAttemps();
  };

  const deleteRecord = async (removedQuestions) => {
    for (const question of removedQuestions) {
      let bodyParams = {
        id: question.quiz_id,
      };
      if (question.quiz_id) {
        try {
          const response = await fetch(
            `${ApiUrl}delete/quiz/question/${question.quiz_id}`,
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
              },
              body: JSON.stringify(bodyParams),
            }
          );
          const responseData = await response.json();
          //console.log(responseData); // Optional: Log response data for debugging
        } catch (error) {
          console.error("Error deleting question:", error);
        }
      }
    }
  };

  const sendwarningHandler = (msg = 0, laststepid) => {
    // console.log(laststepid)
    swal({
      title: "",
      text: `Due to 3 unsuccessful attempts, you are required to review the previous section before retaking this quiz.
            The section will be marked 'Incomplete' for you to review`,
      icon: "success",
    }).then(function () {
      const currenturl = window.location.pathname;
      let arr = currenturl.split("/");
      const filteredArr = arr.filter((element) => element !== "");
      arr = filteredArr.map((item) =>
        item === "trainingsreview" ? "trainings" : item
      );
      arr[arr.length - 1] = bcrypt(laststepid);
      const joinedString = arr.join("/");
      history.push(`/${joinedString}?step=${bcrypt(laststepid)}`);
    });
  };

  const temporaryunlock = () => {
    fetch(`${ApiUrl}` + "training/quiz/anwers/temporaryunlock", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ cat_id: cat_id, step_id: step_id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          const laststepid = response.data.steps;
          //console.log('laststepid ', laststepid)
          if (laststepid) {
            sendwarningHandler("msg", laststepid);
          } else {
            getData();
            toast(response.message, {
              hideProgressBar: true,
              position: "top-center",
              transition: Flip,
              className: "toast-custom-cls success",
            });
          }

          //getData();
          // setIsLocked(response.data.lock)
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const updateTrainingBookmark = (trainingId) => {
    fetch(`${ApiUrl}training/bookmark`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify({
        // is_coach_section: is_coach_section,
        step_id: trainingId,
        // can_edit: params.can_edit || params.can_edit_coach ? true : false,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.status === true) {
          if (response.message == "Training added as bookmark successfully") {
            setBookmark(true);
          } else {
            setBookmark(false);
          }
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
        // setLoaded(true);
      })
      .catch((error) => {
        // setLoaded(true);

        console.error("Error:", error);
      });
  };

  const handleOpenEndedChange = (
    useranswer = false,
    openEndedquestionIndex = null,
    answer
  ) => {
    const openEndedQuestions = questions.filter(
      (question) => question.type === "open-ended"
    );
    const matchedQuestion = openEndedQuestions[openEndedquestionIndex];
    const matchedIndex = questions.findIndex(
      (question) => question.quiz_id === matchedQuestion.quiz_id
    );
    const newQuestions = [...questions];
    newQuestions[matchedIndex].answer = answer;
    setQuestions(newQuestions);
  };

  const handleTrueFalseChange = (questionIndex, answer) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].answer = answer;
    setQuestions(newQuestions);
  };
  const handleNewChange = (questionIndex, option) => {
    // const newQuestions = [...questions];
    // if (!Array.isArray(newQuestions[questionIndex].answer)) {
    //     newQuestions[questionIndex].answer = [];
    // }
    // if (newQuestions[questionIndex].answer.includes(option)) {
    //     newQuestions[questionIndex].answer = newQuestions[questionIndex].answer.filter(ans => ans !== option);
    // } else {
    //     newQuestions[questionIndex].answer.push(option);
    // }
    // setQuestions(newQuestions);

    const newQuestions = [...questions];
    if (!Array.isArray(newQuestions[questionIndex].answer)) {
      newQuestions[questionIndex].answer = [];
    }
    newQuestions[questionIndex].answer = [];
    newQuestions[questionIndex].answer.push(option);
    setQuestions(newQuestions);
  };

  const updateOptions = (e, questionIndex) => {
    e.preventDefault();
    const newQuestions = [...questions];
    newQuestions[questionIndex].options.push({ option: "" });
    setQuestions(newQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, e) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options[optionIndex].option = e.target.value;
    setQuestions(newQuestions);
  };
  const unpublishchangesHandler = async () => {
    await savechangesHandler(cat_id, true);
    props.params.stepMethodAction(
      "Step",
      props.list.step_id,
      props.list.title,
      "Draft",
      props.list.training_for,
      props.list.tag_training,
      props.list.training_certifications
    );
  };

  const publishAndGoLivechangesHandler = async () => {
    await savechangesHandler(cat_id, true);
    props.params.stepMethodAction(
      "Step",
      props.list.step_id,
      props.list.title,
      "Publish",
      props.list.training_for,
      props.list.tag_training,
      props.list.training_certifications
    );
  };

  const viewHtmlComponent = (content) => {
    if (!content && content === "null") return <></>;
    return <div dangerouslySetInnerHTML={{ __html: content }} />;
  };

  const handleMarkAsComplete = (documents, stepId) => {
    swal({
      title: "Are you sure",
      text: "you want to mark this training as incomplete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmation) => {
      if (confirmation) {
        params.documentLogsReset(documents, stepId);
      }
    });
  };

  return (
    <>
      <div
        className={`TraningRightContent p-0 2 test ${
          parentCategory.course_free_paid === "paid" &&
          parentCategory.clientPaid !== "paid"
            ? "disabledview"
            : ""
        }`}
      >
        <div className="TopSection">
          <div className="d-flex justify-content-between align-items-center position-relative TopTitle">
            <h4 className="mb-0">{props.documentList.course_name}</h4>
            <div className="ProgressButtons">
              {/* {(props.params.can_edit_coach && !props.params.can_edit) ||
                            (!props.params.can_edit_coach && !props.params.can_edit) && uInfo().user_id != 2
                            && (canEditCocah !== true && canEditCocah !== false) ? (
                            <div className='RightAreaBtn d-flex align-items-center'>
                                <div className="progress w-100 me-1">
                                    <div
                                        role="progressbar"
                                        className="progress-bar"
                                        aria-valuemin={props.documentList.percentage}
                                        aria-valuemax="100"
                                        aria-valuenow={props.documentList.percentage}
                                        style={{ width: `${props.documentList.percentage}%` }}>
                                    </div>
                                </div>
                                <span>{props.documentList.percentage}%</span>
                            </div>
                        ) : null} */}
              <h4 className="mb-0">{props.documentList.title}</h4>
              {!props.params.can_edit &&
                uInfo().type !== "WlAdmin" &&
                is_coach_section !== "Yes" && (
                  <div className="RightAreaBtn d-flex align-items-center">
                    <div className="progress w-100 mt-1">
                      <div
                        role="progressbar"
                        className="progress-bar"
                        aria-valuemin={props.documentList.percentage}
                        aria-valuemax="100"
                        aria-valuenow={props.documentList.percentage}
                        style={{ width: `${props.documentList.percentage}%` }}
                      />
                    </div>
                    <span className="RightPercent">
                      {props.documentList.percentage}%
                    </span>
                  </div>
                )}
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center position-relative border-bottom p-2 pt-0">
            <h5 className="mb-0 d-none TraningTitles">{title}</h5>
            <div className="d-flex">
              <h5 className="my-auto TraningTitles">{title}</h5>

              {uInfo().type !== "WlAdmin" && is_coach_section !== "Yes" && (
                // <button
                //   className="btn btn-md my-auto"
                //   onClick={() => {
                //     updateTrainingBookmark(step_id);
                //   }}
                // >
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">
                      {bookmark ? "Remove as bookmark" : "Add to Bookmark"}
                    </Tooltip>
                  }
                >
                  {bookmark ? (
                    <i
                      className="fas fa-bookmark text-success ms-2 my-auto"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        updateTrainingBookmark(step_id);
                      }}
                    ></i>
                  ) : (
                    <i
                      className="far fa-bookmark ms-2 my-auto"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        updateTrainingBookmark(step_id);
                      }}
                    ></i>
                  )}
                </OverlayTrigger>
                // </button>
              )}
            </div>
            <div className="d-flex justify-content-end align-items-center">
              {/* <div className='NextBackBtn edit-step-action'>

                            <button className='BackBtn btn btn-secondary btn-sm'>Back</button>
                             <button className='BackBtn NextBtn bwaves-effect waves-light btn btn-primary ms-2'>Next</button>

                            <div className='NextBackBtn edit-step-action'>
                                <button className='BackBtn NextBtn bwaves-effect waves-light btn btn-primary ms-2 disabled btn-sm' >Next</button>
                            </div>
                        </div> */}

              {/* {props.lists.map((item, i) =>
                item.sub_cat.map((sub_item, k) =>
                  sub_item.sub_cat.map(
                    (sub, m) =>
                      sub.sub_steps &&
                      sub.sub_steps.map((step, n) => (
                        <UrlNextPrev
                        className="test-buttons"
                          prevlink={`/trainingsreview/${bcrypt(i)}/${bcrypt(
                            item.cat_id
                          )}/${bcrypt(k)}/${bcrypt(sub_item.cat_id)}/${bcrypt(
                            m
                          )}/${bcrypt(sub.cat_id)}/`}
                          nextlink={`/trainingsreview/${bcrypt(i)}/${bcrypt(
                            item.cat_id
                          )}/${bcrypt(k)}/${bcrypt(sub_item.cat_id)}/${bcrypt(
                            m
                          )}/${bcrypt(sub.cat_id)}/`}
                          link={`/trainingsreview/${bcrypt(i)}/${bcrypt(
                            item.cat_id
                          )}/${bcrypt(k)}/${bcrypt(sub_item.cat_id)}/${bcrypt(
                            m
                          )}/${bcrypt(sub.cat_id)}/`}
                          nvalue={n}
                          docstep={sub.sub_steps}
                          list={props.list}
                          stepCount={sub.sub_steps.length}
                        />
                      ))
                  )
                )
              )} */}

              {is_coach_section === "No" &&
                props.lists.map((item, i) =>
                  item.sub_cat.map((sub_item, k) =>
                    sub_item.sub_cat.map(
                      (sub, m) =>
                        sub.sub_steps &&
                        sub.sub_steps.map((step, n) => (
                          <UrlNextPrev
                            prevlink={`/trainingsreview/${bcrypt(
                              item.cat_id
                            )}/${bcrypt(sub_item.cat_id)}/${bcrypt(
                              sub.cat_id
                            )}/`}
                            nextlink={`/trainingsreview/${bcrypt(
                              item.cat_id
                            )}/${bcrypt(sub_item.cat_id)}/${bcrypt(
                              sub.cat_id
                            )}/`}
                            link={`/trainingsreview/${bcrypt(
                              item.cat_id
                            )}/${bcrypt(sub_item.cat_id)}/${bcrypt(
                              sub.cat_id
                            )}/`}
                            nvalue={n}
                            docstep={sub.sub_steps}
                            list={props.list}
                            stepCount={sub.sub_steps.length}
                            is_coach_section={is_coach_section}
                            can_edit_coach={params.can_edit_coach}
                          />
                        ))
                    )
                  )
                )}

              {is_coach_section === "Yes" &&
                props.coursesList.map((item, i) =>
                  item.sub_cat.map((sub_item, k) =>
                    sub_item.sub_cat.map(
                      (sub, m) =>
                        sub.sub_steps &&
                        sub.sub_steps.map((step, n) => (
                          <UrlNextPrev
                            prevlink={`/trainingsreview/${bcrypt(
                              item.cat_id
                            )}/${bcrypt(sub_item.cat_id)}/${bcrypt(
                              sub.cat_id
                            )}/`}
                            nextlink={`/trainingsreview/${bcrypt(
                              item.cat_id
                            )}/${bcrypt(sub_item.cat_id)}/${bcrypt(
                              sub.cat_id
                            )}/`}
                            link={`/trainingsreview/${bcrypt(
                              item.cat_id
                            )}/${bcrypt(sub_item.cat_id)}/${bcrypt(
                              sub.cat_id
                            )}/`}
                            nvalue={n}
                            docstep={sub.sub_steps}
                            list={props.list}
                            stepCount={sub.sub_steps.length}
                            is_coach_section={is_coach_section}
                            can_edit_coach={params.can_edit_coach}
                          />
                        ))
                    )
                  )
                )}

              {/* {(props.params.can_edit || (props.params.can_edit_coach && is_coach_section === "Yes")) && (
                            <div className='edit-step-action mb-1'>
                                <button className='btn btn-primary submit-step-content ms-2 btn-sm' onClick={() => savechangesHandler(cat_id)}>Save as Draft</button>

                            </div>
                        )} */}
              {(props.params.can_edit ||
                (props.params.can_edit_coach &&
                  props.documentList.user_id !== 2)) && (
                <>
                  {props.list.status === "Publish" ? (
                    <div className="edit-step-action">
                      <button
                        className="BackBtn btn btn-secondary submit-step-content mx-2 btn-sm"
                        id="submit-step-content"
                        onClick={() => savechangesHandler(cat_id)}
                      >
                        Save
                      </button>
                    </div>
                  ) : (
                    <div className="edit-step-action">
                      <button
                        className="BackBtn btn btn-secondary submit-step-content mx-2 btn-sm"
                        id="submit-step-content"
                        onClick={() => savechangesHandler(cat_id)}
                      >
                        Save as Unpublish
                      </button>
                    </div>
                  )}

                  {props.list.status === "Publish" ? (
                    <div className="NextBackBtn edit-step-action">
                      <button
                        className="BackBtn btn btn-secondary btn-sm"
                        style={{ "margin-right": "7px" }}
                        onClick={unpublishchangesHandler}
                      >
                        Unpublish
                      </button>
                    </div>
                  ) : (
                    <div className="NextBackBtn edit-step-action">
                      <button
                        className="BackBtn btn btn-secondary btn-sm"
                        style={{ "margin-right": "7px" }}
                        onClick={publishAndGoLivechangesHandler}
                      >
                        Publish & Go Live
                      </button>
                    </div>
                  )}

                  <div className="NextBackBtn edit-step-action">
                    <button
                      className="BackBtn btn btn-primary btn-sm"
                      style={{ "margin-right": "7px" }}
                      onClick={() =>
                        props.params.stepMethodAction(
                          "Step",
                          props.list.step_id,
                          props.list.title,
                          "Delete",
                          props.list.training_for,
                          props.list.tag_training,
                          props.list.training_certifications
                        )
                      }
                    >
                      Delete
                    </button>
                  </div>
                  <div className="create-step">
                    {(props.params.can_edit ||
                      (props.params.can_edit_coach &&
                        props.documentList.user_id !== 2)) && (
                      <div className="add-new-step">
                        <div className="add-step-btn text-center w-100">
                          <button
                            onClick={() =>
                              props.params.subjectOrDocumentAction(
                                "Step",
                                props.params.documents,
                                props.documentList.title,
                                "AddStep",
                                "",
                                "",
                                "",
                                "",
                                "center"
                              )
                            }
                            className="bwaves-effect waves-light BackBtn btn btn-primary w-100 text-nowrap btn-sm"
                          >
                            New Training
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="TraningsQuiz">
          <div className="QuestionTopBar d-flex justify-content-between">
            <div>
              <span>
                <strong>Questions: </strong>
                {totalQuestions}
              </span>
              {!props.params.can_edit && is_coach_section !== "Yes" && (
                <span>
                  <strong>Your Score: </strong>
                  {totalScore}%
                </span>
              )}
              <span>
                <strong>Pass Rate: </strong>
                {passScoreRate}%
              </span>
              {/* {
                                props.params.can_edit ?
                                    <span><strong>Attempts Allowed: </strong>{quizAdminSetting.total_allow_attempts}</span> : <span><strong>Attempts Allowed: </strong>{quizAdminSetting.allow_attempts == -1 ? 'unlimited' : quizAdminSetting.allow_attempts}</span>

                            } */}
              {/* <span><strong>Time limit: </strong>{quizAdminSetting.time_limit}min</span> */}
              {/* <span><strong>Subset: </strong>No</span> */}
            </div>
            {(props.params.can_edit ||
              (props.params.can_edit_coach && is_coach_section === "Yes")) && (
              <div className="SettingIconDropDown">
                <button
                  className="border-0 CogBtn"
                  onClick={() => setUpdateDivOpen(!updateDivOpen)}
                >
                  <i className=" fas fa-cogs"></i>{" "}
                </button>
                {updateDivOpen && (
                  <div className="PopUpShow">
                    <div className="popupitm mb-2">
                      <label className="mb-1">
                        Pass Rate (%){" "}
                        <a href="">
                          <OverlayTrigger
                            delay={{ hide: 450, show: 300 }}
                            overlay={(props) => (
                              <Tooltip {...props}>
                                The percentage of questions that must be correct
                                for this step to be marked as complete.
                              </Tooltip>
                            )}
                            placement="top"
                          >
                            <i
                              variant="defult"
                              className="far fa-question-circle"
                            ></i>
                          </OverlayTrigger>
                        </a>
                      </label>
                      <Select
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        value={defualtnumbers}
                        isMulti={false}
                        options={numbers}
                        classNamePrefix="select2-selection position-releative"
                        name="quiz_passing_percentage"
                        onChange={(e) => handlePercentageChange(e)}
                      />
                    </div>

                    <div className="popupitm mt-2 modal-footer w-100 border-0 p-0 m-0">
                      <button
                        className="btn btn-primary w-100 mx-0"
                        onClick={() =>
                          savechangesHandlerSetting(cat_id, step_id)
                        }
                      >
                        Update Pass Rate
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="FrolaContent QuizSec">
            {(props.params.can_edit_coach && !props.params.can_edit) ||
            (!props.params.can_edit_coach && !props.params.can_edit) ? (
              totalScore >= passScoreRate ? (
                <div className="TestPass mt-4">
                  <h5>Test Passed</h5>
                  <p>
                    You successfully passed this test on {submitedDate} with a
                    Score of {totalScore}%.
                  </p>
                </div>
              ) : quizAdminSetting.temporarylock ? (
                <div className="TestPass mt-4 quizlock d-flex justify-content-between">
                  <p>Your quiz is temporarily locked. </p>
                  <button
                    className="btn btn-outline-primary waves-effect waves-light btn-sm"
                    onClick={temporaryunlock}
                  >
                    {" "}
                    <i className="mdi mdi-reload"></i> Reset and try again
                  </button>
                </div>
              ) : isLocked ? (
                <div className="TestPass mt-4 quizlock">
                  <p>
                    Time's up! Your quiz has been locked. Please contact the
                    administrator for more information.
                  </p>
                </div>
              ) : (
                quizAdminSetting.lock && (
                  <div className="TestPass mt-4 quizlock">
                    <p>
                      Your attempts have been completed. Please contact the
                      administrator for more attempts.
                    </p>
                  </div>
                )
              )
            ) : (
              ""
            )}

            {props.params.can_edit ||
            (props.params.can_edit_coach && is_coach_section === "Yes") ? (
              <div className="QuestionList">
                <div className="QuestionAnwser mt-4">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="questions">
                      {(provided) =>
                        activeKeys && (
                          <Accordion
                            flush
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            defaultActiveKey={activeKeys}
                            alwaysOpen
                          >
                            {questions ? (
                              questions.map((ques, questionIndex) => (
                                <Draggable
                                  key={`ques-${questionIndex}`}
                                  draggableId={`ques-${questionIndex}`}
                                  index={questionIndex}
                                >
                                  {(provided) => (
                                    <Accordion.Item
                                      eventKey={questionIndex}
                                      key={`accordion-item-${questionIndex}`}
                                      id={`questionIndex_${questionIndex}`}
                                    >
                                      <Accordion.Header>
                                        <div
                                          className="QuestionMove"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <i className="mdi mdi-chevron-up"></i>
                                          <i className="mdi mdi-chevron-down"></i>
                                        </div>
                                        <div className="QuizHeading d-flex justify-content-between align-items-center">
                                          <p>
                                            {ques.question
                                              ? ques.question
                                              : "your questions here"}
                                          </p>
                                          <OverlayTrigger
                                            delay={{ hide: 450, show: 300 }}
                                            overlay={(props) => (
                                              <Tooltip {...props}>
                                                Remove Question
                                              </Tooltip>
                                            )}
                                            placement="top"
                                          >
                                            <i
                                              className="mdi mdi-close"
                                              onClick={() =>
                                                removeQuestion(
                                                  questionIndex,
                                                  ques.quiz_id
                                                )
                                              }
                                            ></i>
                                          </OverlayTrigger>
                                        </div>
                                      </Accordion.Header>
                                      <input
                                        type="hidden"
                                        className="form-control"
                                        name="quiz_id"
                                        defaultValue={ques.quiz_id}
                                      />
                                      <Accordion.Body>
                                        <div className="QuestionBody">
                                          <div
                                            className="QuestionBodyLeft"
                                            key={questionIndex}
                                          >
                                            <input
                                              type="text"
                                              className="form-control"
                                              name="question"
                                              defaultValue={ques.question}
                                              onChange={(e) =>
                                                handleQuestionChange(
                                                  questionIndex,
                                                  e.target.value
                                                )
                                              }
                                              placeholder="Question e.g. What is 1+1"
                                            />

                                            {(ques.type === "radio" ||
                                              ques.type === "true-false") && (
                                              <div>
                                                <Froala
                                                  key={`froala-${questionIndex}`}
                                                  ref={(el) =>
                                                    (editorRefs.current[
                                                      questionIndex
                                                    ] = el)
                                                  }
                                                  use_token={"users"}
                                                  question_content_set={true}
                                                  btnId={`questionIndex_${questionIndex}`}
                                                  content={ques.question_content || ""}
                                                  handleQuestionContentChange={
                                                    handleQuestionContentChange
                                                  }
                                                  inlineEditior={false}
                                                  onboradingToken={true}
                                                />
                                              </div>
                                            )}
                                          </div>
                                          <div className="QuestionChoice mt-2">
                                            {ques.type === "radio" &&
                                              ques.options.map(
                                                (option, optionIndex) => (
                                                  <div
                                                    className="AnswerQuestion mb-2"
                                                    key={optionIndex}
                                                    id={`optionIndex_${optionIndex} questionIndex_${questionIndex}`}
                                                  >
                                                    <div className="d-flex justify-content-between">
                                                      <div className="d-flex mb-2">
                                                        {/* <div className='QuestionMove MoveQ'><i className="mdi mdi-chevron-up"></i><i className="mdi mdi-chevron-down"></i></div> */}
                                                        <p>
                                                          Option #
                                                          {optionIndex + 1}{" "}
                                                        </p>
                                                      </div>
                                                      <div className="ActionIcons">
                                                        <OverlayTrigger
                                                          delay={{
                                                            hide: 450,
                                                            show: 300,
                                                          }}
                                                          overlay={(props) => (
                                                            <Tooltip {...props}>
                                                              Remove Option
                                                            </Tooltip>
                                                          )}
                                                          placement="top"
                                                        >
                                                          <button
                                                            className={`border-0 p-0 bg-transparent optionIndex_${optionIndex} questionIndex_${questionIndex}`}
                                                            onClick={() =>
                                                              removeOption(
                                                                questionIndex,
                                                                optionIndex,
                                                                ques.quiz_id
                                                              )
                                                            }
                                                          >
                                                            <i className="mdi mdi-close-thick"></i>
                                                          </button>
                                                        </OverlayTrigger>
                                                      </div>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        defaultValue={
                                                          `${
                                                            option[`option`]
                                                          }` || ""
                                                        }
                                                        name={`option`}
                                                        required="required"
                                                        onChange={(e) =>
                                                          handleOptionChange(
                                                            questionIndex,
                                                            optionIndex,
                                                            e
                                                          )
                                                        }
                                                        placeholder={`Option e.g ${
                                                          optionIndex + 1
                                                        }`}
                                                      />
                                                      <div
                                                        className="form-check form-switch form-switch-md justify-content-center px-2 d-flex flex-column"
                                                        dir="ltr"
                                                      >
                                                        <input
                                                          type="radio"
                                                          className="form-check-input"
                                                          id="customSwitchsizemd"
                                                          name={`answer-${questionIndex}`}
                                                          checked={
                                                            ques.answer &&
                                                            ques.answer.includes(
                                                              option[`option`]
                                                            )
                                                          }
                                                          defaultValue={
                                                            `${
                                                              option[`option`]
                                                            }` || ""
                                                          }
                                                          onChange={() =>
                                                            handleNewChange(
                                                              questionIndex,
                                                              option[`option`]
                                                            )
                                                          }
                                                        />
                                                        <label
                                                          className="form-check-label"
                                                          htmlFor="customSwitchsizemd"
                                                        >
                                                          {ques.answer &&
                                                          ques.answer.includes(
                                                            option[`option`]
                                                          )
                                                            ? "Correct"
                                                            : "incorrect"}
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                            {ques.type === "true-false" && (
                                              <div className="d-flex align-items-center">
                                                <label className="me-3 mb-0 d-flex align-items-center">
                                                  <input
                                                    className="form-check-input m-0"
                                                    type="radio"
                                                    name={`true-false-${questionIndex}`}
                                                    checked={
                                                      ques.answer === "true"
                                                    }
                                                    onChange={() =>
                                                      handleTrueFalseChange(
                                                        questionIndex,
                                                        "true"
                                                      )
                                                    }
                                                  />
                                                  &nbsp; True
                                                </label>
                                                <label className="mb-0 d-flex align-items-center">
                                                  <input
                                                    className="form-check-input m-0"
                                                    type="radio"
                                                    name={`true-false-${questionIndex}`}
                                                    checked={
                                                      ques.answer === "false"
                                                    }
                                                    onChange={() =>
                                                      handleTrueFalseChange(
                                                        questionIndex,
                                                        "false"
                                                      )
                                                    }
                                                  />
                                                  &nbsp; False
                                                </label>
                                              </div>
                                            )}
                                            {ques.type === "open-ended" && (
                                              <div>
                                                <Froala
                                                  key={`froala-${questionIndex}`}
                                                  ref={(el) =>
                                                    (editorRefs.current[
                                                      questionIndex
                                                    ] = el)
                                                  }
                                                  use_token={"users"}
                                                  useranswer={false}
                                                  openended_ques={true}
                                                  btnId={`questionIndex_${questionIndex}`}
                                                  content={
                                                    `${
                                                      ques.answer && ques.answer
                                                    }` || ""
                                                  }
                                                  handleOpenEndedChange={
                                                    handleOpenEndedChange
                                                  }
                                                  inlineEditior={false}
                                                  // setupreview={true}
                                                  onboradingToken={true}
                                                />

                                                {/* <textarea className='form-control' name={`feedback-${questionIndex}`} defaultValue={ques.answer && ques.answer || ''}
                                                                                                onChange={(e) => handleOpenEndedChange(questionIndex, e.target.value)} placeholder="Your answer here" /> */}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        {ques.type === "radio" && (
                                          <div className="AddNewQuestion d-flex justify-content-end">
                                            <button
                                              className="btn btn-outline-secondary waves-effect"
                                              onClick={(e) =>
                                                updateOptions(e, questionIndex)
                                              }
                                            >
                                              + New Option
                                            </button>
                                          </div>
                                        )}
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  )}
                                </Draggable>
                              ))
                            ) : (
                              <></>
                            )}
                            {provided.placeholder}
                          </Accordion>
                        )
                      }
                    </Droppable>
                  </DragDropContext>
                </div>
                <div className="AddQuestion d-flex justify-content-end">
                  {/* <button className='btn btn-outline-secondary waves-effect mt-3' onClick={toggleModal}>+ Add Question</button> */}
                  <div
                    className="btn-group-vertical  my-3"
                    role="group"
                    aria-label="Vertical Button group "
                  >
                    <ButtonDropdown
                      isOpen={drp_link}
                      toggle={() => {
                        setdrp_link(!drp_link);
                      }}
                    >
                      <DropdownToggle caret color="outline-secondary">
                        + Add Question
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => addMoreQuestions("radio")}>
                          {" "}
                          Multiple Choice
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => addMoreQuestions("true-false")}
                        >
                          True/False
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => addMoreQuestions("open-ended")}
                        >
                          Open Ended
                        </DropdownItem>
                      </DropdownMenu>
                    </ButtonDropdown>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {uInfo().type === "Client" &&
                  !isEmptyObject(parentCategory) && (
                    <PaymentModel
                      initial={
                        parentCategory.course_free_paid === "paid"
                          ? parentCategory.clientPaid !== "paid"
                            ? true
                            : false
                          : false
                      }
                      parentCategory={parentCategory}
                    />
                  )}
                <div className="MultipleQuestion">
                  {/* <div className='MultipleSubmit TopButtonStart'>
                                    <div className='mt-3'>
                                        {
                                            isTimeUp ? (
                                                <div> Time is up!</div>
                                            ) : (
                                                isStart && (
                                                    <Timer initialSeconds={quizAdminSetting.time_limit * 60} onTimeUp={handleTimeUp} />
                                                )
                                            )
                                        }
                                    </div>
                                    {
                                        !isStart && (
                                            < button disabled={(datalogID > 0) || (quizAdminSetting.lock) || (isLocked) || (quizAdminSetting.temporarylock)} className={`btn ${quizAdminSetting.lock || isLocked || quizAdminSetting.temporarylock ? 'btn-danger' : 'btn-primary'} waves-effect mt-3`} onClick={(e) => handlestartquiz(e)}> Start Quiz</button>
                                        )
                                    }
                                </div> */}
                  <div
                    className={`QuestionAnwser mt-3 ${
                      totalScore >= passScoreRate ||
                      quizAdminSetting.temporarylock ||
                      isLocked
                        ? "disabledquestions"
                        : ""
                    }`}
                  >
                    <Accordion defaultActiveKey={activeKeys} alwaysOpen>
                      {questions ? (
                        questions.map((ques, questionIndex) => (
                          <Accordion.Item eventKey={questionIndex}>
                            <div className="QuizCheckbox">
                              <Accordion.Header>
                                <div className="QuestionMove QNumber">
                                  {questionIndex + 1}
                                </div>
                                <div className="QuizHeading d-flex justify-content-between align-items-center">
                                  <div>
                                    <p>{ques.question}</p>

                                    {ques.question_content &&
                                      ques.question_content && (
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div className="form-group mb-3 mt-1 mx-1">
                                            <div
                                              className="TraningContent there"
                                              dangerouslySetInnerHTML={{
                                                __html: true
                                                  ? ques.question_content
                                                  : "",
                                              }}
                                              onContextMenu={(e) =>
                                                e.preventDefault()
                                              }
                                            />
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                  <input
                                    className="form-check-input mb-0"
                                    type="checkbox"
                                    checked={
                                      ques.correctanswer &&
                                      ques.useranswer &&
                                      ques.correctanswer === ques.useranswer
                                    }
                                  />
                                </div>
                              </Accordion.Header>
                              <input
                                type="hidden"
                                className="form-control"
                                name="quiz_id"
                                defaultValue={ques.quiz_id}
                              />
                            </div>
                            <Accordion.Body>
                              <div className="QuestionBody">
                                <div
                                  className="QuestionBodyLeft"
                                  key={questionIndex}
                                >
                                  {!ques.type === "open-ended" && (
                                    <small>
                                      Select the one correct answer from the
                                      following option:
                                    </small>
                                  )}
                                </div>
                                <div className="QuestionChoice mt-2 openEdndbook">
                                  {ques.type === "radio" &&
                                    ques.options.map((option, optionIndex) => (
                                      <div className="AnswerQuestion PickOne mb-2">
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name={`answer-${ques.quiz_id}`}
                                            id={`formRadios${questionIndex}-${optionIndex}`}
                                            onChange={(e) =>
                                              handleAnswerChange(
                                                e,
                                                questionIndex,
                                                option[`option`]
                                              )
                                            }
                                            defaultChecked={
                                              ques.useranswer ===
                                                option[`option`] &&
                                              option[`option`] !== null
                                                ? true
                                                : false
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={`formRadios${questionIndex}-${optionIndex}`}
                                          >
                                            {`${option[`option`]}` || ""}
                                          </label>
                                        </div>
                                      </div>
                                    ))}
                                  {ques.type === "true-false" && (
                                    <div className="me-3 mb-0 d-flex">
                                      <label className="me-3 mb-0 d-flex">
                                        <input
                                          className="form-check-input mb-0"
                                          type="radio"
                                          name={`answer-${ques.quiz_id}`}
                                          defaultChecked={
                                            ques.useranswer === "true"
                                          }
                                          onChange={(e) =>
                                            handleAnswerChange(
                                              e,
                                              questionIndex,
                                              "true"
                                            )
                                          }
                                        />
                                        &nbsp; True
                                      </label>
                                      <label className="mb-0 d-flex">
                                        <input
                                          className="form-check-input mb-0"
                                          type="radio"
                                          name={`answer-${ques.quiz_id}`}
                                          defaultChecked={
                                            ques.useranswer === "false"
                                          }
                                          onChange={(e) =>
                                            handleAnswerChange(
                                              e,
                                              questionIndex,
                                              "false"
                                            )
                                          }
                                        />
                                        &nbsp; False
                                      </label>
                                    </div>
                                  )}
                                  {ques.type === "open-ended" && (
                                    <div>
                                      {/* <Froala
                                                                        tag='textarea'
                                                                        use_token={'users'}
                                                                        useranswer={true}
                                                                        questionIndex={ques.quiz_id}
                                                                        content={ques.answer && ques.answer || ''}
                                                                        handleOpenEndedAnswerChange={handleOpenEndedAnswerChange}
                                                                        inlineEditior={false}
                                                                        onboradingToken={true}
                                                                    /> */}
                                      <>
                                        <div className={`viewhtml mb-3`}>
                                          {ques.admin_coaches_content ? (
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  ques.admin_coaches_content,
                                              }}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </>
                                      <textarea
                                        className="form-control"
                                        name={`answer-${ques.quiz_id}`}
                                        defaultValue={
                                          `${
                                            ques.useranswer && ques.useranswer
                                          }` || ""
                                        }
                                        onChange={(e) =>
                                          handleAnswerChange(
                                            e,
                                            questionIndex,
                                            e.target.value
                                          )
                                        }
                                        placeholder="Your answer here"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        ))
                      ) : (
                        <></>
                      )}
                    </Accordion>
                  </div>
                  {!props.params.can_edit &&
                    uInfo().type !== "WlAdmin" &&
                    is_coach_section !== "Yes" && (
                      <>
                        <div className="MultipleSubmit text-center mt-4 py-4 px-5 pb-4">
                          <p>
                            Submit your test answers to receive your grade.{" "}
                            <b>Note:</b> if you meet the pass grade of{" "}
                            {passScoreRate}% this step will be marked as
                            complete.
                          </p>
                          {totalScore >= passScoreRate ? (
                            datalogID > 0 ? (
                              <>
                                <button
                                  className="btn btn-primary mt-3 btn-sm"
                                  onClick={() =>
                                    props.params.markAsComplete(cat_id, step_id)
                                  }
                                  disabled
                                >
                                  Completed
                                </button>
                                <div className="text-center w-100 mt-2 d-none">
                                  <button
                                    onClick={() =>
                                      handleMarkAsComplete(
                                        params.documents,
                                        props.list.step_id
                                      )
                                    }
                                    className="btn btn-danger UndoCompleted btn-sm mb-2"
                                    style={{ color: "#fff" }}
                                  >
                                    Undo
                                  </button>
                                </div>
                              </>
                            ) : (
                              <button
                                className="btn btn-primary mt-3 btn-sm"
                                onClick={() =>
                                  props.params.markAsComplete(cat_id, step_id)
                                }
                              >
                                Mark as completed
                              </button>
                            )
                          ) : (
                            <button
                              className="btn btn-primary mt-3 btn-sm"
                              onClick={(e) => handleSubmitAnswers(e)}
                              disabled={
                                totalScore >= passScoreRate ||
                                isLocked ||
                                quizAdminSetting.lock ||
                                quizAdminSetting.temporarylock
                              }
                            >
                              Submit Answers
                            </button>
                          )}
                        </div>
                      </>
                    )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TraningQuiz;
