import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Modal
} from "reactstrap";

import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { Link } from "react-router-dom";
import Highcharts from "highcharts";

import { ApiUrl } from "../../config";
import { bcrypt, dcrypt, uInfo, uToken } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import Summary from "./summary.js";

import "./style.css";
import $ from "jquery";
import Loader from "react-loader";
import { currencies } from "../../common/Common-Currency.js";
import Favicon from './../../assets/images/favicon.png';
import Select from "react-select";
import swal from "sweetalert";
import { Badge } from "react-bootstrap";
import Implementation from "./implementation";
import Roi from "../ProfitCenter/roi";


require("highcharts/modules/exporting")(Highcharts);

const Assesments = (props) => {
  const ref = useRef();
  const [params] = useState(props.match.params);

  const [sidebar, setSideBar] = useState([]);
  const [records, setRecords] = useState({ report: [] });
  const [dafault_summary, setDafaultSummary] = useState([]);
  const [loading, setLoading] = useState(false);
  const [assessment, setAssessment] = useState({ report: [], questions: [], reports: [] });
  const [currency, setCurrency] = useState("");
  const [action_results, setActionResults] = useState([]);
  const [action_note_results, setActionNoteResults] = useState([]);
  const [assesment_list, setAssessmentList] = useState([]);
  const [siderbarShow, setSideBarShow] = useState(false);
  const [path_way_progression, setPathWayProgression] = useState([]);
  const [current_module, setCurrentModule] = useState([]);
  const [customer_conversion, setCustomerConversion] = useState({});
  const [PathwayProgressionData, setPathWayProgressionData] = useState({});
  const [modal, setModal] = useState(false);
  const [step, setStep] = useState({});
  const [make_default, setMakeDefalt] = useState(0);
  const [toggleSwitch, settoggleSwitch] = useState(false);
  const [canEdit] = useState(uInfo().type == 'Client' ? false : true);

  // const [current_module, setCurrentModule] = useState([]);


  React.useEffect(() => {
    getAssessment();
    // getActionStepsResults();
  }, [props]);


  useEffect(() => {
    getModules();
    getAssessmentList();
    getPathwayProgression();

  }, []);

  const findSymbol = (code) => {
    let currency = currencies[code];
    if (currency) {
      setCurrency(currency.symbol);
    }
  };
  const getPathwayProgression = () => {
    setLoading(false);
    fetch(`${ApiUrl}` + "get/pathway/progression", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: dcrypt(params.id),
        slug: params.slug,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setCustomerConversion(response.data.module_calculation);
          setPathWayProgression(response.data.records);
          setCurrentModule(response.data.current_module);
          setPathWayProgressionData(response.data);
          setLoading(true);

        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          setLoading(true);
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        setLoading(true);
      });
  };
  const getModules = () => {
    setLoading(false);
    fetch(`${ApiUrl}` + "get/assessment/modules", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: dcrypt(params.id),
        slug: props.match.params.slug,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setSideBar(response.data.records);
          setRecords(response.data);
          setDafaultSummary(response.data.report);
          findSymbol(response.data.assessment.currency_code);
          setLoading(true);

        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          setLoading(true);
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        setLoading(true);
      });
  };
  const getAssessment = () => {
    setLoading(false);
    fetch(`${ApiUrl}` + "get/assessment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: dcrypt(params.id),
        slug: props.match.params.slug,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setAssessment(response.data);
          getPathwayProgression();
          setLoading(true);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          setLoading(true);

        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        setLoading(true);

      });
  };



  const getAssessmentList = () => {
    fetch(`${ApiUrl}` + "assessments", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: dcrypt(params.id),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          console.log(response.data.records, 'recordsss')
          setAssessmentList(response.data.records);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  function NumberWithCommas(x) {
    let y = Math.trunc(x);
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const markAssessmentDefault = () => {
    fetch(`${ApiUrl}` + "mark/assessmet/default", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: dcrypt(params.id),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setMakeDefalt(1);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function isWhatPercentOf(numA, numB) {
    if (numB == 0) {
      return 0;
    }
    return (numA / numB) * 100;
  }
  return (
    <React.Fragment>

      <Row className="mb-4">
        <Col lg={1}></Col>

        <Col lg={siderbarShow ? '9' : '10'}>
          <Row className="mb-1">
            <Col lg="4">
              <section className="breakthrough d-flex border-radius-0 justify-content-start m-0">
                <button title="Back to assessment lists" className="btn btn-link"
                  onClick={() => props.history.push(uInfo().type == 'Client' ? `/client/profile` : `/assessment/list/all`)}>
                  <i className="fas fa-arrow-left"></i>
                </button>
                <div className="MonthlyDropDown p-tracker mb-1">
                  <select value={dcrypt(params.id)} onChange={(e) => {
                    if (e.target.value != '') {
                      props.history.push(`/progress/tracker/${bcrypt(e.target.value)}/${params.slug}`)
                    }
                  }
                  } className="form-select">
                    <option value={''}>Assessment Id</option>
                    {assesment_list.length > 0 &&
                      assesment_list.map((row, i) => {
                        return (
                          <option key={i} value={row.assessment_id}>{row.assessment_id + ' # ' + row.name}</option>
                        )
                      }
                      )
                    }
                  </select>

                </div>

                <div className="MonthlyDropDown w-50 mb-1">
                  {/* <select value={params.slug}
                    onChange={(e) => {
                      if (e.target.value != '') {
                        props.history.push(`/progress/tracker/${params.id}/${e.target.value}`)
                      }
                    }
                    } className="form-control">
                    <option value={''}>Impact Selector</option>
                    {path_way_progression.length > 0 &&
                      path_way_progression.map((row, i) => {
                        return (
                          <option value={row.cat_key}>{row.cat_name}</option>
                        )
                      }
                      )
                    }
                  </select> */}
                </div>
              </section>
            </Col>
            <Col lg="8">
              <div className="d-flex align-items-center justify-content-end me-3">

                <section className="breakthrough d-flex border-radius-0 justify-content-end m-0">

                  {
                    assessment.assessment && (
                      <>
                        <button title="Go to lead details" className="btn btn-link text-dark"
                          onClick={() => props.history.push(uInfo().type == 'Client' ? `/client/profile` : `/client/dialer/${bcrypt(assessment.assessment.client_id)}/${bcrypt(assessment.assessment.coach_id)}`)}>
                          {assessment.assessment.name}
                        </button>
                        {
                          canEdit && assessment.assessment.default_status == 0 && (
                            <input title="Set this assessmet as default assessmet" type={'checkbox'} disabled={make_default == 0 ? false : true} onChange={(e) => e.target.checked && (markAssessmentDefault())} />
                          )
                        }
                      </>
                    )
                  }

                  <h5 className="ml-3 mb-0 mr-3"> Total Impact:</h5>
                  <h3 title="Total impact" className="mb-0 color-success mr-30">
                    <span className="currencyIcon">{currency} </span>
                    {dafault_summary.length > 0 && !toggleSwitch && (
                      NumberWithCommas(dafault_summary[0].amount)
                    )}

                    {dafault_summary.length > 0 && toggleSwitch && (
                      NumberWithCommas(dafault_summary[4].amount)
                    )}
                  </h3>
                </section>
                <div className="form-check form-switch pull-right" >
                  <label className="m-0">Toggle Net Profit</label>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customSwitch2"
                    onClick={e => {
                      settoggleSwitch(!toggleSwitch)
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <section className="top-section Assessments card card-body p-0">
                <Row>
                  <Col xs="12">
                    <div className="SectionHeader">
                      {dafault_summary.length > 0 && !toggleSwitch &&
                        <div className="Percents">
                          <h4 title={'Current revenue ' + currency + '' + NumberWithCommas(dafault_summary[1].amount)} className="GrayBG" style={{ background: '#041934', width: `${isWhatPercentOf(dafault_summary[1].amount, Number(dafault_summary[1].amount) + Number(dafault_summary[3].amount))}%` }}>
                            <span className="pull-left">
                              <p>
                                <span className="currencyIcon">{currency}</span>
                                {NumberWithCommas(dafault_summary[1].amount)}
                              </p>
                            </span>
                          </h4>
                          <h4 title={'New Annual gross revenue ' + currency + '' + NumberWithCommas(dafault_summary[3].amount)} className="GreenBG text-end" style={{ background: '#008037', width: `${isWhatPercentOf(dafault_summary[3].amount, Number(dafault_summary[1].amount) + Number(dafault_summary[3].amount))}%` }}>
                            <div className="show-value"> <span className="currencyIcon">{currency} </span>

                              {NumberWithCommas(dafault_summary[3].amount)}
                            </div>

                          </h4>
                          <div className="TotalPercent">
                            <h4 title="Profit impact" className="text-end">

                              <span className="percentageSign pull-right"> <i className="fa fa-arrow-circle-up"></i>
                                {dafault_summary[0].percentage}%</span>

                            </h4>
                          </div>
                        </div>
                      }
                      {/* changed 5 "current_net_profit" to 9 "current_gross_profit" */}
                      {/* changed 6 "new_annual_net_profit" to 10 "new_annual_gross_profit" */}
                      {dafault_summary.length > 0 && toggleSwitch &&
                        <div className="Percents">
                          <h4 title={'Current net profit ' + currency + '' + NumberWithCommas(dafault_summary[5].amount)} className="GrayBG" style={{ background: '#041934', width: `${isWhatPercentOf(dafault_summary[5].amount, Number(dafault_summary[5].amount) + Number(dafault_summary[6].amount))}%` }}>
                            <span className="pull-left">
                              <p>
                                <span className="currencyIcon">{currency}</span>
                                {NumberWithCommas(dafault_summary[5].amount)}
                              </p>
                            </span>
                          </h4>
                          <h4 title={'New Annual net profit ' + currency + '' + NumberWithCommas(dafault_summary[6].amount)} className="GreenBG text-end" style={{ background: '#008037', width: `${isWhatPercentOf(dafault_summary[6].amount, Number(dafault_summary[5].amount) + Number(dafault_summary[6].amount))}%` }}>
                            <div className="show-value"><span className="currencyIcon">{currency} </span>

                              {NumberWithCommas(dafault_summary[6].amount)}
                            </div>
                          </h4>
                          <div className="TotalPercent">
                            <h4 title="Profit impact" className="text-end">

                              <span className="percentageSign pull-right"> <i className="fa fa-arrow-circle-up"></i>
                                {dafault_summary[4].percentage}%</span>

                            </h4>
                          </div>
                        </div>
                      }

                    </div>
                  </Col>
                </Row>
                {loading &&
                  <Summary
                    currency={currency}
                    slug={props.match.params.slug}
                    questions={path_way_progression}
                    assessment={dcrypt(params.id)}
                    module={assessment.current_module}
                    history={props.history}
                    profitImpact={dafault_summary.length > 0 ? NumberWithCommas(dafault_summary[0].amount) : 0}
                    currentProfit={dafault_summary.length > 0 ? Number(dafault_summary[2].amount) : 0}
                    currentRevenue={dafault_summary.length > 0 ? Number(dafault_summary[1].amount) : 0}
                    ExpectedIncrease={dafault_summary.length > 0 ? Number(dafault_summary[3].amount) : 0}
                    AnnualProfit={dafault_summary.length > 0 ? Number(dafault_summary[4].amount) : 0}
                    AnnualGrossRevenue={dafault_summary.length > 0 ? Number(dafault_summary[5].amount) : 0}
                    YearProfitImpact={dafault_summary.length > 0 ? Number(dafault_summary[6].amount) : 0}
                  />
                }
              </section>
            </Col>
          </Row>
          <Loader loaded={loading}></Loader>
          <section className="mt-3 ClientProgress SummaryAssessment">
            <Row>
              <Col lg={12}>
                {
                  path_way_progression && (
                    <Implementation
                      currency={currency}
                      slug={props.match.params.slug}
                      assessment={dcrypt(params.id)}
                      module={assessment.current_module}
                      path_way_progression={path_way_progression}
                      name={records.assessment ? records.assessment.name : ''}
                      canEdit={canEdit}
                      getAssessment={getAssessment}

                    />
                  )
                }
              </Col>
              <Col xs="12">
                <div className={`${props.match.params.slug == "reports" ? 'card card-body fromPrgressTracker' : 'survey fromPrgressTracker'}`}>

                  {loading && assessment.assessment && dafault_summary.length > 0 &&
                    <Roi
                      key={Math.random()}
                      slug={props.match.params.slug}
                      currency={currency}
                      assessment={dcrypt(params.id)}
                      module={assessment.current_module}
                      investment={assessment.assessment.other_settings}
                      getAssessment={getAssessment}
                      questions={assessment.reports}
                      name={records.assessment ? records.assessment.name : ''}
                      currentRevenue={dafault_summary[1].amount}
                      AnnualGrossRevenue={dafault_summary[3].amount}
                      currentRevenuePercentage={dafault_summary[0].percentage}
                      ExpectedIncrease={dafault_summary[2].amount}

                      currentProfit={dafault_summary[9].amount}
                      AnnualProfit={dafault_summary[10].amount}
                      currentProfitPercentage={dafault_summary[8].percentage}
                      ExpectedIncreaseProfit={dafault_summary[8].amount}


                      currentNetProfit={dafault_summary[5].amount}
                      AnnualNetProfit={dafault_summary[6].amount ?? 0}
                      currentNetProfitPercentage={dafault_summary[4].percentage}
                      ExpectedIncreaseNetProfit={dafault_summary[4].amount}

                      profitImpact={dafault_summary[3].amount}
                      YearProfitImpact={dafault_summary[6].amount}


                      pathWayProgression={path_way_progression}
                      pathWayProgressionCalculation={PathwayProgressionData}
                      customerConversion={customer_conversion}
                      canEdit={canEdit}

                    />
                  }
                </div>
              </Col>
            </Row>
          </section>

          <Row></Row>
        </Col>
        <Col lg={1} className="assessment">

        </Col>

      </Row>

    </React.Fragment>
  );
};

export default Assesments;
