import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
} from "reactstrap";

import Helmet from "react-helmet";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { ApiUrl, ProductName } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import CampaignNav from "../nav";
import { dcrypt, bcrypt, uToken } from "../../../useToken";
import CampaignReportingNav from "./nav";
import { Modal, Tab, Tabs } from "react-bootstrap";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Loader from "react-loader";
import { data } from "jquery";

export default class CampaignReportInsight extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id
                ? dcrypt(this.props.match.params.id)
                : null,
            page_name: "Campaign Insight",
            filter: {},
            record: {},
            showModal: false,
            records: [],
            total_pages: 0,
            series: [],
            master_type: '0',
            type: '',
            loading: false,
            seriesData: ['', '', '', '', '', '', '']
        };
    }


    componentDidMount() {
        this.getData();
    }



    getData = (queryString = "", data) => {
        fetch(`${ApiUrl}` + `campaign/report/summary/${this.state.id}` + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        total_pages: response.data.total,
                        records: response.data.records,
                        loading: true
                    });
                    this.getRecord('rvm');
                } else {
                     toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    handleFilterSubmit = async (event, values) => {
        this.setState({
            filter: values,
        });
        this.getData("", values);
    };

    cancelFilter = async () => {
        this.form && this.form.reset();
        this.setState({ filter: {} });
        this.getData("", "");
    };


    getSeries = (master_type) => {
        fetch(`${ApiUrl}` + `campaign/series/report/${this.state.id}/${master_type}/0`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {
                    console.log(response, 'debugging');
                    this.setState({
                        series: response.data.records,
                        master_type: master_type,
                    });

                } else {
                     toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    getRecord = (key) => {

        if (this.state.records.length > 0) {
            if (key == 'rvm') {
                let datam = this.state.records.find(record => record.label == 'RVM');
                let ids = datam.data.map((item) => item.value);
                this.setState({ seriesData: ids });
            } else if (key == 'sms') {
                let datam = this.state.records.find(record => record.label == 'SMS');
                let ids = datam.data.map((item) => item.value);
                this.setState({ seriesData: ids });
            } else if (key == 'email' || key == 'opened_vs_clicked') {
                let datam = this.state.records.find(record => record.label == 'Email');
                let ids = datam.data.map((item) => item.value);
                this.setState({ seriesData: ids });
            } else if (key == 'inbound') {
                let datam = this.state.records.find(record => record.label == 'Inbound Calls');
                let ids = datam.data.map((item) => item.value);
                let datam1 = this.state.records.find(record => record.label == 'Unique Caller');
                let ids1 = datam1.data.map((item) => item.value);
                let array = [ids[2], ids[3], ids1[2], ids1[3]];
                this.setState({ seriesData: array });
            } else if (key == 'overall') {
                let datam = this.state.records.find(record => record.label == 'Overall');
                let ids = datam.data.map((item) => item.value);
                this.setState({ seriesData: ids });
            }



        } else {
            this.setState({
                seriesData: ['', '', '', '', '', '', '']
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.state.page_name}
                        Tables
                    </title>
                </Helmet>

                <Row>
                    <Col sm={5}>
                        <div className="page-title-box">
                            <h4>{this.state.page_name} Lists</h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#">{this.state.page_name} Lists</Link>
                                </li>
                            </ol>
                        </div>
                    </Col>
                </Row>

                <ToastContainer />
                <Loader loaded={this.state.loading}></Loader>

                <CampaignNav activeTab={'9'} campaignId={bcrypt(this.state.id)} />
                <Row >
                    <Col className="col-12">
                        <Card className="mb-0">
                            <CampaignReportingNav activeTab={'6'} campaignId={bcrypt(this.state.id)} />

                            <CardBody className="py-0">
                                <AvForm
                                    onValidSubmit={this.handleFilterSubmit}
                                    ref={(c) => (this.form = c)}
                                    className="needs-validation"
                                >
                                    <Row>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <AvField
                                                    className="select form-select"
                                                    type="select"
                                                    name="master_type"
                                                    label="Select Area"
                                                    onChange={(e) =>
                                                        this.getSeries(e.target.value)
                                                    }
                                                >
                                                    <option value="0"> All Time </option>
                                                    <option value="Scheduling">Broadcasts </option>
                                                    <optgroup label="Automation">
                                                        <option value="Autoresponder">Drips</option>
                                                        <option value="Eventreminder">Event Reminder</option>
                                                        <option value="Automation">Trigger</option>
                                                    </optgroup >
                                                    <option value="Manual">Manual</option>
                                                </AvField>
                                            </div>
                                        </Col>
                                        {
                                            (this.state.master_type != 0 && this.state.master_type != 'Manual') && (
                                                <Col md="2">
                                                    <div className="mb-3">
                                                        <AvField
                                                            className="select form-select"
                                                            type="select"
                                                            name="series_id"
                                                            label={`Select ${this.state.master_type}`}
                                                        >
                                                            <option value=""> All </option>
                                                            {
                                                                this.state.series.map((row, i) => {
                                                                    return <option value={row.series_id}>{row.type} #{row.series_id}</option>
                                                                })
                                                            }
                                                        </AvField>
                                                    </div>
                                                </Col>
                                            )
                                        }

                                        <Col md="2">
                                            <div className="mb-3">
                                                <AvField
                                                    className="select form-select"
                                                    type="select"
                                                    name="report_type"
                                                    label="Type"
                                                >
                                                    <option value=""> All </option>
                                                    <option value="sms">SMS </option>
                                                    <option value="rvm">RVM</option>
                                                    <option value="email">Email</option>
                                                    <option value="call">Call</option>
                                                </AvField>
                                            </div>
                                        </Col>

                                        <Col md="2">
                                            <div className="mb-3">
                                                <AvField
                                                    className="select form-select"
                                                    type="select"
                                                    name="flow"
                                                    label="Direction"
                                                >
                                                    <option value=""> All </option>
                                                    <option value="in">IN </option>
                                                    <option value="out">OUT</option>
                                                    <option value="system_out">SYSTEM OUT</option>
                                                </AvField>
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <AvField
                                                    name="from_date"
                                                    label="From Date"
                                                    className="form-control"
                                                    placeholder="Enter Email Address"
                                                    type="date"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <AvField
                                                    name="to_date"
                                                    label="To date"
                                                    className="form-control"
                                                    type="date"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <label className="visibilty-hidden"> {"Action"}</label>
                                                <div className="button-items">

                                                    <Button className="btn-sm" color="primary" type="submit">
                                                        Filter
                                                    </Button>
                                                    <Button
                                                        onClick={() => this.cancelFilter()}
                                                        outline
                                                        color="secondary"
                                                        className="waves-effect btn-sm"
                                                        type="button"
                                                    >
                                                        Reset
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Card>
                    <Tabs defaultActiveKey="rvm" id="uncontrolled-tab-example" className="mb-3" onSelect={(key) => this.getRecord(key)}>
                        <Tab eventKey="rvm" title="Ringless Voicemails Attempted">
                            <div className="card card-body">
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={{
                                        title: {
                                            text: 'RVM Report'
                                        },
                                        xAxis: {
                                            categories: ['RVM Attempted', 'RVM Delivered']
                                        },
                                        series: [{
                                            type: 'column',
                                            colorByPoint: true,
                                            data: [this.state.seriesData[0], this.state.seriesData[1]],
                                            name: 'Status'
                                        }]
                                    }}
                                />
                            </div>
                        </Tab>
                        <Tab eventKey="sms" title="SMS Attempted">
                            <div className="card card-body">
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={{
                                        title: {
                                            text: 'SMS Report'
                                        },
                                        xAxis: {
                                            categories: ['SMS Attempted', 'SMS Delivered', 'SMS undelivered', 'SMS Failed']
                                        },
                                        series: [{
                                            type: 'column',
                                            colorByPoint: true,
                                            data: [this.state.seriesData[1], this.state.seriesData[2], this.state.seriesData[3], this.state.seriesData[4]],
                                            name: 'Status'
                                        }]
                                    }}
                                />
                            </div>
                        </Tab>
                        <Tab eventKey="email" title="Email">
                            <div className="card card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={{
                                                title: {
                                                    text: 'EMAIL Attempted VS Delivered'
                                                },
                                                xAxis: {
                                                    categories: ['EMAIL Attempted', 'EMAIL Delivered']
                                                },
                                                series: [{
                                                    type: 'column',
                                                    colorByPoint: true,
                                                    data: [this.state.seriesData[2], this.state.seriesData[3]],
                                                    name: 'Status'
                                                }]
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={{
                                                chart: {
                                                    plotBackgroundColor: null,
                                                    plotBorderWidth: null,
                                                    plotShadow: false,
                                                    type: 'pie'
                                                },
                                                title: {
                                                    text: 'Email Opened VS Clicked'
                                                },
                                                tooltip: {
                                                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                                                },
                                                accessibility: {
                                                    point: {
                                                        valueSuffix: '%'
                                                    }
                                                },
                                                plotOptions: {
                                                    pie: {
                                                        allowPointSelect: true,
                                                        cursor: 'pointer',
                                                        dataLabels: {
                                                            enabled: true,
                                                            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                                                        }
                                                    }
                                                },
                                                series: [{
                                                    name: 'count',
                                                    colorByPoint: true,
                                                    data: [
                                                        {
                                                            name: 'Email Opened',
                                                            y: this.state.seriesData[0] || 0,
                                                        }, {
                                                            name: 'Email Clicked',
                                                            y: this.state.seriesData[1] || 0
                                                        }
                                                    ]

                                                }]
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>
                        </Tab>
                        <Tab eventKey="inbound" title="Inbound Calls VS Unique">
                            <div className="card card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={{
                                                chart: {
                                                    plotBackgroundColor: null,
                                                    plotBorderWidth: null,
                                                    plotShadow: false,
                                                    type: 'pie'
                                                },
                                                title: {
                                                    text: 'Inbound Call Status'
                                                },
                                                tooltip: {
                                                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                                                },
                                                accessibility: {
                                                    point: {
                                                        valueSuffix: '%'
                                                    }
                                                },
                                                plotOptions: {
                                                    pie: {
                                                        allowPointSelect: true,
                                                        cursor: 'pointer',
                                                        dataLabels: {
                                                            enabled: true,
                                                            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                                                        }
                                                    }
                                                },
                                                series: [{
                                                    name: 'Call Value',
                                                    colorByPoint: true,
                                                    data: [
                                                        {
                                                            name: 'More than 30sec',
                                                            y: this.state.seriesData[0] || 0,
                                                        }, {
                                                            name: 'Less than 30sec',
                                                            y: this.state.seriesData[1] || 0
                                                        }
                                                    ]

                                                }]
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={{
                                                chart: {
                                                    plotBackgroundColor: null,
                                                    plotBorderWidth: null,
                                                    plotShadow: false,
                                                    type: 'pie'
                                                },
                                                title: {
                                                    text: 'Unique Inbound Call'
                                                },
                                                tooltip: {
                                                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                                                },
                                                accessibility: {
                                                    point: {
                                                        valueSuffix: '%'
                                                    }
                                                },
                                                plotOptions: {
                                                    pie: {
                                                        allowPointSelect: true,
                                                        cursor: 'pointer',
                                                        dataLabels: {
                                                            enabled: true,
                                                            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                                                        }
                                                    }
                                                },
                                                series: [{
                                                    name: 'Call Value',
                                                    colorByPoint: true,
                                                    data: [
                                                        {
                                                            name: 'More than 30sec',
                                                            y: this.state.seriesData[2] || 0,
                                                        }, {
                                                            name: 'Less than 30sec',
                                                            y: this.state.seriesData[3] || 0
                                                        }
                                                    ]

                                                }]
                                            }}
                                        />
                                    </div>

                                </div>

                            </div>
                        </Tab>
                        <Tab eventKey="overall" title="Overall Report">
                            <div className="card card-body">
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={{
                                        chart: {
                                            inverted: true,
                                        },
                                        title: {
                                            text: 'Overall Report'
                                        },
                                        xAxis: {
                                            categories: ['RVM Delivered', 'SMS Delivered', 'EMAIL Delivered',
                                                'Inbound Calls', 'Unique Calls', 'More than 30 seconds (Inbound)',
                                                'More than 30 seconds (Unique)'],
                                        },
                                        series: [{
                                            name: 'Report',
                                            type: 'column',
                                            colorByPoint: true,
                                            data: [this.state.seriesData[0], this.state.seriesData[1], this.state.seriesData[2],
                                            this.state.seriesData[3], this.state.seriesData[4], this.state.seriesData[5],
                                            this.state.seriesData[6]],
                                        }]
                                    }}
                                />
                            </div>
                        </Tab>
                        {/* <Tab eventKey="opened_vs_clicked" title="Email Opened Vs Clicked">
                            <div className="card card-body">
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={{
                                        title: {
                                            text: 'Email Opened Vs Clicked'
                                        },
                                        xAxis: {
                                            categories: ['Open Rate', 'Click Rate']
                                        },
                                        series: [{
                                            type: 'column',
                                            colorByPoint: true,
                                            data: [this.state.seriesData[0], this.state.seriesData[1]],
                                            name: 'Rate'
                                        }]
                                    }}
                                />
                            </div>
                        </Tab> */}
                    </Tabs>
                </Card>
            </React.Fragment >
        );
    }
}
