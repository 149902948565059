import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Helmet from "react-helmet";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import { Progress } from "reactstrap";

import useToken, {
  bcrypt,
  isAllowed,
  isObjectEmpty,
  uInfo,
  uToken,
  convertPhoneFormat,
  NumberWithCommas,
  hasRole,
} from "../../../useToken";
import { ApiUrl } from "../../../config";
import Dropdownaction from "./Dropdown";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import ErrorBoundary from "../../../components/Common/ErrorBoundary";
import swal from 'sweetalert';
import Lock from "../../../assets/images/padlock.png";
import Phone from "../../InitiateCall/Phone";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";


export default class UsersDatatableTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      total_revenue: {},
      coachPermission: {
        available_coach: 0,
        remaining_coach: 0,
      },
      roles: [],
      total_pages: 0,
      modal: false,
      record: {},
      filter: {},
      page_name: "Coach",
      customDate: true,
      approvalModal: false,
      approvalUser: {},
      approvalStatus: '',
      slide: '',
      tokens: [],
      phoneNumbers: [],
      smsTemplates: [],
      dynamicModal: false,
      emailModal: false,
      dynamicModalName: 'note',
      selectedClient: {},
      token: '',
      html: '',
      callToken: null,
      phone_number: '',
      paramsters: {
        from_number: uInfo().outbound_phone,
      },
      verifiedFromEmail: [],
      verifiedFromEmailId:"",
      verifiedFromName: "",
    };
    this.columns = [
      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          return (
            <Dropdownaction userid={record.id} props={record.id} status={record.status} loginAsSubCoach={this.loginAsSubCoach} trigger_get_user={this.trigger_get_user} />
          );
        },
      },
      {
        text: "Name",
        key: "name",
        cell: (record, index) => {
          return (
            <Fragment>
              {isAllowed(uInfo(), ["can_view"]) && uInfo().type != 'WlAdmin' ? (
                <button
                  className="waves-effect btn-link"
                  onClick={() => this.props.history.push("/coach/dialer/" + bcrypt(record.client_id) + "/" + bcrypt(record.user_id) + "/" + bcrypt(record.wl_admin_id))}
                >
                  {record.name}
                </button>
              ) : (
                record.name
              )}
            </Fragment>
          );
        },
      },
      {
        text: "Email",
        key: "email",
      },
      {
        text: "Phone",
        key: "phone",
      },
      {
        text: "Trust Hub Profile|Status",
        key: "business_profile",
        cell: (record, index) => {
          return (
            <Fragment>
              {record.business_profile == "1" && (
                <Badge bg="success"> Approved </Badge>
              )}
              {record.business_profile == "0" && (
                <Badge bg="danger">(Not Approved)</Badge>
              )}
              {record.business_profile_type == "0" && (
                <Badge className="ml-5" bg="warning"> No profile </Badge>
              )}
              {record.business_profile_type == "1" && (
                <Badge className="ml-5" bg="info"> Starter  </Badge>
              )}
              {record.business_profile_type == "2" && (
                <Badge className="ml-5" bg="success"> Secondary</Badge>
              )}

            </Fragment>
          );
        },
      },
      {
        text: "Status",
        key: "status",
        cell: (record, index) => {
          return (

            <Fragment>
              {record.status == "Active" && (
                <Badge bg="success"> {record.status} </Badge>
              )}
              {record.status == "Inactive" && (
                <Badge bg="danger"> {record.status} </Badge>
              )}
              {record.status == "Pending" && (
                <Badge bg="warning"> {record.status} </Badge>
              )}
              {record.status == "Abandoned" && (
                <Badge bg="warning"> {record.status} </Badge>
              )}
              {record.status == "Suspended" && (
                <Badge bg="danger"> {record.status} </Badge>
              )}
              {record.status == "Canceled" && (
                <Badge bg="danger"> {record.status} </Badge>
              )}
              {record.status == "System-Inactive" && (
                <Badge bg="danger"> {record.status} </Badge>
              )}
            </Fragment>
          );
        },
      },
      {
        text: "Is Approved",
        key: "is_approved",
        cell: (record, index) => {
          return (

            <Fragment>
              {
                record.is_approved != "Yes" ? (
                  <button
                    className="border-0 p-0 lh-1"
                    onClick={this.changeApprovalUser.bind(this, record, index)}
                  >
                    {record.is_approved == "No" && (
                      <Badge bg="warning"> {record.is_approved} </Badge>
                    )}

                    {record.is_approved == "Declined" && (

                      <Badge bg="danger"> {record.is_approved} </Badge>
                    )}
                  </button>
                ) : (
                  <Badge bg="success"> {record.is_approved} </Badge>
                )
              }
            </Fragment>
          );
        },

      },

      {
        text: "Created At",
        key: "created_at",
      },
    ];
    this.config = {
      key_column: "sr",
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
      button: {
        excel: false,
        print: true,
        csv: true,
      },
    };

  }

  handleOutCall = () => {
    fetch(`${ApiUrl}` + "voice/token", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ identity: encodeURIComponent("proglobal") }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log('---------------------------------------')
        if (response.status === true) {
          this.setState({
            callToken: response.data.token
          })
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getVerifiedFromEmail = () => {
    fetch(`${ApiUrl}` + "verify/email/list", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
            const defaultFromEmail = response.data.records && response.data.records.filter(item => item.is_default === 1);
            this.setState({
              verifiedFromEmail:response.data.records,
              defaultFromEmail:defaultFromEmail,
              verifiedFromEmailId: defaultFromEmail && defaultFromEmail[0].setting_id
            });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

  };
  changeApprovalUser = (record, index) => {
    this.setState({
      approvalModal: true,
      approvalUser: record,
      approvalStatus: ''
    })
  }
  handleSubmit = async (event, values) => {
    values.client_id = this.state.selectedClient.client_id;
    values.type = this.state.dynamicModalName.toLowerCase();
    fetch(`${ApiUrl}` + "lead/activity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log(data, "Editing");
        if (data.status === true) {
          this.closeDynamicModal();
          //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }

      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleEmailSubmit = async (event, values) => {
    values.client_id = this.state.selectedClient.client_id;
    values.type = this.state.dynamicModalName.toLowerCase();
    if (this.state.html != "") {
      let body = document.getElementById("custom-template").innerHTML;
      values.body = body;
    }else{
      values.body = this.state.smsBody;
    }
    
    values.setting_id = this.state.verifiedFromEmailId;
    fetch(`${ApiUrl}` + "company/email/activity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log(data, "Editing");
        if (data.status === true) {
          this.closeDynamicModal();
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getEmailTemplate = () => {
    fetch(`${ApiUrl}` + "get/email/template", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          var tokens_group = [
            {
              label: "Default Field",
              options: response.data.tokens.default_fields,
            },
            {
              label: "Custom Field",
              options: response.data.tokens.custom_fields,
            },
          ];

          this.setState({
            tokens: tokens_group,
            smsTemplates: response.data.templates
          })
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getSmsTemplate = () => {
    fetch(`${ApiUrl}` + "get/sms/template", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          var templates = this.groupArrayOfObjects(response.data.templates, "type");
          var templates_group = [
            { label: "SMS", options: templates.SMS },
            { label: "MMS", options: templates.MMS },
          ];

          var tokens_group = [
            {
              label: "Default Field",
              options: response.data.tokens.default_fields,
            },
            {
              label: "Custom Field",
              options: response.data.tokens.custom_fields,
            },
          ];

          this.setState({
            smsTemplates: templates_group,
            tokens: tokens_group,
            phoneNumbers: response.data.phoneNumbers
          })
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  closeDynamicModal = () => {
    this.setState({
      dynamicModal: false,
      emailModal: false,
      dynamicModalName: '',
      smsBody: '',
      token: "",
      subject: "",
      html: "",
      notes: [],
      selectedClient: {}

    });
  }
  getEmailTemplateHtml = (id) => {
    fetch(`${ApiUrl}` + "get/html/" + id + "/" + uInfo().user_id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        this.setState({ html: response.theme })
        // localStorage.setItem("theme", response.theme);
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  changeApprovalStatus = (status) => {
    let record = this.state.approvalUser;
    let msg = record.is_verification_status == 'Y' ? 'You want to approve this user' :
      `You want to approve this user. ${'\n'}This user not complete verification wizard`;
    swal({
      title: "Are you sure?",
      text: msg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "approve/user/account/" + record.id + '/' + status, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                this.getData();
                this.setState({
                  approvalUser: {},
                  approvalModal: false
                })
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        } else {
          this.setState({
            approvalUser: {},
            approvalModal: false
          })
        }
      });
  };

  deleteRecord = (record, index) => {
    fetch(`${ApiUrl}` + "delete/user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: record.id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          this.getData();

          // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  componentDidMount() {
    this.getData();
    this.handleOutCall();
  }

  loginAsSubCoach = (user) => {
    localStorage.setItem("authUser", JSON.stringify(user));
    setTimeout(this.props.history.push("/"), 4000);
  }
  trigger_get_user = () => {
    console.log('trigger_get_user');
    this.getData();
  }
  NumberWithCommas = (x) => {
    let y = Math.trunc(x);
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  getData = (queryString = "", data) => {
    let body = { type: 'Coach' };
    if (data && !isObjectEmpty(data)) {
      body = data;
    }
    fetch(`${ApiUrl}` + "my/coaches" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            records: response.data.records,
            total_revenue: response.data.total_revenue,
            coachPermission: response.data.coachPermission
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  groupArrayOfObjects(list, key) {
    return list.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  handleFilterSubmit = async (event, values) => {
    values.type = 'Coach';
    this.setState({
      filter: values,
    });
    this.getData("", values);
  };

  cancelFilter = async () => {
    this.form && this.form.reset();
    this.setState({ filter: {}, customDate: true });
    this.getData("", "");
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData("?" + queryString, this.state.filter);
  };
  getCallSid = (call_sid) => {

  }
  render() {
    return (
      <ErrorBoundary>
        <div className="my-coaches">
          <Helmet>
            <title>
              {this.state.page_name}  Tables </title>
          </Helmet>
          <Row>
            <Col lg={3}>
              <div className="CoachRevnewCount p-2">
                <div className="RevenueCoach">Total YTD Revenue</div>
                <div className="CoachRevCount">${this.NumberWithCommas(this.state.total_revenue.total_ytd_revenue)}</div>
              </div>
            </Col>
            <Col lg={3}>
              <div className="CoachRevnewCount p-2">
                <div className="RevenueCoach">Monthly Recurring Revenue</div>
                <div className="CoachRevCount">${this.NumberWithCommas(this.state.total_revenue.monthly_recurring_revenue)}</div>
              </div>
            </Col>
            <Col lg={3}><div className="CoachRevnewCount p-2">
              <div className="RevenueCoach">Total Earned Client Revenue (Coaches)</div>
              <div className="CoachRevCount">${this.NumberWithCommas(this.state.total_revenue.total_earned_client_revenue)}</div>
            </div></Col>
            <Col lg={3}><div className="CoachRevnewCount p-2">
              <div className="RevenueCoach">Total Client Profit (Coaches)</div>
              <div className="CoachRevCount">${this.NumberWithCommas(this.state.total_revenue.total_client_profit)}</div>
            </div></Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="page-title-box text-align-right mt-2">
                {(hasRole(uInfo(), ["add_coach"]) && this.state.coachPermission.remaining_coach > 0) && (
                  <Link
                    type="button"
                    color="info"
                    className="btn btn-info btn-sm"
                    to={"/users/coach/add"}
                  >
                    <i className="mdi mdi-plus"> </i>
                    Create
                  </Link>
                )}
              </div>
            </Col>
          </Row>
          <Row className="mb-4">

            {
              this.state.records.map((row, i) => {
                var i = i + 1;
                return (
                  <Col lg={4}>
                    {
                      row.id > 0 ? (
                        <>
                          <Card className="CoachesLists mb-2">
                            {/* Nov 16th, 2022  */}
                            <div className="LastLogin mb-4 pl-5">Last Login: {row.login_at}</div>
                            <CardBody className="pb-1">
                              <div className="MyCoachesList">
                                <div className={`DashboardHeadingSection code-${row.current_month_calculations.coach_header_code} mb-4`}><h4 className="card-title">Coach #{i}</h4></div>
                                <div className="PointsContent">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="CoachStatus Offline my-2 d-flex justify-content-center">
                                        {row.login_status == 'yes' ? (
                                          <>
                                            <div className="StatusDot code-green"></div> <span>Online</span>
                                          </>
                                        ) : (
                                          <>
                                            <div className="StatusDot code-red"></div> <span>Offline</span>
                                          </>
                                        )}
                                        {row.status == 'Active' ? (
                                          <>
                                            <div className="StatusDot code-green ml-3"></div> <span>Active</span>
                                          </>
                                        ) : (
                                          <>
                                            <div className="StatusDot code-red ml-3"></div> <span>{row.status}</span>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-lg-4 pl-0">
                                      <div className="row">
                                        <div className="col-lg-12">
                                          <div className="PointsCount code-green d-flex mb-1 p-2 flex-shrink-0">
                                            <button
                                              onClick={() => {
                                                var phone_number = row.forward_number;
                                                this.setState(prevState => ({
                                                  paramsters: {                   // object that we want to update
                                                    ...prevState.paramsters,    // keep all other key-value pairs
                                                    phoneno: phone_number,
                                                    token: this.state.callToken,
                                                    record: "false",
                                                    client_id: row.client_id,
                                                    coach_id: row.wl_admin_id,     // update the value of specific key
                                                  }
                                                }), () => {
                                                  this.setState({
                                                    dynamicModal: true,
                                                    dynamicModalName: "call",
                                                    selectedClient: row,
                                                    phoneno: phone_number,
                                                  });
                                                });
                                              }}
                                              className="btn btn-link text-white p-0"
                                            >   <i className="fas fa-phone-alt pr-5"></i> Call Now
                                            </button>
                                          </div>
                                        </div>
                                        <div className="col-lg-12">
                                          <div className="PointsCount code-green d-flex mb-1 p-2">
                                            <button
                                              onClick={() => {
                                                this.setState({
                                                  dynamicModal: true,
                                                  dynamicModalName: "sms",
                                                  selectedClient: row
                                                })
                                                this.getSmsTemplate();
                                              }}
                                              className="btn btn-link text-white p-0"
                                            >
                                              <i className="mdi mdi-comment"></i> Send SMS
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-2 px-1"><div className={`code-${row.current_month_calculations.outbound_goals_thumb} TotalPoints`} title={'Outbound Goals'}><i className={`ion ion-md-thumbs-${row.current_month_calculations.outbound_goals_thumb_direction}`}></i></div></div>
                                    <div className="col-lg-2 px-1"><div className={`code-${row.current_month_calculations.revenue_goals_thumb} TotalPoints`} title={'Revenue Goals'}><i className={`ion ion-md-thumbs-${row.current_month_calculations.revenue_goals_thumb_direction}`}></i></div></div>
                                    <div className="col-lg-4 pr-0">
                                      <div className="row">
                                        <div className="col-lg-12">
                                          <div className="PointsCount code-green d-flex mb-1 p-2">
                                            <button
                                              onClick={() => {
                                                this.setState({
                                                  emailModal: true,
                                                  dynamicModalName: "email",
                                                  selectedClient: row
                                                });
                                                this.getEmailTemplate();
                                                this.getVerifiedFromEmail("");
                                              }}
                                              className="btn btn-link text-white p-0"
                                            >   <i className="ion ion-md-mail"></i> Send Email
                                            </button>
                                          </div>
                                        </div>
                                        <div className="col-lg-12">
                                          <div className="PointsCount code-green d-flex mb-1 p-2">
                                            <Link
                                              type="button"
                                              color="info"
                                              className="btn btn-link text-white p-0"
                                              to={`/chat/${row.id}/client`}
                                            >
                                              <i className="fas fa-user-alt"></i> Instant Meet
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <Row>
                                        <div className="CoachContacts mt-2 mb-2 p-0">
                                          <div className="ContactsCoach"><i className="mdi mdi-web"></i><div class="UserDetalisCoach">{row.domain}</div></div>
                                          <div className="ContactsCoach"><i className="fas fa-phone-alt"></i><div class="UserDetalisCoach">{row.phone}</div></div>
                                          <div className="ContactsCoach"><i className="fas fa-envelope"></i><div class="UserDetalisCoach">{row.email}</div></div>
                                        </div>
                                      </Row>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                          <Card className="VariousCalls CoachProgressBars mb-4 m-0">
                            <CardBody>
                              <div className="MyCoachesList ">
                                <div className="ProgressDashboard OutboundGoals">
                                  <div className="BarProgress">
                                    <div className="ProgressLabel"><label>MONTHLY CONTRACTED REVENUE</label>
                                      <Progress color="primary" title={`Monthly Contracted Revenue: $ ${row.current_month_calculations.coaching_revenue}`} value={row.current_month_calculations.coaching_revenue_percentage}>
                                        ${row.current_month_calculations.coaching_revenue} <div className="TotalProgress">${NumberWithCommas(row.current_month_calculations.revenue_goals.monthly_revenue)}</div>
                                      </Progress>
                                    </div>
                                  </div>
                                </div>
                                <div className="ProgressDashboard OutboundGoals mt-3">
                                  <div className="BarProgress">
                                    <div className="ProgressLabel"><label>Total Clients</label>
                                      <Progress color="primary" title={`Total Clients: ${row.current_month_calculations.total_clients}`} value={row.current_month_calculations.total_clients_percentage}>
                                        {row.current_month_calculations.total_clients} <div className="TotalProgress"> {NumberWithCommas(row.current_month_calculations.revenue_goals.total_clients)}</div>
                                      </Progress>
                                    </div>
                                  </div>
                                </div>
                                <div className="ProgressDashboard OutboundGoals mt-3">
                                  <div className="BarProgress">
                                    <div className="ProgressLabel"><label>Monthly Academy Revenue</label>
                                      <Progress color="primary" title={`Monthly Academy Revenue:$ ${row.current_month_calculations.academy_revenue}`} value={row.current_month_calculations.academy_revenue_percentage}>
                                        ${row.current_month_calculations.academy_revenue} <div className="TotalProgress">${NumberWithCommas(row.current_month_calculations.revenue_goals.lmc_monthly_revenue)}</div>
                                      </Progress>
                                    </div>
                                  </div>
                                </div>
                                <div className="ProgressDashboard OutboundGoals mt-3">
                                  <div className="BarProgress">
                                    <div className="ProgressLabel"><label>Total Academy Subscribers</label>
                                      <Progress color="primary" title={`Total Academy Subscribers: ${row.current_month_calculations.academy_subscribers}`} value={row.current_month_calculations.academy_subscribers_percentage}>
                                        {row.current_month_calculations.academy_subscribers} <div className="TotalProgress"> {NumberWithCommas(row.current_month_calculations.revenue_goals.lmc_total_subscriptions)}</div>
                                      </Progress>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                            <div className="col-lg-12">
                              <div className="float-right mt-2">
                                <button
                                  onClick={() => this.props.history.push("/users/admin/coach/edit/" + bcrypt(row.id))}
                                  className="btn btn-primary code-green border-0 btn-sm">Edit</button>

                                <button
                                  onClick={() => this.props.history.push("/coach/dialer/" + bcrypt(row.client_id) + "/" + bcrypt(row.id) + "/" + bcrypt(row.wl_admin_id))}
                                  className="btn btn-primary code-green border-0 ms-2 btn-sm">View</button>
                              </div>
                            </div>
                          </Card>
                        </>
                      ) : (
                        <Card className="CoachesLists LockCoach mb-2">
                          <div className="LastLogin mb-4 pl-5">&nbsp;</div>
                          <CardBody className="pb-1">
                            <div className="MyCoachesList">
                              <div className="DashboardHeadingSection code-dark mb-4"><h4 className="card-title">Coach #{i}</h4></div>
                              <img src={Lock}></img>
                            </div>

                          </CardBody>
                        </Card>
                      )}
                  </Col>
                )
              })
            }

          </Row>

          <ToastContainer />
          <Modal
            isOpen={this.state.approvalModal}
            fade={false}
            toggle={() => this.setState({ approvalModal: false })}
          >
            <ModalBody>
              <div className="form-group mb-3">
                <label>Select Status</label>
                <select onChange={(e) => this.setState({ approvalStatus: e.target.value })} name="from_number" className="form-select" >
                  <option value={''}>Select option</option>
                  <option value={'No'}>No</option>
                  <option value={'Yes'}>Yes</option>
                  <option value={'Declined'}>Declined</option>
                </select>
              </div>
            </ModalBody>
            <ModalFooter>
              {
                this.state.approvalStatus != '' ?
                  <Button className="btn-sm" color="info" onClick={() => this.changeApprovalStatus(this.state.approvalStatus)}>
                    Yes, Change it
                  </Button>
                  :
                  <Button className="btn-sm" disabled color="info" >
                    Yes, Change it
                  </Button>
              }
              <Button className="btn-sm" color="danger" onClick={() => this.setState({ approvalModal: false })}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={this.state.dynamicModal}
            fade={true}
            size="md"
            toggle={() => this.closeDynamicModal()}
          >
            <AvForm
              onValidSubmit={this.handleSubmit}
              // model={this.state.record}
              className="needs-validation"
            >
              {this.state.dynamicModalName == "sms" ? (
                <div className="sms">
                  <div className="modal-head top-heading">
                    <h2> Send SMS </h2>
                    <button
                      onClick={() => this.closeDynamicModal()}
                      className="btn btn-link text-white p-0 p-0"
                    >
                      <i className="fas fa-times"> </i>
                    </button>
                  </div>
                  <ModalBody>
                    <div className="mb-3">
                      <div className="MainTZone SendSMS One">
                        <Select
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          // value={options.filter(({ id }) => id === this.state.country_code)}
                          isMulti={false}
                          options={this.state.smsTemplates}
                          classNamePrefix="select2-selection"
                          name="country_id"
                          placeholder={"Select sms template"}
                          onChange={(e) => {
                            this.setState({
                              smsBody: e.body,
                              template_id: e.id
                            })
                          }}
                        />
                      </div>
                      <AvField type="hidden" name="template_id" value={this.state.template_id} />
                      <AvField type="hidden" name="tos" value={this.state.selectedClient.forward_number} />
                      <AvField type="hidden" name="activity" value={"sms send"} />
                    </div>
                    <div className="mb-3">
                      <AvField
                        className="select form-select"
                        type="select"
                        name="froms"
                        required
                      >
                        <option value=""> Select Phone Number </option>
                        {this.state.phoneNumbers.map((row, i) => {
                          return (
                            <option key={"phone-" + i} value={row.phone_number}>
                              {convertPhoneFormat(row.phone_number)}
                            </option>
                          );
                        })}
                      </AvField>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-8">
                        <div className="MainTZone">
                          <Select
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.key}
                            // value={options.filter(({ id }) => id === this.state.country_code)}
                            isMulti={false}
                            options={this.state.tokens}
                            classNamePrefix="select2-selection"
                            name="country_id"
                            placeholder={"Select token"}
                            onChange={(e) => {
                              this.setState({
                                token: `{${e.key}}`
                              })
                            }}
                          />
                        </div>
                        {/* <AvField type="hidden" name="country_code" value={token} /> */}
                      </div>
                      <div className="col-md-4">
                        <button
                          onClick={() => this.setState({ smsBody: `${this.state.smsBody} ${this.state.token}` })}
                          type="button"
                          className="btn btn-primary"
                        >
                          Insert Token
                        </button>
                      </div>
                    </div>
                    <div className="mb-3">
                      <AvField
                        className="form-control"
                        type="textarea"
                        name="body"
                        required
                        value={this.state.smsBody}
                        placeholder={"Enter body"}
                        onChange={(e) => this.setState({ smsBody: e.target.value })}
                      />
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button className="btn-sm" color="info" type="submit">
                      Send
                    </Button>
                    <Button className="btn-sm" color="danger" onClick={() => this.closeDynamicModal()}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </div>
              ) : this.state.dynamicModalName == "call" ? (
                <div className="call">
                  <div className="modal-head top-heading">
                    <h2> Initiate Outbound Call </h2>
                    <button
                      onClick={() => this.closeDynamicModal()}
                      className="btn btn-link text-white p-0"
                      type="button"
                    >
                      <i className="fas fa-times"> </i>
                    </button>
                  </div>
                  <ModalBody>
                    <div className="mb-3">
                      {this.state.callToken ? (
                        <Phone paramsters={this.state.paramsters} getCallSid={this.getCallSid}> </Phone>
                      ) : (
                        <p> Loading... </p>
                      )}
                    </div>
                  </ModalBody>
                </div>
              ) : (
                <div className=""> </div>
              )}
            </AvForm>
          </Modal>
          <Modal
          isOpen={this.state.emailModal}
          fade={true}
          size="lg"
          toggle={() => this.closeDynamicModal()}
        >
          <AvForm
            onValidSubmit={this.handleEmailSubmit}
            autoComplete="off"
            className="needs-validation"
          >
            <div className="email">
              <div className="modal-head top-heading">
                <h2> Send Email </h2>
                <button
                  onClick={() => this.closeDynamicModal()}
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"> </i>
                </button>
              </div>
              <ModalBody>
              <div className="col-md-12 mb-3">
                  <div className="MainTZone ClientDetails Two 8">
                    <Select
                      getOptionLabel={(option) => `${option.from_name} - ${option.from_email} - ${option.type}`}
                      getOptionValue={(option) => option.setting_id}
                      isMulti={false}
                      options={this.state.verifiedFromEmail}
                      classNamePrefix="select2-selection"
                      placeholder={"Select From Email"}
                      autocomplete="off"
                      value={this.state.defaultFromEmail}
                      
                      name="froms"
                      onChange={(option) => {
                        this.setState({
                          defaultFromEmail: option,
                          verifiedFromEmailId : option.setting_id
                        })
                      
                      }}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <Select
                    getOptionLabel={(option) => option.title}
                    getOptionValue={(option) => option.id}
                    isMulti={false}
                    options={this.state.smsTemplates}
                    classNamePrefix="select2-selection"
                    placeholder={"Select email template"}
                    name="country_id"
                    onChange={(e) => {
                      this.setState({
                        subject: e.title
                      })
                      this.getEmailTemplateHtml(e.id);
                    }}
                  />
                  <AvField type="hidden" name="tos" value={this.state.selectedClient.email} />
                  <AvField type="hidden" name="activity" value={"Email: sent"} />
                </div>
                <div className="mb-3 d-none">
                  {/* <AvField
                    className="form-control"
                    type="text"
                    name="froms"
                    value={uInfo().from_email}
                    required
                    placeholder="Enter from email"
                  /> */}
                </div>
                <div className="row mb-3">
                  <div className="col-md-12 mb-3">
                    <Select
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.key}
                      // value={options.filter(({ id }) => id === this.state.country_code)}
                      isMulti={false}
                      options={this.state.tokens}
                      classNamePrefix="select2-selection"
                      name="country_id"
                      onChange={(e) => {
                        this.setState({
                          token: `{${e.key}}`
                        })
                      }}
                    />
                    {/* <AvField type="hidden" name="country_code" value={token} /> */}
                  </div>
                
                  <div className="col-md-6">
                  {this.state.html == "" && (
                      <button
                        onClick={() => this.setState({ smsBody: `${this.state.smsBody} ${this.state.token}` })}
                        type="button"
                        className="btn btn-primary btn-sm"
                      >
                        Insert Body
                      </button>
                  )}
                 
                    <button
                      onClick={() => this.setState({ subject: `${this.state.subject} ${this.state.token}` })}
                      type="button"
                      className="btn btn-primary btn-sm ms-2"
                    >
                      Insert Subject
                    </button>
                  </div>
                </div>
                <div className="mb-3">
                  <AvField
                    className="form-control"
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    required
                    value={this.state.subject}
                    onChange={(e) => this.setState({ subject: e.target.value })}
                  />
                </div>
                {this.state.html == "" ? (
                  <div className="mb-3">
                    {/* <AvField
                      className="form-control"
                      type="textarea"
                      name="body"
                      placeholder="Enter bodyy"
                      required
                      value={this.state.smsBody}
                      onChange={(e) => this.setState({ smsBody: e.target.value })}
                    /> */}
                    <SunEditor 
                      name="body"
                      setOptions={
                      {
                        height: 300,
                        width: '100%',
                        resizeEnable: 'false',
                        buttonList: [
                          ["undo", "redo", "font", "fontSize", "formatBlock"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                            "removeFormat",
                          ],
                          [
                            "fontColor",
                            "hiliteColor",
                            "outdent",
                            "indent",
                            "align",
                            "horizontalRule",
                            "list",
                            "table",
                          ],
                          [
                            "link",
                            "image",
                            "video",
                            "fullScreen",
                            "showBlocks",
                            "codeView",
                            "preview",
                          ],

                        ],
                        proportion: 'Constrain proportions assasa',
                      }
                      }
                      setContents={this.state.smsBody}
                      value={this.state.smsBody}
                      onChange={(e) => this.setState({ smsBody: e})}
                    />
                  </div>
                ) : (
                  <>
                    <div
                      id="custom-template"
                      className="height-500 custom-scroll"
                      dangerouslySetInnerHTML={{ __html: this.state.html }}
                    ></div>
                    {/* <AvField type="hidden" name="body" value={document.getElementById('custom-template')}/> */}
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <Button className="btn-sm" color="info" type="submit">
                  Send
                </Button>
                <Button className="btn-sm" color="danger" onClick={() => this.closeDynamicModal()}>
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          </AvForm>
        </Modal>
        </div>


      </ErrorBoundary>
    );
  }
}
