import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Modal } from "react-bootstrap";
import ReactDatatable from "@ashvin27/react-datatable";
//Import Action to copy breadcrumb items from local state to redux state
import PackagesSidebar from "../Packages/packages-sidebar";
import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { dcrypt, bcrypt, isAllowed, uInfo, uToken } from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import swal from 'sweetalert';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-bootstrap";
export default class Proposals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.match.params.type,
      records: [],
      roles: [],
      total_pages: 0,
      proposal_link: "",
      modal: false,
      record: {},
      filter: {},
      page_name: "Proposal Lists ",
      customDate: true,
    };
    this.columns = [
      {
        text: "#",
        key: "proposal_id",
        sortable: true,
      },
      {
        text: "Title",
        key: "name",
        sortable: true,
      },
      {
        text: "Price",
        key: "price",
        sortable: true,
      },
      {
        text: "Set Up Fee",
        key: "setup_price",
        sortable: true,
      },
      {
        text: "Type",
        key: "type",
      },
      {
        key: "link",
        text: "Link",
        cell: (record, index) => {
          return (
            <Fragment>
              <button
                title="Link"
                type="button"
                color="info"
                className="btn btn-link btn-sm mb-1 mr-5"
                onClick={() => { this.setState({ modal: true, proposal_link: record.proposal_link }) }}
              >
                <i className="fas fa-fw  fa-link"></i>
              </button>

            </Fragment>
          );
        },
      },
      {
        key: "link",
        text: "Accept Type",
        cell: (record, index) => {
          return (
            <Fragment>
              {record.type == "Package" && (
                <Badge bg="info">Public</Badge>
              )}

              {record.type != "Package" && (
                <Badge bg="warning">Private</Badge>
              )}

            </Fragment>
          );
        },
      },
      {
        text: "Status",
        key: "proposal_status",
        cell: (record, index) => {
          return (
            <Fragment>
              {record.proposal_status == "Active" && (
                <Badge bg="success">{record.proposal_status}</Badge>
              )}
              {record.proposal_status == "Inactive" && (
                <Badge bg="danger">{record.proposal_status}</Badge>
              )}
            </Fragment>
          );
        },
      },

      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          return (
            <Fragment>
              <Link
                className="btn-primary btn-sm mr-5"
                to={"/print/proposal/pages/" + bcrypt(record.proposal_id)}
                title={"Print Proposal"}
              >
                <i className="mdi mdi-printer"></i>
              </Link>
              {/* {" | "} */}
              {isAllowed(uInfo(), ["can_edit"]) && (
                <Link
                  className="btn-primary btn-sm mr-5"
                  to={"edit/" + bcrypt(record.proposal_id)}
                  title={"Edit Proposal"}
                >
                  <i className="mdi mdi-pencil"></i>
                </Link>
              )}

              {record.type == 'Roadmap' && ['default', 'clone'].includes(record.created_type) ?
                <></>
                :
                <>
                  {/* {" | "} */}
                  < Link
                    className="btn-primary btn-sm mr-5"
                    to={"content/" + bcrypt(record.proposal_id)}
                    title={"View & Manage Proposal Content"}
                  >
                    <i className="mdi mdi-eye"></i>
                  </Link>
                </>
              }
              {/* {
                record.created_type == 'default' && ( */}
                  <>
                    {/* {" | "} */}
                    <Link
                      className="btn-primary btn-sm mr-5"
                      onClick={this.cloneRecord.bind(this, record, index)}
                      title={"Clone this proposal"}
                    >
                      <i className="mdi mdi-content-copy"></i>
                    </Link>
                  </>
                {/* )
              } */}

              {isAllowed(uInfo(), ["can_delete"]) && record.created_type != 'default' && (
                <>
                  {/* {" | "} */}
                  <Link
                    className="btn-danger mb-1  btn-sm"
                    onClick={this.deleteRecord.bind(this, record, index)}
                    title={"Delete this proposal"}
                  >
                    <i className="mdi mdi-delete"></i>
                  </Link>
                </>
              )}
            </Fragment>
          );
        },
      },
    ];


    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
    };


  }
  deleteRecord = (record, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "delete/proposal", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: record.proposal_id }),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.status === true) {
                this.getData("", "");
                //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });

        }
      });
  };
  cloneRecord = (record, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to clone this proposal.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "clone/proposal", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: record.proposal_id }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                this.getData("", "");
                //    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            //Then with the error genereted...
            .catch((error) => {
              console.error("Error:", error);
            });

        }
      });
  };


  componentDidMount() {
    this.getData("", "");

  }

  getData = (queryString, data) => {
    
    fetch(`${ApiUrl}` + "proposal/list" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {

          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleFilterSubmit = async (event, values) => {
    this.setState({
      filter: values,
    });
    this.getData("", values);
  };

  cancelFilter = async () => {
    this.form && this.form.reset();
    this.setState({ filter: {}, customDate: true });
    this.getData("", "");
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData("?" + queryString, this.state.filter);
  };

  render() {
    //window.location.reload();
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.page_name}</title>
        </Helmet>

        <Row>
          <Col lg="2" className="pl-0">
            <PackagesSidebar menuOpen={false} props={this.props} />

          </Col>
          <Col lg="10">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4>
                    Proposal Lists
                  </h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#">
                        {this.state.page_name}
                      </Link>
                    </li>
                  </ol>
                </div>
              </Col>
              <Col sm={6}>
                <div className="page-title-box text-align-right">
                  {isAllowed(uInfo(), ["can_create"]) && (
                    <Link
                      type="button"
                      color="info"
                      className="btn btn-info btn-sm"
                      to={"add"}
                    >
                      <i className="mdi mdi-plus"> </i>
                      Create
                    </Link>
                  )}
                </div>
              </Col>
            </Row>

            <ToastContainer />

            <Row>
              <Col xl="12" className="d-none">
                <Card>
                  <CardBody>
                    <AvForm
                      onValidSubmit={this.handleFilterSubmit}
                      ref={(c) => (this.form = c)}
                      className="needs-validation"
                    >
                      <Row>
                        <Col md="2">
                          <div className="mb-3">
                            <AvField
                              className="select form-select"
                              type="select"
                              name="date_range"
                              label="Date Filter"
                              onChange={(e) =>
                                e.target.value == "Custom Date"
                                  ? this.setState({
                                    customDate: false,
                                  })
                                  : this.setState({
                                    customDate: true,
                                  })
                              }
                            >
                              <option value=""> All Time </option>
                              <option value="Current Week">

                                Current Week
                              </option>
                              <option value="Last Week"> Last Week </option>
                              <option value="Current Month">

                                Current Month
                              </option>
                              <option value="Last Month"> Last Month </option>
                              <option value="Last 3 Months">

                                Last 3 Months
                              </option>
                              <option value="Last 6 Months">

                                Last 6 Months
                              </option>
                              <option value="Current Year">

                                Current Year
                              </option>
                              <option value="Last Year"> Last Year </option>
                              <option value="Custom Date"> Custom Date </option>
                            </AvField>
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <AvField
                              name="start_date"
                              label="Start date"
                              className="form-control"
                              placeholder="Enter Email Address"
                              type="date"
                              disabled={this.state.customDate}
                              required={!this.state.customDate}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <AvField
                              name="end_date"
                              label="End date"
                              className="form-control"
                              placeholder="Enter Email Address"
                              type="date"
                              disabled={this.state.customDate}
                              required={!this.state.customDate}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <AvField
                              className="select form-select"
                              type="select"
                              id="s"
                              name="proposal_status"
                              label="Proposal Status"
                            >
                              <option value={""}> Select Status </option>
                              <option value={"Active"}> Active </option>
                              <option value={"Inactive"}> Inactive </option>
                            </AvField>
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <label className=""> {"Action"}</label>
                            <div className="button-items">
                              <Button
                                className="btn-sm"
                                color="primary"
                                type="submit"
                              >
                                Filter
                              </Button>
                              <Button
                                onClick={() => this.cancelFilter()}
                                outline
                                color="secondary"
                                className="waves-effect btn-sm"
                                type="button"
                              >
                                Reset
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <ReactDatatable
                      config={this.config}
                      records={this.state.records}
                      columns={this.columns}
                      dynamic={true}
                      total_record={this.state.total_pages}
                      onChange={this.tableChangeHandler}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <ToastContainer />
            <Modal show={this.state.modal}>
              <Modal.Header>
                <Modal.Title>Proposal Public Link</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="url">
                  <a
                    href={`${this.state.proposal_link}`}
                    target="_blank"
                    className="pr-5 mr-5"
                  >
                    {this.state.proposal_link}
                  </a>

                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="overlay-example">
                        Copy url
                      </Tooltip>
                    }
                  >
                    <button
                      className="btn btn-link btn-md"
                      onClick={() => {
                        navigator.clipboard.writeText(`${this.state.proposal_link}`);
                      }}
                    >
                      <i className="fas fa-copy"></i>
                    </button>
                  </OverlayTrigger>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                className="btn-sm"
                  color="info"
                  onClick={() => this.setState({
                    modal: false,
                    proposal_link: ""
                  })}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
