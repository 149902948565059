import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Table,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  CardHeader,
  Container,
} from "reactstrap";

import Helmet from "react-helmet";

import { toast, ToastContainer, Flip } from "react-toastify";

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import moment from 'moment';
import { ApiUrl } from "../../config";
import { Dropdown, DropdownButton, FormControl, ListGroup, Modal } from "react-bootstrap";
import $ from "jquery";
import { AvField, AvForm } from "availity-reactstrap-validation";
import Loader from "react-loader";
import { dcrypt } from "../../useToken";

const MeetingCalander = (props) => {
  const [page_name] = useState("Meet");
  const [calendar_id] = useState(props.match.params.calendar_id);
  const [meeting_id] = useState(props.match.params.meeting_id);

  const [info, setInfo] = useState({});
  const [hiddenDays, setHiddenDays] = useState([]);
  const [pickDate, setPickDate] = useState(moment().format('YYYY-MM-DD'));

  const [slots, setSlots] = useState([]);
  const [modal, setModal] = useState(true);
  const calendarRef = React.createRef()

  const [step, setStep] = useState('1');
  const [slot, setSlot] = useState('');

  const [form, setForm] = useState('');
  const [loaded, setLoaded] = useState(true);
  const handleDateClick = (arg) => { // bind with an arrow function
    // alert(arg.dateStr)
  }


  useEffect(() => {
    getPickDateData();
    setSlot("");
  }, [pickDate]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    fetch(`${ApiUrl}` + "get/meeting/info", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ calendar_id: dcrypt(calendar_id), meeting_id: dcrypt(meeting_id), date: pickDate }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setInfo(response.data.record);
          setPickDate(response.data.record.meeting_date);

          $('[data-date=' + moment(response.data.record.meeting_date).format('YYYY-MM-DD') + ']').addClass('selectedDate');
          $('[data-date=' + moment(response.data.record.meeting_date).format('YYYY-MM-DD') + ']').addClass('selectedDate');
          $('[data-date=' + moment(response.data.record.meeting_date).format('YYYY-MM-DD') + ']').children().children().addClass('childselectedDate');

        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getPickDateData = () => {
    fetch(`${ApiUrl}` + "get/meeting/info", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ calendar_id: dcrypt(calendar_id), meeting_id: dcrypt(meeting_id), date: pickDate }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setHiddenDays(response.data.off_days);
          setSlots(response.data.slots);
          setForm(response.data.schedule_form);
          setPickDate(response.data.record.meeting_date);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSubmit = async () => {
    setLoaded(false);
    let values = {};
    values.calendar_id = dcrypt(calendar_id);
    values.meeting_id = dcrypt(meeting_id);
    values.pick_date = pickDate;
    values.from_time = slot;
    fetch(`${ApiUrl}` + "update/meeting/info", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls' });
          getData();
          setLoaded(true);
        } else {
          setLoaded(true);
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (

    <React.Fragment>
      <Helmet>
        <title>{page_name}</title>
      </Helmet>
      <Container className="cal-custom custom-calander mb-4">

        <ToastContainer />
        <Loader loaded={loaded}></Loader>

        <Row className="calander-meetings">
        <div className="col-lg-10 offset-lg-1">
          

          <div className="CustomShadow">
              
              <div className="row ms-0">
          <Col md={6} className="px-0">
          <div className="MeetingCalendar">
                  <div className="TimeDuration">
                    <p className="mb-0 MeetingUser"><i class="far fa-calendar-alt"></i><b>Existing Date:</b> {info.server_date_meeting}</p>
                    <p className="mb-0 MeetingUser"><i class="far fa-clock"></i><b>Existing Slot:</b> {info.book_slot} - {info.duration} minutes</p>
                   
                    {/* <p className="mb-0 MeetingUser"><i class="far fa-clock"></i> </p> */}

                  </div>
            {
              info.meeting_date && (
                <FullCalendar
                  plugins={[dayGridPlugin, interactionPlugin]}
                  initialView="dayGridMonth"
                  initialDate={pickDate}
                  ref={calendarRef}
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: ""
                  }}
                  selectable={true}
                  validRange={{
                    // start: moment().format("YYYY-MM-DD"),
                  }}
                  // initialDate={new Date('2022-06-23')}
                  dateClick={function (info) {
                    var now = moment(new Date()); // today's date
                    var end = moment(info.dateStr); // clicked date
                    var duration = moment.duration(now.diff(end));
                    var days = duration.asDays();
                  
                    if (days <= 0) {
                      if (!hiddenDays.includes(moment(info.dateStr).format('d'))) {
                        // Remove `selectedDate` and `childselectedDate` from all days
                        $('.fc-daygrid-day').removeClass('selectedDate');
                        $('.fc-daygrid-day').find('.childselectedDate').removeClass('childselectedDate');
                  
                        // Add `selectedDate` and `childselectedDate` to the clicked date
                        $('[data-date=' + info.dateStr + ']').addClass('selectedDate');
                        $('[data-date=' + info.dateStr + ']')
                          .children()
                          .children()
                          .addClass('childselectedDate');
                  
                        setPickDate(info.dateStr);
                        return true;
                      } else {
                        return false;
                      }
                    } else if (days > 0 && days < 1) {
                      $('.fc-daygrid-day').removeClass('selectedDate');
                      $('.fc-daygrid-day').find('.childselectedDate').removeClass('childselectedDate');
                  
                      $('[data-date=' + moment().format('YYYY-MM-DD') + ']').addClass('selectedDate');
                      setPickDate(info.dateStr);
                    } else {
                      info.dayEl.click.disabled = true;
                      return false;
                    }
                  }}

                  selectAllow={function (select) {
                    if (hiddenDays.includes(moment(select.start).format('d'))) {
                      return false
                    }
                    else {
                      return true
                    }
                    // return moment(info.startStr).add(1, 'days').format('YYYY-MM-DD') == moment(info.endStr).format('YYYY-MM-DD');
                  }}


                  // hiddenDays={hiddenDays}

                  select={
                    function (info) {

                    }
                  }

                // unselect={function () {
                //   $('#submitButton').unbind();
                // }}
                />
              )
            }
            </div>
          </Col>
          <Col md={6} className="ps-0">
          <div className="RightSidebar border-left">
            <h5>Available times for {moment(pickDate).format('ll')}</h5>
            {
              slots.length < 1 && (
                <h6>No available times</h6>
              )
            }

            <ListGroup horizontal className="slot-list">
              {
                slots.map((row, i) => {
                  return <ListGroup.Item onClick={() => { setStep('2'); setSlot(row); }} className={`list-group-item ${slot == row ? 'active' : ''}`} key={i}>{row}</ListGroup.Item>
                })
              }
            </ListGroup>

            </div>
          </Col>
          </div>
          <Row className="mx-0">
            <Col lg={6} className="py-2 border-top px-3 d-flex align-items-center">
              <p><b>Time zone:</b> {info.time_zone}</p>
            </Col>
            <Col lg={6} className="py-2 border-top px-3 d-flex justify-content-center">
              {
                slot && (
                  <button onClick={() => handleSubmit()} className="btn btn-info w-75">Update Appointment</button>
                )
              }
          </Col>
          </Row>
          </div>
          </div>
        </Row>

      </Container>
    </React.Fragment>
  );
};

export default MeetingCalander;
