import React, { useState, useCallback, useEffect, useRef } from 'react';
import { toast, ToastContainer, Flip } from "react-toastify";
import { ApiUrl } from '../../../config';
import { uInfo, uToken, truncateString, dcrypt } from '../../../useToken';
import $ from "jquery";
import Modal from 'react-bootstrap/Modal'
import SweetAlert from "react-bootstrap-sweetalert"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { Button, ButtonToolbar, Overlay, Tooltip } from 'react-bootstrap';
import "./updatenode.css";
import { bcrypt } from '../../../useToken';
import { Link } from 'react-router-dom';
import Froala from "../../Froala";
import Loader from "react-loader";
const arrowsPlus = require('../../../assets/images/arrows_plus.png');

const InteractionFlow = (props) => {

    const [chart, setChart] = useState(`<li className="plus_outer startNow"></li>`);
    const [record, setRecord] = useState([]);
    const [show, setShow] = useState(false);
    const [getTypeContent, setTypeContent] = useState("");
    const [alert, setAlert] = useState(false);
    const [msg, setMsg] = useState("");
    const [menu, setMenu] = useState("");
    const [getProcessed, setProcessed] = useState([]);

    const [childid, setChildid] = useState(0);
    const [nextId, setNextId] = useState(0);
    const [nodeId, setNodeId] = useState(0);
    const [isOk, setIsOk] = useState('no');
    const [g_cond, setGCond] = useState('');
    const [campaignId] = useState(props.match.params ? dcrypt(props.match.params.id) : 0);
    const [group_id] = useState(props.match.params ? dcrypt(props.match.params.group_id) : 0);
    const [automationId] = useState(props.match.params ? dcrypt(props.match.params.flow_id) : 0);
    const [metaData, setMetaData] = useState({
        currentAutomations: {}

    });

    const [loading, setLoading] = useState(true);
    const [editModal, setEditModal] = useState(false);
    const [emalil_template_selected, setEmailTemaplateSelected] = useState('');

    const [verifiedFromEmail, setVerfiedFromEmail] = useState([]);
    const [verifiedFromEmailId, setVerfiedFromEmailId] = useState("");
    const [verifiedFromName, setVerfiedFromName] = useState("");

    const [formFieldData, setFormFieldData] = useState({
        email_subject: '',
        email_body: '',
        sms_body: '',
        notify_email_subject: '',
        notify_email_body: '',
        notify_sms_body: '',
        notify_email: 'off',
        notify_sms: 'off',
        email_token: '',
        sms_token: '',
        from_email_id:'',
        notify_email_token: '',
        notify_sms_token: '',
    });
    const [seletedTemplate, setSeletedTemplate] = useState('');

    const [html, setHtml] = useState("");
    const [path, setPath] = useState("");

    const [showP, setShowP] = useState({});
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const handleClick = (event, key) => {
        setShowP({ ...showP, [key]: true });
        // setShowP(false);
        // setShowP(true);
        setTarget(event.target);
    };

    const form = useRef(null);

    const overlayShow = useRef(null);

    useEffect(() => {
        getSeries();
        getMetaData();
        
        var outerContent = $('.house-tree');
        var innerContent = $('.tree-point');
        outerContent.scrollLeft((innerContent.width() - outerContent.width()) / 2);
    }, []);


    const getMetaData = () => {
        fetch(`${ApiUrl}` + "get/automation/meta/data", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ 'automation_id': automationId })
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    var tokens_group = [
                        {
                            label: "Default Field",
                            options: response.data.tokens.default_fields,
                        },
                        {
                            label: "Custom Field",
                            options: response.data.tokens.custom_fields,
                        },
                    ];
                    let meta = response.data;
                    meta.tokens_group = tokens_group;
                    setMetaData(meta)
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

   


    const renderHtml = (data) => {

        if (data.records.length > 0) {

            let obj = Object.values(data.bTree);
            // console.log(obj);
            return obj.map(($treeArray, $parentNode) => {
                //let idx = $treeArray.indexOf(); 
                //console.log($treeArray, 'is here');
                let $nodeId = $treeArray['0'];
                let $childid = $treeArray['1'];
                //let $parentNode = $treeArray['2'];
                //console.log($parentNode, 'parentNode');
                let $nxtId = 0;
                if ($childid != 0) {
                    $nxtId = $nodeId;
                }

                if ($parentNode == 0) {
                    return (
                        <li className="plus_outer startNow" key={$parentNode}>
                            <ul>
                                <li>
                                    {addChildHtml($nodeId, $childid)}
                                </li>

                            </ul>
                        </li>
                    )
                }
            });
        } else {
            return <li className="plus_outer startNow">
                <a className="plus" onClick={(e) => chooseAction(0, 0, e)}>
                    <img src={arrowsPlus} />
                </a>
            </li>;
        }


    }


    function notIn($type) {
        let $actionArr = ['end', 'goto', 'start'];
        if ($actionArr.includes($type) === false) {
            return true;
        } else {
            return false;
        }
    }

    // const UpdatingPopover = React.forwardRef(
    //     // useEffect(() => {
    //     //     console.log('updating!');
    //     //     popper.scheduleUpdate();
    //     // }, [children, popper]);


    //     <Popover>
    //     </Popover>

    // );

    const addChildHtml = ($nodeId, $childid) => {


        let $dataArray = record.dataArray;

        let $childData = $dataArray[$nodeId];
        let $series = JSON.parse($childData.seriesData);
        let $returnHtml = '<p>A</p>';

        if ($childData.length == 0) {
            return $returnHtml;
        }

        $series.p_series_id = $dataArray[$nodeId].series_id;
        $series.html = $dataArray[$nodeId].html;



        // if (getProcessed.includes($nodeId)) {
        //     return $returnHtml;
        // }

        // setProcessed(oldArray => [...oldArray, $nodeId]);


        let $childNotes = $childData.notes != '' ? JSON.parse($childData.notes) : 0;



        return (
            <>
                <a href="javascript:;" className="delete"
                    onClick={() => deleteNode($nodeId, $childid, $childData.previous_id, $childData.type)}
                    title="Delete"><i className="fa fa-times" aria-hidden="true"></i>
                </a>

                <div style={{ position: 'relative' }}>
                    <div className='button-notes-view'>
                        <button variant='none' className='notes-list' onClick={(e) => handleClick(e, `key_${$nodeId}`)}><i className="fa fa-file" aria-hidden="true"></i></button>

                        <Overlay
                            show={showP[`key_${$nodeId}`]}
                            target={target}
                            placement="left"
                            rootClose={true}
                            onHide={() => setShowP({ ...showP, [`key_${$nodeId}`]: false })}
                        >
                            <Popover id="popover-contained">
                                <Popover.Header as="h3">Notes </Popover.Header>
                                <Popover.Body style={{ padding: "0.5rem 0.75rem 0" }}>
                                    <div className="add_note_drapdown_body">
                                        <div className={`col-md-12 show-notes nkt-${$nodeId}`}>
                                            {
                                                $childNotes.length > 0 && (
                                                    $childNotes.map(($note, i) => {
                                                        return <div className={`notes notee-${i}`}>
                                                            <div className="notes-text">{$note}</div>
                                                            <span className="notes-delete fas fa-trash cursor-pointer" title='Delete a note' href="javascript:;" onClick={() => deleteNote($nodeId, i)}></span>
                                                        </div>
                                                    })
                                                )
                                            }


                                        </div>
                                        <textarea className={`form-control text-note-${$nodeId}`} placeholder="Type note here ..."></textarea>
                                        <div className="add_note_drapdown_button">
                                            <button onClick={() => setShowP({ ...showP, [`key_${$nodeId}`]: false })} className="btn btn-info mr-5">Cancel</button>
                                            <button onClick={(e) => addNote($nodeId, e)} className="btn btn-primary">Add</button>
                                        </div>
                                    </div>
                                </Popover.Body>
                            </Popover>
                        </Overlay>
                    </div>
                </div>
                {
                    $childData.type == 'sms' ?
                        <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'sms', 'sms')}>
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip id="button-tooltip">
                                        FollowUpId # {$series.p_series_id}
                                    </Tooltip>
                                }
                            >
                                <div className="sb_button_inner btn-primary sb_editable"
                                    childid={`${$nodeId}`}>
                                    <i className="fa fa-mobile" aria-hidden="true"></i> Send SMS ( {truncateString($series.sms_body, 30)} )
                                </div>
                            </OverlayTrigger>
                        </div>
                        :
                        $childData.type == 'voicemail' ?
                            <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'voicemail', 'voicemail')}>
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip id="button-tooltip">
                                            FollowUpId # {$series.p_series_id}
                                        </Tooltip>
                                    }
                                >

                                    <div className="sb_button_inner btn-primary sb_editable"
                                        childid={`${$nodeId}`}>
                                        <i className="fa fa-phone" aria-hidden="true"></i> RvM
                                    </div>
                                </OverlayTrigger>
                            </div>
                            : $childData.type == 'webhook' ?
                                <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'webhook', 'webhook')}>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip id="button-tooltip">
                                                FollowUpId # {$series.p_series_id}
                                            </Tooltip>
                                        }
                                    >

                                        <div className="sb_button_inner btn-primary sb_editable"
                                            childid={`${$nodeId}`}>
                                            <i className="fa fa-link" aria-hidden="true"></i> webhook ( {truncateString($series.webhook_url, 30)} )
                                        </div>
                                    </OverlayTrigger>
                                </div>
                                :
                                $childData.type == 'email' ?
                                    <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'email', 'email')} >
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip id="button-tooltip">
                                                    FollowUpId # {$series.p_series_id}
                                                </Tooltip>
                                            }
                                        >

                                            <div className="sb_button_inner btn-primary sb_editable"
                                                childid={`${$nodeId}`}>
                                                <i className="fa fa-envelope" aria-hidden="true"></i> Email
                                            </div>
                                        </OverlayTrigger>
                                    </div>
                                    :
                                    $childData.type == 'notify' ?
                                        <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'notify', 'notify')}>
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                    <Tooltip id="button-tooltip">
                                                        FollowUpId # {$series.p_series_id}
                                                    </Tooltip>
                                                }
                                            >

                                                <div className="sb_button_inner btn-primary sb_editable"
                                                    childid={`${$nodeId}`}>
                                                    <i className="fa fa-envelope" aria-hidden="true"></i> Notify ({$series.notify})
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                        : $childData.type == 'start' ?
                                            <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'start', 'start')}>
                                                <OverlayTrigger
                                                    placement="right"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={
                                                        <Tooltip id="button-tooltip">
                                                            FollowUpId # {$series.p_series_id}
                                                        </Tooltip>
                                                    }
                                                >

                                                    <div className="sb_button_inner btn-primary sb_editable"
                                                        childid={`${$nodeId}`}>
                                                        <i className="fas fa-sync" aria-hidden="true"></i> Start Automation ( {truncateString($series.title, 30)} )
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                            : $childData.type == 'end' ?
                                                // onClick={() => dynamicSetting(true, $series, 'end', 'end')}
                                                <div className="sb_button" >
                                                    <OverlayTrigger
                                                        placement="right"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={
                                                            <Tooltip id="button-tooltip">
                                                                FollowUpId # {$series.p_series_id}
                                                            </Tooltip>
                                                        }
                                                    >

                                                        <div className="sb_button_inner btn-primary sb_editable"
                                                            childid={`${$nodeId}`}>
                                                            <i className="fas fa-sync" aria-hidden="true"></i> End Automation
                                                        </div>
                                                    </OverlayTrigger>
                                                </div>
                                                : $childData.type == 'condition' ?
                                                    <></>
                                                    // <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'condition', 'condition')}>
                                                    //     <div className="sb_button_inner btn-primary sb_editable"
                                                    //         childid={`${$nodeId}`}>
                                                    //         <i className="fas fa-sync" aria-hidden="true"></i> Condition ({$series.title})
                                                    //     </div>
                                                    // </div>

                                                    : "h"

                }



                {
                    $childData.type == 'condition' ? (
                        conditionHtml($childData, $dataArray, $nodeId, $childid, $childNotes, $series)
                    ) : conditionHtml($childData, $dataArray, $nodeId, $childid, $childNotes, $series)
                }


            </>
        );



    }


    function addNote($nodeId, obj) {
        var note = $.trim($('.text-note-' + $nodeId).val());
        if (note != '') {
            fetch(`${ApiUrl}` + "add/note/series/" + $nodeId, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + uToken(),
                },
                body: JSON.stringify({ note: note })
            })
                .then((response) => response.json())
                //Then with the data from the response in JSON...
                .then((response) => {
                    if (response.status === true) {
                        $('.text-note-' + $nodeId).val(' ');
                        getSeries();
                    } else {
                        toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                    }
                })
                //Then with the error genereted...
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    }

    function conditionHtml($childData, $dataArray, $nodeId, $childid, $childNotes, $series) {
        if ($childData.type == 'condition') {
            let $subChild = $dataArray[$childid] ? $dataArray[$childid] : {};
            let $preChild = [];
            if ($childData.pre_next_id != 0 && $childData.pre_next_id != $childid) {
                $preChild = $dataArray[$childData.pre_next_id];
            }

            let $trueChildId = 0;
            let $falseChildId = 0;

            if ($subChild.condition_status == 'true' && $subChild.series_id == $childid) {
                $trueChildId = $childid;
            }
            if ($subChild.condition_status == 'true' && $subChild.series_id == $childData.pre_next_id) {
                $trueChildId = $childData.pre_next_id;
            }
            if ($preChild.condition_status == 'true' && $preChild.series_id == $childid) {
                $trueChildId = $preChild.series_id;
            }
            if ($preChild.condition_status == 'true' && $preChild.series_id == $childData.pre_next_id) {
                $trueChildId = $childData.pre_next_id;
            }
            if ($subChild.condition_status == 'false' && $subChild.series_id == $childid) {
                $falseChildId = $childid;
            }
            if ($subChild.condition_status == 'false' && $subChild.series_id == $childData.pre_next_id) {
                $falseChildId = $childData.pre_next_id;
            }
            if ($preChild.condition_status == 'false' && $preChild.series_id == $childid) {
                $falseChildId = $preChild.series_id;
            }
            if ($preChild.condition_status == 'false' && $preChild.series_id == $childData.pre_next_id) {
                $falseChildId = $childData.pre_next_id;
            }

            return (
                <>
                    <div className="sb_button conditional" onClick={() => dynamicSetting(true, $series, 'condition', 'condition')}>
                        <div className="sb_button_inner btn-primary sb_editable"
                            childid={`${$nodeId}`}>
                            <i className="fas fa-sync" aria-hidden="true"></i> Condition ({$series.condition_title})
                        </div>
                    </div>

                    <ul className='tree-condition'>
                        <li>
                            <ul className='tree-condition-ul'>
                                <li className='tree-condition-li-yes'>
                                    <ul>
                                        <li className="plus_outer yes-outer">
                                            <a className="sb_button_inner condition_yes " childid="end">Yes</a>
                                            <br />
                                            <a className="plus" onClick={(e) => chooseAction($trueChildId, $nodeId, e, 'yes')}><img src="https://hotprospector.com/glu/assests/images/arrows_plus.png" /></a>
                                            {/* $returnHtml .= $this->getChild($nodeId, $trueChildId); */}
                                            {getChild($nodeId, $trueChildId)}
                                        </li>
                                    </ul>
                                </li>
                                <li className='tree-condition-li-no'>
                                    <ul>
                                        <li className="plus_outer">
                                            <a className="sb_button_inner condition_no" id={`yes_${$nodeId}`} childid="end">No</a>
                                            <br />
                                            <a className="plus" onClick={(e) => chooseAction($falseChildId, $nodeId, e, 'no')}><img src="https://hotprospector.com/glu/assests/images/arrows_plus.png" /></a>
                                            {getChild($nodeId, $falseChildId)}
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </>
            )


        }

        if (notIn($childData.type)) {
            return <ul>
                <li className="plus_outer">
                    <a className="plus" onClick={(e) => chooseAction($childid, $nodeId, e)}>
                        <img src="https://hotprospector.com/glu/assests/images/arrows_plus.png" /></a>
                    {getChild($nodeId, $childid)}
                </li>
            </ul>
        }
    }

    function getChild($parentNode, $nodeId) {
        let $dataArray = record.dataArray;

        if ($nodeId != 0) {
            let $childData = $dataArray[$nodeId];
            let $childid = $childData.next_id;
            return (
                <ul>
                    <li>
                        {addChildHtml($nodeId, $childid)}
                    </li>
                </ul>
            )
        }
    }


    const dynamicSetting = (modal, data, type, contetType) => {

        console.log(data, 'sdassss')
        if (data.html) {
            setHtml(data.html);
        } else {
            setHtml('');
        }

        if (data.condition) {
            data.condition_id = data.condition;
        }

        data.email_token = '';
        data.sms_token = '';
        data.notify_email_token = '';
        data.notify_sms_token = '';
        setShow(modal);
        setFormFieldData(data);
        setMenu(type);
        setTypeContent(contetType);
        setEditModal(true);
    }

    const dynamicModal = (type) => {
        setMenu(type);
        setTypeContent(type);
    }

    const modalHide = () => {
        setShow(false);
        setFormFieldData({
            email_subject: '',
            email_body: '',
            sms_body: '',
            notify_email_subject: '',
            notify_email_body: '',
            notify_sms_body: '',
            notify_email: 'off',
            notify_sms: 'off',
            email_token: '',
            sms_token: '',
            notify_email_token: '',
            notify_sms_token: '',
        });
        setMenu('');
        setTypeContent('');
        setEditModal(false);
        setSeletedTemplate('');
        setHtml('');
        setPath('');
    }

    const deleteNode = ($nodeId, $childid, $previous_id, $type) => {
        fetch(`${ApiUrl}` + "delete/automations/series/" + automationId, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ nodeId: $nodeId, childId: $childid, previousId: $previous_id, type: $type })
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    getSeries();
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const deleteNote = ($nodeId, $noteIndex) => {
        fetch(`${ApiUrl}` + "delete/note/series/" + $nodeId, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ index: $noteIndex })
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    getSeries();
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }




    const getSeries = () => {
        fetch(`${ApiUrl}` + "automations/series/" + automationId, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    //console.log(response.data, 'getSeries');
                    setRecord(response.data)
                    // renderHtml(response.data.records);
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }


    const chooseAction = (nId, cId, obj, cond = '') => {
        $('.btn-group-vertical .btn.green').removeClass('active');
        $('.action-description').html('<h4 style="text-align:center;">Select Action From Menu</h4>');
        // getTypeContent('type');
        $('#action-detail').removeClass('col-md-12');
        $('#action-detail').addClass('col-md-9');
        $('#action-nav').show();
        // $('.modal-title').html('Add New Action');
        setShow(true);
        setChildid(0);
        setNextId(nId);
        setNodeId(cId);
        setIsOk('no');
        setGCond(cond);

    }


    const saveAction = (type, email_body = '') => {
        if (type == 'sms') {
            if ($('textarea[name=sms_body]').val() == '') {
                setMsg('Please enter sms body!');
                setAlert(true);
                return false;
            }
        }
        if (type == 'voicemail') {
            if ($('select[name=rvm_template]').val() == '') {
                setMsg('Please select voicemail!');
                setAlert(true);
                return false;
            }
        }

        if (type == 'webhook') {
            if ($('input[name=webhook_url]').val() == '') {
                setMsg('Please enter webhook_url!');
                setAlert(true);
                return false;
            }
        }

        if (type == 'email') {
            if ($('input[name=email_subject]').val() == '') {
                setMsg('Please enter email subject!');
                setAlert(true);
                return false;
            }

            if (($('textarea[name=email_body]').val() == ''  || email_body == '') && (emalil_template_selected ==  undefined || emalil_template_selected == '' || emalil_template_selected == 0)) {
                setMsg('Please enter email body!');
                setAlert(true);
                return false;
            }
        }

        if (type == 'start') {
            if ($('select[name=s_automation_id]').val() == '') {
                setMsg('Please select automation!');
                setAlert(true);
                return false;
            }

        }


        if (type == 'notify') {

            if ($("input[name='notify_email']:checked").val() === undefined && $("input[name='notify_sms']:checked").val() === undefined) {
                setMsg('Please select notify type!');
                setAlert(true);
                return false;
            }


            if ($("input[name='notify_email']:checked").val() == 'on') {

                if ($('input[name=notification_email]').val() == '') {
                    setMsg('Please enter email!');
                    setAlert(true);
                    return false;
                }

                if ($('input[name=notify_email_subject]').val() == '') {
                    setMsg('Please enter email subject!');
                    setAlert(true);
                    return false;
                }

                if ($('textarea[name=notify_email_body]').val() == '') {
                    setMsg('Please enter email body!');
                    setAlert(true);
                    return false;
                }

            }

            if ($("input[name='notify_sms']:checked").val() == 'on') {

                if ($('input[name=notification_number]').val() == '') {
                    setMsg('Please enter number!');
                    setAlert(true);
                    return false;
                }



                if ($('textarea[name=notify_sms_body]').val() == '') {
                    setMsg('Please enter sms body!');
                    setAlert(true);
                    return false;
                }

            }
        }

        if (type == 'condition') {
            if ($('select[name=condition]').val() == '0') {
                setMsg('Please select condition!');
                setAlert(true);
                return false;
            }
        }




        var inserted_templateId = 0;
        var sms_inserted_templateId = 0;
        var email_inserted_templateId = 0;
        let data = {
            campaignId: campaignId,
            childId: childid,
            nextId: nextId,
            inserted_templateId: inserted_templateId,
            sms_inserted_templateId: sms_inserted_templateId,
            email_inserted_templateId: email_inserted_templateId,
            nodeId: nodeId,
            group_id: group_id,
            automationId: automationId,
            cond: g_cond,
            isOk: isOk,
            type: type,
            email_body: email_body,
        }
        setLoading(false);

        fetch(`${ApiUrl}` + "save/automation/action?" + $('.' + type + '-form').serialize(), {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {
                setLoading(true);
                if (response.status === true) {

                    getSeries();
                    setShow(false);
                    modalHide();

                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                setLoading(true);
                console.error("Error:", error);
            });
    }

    const handleEmailBody = async (event, values) => {
        setLoading(false);
        saveAction('email',values)
       
      }
    const handleChange = (e) => {
        // console.log(e.target)
        const { name, value } = e.target;

        setFormFieldData({ ...formFieldData, [name]: value });

        if (name == 'sms_template') {
            setFormFieldData({ ...formFieldData, ['sms_body']: value });
        }

        if (name == 'email_template' && value != '') {
            setFormFieldData({ ...formFieldData, ['email_subject']: e.target.selectedOptions[0].getAttribute("data-title") });
            getEmailTemplateHtml(value);
        }

        if (name == 's_automation_id' && value != '') {
            setFormFieldData({ ...formFieldData, ['title']: e.target.selectedOptions[0].getAttribute("data-title") });
        }
        
        if (name == 'from_email_id' && value != ''){
            setFormFieldData({...formFieldData, ['from_email_id']:value});
        }


        if (name == 'email_template' && value == '') {
            setFormFieldData({ ...formFieldData, ['email_subject']: '' });
            setHtml('');
            setPath('');
        }

        if (name == 'condition' && value != '') {
            setFormFieldData({ ...formFieldData, ['condition_id']: e.target.selectedOptions[0].getAttribute("data-id") });
            $('#condition_title').val(e.target.selectedOptions[0].getAttribute("data-title"));
            // setFormFieldData({ ...formFieldData, ['condition_title']: e.target.selectedOptions[0].getAttribute("data-title") });

        }


        // if (name == 'e_automation_id' && value != '') {
        //     let automation = metaData.getAutomations.filter(x => x.automation_id == value);
        //     // $('#e_automation').val(automation[0].title);
        //     setFormFieldData({ ...formFieldData, ['title']: automation.title });
        // }

        if (name === 'sms_template' || name === 'email_template' || name === 'rvm_template') {
            setSeletedTemplate(e.target.selectedOptions[0].getAttribute("data-id"));

        }



    }


    const getEmailTemplateHtml = (id) => {
        fetch(`${ApiUrl}` + "get/html/" + id + "/" + uInfo().user_id, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                setHtml(response.theme);
                setPath(response.path);
                setEmailTemaplateSelected(id);

            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    return (
        <React.Fragment>
            <Loader loaded={loading}></Loader>
            <ToastContainer />
            <div className='mb-4'>

                <div className='row'>
                    <div className='col-sm-5' sm={5}>
                        <div className="page-title-box">
                            <h4>
                                {metaData.currentAutomations.title}
                            </h4>
                        </div>
                    </div>
                    <div className='col-sm-3' sm={3}></div>
                    <div className='col-sm-4'>
                        <div className="page-title-box text-align-right">
                            <button className='btn btn-secondary btn-sm'
                                onClick={() => props.history.goBack()}
                            >
                                Back
                            </button>
                        </div>
                    </div>
                </div>


                {alert ? (
                    <SweetAlert
                        title={msg}
                        warning
                        onConfirm={() => setAlert(false)}
                    />
                ) : null}


                <div className='card card-body house-tree custom-scroll' >


                    <div className='event-tree'>
                        <h5>{metaData.currentAutomations.event_type}</h5>
                    </div>
                    <ul className="tree-point pb-4">
                        {record.html == '' ? renderHtml(record) : ""}
                    </ul>

                </div>

                <Modal
                    show={show}
                    onHide={() => modalHide()}
                    dialogClassName="modal-90w"
                    size="lg"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {editModal ? 'Edit Action' : 'Add Action'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='row'>
                        <div className={`${editModal ? 'd-none' : 'col-md-3 col-sm-3 col-xs-12'}`} id="action-nav">
                            <div className={`btn-group-vertical left_vertical_tabs  ${editModal ? 'd-none' : ''}`}>

                                <div className="btn-group">
                                    <button type="button" className={`btn green dropdown-toggle ${menu == 'email' ? 'active' : ''}`} onClick={() => dynamicModal('email')} data-toggle="dropdown">
                                        <i className="fas fa-envelope"></i> Send Email
                                    </button>
                                </div>

                                <div className="btn-group">
                                    <button type="button" className={`btn green dropdown-toggle ${menu == 'sms' ? 'active' : ''}`} onClick={() => dynamicModal('sms')} data-toggle="dropdown">
                                        <i className="fas fa-mobile"></i> Send SMS
                                    </button>
                                </div>

                                <div className="btn-group">
                                    <button type="button" className={`btn green dropdown-toggle ${menu == 'voicemail' ? 'active' : ''}`} onClick={() => dynamicModal('voicemail')} data-toggle="dropdown">
                                        <i className="fas fa-phone"></i> Voicemail
                                    </button>
                                </div>

                                <div className="btn-group">
                                    <button type="button" className={`btn green dropdown-toggle ${menu == 'webhook' ? 'active' : ''}`} onClick={() => dynamicModal('webhook')} data-toggle="dropdown">
                                        <i className="fas fa-link"></i> Webhook
                                    </button>
                                </div>

                                <div className="btn-group">
                                    <button type="button" className={`btn green dropdown-toggle ${menu == 'condition' ? 'active' : ''}`} onClick={() => dynamicModal('condition')} data-toggle="dropdown">
                                        <i className="fas fa-link"></i>  Condition (if / else)
                                    </button>
                                </div>

                                <div className="btn-group">
                                    <button type="button" className={`btn green dropdown-toggle ${menu == 'notify' ? 'active' : ''}`} onClick={() => dynamicModal('notify')} data-toggle="dropdown">
                                        <i className="fas fa-bell"></i>  Notify Someone
                                    </button>
                                </div>

                                <div className="btn-group">
                                    <button type="button" className={`btn green dropdown-toggle ${menu == 'start' ? 'active' : ''}`} onClick={() => dynamicModal('start')} data-toggle="dropdown">
                                        <i className="fas fa-sync"></i> Start Automation
                                    </button>
                                </div>

                                <div className="btn-group">
                                    <button type="button" className={`btn green dropdown-toggle ${menu == 'end' ? 'active' : ''}`} onClick={() => dynamicModal('end')} data-toggle="dropdown">
                                        <i className="fas fa-sync"></i>   End Automation
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className={`${editModal ? 'col-md-12 col-sm-12 col-xs-12' : 'col-md-9 col-sm-9 col-xs-12'}`}>
                            {
                                getTypeContent == 'sms' ?
                                    <div className="sms-content">
                                        <form ref={form} className="sms-form" method="post">
                                            <div className="form-group mb-3">
                                                <label>SMS Template</label>
                                                <div className="input-group">
                                                    <select defaultValue={formFieldData.sms_template} onChange={(e) => handleChange(e)} name="sms_template" className='form-select' >
                                                        <option value={''}>Select</option>
                                                        {
                                                            metaData.smsTemplates.map((sms, s) => {
                                                                return <option data-id={sms.id} key={s} value={sms.body}>{sms.name}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <input readOnly type={'hidden'} name="template_id" value={seletedTemplate} />


                                            <div className="form-group mb-3">
                                                <label> SMS Token</label>
                                                <div className="input-group">
                                                    <select onChange={(e) => handleChange(e)} name="sms_token" className='form-select' >
                                                        <option value={''}>Select Token</option>
                                                        <optgroup label="Lead Default Field Token" >
                                                            {
                                                                metaData.tokens.default_fields.map((df, d) => {
                                                                    return <option key={d} value={`{${df.key}}`}>{df.label}</option>
                                                                })
                                                            }
                                                        </optgroup>
                                                        <optgroup label="Lead Custom Field Token" >
                                                            {
                                                                metaData.tokens.custom_fields.map((cf, c) => {
                                                                    return <option key={c} value={`{${cf.key}}`}>{cf.label}</option>
                                                                })
                                                            }
                                                        </optgroup>
                                                        <optgroup label="User Tokens" >
                                                            {
                                                                metaData.tokens.users_token.map((cf, c) => {
                                                                    return <option key={'custom' + c} value={`{{${cf.key}}}`}>{cf.label}</option>
                                                                })
                                                            }
                                                        </optgroup>
                                                    </select>

                                                    <button onClick={() => setFormFieldData({ ...formFieldData, ['sms_body']: formFieldData.sms_body + '  ' + formFieldData.sms_token })} type='button' className='btn btn-primary btn-md'>Insert Into Body</button>
                                                </div>
                                            </div>

                                            <div className="form-group mb-3">
                                                <label>SMS Body</label>
                                                <div className="input-group">
                                                <textarea placeholder='Add message body' rows="4" cols="50" value={formFieldData.sms_body} onChange={(e) => handleChange(e)} name="sms_body" className='form-control' />
                                                 
                                                </div>
                                            </div>
                                            <input type={'hidden'} name="series_id" value={formFieldData.p_series_id} />
                                            <div className="form-group">
                                                <a href="javascript:void(0)" className="btn btn-primary pull-right save-btn mt-2" onClick={() => saveAction('sms')}>Save</a>
                                            </div>
                                        </form>
                                    </div>
                                    :
                                    getTypeContent == 'voicemail' ?
                                        <div className="voicemail-content">
                                            <form ref={form} className="voicemail-form" method="post">
                                                <div className="form-group mb-3">
                                                    <label>RVM Template</label>
                                                    <div className="input-group">
                                                        <select defaultValue={formFieldData.rvm_template} onChange={(e) => handleChange(e)} name="rvm_template" className='form-select' >
                                                            <option value={''}>Select</option>
                                                            {
                                                                metaData.voiceTemplates.map((rvm, r) => {
                                                                    return <option data-id={rvm.id} key={r} value={rvm.body}>{rvm.name}</option>
                                                                })
                                                            }
                                                        </select>

                                                        <input readOnly type={'hidden'} name="template_id" value={seletedTemplate} />
                                                    </div>
                                                </div>
                                                <input type={'hidden'} name="series_id" value={formFieldData.p_series_id} />

                                                <div className="form-group">
                                                    <a href="javascript:void(0)" className="btn btn-primary pull-right save-btn mt-2" onClick={() => saveAction('voicemail')}>Save</a>
                                                </div>
                                            </form>
                                        </div>
                                        : getTypeContent == 'webhook' ?
                                            <div className="webhook-content">
                                                <form ref={form} className="webhook-form" method="post">
                                                    <div className="form-group">
                                                        <label>Webhook Url</label>
                                                        <div className="input-group">
                                                            <input type="text" defaultValue={formFieldData.webhook_url} name="webhook_url" className="form-control" placeholder="https://example.com/your-page" />
                                                        </div>
                                                    </div>
                                                    <input type={'hidden'} name="series_id" value={formFieldData.p_series_id} />
                                                    <div className="form-group">
                                                        <a href="javascript:void(0)" className="btn btn-primary pull-right save-btn mt-2" onClick={() => saveAction('webhook')}>Save</a>
                                                    </div>
                                                </form>
                                            </div>
                                            : getTypeContent == 'email' ?
                                                <div className="email-content">
                                                    <form ref={form} className="email-form" method="post">
                                                        <div className="form-group mb-3">
                                                            <label>Email Template</label>
                                                            <div className="input-group">
                                                                <select defaultValue={formFieldData.email_template} onChange={(e) => handleChange(e)} name="email_template" className='form-select' >
                                                                    <option value={''}>Select</option>
                                                                    {
                                                                        metaData.emailTemplates.map((email, e) => {
                                                                            return <option data-title={email.title} data-id={email.id} key={e} value={email.id}>{email.title}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="form-group mb-3">
                                                            <label> Email Token</label>
                                                            <div className="input-group">
                                                                <select onChange={(e) => handleChange(e)} name="email_token" className='form-select' >
                                                                    <option value={''}>Select Token</option>
                                                                    <optgroup label="Lead Default Field Tokens" >
                                                                        {
                                                                            metaData.tokens.default_fields.map((df, d) => {
                                                                                return <option key={d} value={`{${df.key}}`}>{df.label}</option>
                                                                            })
                                                                        }
                                                                    </optgroup>
                                                                    <optgroup label="Lead Custom Field Tokens" >
                                                                        {
                                                                            metaData.tokens.custom_fields.map((cf, c) => {
                                                                                return <option key={c} value={`{${cf.key}}`}>{cf.label}</option>
                                                                            })
                                                                        }
                                                                    </optgroup>
                                                                    <optgroup label="User Tokens" >
                                                                        {
                                                                            metaData.tokens.users_token.map((cf, c) => {
                                                                                return <option key={'custom' + c} value={`{{${cf.key}}}`}>{cf.label}</option>
                                                                            })
                                                                        }
                                                                    </optgroup>
                                                                </select>

                                                                <button
                                                                    onClick={() => setFormFieldData({ ...formFieldData, ['email_subject']: formFieldData.email_subject + '  ' + formFieldData.email_token })}
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                >
                                                                    Insert Subject
                                                                </button>
                                                                {/* {html == "" && (
                                                                    <button onClick={() => setFormFieldData({ ...formFieldData, ['email_body']: formFieldData.email_body + '  ' + formFieldData.email_token })} type='button' className='btn btn-primary btn-md'>Insert Into Body</button>
                                                                )} */}
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="form-group mb-3 d-none">
                                                        <label>Email From</label>
                                                        <div className="input-group">
                                                            <select defaultValue={formFieldData.from_email_id} onChange={(e) => handleChange(e)} name="from_email_id" className='form-select' >
                                                                <option value={''}>Select</option>
                                                                {
                                                                    metaData.FromEmail.map((email, e) => {
                                                                        return <option data-title={email.from_email} data-id={email.id} key={e} value={email.id}>{email.from_name} - {email.from_email}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                       </div>
                                                       
                                                    
                                                        <div className="form-group mb-3">
                                                            <label>Email Subject</label>
                                                            <div className="input-group">
                                                                <input value={formFieldData.email_subject} onChange={(e) => handleChange(e)} className='form-control' name="email_subject" />
                                                            </div>
                                                        </div>
                                                       

                                                        

                                                        {html == "" ? (
                                                            <div className="form-group mb-3">
                                                                <label>Email Body</label>
                                                                <div className="input-group">
                                                                    {/* <textarea value={formFieldData.email_body} onChange={(e) => handleChange(e)} name="email_body" className='form-control' /> */}
                                                                    <Froala btnId={'add-drip-email'}  content={formFieldData.email_body}  handleSubmit={handleEmailBody}  use_token={'users'} />
                                                                </div>
                                                            </div>
                                                        ) : (<>
                                                            <div
                                                                id="custom-template"
                                                                className="height-500 custom-scroll" style={{ Width: "100%" }}
                                                                dangerouslySetInnerHTML={{ __html: html }}
                                                            />
                                                            <input value={path} name="email_body" className='d-none' />
                                                        </>
                                                        )}
                                                        {/* <textarea readOnly value={JSON.stringify(html)} name="email_body" className='form-control d-none' /> */}
                                                        <input type={'hidden'} name="series_id" value={formFieldData.p_series_id} />
                                                        <div className="form-group">
                                                        {html == "" ? (
                                                            <button type='button' id='add-drip-email' className="btn btn-primary pull-right save-btn mt-2" >Save</button>
                                                        ) : (<>
                                                            <a href="javascript:void(0)" className="btn btn-primary pull-right save-btn mt-2" onClick={() => saveAction('email')}>Save</a>
                                                        </>
                                                        )}
                                                        </div>
                                                    </form>
                                                </div>
                                                : getTypeContent == 'notify' ?
                                                    <div className="notify-content">
                                                        <form ref={form} className="notify-form" method="post">

                                                            <div className="form-group">
                                                                <label className="control-label"> Notifications: </label>
                                                                <div className="input-group">
                                                                    <label className="checkbox-inline mr-10">
                                                                        <input type="checkbox" defaultChecked={formFieldData.notify_email == 'on' ? true : false} onChange={(e) => setFormFieldData({ ...formFieldData, ['notify_email']: e.target.checked ? 'on' : 'off' })} name="notify_email" />

                                                                        <span> Email (Team)</span>
                                                                    </label>
                                                                    <label className="checkbox-inline">
                                                                        <input type="checkbox" defaultChecked={formFieldData.notify_sms == 'on' ? true : false} onChange={(e) => setFormFieldData({ ...formFieldData, ['notify_sms']: e.target.checked ? 'on' : 'off' })} name="notify_sms" />

                                                                        <span> SMS (Team)</span>
                                                                    </label>
                                                                </div>
                                                            </div>

                                                            {formFieldData.notify_email == 'on' && (
                                                                <div className="notify-section p-3 mb-3">
                                                                    <div className="form-group mb-3">
                                                                        <label>Notification Email</label>
                                                                        <input className="form-control" value={formFieldData.notification_email} onChange={(e) => handleChange(e)} name="notification_email" placeholder="Add one/more notification email(s) separated by comma" type="text" />
                                                                    </div>
                                                                    <div className="form-group mb-3">
                                                                        <label> Email Token</label>
                                                                        <div className="input-group">
                                                                            <select onChange={(e) => handleChange(e)} name="notify_email_token" className='form-select' >
                                                                                <option value={''}>Select Token</option>
                                                                                <optgroup label="Lead Default Field Token" >
                                                                                    {
                                                                                        metaData.tokens.default_fields.map((df, d) => {
                                                                                            return <option key={d} value={`{${df.key}}`}>{df.label}</option>
                                                                                        })
                                                                                    }
                                                                                </optgroup>
                                                                                <optgroup label="Lead Custom Field Tokens" >
                                                                                    {
                                                                                        metaData.tokens.custom_fields.map((cf, c) => {
                                                                                            return <option key={c} value={`{${cf.key}}`}>{cf.label}</option>
                                                                                        })
                                                                                    }
                                                                                </optgroup>
                                                                                <optgroup label="User Tokens" >
                                                                                    {
                                                                                        metaData.tokens.users_token.map((cf, c) => {
                                                                                            return <option key={'custom' + c} value={`{{${cf.key}}}`}>{cf.label}</option>
                                                                                        })
                                                                                    }
                                                                                </optgroup>     
                                                                            </select>

                                                                            <button
                                                                                onClick={() => setFormFieldData({ ...formFieldData, ['notify_email_subject']: formFieldData.notify_email_subject + '  ' + formFieldData.notify_email_token })}
                                                                                type="button"
                                                                                className="btn btn-primary"
                                                                            >
                                                                                Insert Subject
                                                                            </button>

                                                                            <button onClick={() => setFormFieldData({ ...formFieldData, ['notify_email_body']: formFieldData.notify_email_body + '  ' + formFieldData.notify_email_token })} type='button' className='btn btn-primary btn-md'>Insert Into Body</button>

                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group mb-3">
                                                                        <label>Email Subject</label>
                                                                        <div className="input-group">
                                                                            <input value={formFieldData.notify_email_subject} onChange={(e) => handleChange(e)} className='form-control' name="notify_email_subject" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group mb-3">
                                                                        <label>Email Body</label>
                                                                        <div className="input-group">
                                                                            <textarea value={formFieldData.notify_email_body} onChange={(e) => handleChange(e)} name="notify_email_body" className='form-control' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {formFieldData.notify_sms == 'on' && (
                                                                <div className="notify-section p-3">
                                                                    <div className="form-group mb-3">
                                                                        <label>Notification Number</label>
                                                                        <input className="form-control" value={formFieldData.notification_number} onChange={(e) => handleChange(e)} name="notification_number" placeholder="Add one/more notification phone(s) with country code separated by comma" type="text" />
                                                                    </div>
                                                                    <div className="form-group mb-3">
                                                                        <label> Sms Token</label>
                                                                        <div className="input-group">
                                                                            <select onChange={(e) => handleChange(e)} name="notify_sms_token" className='form-select' >
                                                                                <option value={''}>Select Token</option>
                                                                                <optgroup label="Lead Default Field Token" >
                                                                                    {
                                                                                        metaData.tokens.default_fields.map((df, d) => {
                                                                                            return <option key={d} value={`{${df.key}}`}>{df.label}</option>
                                                                                        })
                                                                                    }
                                                                                </optgroup>
                                                                                <optgroup label="Lead Custom Field Token" >
                                                                                    {
                                                                                        metaData.tokens.custom_fields.map((cf, c) => {
                                                                                            return <option key={c} value={`{${cf.key}}`}>{cf.label}</option>
                                                                                        })
                                                                                    }
                                                                                </optgroup>
                                                                                <optgroup label="User Tokens" >
                                                                                    {
                                                                                        metaData.tokens.users_token.map((cf, c) => {
                                                                                            return <option key={'custom' + c} value={`{{${cf.key}}}`}>{cf.label}</option>
                                                                                        })
                                                                                    }
                                                                                </optgroup>
                                                                            </select>


                                                                            <button onClick={() => setFormFieldData({ ...formFieldData, ['notify_sms_body']: formFieldData.notify_sms_body + '  ' + formFieldData.notify_sms_token })} type='button' className='btn btn-primary btn-md'>Insert Into Body</button>

                                                                        </div>
                                                                    </div>

                                                                    <input type={'hidden'} name="series_id" value={formFieldData.p_series_id} />

                                                                    <div className="form-group mb-3">
                                                                        <label>SMS Text</label>
                                                                        <div className="input-group">
                                                                            <textarea value={formFieldData.notify_sms_body} onChange={(e) => handleChange(e)} name="notify_sms_body" className='form-control' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            <div className="form-group">
                                                                <a href="javascript:void(0)" className="btn btn-primary pull-right save-btn mt-2" onClick={() => saveAction('notify')}>Save</a>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    : getTypeContent == 'start' ?
                                                        <div className="start-content">
                                                            <form ref={form} className="start-form" method="post">
                                                                <label> Select Automation</label>
                                                                <div className="input-group">
                                                                    <select defaultValue={formFieldData.s_automation_id} onChange={(e) => handleChange(e)} name="s_automation_id" className='form-select' >
                                                                        <option value={''}>Select Automation</option>
                                                                        {
                                                                            metaData.getAutomations.map((row, a) => {
                                                                                return <option data-title={row.title} value={row.automation_id} key={a}>{row.title}</option>
                                                                            })
                                                                        }
                                                                    </select>
                                                                    <input type={'hidden'} defaultValue={formFieldData.title} name="title" id="s_automation" />


                                                                    <input type={'hidden'} name="series_id" value={formFieldData.p_series_id} />
                                                                </div>
                                                                <div className="form-group">
                                                                    <a href="javascript:void(0)" className="btn btn-primary pull-right save-btn mt-2" onClick={() => saveAction('start')}>Save</a>
                                                                </div>
                                                            </form>
                                                        </div>
                                                        : getTypeContent == 'end' ?
                                                            <div className="end-content">
                                                                <form ref={form} className="end-form" method="post">
                                                                    {/* <label> Select Automation</label> */}
                                                                    {/* <div className="input-group">
                                                                        <select defaultValue={formFieldData.e_automation_id} onChange={(e) => handleChange(e)} name="e_automation_id" className='form-control' >
                                                                            <option value={''}>Select Automation</option>
                                                                            {
                                                                                metaData.getAutomations.map((row, a) => {
                                                                                    return <option value={row.automation_id} key={a}>{row.title}</option>
                                                                                })
                                                                            }
                                                                        </select>
                                                                        <input type={'hidden'} value={formFieldData.title} name="title" id="e_automation" />

                                                                    </div> */}
                                                                    <input type={'hidden'} name="series_id" value={formFieldData.p_series_id} />
                                                                    <div className="form-group">
                                                                        <a href="javascript:void(0)" className="btn btn-primary pull-right save-btn mt-2" onClick={() => saveAction('end')}>Save</a>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            : getTypeContent == 'condition' ?
                                                                <div className="condition-content">
                                                                    <form ref={form} className="condition-form" method="post">
                                                                        <Link to={'/filter/add'} target={'_blank'} className='btn btn-info pull-right btn-sm mb-3'>Add Filter</Link>
                                                                        <label> Select Filter</label>
                                                                        <div className="input-group">
                                                                            <select defaultValue={formFieldData.condition} onChange={(e) => handleChange(e)} name="condition" className='form-select' >
                                                                                <option data-title={''} value={'0'}>Select Condition</option>
                                                                                {
                                                                                    metaData.SearchFilter.map((filter, e) => {
                                                                                        return <option data-title={filter.name} data-id={filter.id} key={e} value={filter.id}>{filter.name}</option>
                                                                                    })
                                                                                }

                                                                            </select>

                                                                            {
                                                                                formFieldData.condition_id && formFieldData.condition_id != '0' && (
                                                                                    <a style={{ lineHeight: '30px' }} target={'_blank'} href={`/filter/edit/${bcrypt(formFieldData.condition_id)}`} className='btn btn-sm btn-primary'>Manage Condition</a>
                                                                                )
                                                                            }
                                                                            <input id="condition_title" type={'hidden'} name="condition_title" />

                                                                            <input type={'hidden'} name="series_id" value={formFieldData.p_series_id} />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <a href="javascript:void(0)" className="btn btn-primary pull-right save-btn mt-2" onClick={() => saveAction('condition')}>Save</a>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                                : <></>
                            }
                        </div>
                    </Modal.Body>
                </Modal >
            </div>
        </React.Fragment >
    );
};

export default InteractionFlow;
