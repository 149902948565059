import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Modal
} from "reactstrap";

import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { Link } from "react-router-dom";
import Highcharts from "highcharts";

import { ApiUrl } from "../../config";
import { bcrypt, dcrypt, uInfo, uToken } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import Summary from "./summary.js";
import Results from "./results";

import Roi from "./roi.js";
import Implementation from "./implementation";
import "./style.css";
import $ from "jquery";
import Loader from "react-loader";
import { currencies } from "../../common/Common-Currency.js";
import Favicon from './../../assets/images/favicon.png';
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";


require("highcharts/modules/exporting")(Highcharts);

const Assesments = (props) => {
  const ref = useRef();
  const [params] = useState(props.match.params);

  const [sidebar, setSideBar] = useState([]);
  const [records, setRecords] = useState({ report: [] });
  const [dafault_summary, setDafaultSummary] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [assessment, setAssessment] = useState({ report: [], questions: [], reports: [] });
  const [currency, setCurrency] = useState("");
  const [results, setResults] = useState([]);
  const [assesment_list, setAssessmentList] = useState([]);
  const [report, setReport] = useState(false);
  const [siderbarShow, setSideBarShow] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [path_way_progression, setPathWayProgression] = useState([]);
  const [customer_conversion, setCustomerConversion] = useState({});
  const [PathwayProgressionData, setPathWayProgressionData] = useState({});

  const [current_module, setCurrentModule] = useState([]);

  const initMenu = () => {
    let pathName = window.location.pathname;
    if (pathName.split("/").pop() == 'result') {
      pathName = window.location.pathname.substring(0, pathName.lastIndexOf('/'));
    }
    new MetisMenu("#side-menu");
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        if (items[i].parentNode.parentElement.tagName == "LI") {
          items[i].parentNode.parentElement.firstChild.classList.add("active");
        }
        if (items[i].parentNode.parentElement.tagName == "DIV") {
          items[i].parentNode.parentElement.classList.add("d-block");
          items[i].parentNode.parentElement.parentElement.firstChild.classList.add("active");
        }
        items[i].parentNode.classList.add("d-block");
        items[i].classList.add("active");
        // break;
      } else {
        items[i].classList.remove("active");
      }
    }
  };

  React.useEffect(() => {
    initMenu();
    getAssessment();
    getResults();
  }, [props]);

  React.useEffect(() => {
    initMenu();
  }, [sidebar]);

  useEffect(() => {
    getModules();
    getAssessmentList();
    getPathwayProgression();
  }, []);

  const findSymbol = (code) => {
    let currency = currencies[code];
    if (currency) {
      setCurrency(currency.symbol);
    }
  };
  const getPathwayProgression = () => {
    fetch(`${ApiUrl}` + "get/pathway/progression", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: dcrypt(params.id),
        slug: params.slug,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setPathWayProgression(response.data.records);
          setCustomerConversion(response.data.module_calculation);
          setCurrentModule(response.data.current_module);
          setPathWayProgressionData(response.data);
          setLoading(true);

        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          setLoading(true);
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        setLoading(true);
      });
  };
  const getModules = () => {
    fetch(`${ApiUrl}` + "get/assessment/modules", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: dcrypt(params.id),
        slug: props.match.params.slug,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setSideBar(response.data.records);
          setRecords(response.data);
          setDafaultSummary(response.data.report);
          findSymbol(response.data.assessment.currency_code);
          setLoading(true);

          setChartData({
            chartData: {
              chart: {
                type: "column",
              },
              title: {
                text: "1 YEAR IMPACTS",
              },
              xAxis: {
                categories: ["Profit", "Revenue"],
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "",
                },
              },

              series: [
                {
                  name: "Current",
                  data: [
                    JSON.parse(response.data.report[4].amount),
                    JSON.parse(response.data.report[1].amount),
                  ],
                },
                {
                  name: "1 Year",
                  data: [
                    JSON.parse(response.data.report[5].amount),
                    JSON.parse(response.data.report[3].amount),
                  ],
                },
              ],
            },
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          setLoading(true);
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        setLoading(true);
      });
  };
  // const defaultSummary = async () => {
  //   getModules();
  // };

  const getAssessment = () => {
    setLoading2(false);
    setLoading(false);
    fetch(`${ApiUrl}` + "get/assessment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: dcrypt(params.id),
        slug: props.match.params.slug,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          // setSideBar(response.data.records);
          //console.log(response.data.assessment.module, "setAssessment");

          if (response.data.assessment.other_settings.welcome_accepted == 0) {
            setConfirmModal(true);
          }
          setAssessment(response.data);
          setLoading2(true);
          setLoading(true);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          setLoading2(true);
          setLoading(true);

        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        setLoading2(true);
        setLoading(true);

      });
  };
  const getAssessmentList = () => {
    fetch(`${ApiUrl}` + "assessments", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: 2,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          console.log(response.data.records, 'recordsss')
          setAssessmentList(response.data.records);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getResults = () => {
    fetch(`${ApiUrl}` + "get/assessments/results", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: dcrypt(params.id),
        slug: props.match.params.slug,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          // setSideBar(response.data.records);
          setResults(response.data);
          setReport(response.data.report);
          setLoading(true);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          setLoading(true);
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        setLoading(true);
      });
  };


  const welcomeAccepted = () => {
    fetch(`${ApiUrl}` + "assessments/accepted", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: dcrypt(params.id),
        slug: props.match.params.slug,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setConfirmModal(false);
          props.history.push(`/assessment/${params.id}/revenue_statement`);
          setLoading(true);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          setLoading(true);
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        setLoading(true);
      });
  }
  const data = {
    columns: [
      ["Current", 150, 80, 70, 152, 250, 95],
      ["Years", 200, 130, 90, 240, 130, 220],
    ],
    type: "bar",
    colors: { Current: "#4499d7", Years: "#408f4d" },
  };

  const [options, setChartData] = React.useState({
    chartData: {
      chart: {
        type: "column",
      },
      title: {
        text: "1 YEAR IMPACTS",
      },
      xAxis: {
        categories: ["Profit", "Revenue", "Valuation"],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      series: [
        {
          name: "Current",
          data: [0.0, 0.0, 0.0],
        },
        {
          name: "1 Year",
          data: [0.0, 0.0, 0.0],
        },
      ],
    },
  });

  function NumberWithCommas(x) {
    let y = Math.trunc(x);
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  return (
    <React.Fragment>

      <Row className="mb-4">
        <Col lg={1}></Col>

        <Col lg={siderbarShow ? '9' : '10'}>
          <Row className="mb-1">
            <Col lg="3">
              {
                uInfo().type != 'Client' && (
                  <section className="breakthrough d-flex border-radius-0 justify-content-start m-0">
                    <div className="MonthlyDropDown p-tracker mb-1">
                      <select value={dcrypt(params.id)} onChange={(e) => {
                        if (e.target.value != '') {
                          props.history.push(`/profit/center/${bcrypt(e.target.value)}/${params.slug}`)
                        }
                      }
                      } className="form-select">
                        <option value={''}>Client ID</option>
                        {assesment_list.length > 0 &&
                          assesment_list.map((row, i) => {
                            return (
                              <option value={row.assessment_id}>{row.client_id + ' # ' + row.client_name}</option>
                            )
                          }
                          )
                        }
                      </select>
                    </div>
                  </section>
                )
              }
            </Col>
            <Col lg="9">
              <section className="breakthrough d-flex border-radius-0 justify-content-end m-0">
                <h5 className="mb-0 mr-3 text-transform-capitalize">{records.assessment && records.assessment.name}'s Total Impact:</h5>
                <h3 title="Current profit" className="mb-0 color-success mr-30">
                  <span className="currencyIcon">{currency} </span>
                  {dafault_summary.length > 0 && (
                    NumberWithCommas(dafault_summary[3].amount)
                  )}
                </h3>
              </section>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <section className="top-section Assessments card card-body p-0">
                <Row>
                  <Col xs="12">
                    <div className="SectionHeader">
                      {dafault_summary.length > 0 &&
                        <div className="Percents">
                          <h4 className="GrayBG" style={{ background: '#041934', width: `${dafault_summary[1].amount > 0 ? '30%' : '10%'}` }}>
                            <span className="pull-left">

                              <p title="Current revenue">
                                <span className="currencyIcon">{currency}</span>
                                {NumberWithCommas(dafault_summary[1].amount)}
                              </p>
                            </span>
                          </h4>
                          <h4 title="Annual gross revenue" className="GreenBG text-end" style={{ background: '#008037', width: `${dafault_summary[5].amount > 0 ? '70%' : '10%'}` }}>
                            <span className="currencyIcon">{currency} </span>

                            {NumberWithCommas(dafault_summary[5].amount)}

                          </h4>
                          <div className="TotalPercent">
                            <h4 title="Profit impact" className="text-end">

                              <span className="percentageSign pull-right"> <i className="fa fa-arrow-circle-up"></i>
                                {dafault_summary[0].percentage}%</span>

                            </h4>
                          </div>
                        </div>
                      }

                    </div>
                  </Col>


                </Row>
                {loading &&
                  <Summary
                    currency={currency}
                    slug={props.match.params.slug}
                    questions={path_way_progression}
                    assessment={dcrypt(params.id)}
                    module={assessment.current_module}
                    history={props.history}
                    profitImpact={dafault_summary.length > 0 ? NumberWithCommas(dafault_summary[0].amount) : 0}
                    currentProfit={dafault_summary.length > 0 ? Number(dafault_summary[2].amount) : 0}
                    currentRevenue={dafault_summary.length > 0 ? Number(dafault_summary[1].amount) : 0}
                    ExpectedIncrease={dafault_summary.length > 0 ? Number(dafault_summary[3].amount) : 0}
                    AnnualProfit={dafault_summary.length > 0 ? Number(dafault_summary[4].amount) : 0}
                    AnnualGrossRevenue={dafault_summary.length > 0 ? Number(dafault_summary[5].amount) : 0}
                    YearProfitImpact={dafault_summary.length > 0 ? Number(dafault_summary[6].amount) : 0}
                  />

                }
              </section>

              <Loader loaded={loading}></Loader>
              <section className="mt-3 SummaryAssessment">
                <Row>
                  {
                    props.match.params.slug == "implementation" || props.match.params.slug == 'summary' && (
                      loading &&
                      props.match.params.slug == "implementation" || props.match.params.slug == 'summary' &&
                      !props.match.params.result && (
                        <Col xs="12">
                          <div className="">
                            <Implementation
                              slug={props.match.params.slug}
                              assessment={dcrypt(params.id)}
                              module={assessment.current_module}
                              name={records.assessment ? records.assessment.name : ''}
                            />
                          </div>
                        </Col>
                      ))
                  }
                  <Col xs="12">
                    <div className={`${props.match.params.slug == "reports" ? 'card card-body' : 'survey'}`}>
                      {results.datam && props.match.params.result && (
                        <Results
                          currency={currency}
                          slug={props.match.params.slug}
                          results={results.datam}
                          assessment={dcrypt(params.id)}
                          module={assessment.current_module}
                        />
                      )}

                      {
                        dafault_summary.length > 0 && assessment.assessment && loading && (
                          <Roi
                            slug={props.match.params.slug}
                            currency={currency}
                            assessment={dcrypt(params.id)}
                            module={assessment.current_module}
                            investment={assessment.assessment.other_settings}
                            getAssessment={getAssessment}
                            questions={assessment.reports}
                            currentProfit={dafault_summary[2].amount}
                            currentRevenue={dafault_summary[1].amount}
                            profitImpact={dafault_summary[0].amount}
                            ExpectedIncrease={dafault_summary[3].amount}
                            AnnualProfit={dafault_summary[4].amount}
                            AnnualGrossRevenue={dafault_summary[5].amount}
                            YearProfitImpact={dafault_summary[6].amount}
                            currentRevenuePercentage={dafault_summary[1].percentage}
                            currentProfitPercentage={dafault_summary[4].percentage}
                            currentNetProfitPercentage={dafault_summary[8].percentage}
                            currentNetProfit={dafault_summary[7].amount}
                            AnnualNetProfit={dafault_summary[8].amount}
                            ExpectedIncreaseProfit={dafault_summary[4].amount - dafault_summary[2].amount}
                            ExpectedIncreaseNetProfit={dafault_summary[8].amount - dafault_summary[7].amount}
                            pathWayProgression={path_way_progression}
                            pathWayProgressionCalculation={PathwayProgressionData}
                            customerConversion={customer_conversion}
                          />
                        )}
                    </div>
                  </Col>
                </Row>
              </section>
            </Col>
          </Row>
          <Row></Row>
        </Col>
        <Col lg={siderbarShow ? '2' : '1'} className="assessment">
          <button className={!siderbarShow ? 'border-0 fixed-faveicon' : 'd-none'} onClick={() => setSideBarShow(!siderbarShow)} >
            <img src={Favicon} width={40} />
          </button>
          <div className={siderbarShow ? 'assessment-vertical-menu' : 'd-none'}>
            <div data-simplebar className={'h-100'}>
              <i onClick={() => setSideBarShow(!siderbarShow)} className="far fa-window-close sidebar-close"></i>
              <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
                <div id="sidebar-menu">
                  <ul className="metismenu list-unstyled" id="side-menu">
                    <li className="all-item sub-nav-bg p-3 text-center mb-1" key={"s-0"}> <h5>Impact Strategies</h5></li>
                    {sidebar.map((row, i) => {
                      return (
                        <li className={'all-item '} key={"s-" + i}>
                          {row.has_child == 0 ? (
                            <Link
                              to={`/assessment/${params.id}/${row.key}`}
                              className={`waves-effect dropdown-btn i_${i}`}
                              onClick={() => {
                                $(".dropdown-btn").removeClass("active");
                                $(".all-item").removeClass("active");
                                $(".dropdown-container").removeClass("d-block");
                                $(".i_" + i).addClass("active");
                              }}
                            >
                              <span>{row.label}</span>
                            </Link>
                          ) : row.has_child == 1 ? (
                            <>
                              <button
                                className={`dropdown-btn i_${i}`}
                                onClick={() => {
                                  if ($('.i_' + i).hasClass('active')) {
                                    $(".dropdown-btn").removeClass("active");
                                    $(".dropdown-container").removeClass(
                                      "d-block"
                                    );
                                  } else {
                                    $(".dropdown-btn").removeClass("active");
                                    $(".dropdown-container").removeClass(
                                      "d-block"
                                    );
                                    $(".i_" + i).addClass("active");
                                    $(".k_" + i).addClass("d-block");
                                  }
                                }}
                              >
                                {row.label}
                                {/* <i className="fa fa-caret-down"></i> */}
                              </button>
                              <div className={`dropdown-container k_` + i}>
                                {row.child &&
                                  row.child.map((c, k) => {
                                    if (c.has_child == 1) {
                                      return (
                                        <div key={k}>
                                          <button
                                            className={`dropdown-btn i_${i}`}
                                            onClick={() => {
                                              $(".dropdown-btn").removeClass("active");
                                              $(".dropdown-container").removeClass(
                                                "d-block"
                                              );
                                              $(".i_" + i).addClass("active");
                                              $(".k_" + i).addClass("d-block");
                                            }}
                                          >
                                            {c.label}
                                            {/* <i className="fa fa-caret-down"></i> */}
                                          </button>
                                          <div className={`sub-child dropdown-container k_` + i}>
                                            {c.child &&
                                              c.child.map((s, k) => {
                                                return (
                                                  <Link
                                                    key={i + "---" + k}
                                                    to={`/assessment/${params.id}/${s.key}`}
                                                    className="waves-effect sub-child-a"
                                                  >
                                                    <span>{s.label}</span>
                                                  </Link>
                                                );
                                              })}
                                          </div>
                                        </div>
                                      )
                                    } else {
                                      return (
                                        <Link
                                          key={i + "---" + k}
                                          to={`/assessment/${params.id}/${c.key}`}
                                          className={`waves-effect ${c.fill == 1 ? 'survey-filled' : ''}`}
                                        >
                                          <span>{c.label}</span>
                                        </Link>
                                      );
                                    }
                                  })}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </SimpleBar>
            </div>
          </div>
        </Col>

        <Modal
          isOpen={confirmModal}
          fade={true}
          className="confirm"
          backdrop={'static'}
          size={'lg'}
          centered={true}
        >
          <div className="confirm-title-header">
            <h3 className="title btn-primary btn w-50">Business Fundamentals</h3>
          </div>
          <div className="alert text-center">
            <h5>Welcome (<b>{uInfo().name}</b>) to your Pathway to Profit!</h5>
            <p>To get started, please first begin by inputting your company's business fundamentals so your coach has
              the information necessary to begin selecting key impact strategies.
            </p>
            <br />
            <p>Have your information ready to quickly input past revenue numbers, details about your typical
              customer, business valuation, and strategic outlook for your future. This onboarding phase only takes a

              few minutes and when you're finished, your coach will begin working with you on building out your
              detailed Pathway to Profit with specific and actionable strategies based on your real financials.</p>
            <br />
            <h6>Ready to get started?</h6>
            <button onClick={() => welcomeAccepted()} className="btn btn-success mt-3 w-50">Start</button>

          </div>

        </Modal>
      </Row>
    </React.Fragment>
  );
};

export default Assesments;
